import React    from 'react';
import cx       from 'classnames';
import Hint     from '../Hint';

export default class CalculatorStep3 extends React.Component {
    constructor(props) {
        super(props);

        let loaders = props.tariffs ? this.preparationData(props) : [];

        this.state = {
			loaders,
            stevedores: props.data.stevedores,
            advanceService: props.data.advanceService,
            error: props.errors && props.errors.step === 3 ? props.errors : null
        };
    }

    componentWillReceiveProps(newProps){
        if (newProps.errors && newProps.errors.step === 3) this.setState({error: newProps.errors});

        let loaders;
        if(newProps.tariffs) loaders = this.preparationData(newProps);

        this.setState({ loaders, stevedores: newProps.data.stevedores});
    }

    preparationData = (props) => {

        let tariff, { lang } = props;

        props.tariffs.map((item) => {
            if(props.data.tariff.value === item.identifier) tariff = item;
        });

        let _priceLifting, _priceAssembly;

        tariff.services_min_price.map((service) => {
            if(service.services.length == 1) {
                if(service.services[0] == 'lifting') _priceLifting = service.min_hour_price;
                if(service.services[0] == 'assembly') _priceAssembly = service.min_hour_price;
            }
        });


        let loaders = tariff.loaders.map((item) => {
            let price = tariff.loaders_min_price * item;
            let priceLifting = item ? _priceLifting : '-';
            let priceAssembly = item ? _priceAssembly : '-';
            let priceHours = item ? tariff.loaders_min_hour_price * item : '-';
            let priceWaiting = item ? 300 * item : '-';

            // loaders_min_hour_price - доп час
            // Спуск подъем

            return {
                key: 'loaders-' + item,																	// Ключ для перебора
                value: item,																			// Число грузчиков
                title: item ? (item == 1 ? item + lang.Calculator_1_mover : item + lang.Calculator_2_movers) : lang.Calculator_no_movers,	// Название блока
                price: price ? price : lang.Calculator_free,														// Цена
                avalible: tariff.available_count, 														// Экипажей онлайн
                minHours: tariff.min_hours, 															// Мин число часов аренды
                priceLifting,																			// Спкуск / Подъем
                priceAssembly,																			// Сборка / разборка
                priceHours, 																			// Стоимость доп часа работы																				// Сборка / разборка
                priceWaiting, 																			// Ожидание																				// Сборка / разборка
            }
        });

        return loaders;
    };

    setLoaders = async (value, price) => {
        this.setState({
            stevedores: {
                value: value,
                advanceInfo: {
                    price
                }
            }
        }, async () => {
            await this.props.updateData({
                stevedores: {
                    value: value,
                    advanceInfo: { price }
                }
            }, 'stevedores');
        });


    };

    // Сборка разборка
    setAdvansedServiceAssembly = () => {
        this.setState({
            advanceService: {
                assembly: this.state.advanceService.assembly ? 0 : 1,
                lifting: this.state.advanceService.lifting
            }
        }, async () => {
            await this.props.updateData({ advanceService: this.state.advanceService }, 'advanceService');
        });
    };

    // Спуск подъем
    setAdvansedServiceLifting = () => {
        this.setState({
            advanceService: {
                assembly: this.state.advanceService.assembly,
                lifting: this.state.advanceService.lifting ? 0 : 1
            }
        }, async () => {
            await this.props.updateData({ advanceService: this.state.advanceService }, 'advanceService');
        });
    };

    renderLoaders = (item, index) => {
        const { lang } = this.props;
    	let advancedInfo = (item) => {
            return (
                <div className="check-cnt">
                    <div className="porter-info">
                        <p className="desc">{lang.Calculator_heavier_than_60_kg}</p>
                        <div className="check-info">
							<span id="item1" className="check-time">
                                <i className="icon"/>
								{lang.CalcStep3_check_time_1} <span>30 {lang.CalcStep3_check_time_2}</span> <br/> <span>{item.avalible} {lang.CalcStep3_check_time_3}</span> {lang.CalcStep3_check_time_4}
							</span>
                            <span id={"calculatorLoadersPriceBig" + index} className="check-price">{item.price} <span className="als-rubl">₽</span><span className="count">/{item.minHours}{lang.CalcStep3_count}</span></span>
                        </div>
                    </div>
                    <div className="porter-price">
                        <ul className="list-unstyled price-list">
                            <li>
                                <span className="title">{lang.Calculator_add_hour_work}</span>
                                <span id={"calculatorLoadersPriceList" + index + "_0"} className="price">{item.priceHours} <span className="als-rubl">₽</span></span>
                            </li>
                            <li>
                                <span className="title">{lang.Calculator_loaders_waiting}</span>
                                <span id={"calculatorLoadersPriceList" + index + "_1"} className="price">{item.priceWaiting} <span className="als-rubl">₽</span></span>
                            </li>
                            <li>
                                <span className="title">{lang.Calculator_assembly}</span>
                                <span id={"calculatorLoadersPriceList" + index + "_2"} className="price">+ {item.priceAssembly} <span className="als-rubl">₽</span></span>
                            </li>
                            <li>
                                <span className="title">{lang.Calculator_lifting}</span>
                                <span id={"calculatorLoadersPriceList" + index + "_3"} className="price">+ {item.priceLifting} <span className="als-rubl">₽</span></span>
                            </li>
                        </ul>
                    </div>
                </div>
            );
        };

        let itemClass = cx({
            'check-item': true,
            active: item.value === this.state.stevedores.value
        });

        let price = item.price == lang.Calculator_free ? (
            <span id={"calculatorLoadersPrice" + index} className="price">
                <b>{item.price}</b>
            </span>) : (
            <span id={"calculatorLoadersPrice" + index} className="price">
                <b>{item.price} <span className="als-rubl">₽</span></b><span className="count">/{item.minHours}{lang.CalcStep3_count}</span>
            </span>
        );

    	return (
            <div className={itemClass} key={item.key}>
                <div className="check-head" onClick={this.setLoaders.bind(this, item.value, item.price)}>
                    <span id={"calculatorLoadersCheck" + index} className="check" />
                    <div className="info">
                        <span className="type">{item.title} {/*<i className="icon"></i>*/}</span>
                        <span id={"calculatorLoadersTime" + index} className="time"><i className="icon"/> 30 {lang.CalcStep3_time}</span>

                        { price }
                    </div>
                </div>

				{item.key !== 'loaders-0' ? advancedInfo(item) : null}
            </div>
		);
	};

    render() {
        const { lang, isActive, data, calculated } = this.props;

        const itemCSS = cx({
            'calculator-content': true,
            'step3': true,
            'active': isActive,
        });

        const cargoServise = () => {
            return (
                <div className="cargo-services">
                    <div className="cargo-services__label">
                        <label className="label">{lang.Calculator_add_services}</label>
                        {/* <i className="icon"></i> */}
                    </div>

                    <div className="cargo-wrap t-services">
                        <div id="calculatorLoadersMounting" className="cargo-service" onClick={this.setAdvansedServiceAssembly}>
                            <input checked={!!this.state.advanceService.assembly} type="checkbox" name="cargoService" value="1"/>
                            <label className="cargo-service__title">{lang.Calculator_assembly}</label>
                            <div className="cargo-service__desc">{lang.Calculator_assembly_desc}</div>
                            <div className="cargo-service__info">
                                <span id="calculatorLoadersMountingPrice" className="price"><b>0</b> <span className="als-rubl">₽</span><span className="count">/1{lang.CalcStep3_count}</span></span>
                                <i id="calculatorLoadersMountingCheck" className="icon" />
                            </div>
                        </div>
                        <div id="calculatorLoadersMovingUp" className="cargo-service" onClick={this.setAdvansedServiceLifting}>
                            <input checked={!!this.state.advanceService.lifting} type="checkbox" name="cargoService" value="2"/>
                            <label className="cargo-service__title">{lang.Calculator_lifting}</label>
                            <div className="cargo-service__desc">{lang.Calculator_lifting_desc}</div>
                            <div className="cargo-service__info">
                                <span id="calculatorLoadersMovingUpPrice" className="price"><b>100</b> <span className="als-rubl">₽</span><span className="count">/1{lang.CalcStep3_count}</span></span>
                                <i id="calculatorLoadersMovingUpCheck" className="icon"/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        };

        return (
            <div className={itemCSS} >
                <h2 className="title">{lang.Calculator_add_mover}</h2>

				<div className="check-wrap">
					{this.state.loaders.map(this.renderLoaders)}
				</div>

                {data.stevedores.value ? cargoServise() : null}

                <Hint idWrapper="calculatorLoadersHint" idClose="calculatorLoadersHintClose" deleteError={this.props.deleteError} title={lang.Calculator_specify} desc={lang.Calculator_insured} type="info" />
            </div>
        );
    }
}