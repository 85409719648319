import React                from 'react';
import CalculatorHeader     from './CalculatorHeader';
import CalculatorSideBar    from './CalculatorSideBar';
import CalculatorInner      from './CalculatorInner';
import CalculatorOrder      from './CalculatorOrder';
import Calculator           from '../../API/calculator';
import { connect }          from 'react-redux';

class WidgetCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: props.calculator.errors ? props.calculator.errors.step : 1,
            isFinish: props.isFinish,
            errors: props.calculator ? props.calculator.errors : null,
        };
    }

    setActive = (val) => {
        this.setState({
            step: val
        });
    };

    componentWillReceiveProps(newProps) {
        if (newProps.isFinish) this.setState({isFinish: newProps.isFinish});
        if (newProps.calculator.errors) this.setState({step: newProps.calculator.errors.step});
        if (newProps.userInfo.errors && newProps.userInfo.errors !== this.props.userInfo.errors) {
            if(newProps.userInfo.errors === 401) {
                this.props.calculatorUpdate({
                    errors: {
                        step: 5,
                        information: {
                            text: 'Неверный логин или пароль',
                            field: ['userphone', 'inp-pass']
                        }
                    }
                });
            }

            if(newProps.userInfo.errors === 404) {
                this.props.calculatorUpdate({
                    errors: {
                        step: 5,
                        information: {
                            text: 'Такого аккаунта не существует',
                            field: ['userphone', 'inp-pass']
                        }
                    }
                });
            }
        }

        if (this.props.userInfo.errors && newProps.userInfo.errors !== this.props.userInfo.errors) {
            this.props.calculatorUpdate({errors: null});
        }
    }

    deleteError = () => {
        this.props.calculatorUpdate({ errors: null });
    };

    updateData = async (newData, type) => {
        let _data = this.props.data;
        let data;
        let selectedGroupLengthValue = _data.selectedGroupLengthValue;
        let selectedGroupLength = _data.selectedGroupLength;

        switch (type) {
            case 'adress':
                _data.points = newData.points;
                data = Object.assign({}, _data);

                let info = await Calculator.prepareData({
                    latitude: data.points[0].adress.latitude,
                    longitude: data.points[0].adress.longitude,
                    userData: data
                });

                this.props.setInfo(info);
                this.props.setData(data);
                this.deleteError();

                break;
            case 'goods':
                _data.goods = newData.goods;
                data = Object.assign({}, _data);
                this.props.setData(data);

                break;
            case 'goodsServices':
                _data.goodsServices = newData.goodsServices;
                data = Object.assign({}, _data);
                this.props.setData(data);

                break;
            case 'advanceService':
                _data.advanceService = newData.advanceService;
                data = Object.assign({}, _data);

                info = await Calculator.prepareData({
                    latitude: data.points[0].adress.latitude,
                    longitude: data.points[0].adress.longitude,
                    userData: data
                });

                this.props.setInfo(info);
                this.props.setData(data);

                break;
            case 'selectedGroupLengthValue':
                selectedGroupLengthValue = newData.selectedGroupLengthValue;
                selectedGroupLength = newData.selectedGroupLength;

                this.props.setGroupLength({
                    selectedGroupLength,
                    selectedGroupLengthValue
                });

                break;
            case 'tariff':
                _data.tariff = newData.tariff;
                let tariff;

                this.props.tariffs.map((item) => {
                    // Выбираем из тиров тариф
                    if(this.props.data.tariff.value === item.identifier) tariff = item;
                });
                // Пересчитываем стоимость грузчиков
                _data.stevedores.advanceInfo.price = tariff.loaders_min_price * this.props.data.stevedores.value;

                data = Object.assign({}, _data);

                info = await Calculator.prepareData({
                    latitude: data.points[0].adress.latitude,
                    longitude: data.points[0].adress.longitude,
                    userData: data
                });

                this.props.setInfo(info);
                this.props.setData(data);

                break;
            case 'stevedores':
                _data.stevedores = newData.stevedores;
                data = Object.assign({}, _data);

                info = await Calculator.prepareData({
                    latitude: data.points[0].adress.latitude,
                    longitude: data.points[0].adress.longitude,
                    userData: data
                });

                this.props.setInfo(info);
                this.props.setData(data);

                break;
            case 'arrivalTime':
                _data.arrivalTime = newData.arrivalTime;
                data = Object.assign({}, _data);

                this.props.setData(data);

                break;
            case 'paymentType':
                _data.paymentType = newData.paymentType;
                data = Object.assign({}, _data);

                // Обнулить при смене способа оплаты с карты.
                if(newData.paymentType !== 'card') this.deleteError();

                this.props.setData(data);

                break;
            default:
                data = Object.assign({}, _data, newData);

                this.props.setData(function(state, props) {
                    let _data = props.data;
                    let data = Object.assign({}, _data, newData);

                    return { data }
                });
        }
    };

    searchStart = () => {
        const { userInfo, data } = this.props;

        if(userInfo.account.id) {
            if(data.paymentType !== 'card') {
                let errorFields = [];

                data.points.map((point, index) => {
                    if(point.defaultValue === true) {
                        errorFields.push('point-' + index);
                    }
                });

                if(errorFields.length) {
                    this.props.calculatorUpdate({
                        errors: {
                            step: 1,
                            information: {
                                text: '',
                                field: errorFields
                            }
                        }
                    });

                    return;
                }

                this.props.saveDraft(data);
                this.props.createDraft();
            } else {
                if(userInfo.cards.length) {
                	for (let i = 0; i < userInfo.cards.length; i++) {
						if (userInfo.cards[i].active === true) {
                            this.props.saveDraft(data);
                            this.props.createDraft();
						} else {
                            this.props.calculatorUpdate({
                                errors: {
                                    step: 5,
                                    information: {
                                        text: 'У вас нет активной карты',
                                        field: []
                                    }
                                }
                            });
						}
					}
                } else {
                    // Кинуть добавить карту

                    this.props.calculatorUpdate({
                        errors: {
                            step: 5,
                            information: {
                                text: 'Для того, что бы выбрать данный способ оплаты, вам необходимо указать данные своей карты',
                                field: []
                            }
                        }
                    });
                }
            }
        } else {
            // Кинуть не авторизован

            this.props.calculatorUpdate({
                errors: {
                    step: 5,
                    information: {
                        text: 'Пройдите авторизацию, чтобы продолжить',
                        field: 'userphone'
                    }
                }
            });
        }

    };

    render() {
        const { step, isFinish } = this.state;
        const { lang, data, info, calculated, curDraft, driver, phone, calculator, calcInfoOpen, draftId, mask } = this.props;

        let orderMode = '';
        if(draftId) {
            if(data.goodsServices && data.goodsServices.storage && data.goodsServices.storage.active) {
                orderMode = 'with_storage';
            } else {
                if(data.arrivalTime)
                    orderMode = 'pending';
                else
                    orderMode = 'urgent';
            }
        }

        let moverCalculator = () => {
            return (
                <div className='row'>
                    <CalculatorSideBar
                        step={step}
                        lang={lang}
                        setActive={this.setActive}
                        data={data}
                        calculated={calculated}
                        info={info}
                        searchStart={this.searchStart}

                    />
                    <CalculatorInner
                        getUserPosition={this.props.getUserPosition}
                        errors={calculator.errors}
                        step={step}
                        lang={lang}
                        updateData={this.updateData.bind(this)}
                        data={data}
                        calculated={calculated}
                        info={info}
                        userInfo={this.props.userInfo}
						changePhone={this.props.changePhone}
                        sendAuthCode={this.props.sendAuthCode}
                        deleteError={this.deleteError}

                        getUserInfo={this.props.getUserInfo}
                        getUserCards={this.props.getUserCards}
                        getUserBalance={this.props.getUserBalance}
                        getUserPromoCode={this.props.getUserPromoCode}
                        getUserInfoFull={this.props.getUserInfoFull}
                        logout={this.props.logout}
                        login={this.props.login}
                        deleteCard={this.props.deleteCard}
                        setActiveCard={this.props.setActiveCard}
                        savePromoCode={this.props.savePromoCode}
                    />
                </div>
            )
        };

        return (
            <div id="calculatorWrapper" className="сalculator-wrapper">
                { mask ? (<div className="mask"/>) : null }
                <CalculatorHeader
                    isFinish={isFinish}
                    lang={lang}
                    data={data}
                    draftId={curDraft ? curDraft.draftId : null}
                    searchStop={this.props.searchStop}
                    closeModal={this.props.closeModalRefresh}
                    updateData={this.updateData.bind(this)}
                />

                { curDraft && curDraft.draftId ? <CalculatorOrder
                    lang={lang}
                    isFinish={isFinish}
                    draftId={curDraft.draftId}
                    identifier={data.tariff.value}
                    agreeOrder={this.props.agreeOrder}
                    cancelOrder={this.props.cancelOrder}
                    searchStart={this.props.searchStart}
                    mode={orderMode}
                    phone={phone ? phone : 'Ваш номер уже есть в системе'}
                    driver={driver}/> :

                isFinish ? <CalculatorOrder
                    lang={lang}
                    identifier={curDraft.tariff.value}
                    isFinish={isFinish}
                    draftId={curDraft.draftId}
                    agreeOrder={this.props.agreeOrder}
                    cancelOrder={this.props.cancelOrder}
                    searchStart={this.props.searchStart}
                    mode={orderMode}
                    phone={phone ? phone : 'Ваш номер уже есть в системе'}
                    driver={driver}/> : moverCalculator()}

				{/*{calcInfoOpen ? (*/}
				{/*	<div className="calculator-info">*/}
				{/*		<div className="inner-info">*/}
				{/*			<p className="title-info">Ваш заказ #{ draftId} принят!</p>*/}
				{/*			<p className="desc-info">Желаем приятных перевозок!</p>*/}
                {/*            {data.goodsServices.storage.active ? (*/}
                {/*                <div className="cargo-storage-info">С вами свяжутся через 2 часа</div>*/}
                {/*            ) : null}*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*) : null}*/}

            </div>
        );
    }
}

const mapState = state => ({
    lang: state.lang,
    userInfo: state.user,
    calculator: state.calculator,
});

const mapDispatch = ({
                         user: { getUserInfo, getUserCards, getUserBalance, getUserPromoCode, getUserInfoFull, logout, login, deleteCard, setActiveCard, savePromoCode },
                         calculator: { update },
                         drafts: { saveDraft },
                     }) => ({
    getUserInfo:        () => getUserInfo(),
    getUserCards:       () => getUserCards(),
    getUserBalance:     () => getUserBalance(),
    getUserPromoCode:   () => getUserPromoCode(),
    getUserInfoFull:    () => getUserInfoFull(),
    logout:             () => logout(),
    login:              (username, password) => login({username, password}),
    deleteCard:         (cardId) => deleteCard(cardId),
    setActiveCard:      (cardId) => setActiveCard(cardId),
    savePromoCode:      (promoCode) => savePromoCode(promoCode),
    // Калькулятор
    calculatorUpdate:   (data) => update(data),
    saveDraft:          (curDraft) => saveDraft(curDraft)
});

export default connect(mapState, mapDispatch, null, { pure: false })(WidgetCalculator);
