import React from 'react';

export default class DropFloor extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			floor: props.floor || '',
            lift: props.lift || 0,
		};
	}

    setLiftInfo = ({ target = {} }) => {
    	const { id } = target;
        const { lift } = this.state;
        let newLiftValue;

    	switch (id) {
            case 'dropCheck0':
                newLiftValue = 0;

                break;
            case 'dropCheck1':
                newLiftValue = 1;

            	break;
            case 'dropCheck2':
                newLiftValue = 2;

            	break;
            case 'dropCheck3':
                newLiftValue = 3;

            	break;
		}

        this.setState({ lift: newLiftValue }, async () => {
            await this.props.setAdressInfo({ lift: newLiftValue });
        });

	};

	setFloor = ({ target = {} }) => {
        const { value = '' } = target;
        const { floor } = this.state;
        let floorVal = parseInt(value.replace(/\D/g, ''));
        let newFloor = isNaN(floorVal) ? 0 : floorVal;

        if(floor !== newFloor) {
            this.setState({ floor: newFloor }, async () => {
                await this.props.setAdressInfo({ floor: newFloor });
            });
		}
	};

    render() {
        let { lang, index } = this.props;
        return (
			<div className="drop-wrapper floor">
				<div id={"calculatorDropFloorWrapper" + index} className="drop-inner">
					<div className="drop-contacts row">
						<div className="col">
							<div className="user">
								<span className="title">Этаж</span>
								<span className="subtitle">Нужно для грузчиков</span>
							</div>
						</div>
					</div>
					<div className="drop-form">
						<div className="form-group">
							<input id={"calculatorDropFloorInput" + index} onChange={this.setFloor} value={this.state.floor} type="text" className="form-control" placeholder="Укажите этаж"/>
						</div>
						<div className="form-group">
							<div id={"calculatorDropFloorInput" + index + "_0"} className="form-radio">
								<input onChange={this.setLiftInfo} defaultChecked={this.state.lift == 0 ? 'checked' : ''} type="radio" name="dropCheck1" id="dropCheck0"/>
								<label htmlFor="dropCheck0" className="label"><div className="circle"></div>Лифт отсутствует</label>
							</div>
							<div id={"calculatorDropFloorInput" + index + "_1"} className="form-radio">
								<input onChange={this.setLiftInfo} defaultChecked={this.state.lift == 1 ? 'checked' : ''} type="radio" name="dropCheck1" id="dropCheck1"/>
								<label htmlFor="dropCheck1" className="label"><div className="circle"></div>Пассажирский лифт</label>
							</div>
							<div id={"calculatorDropFloorInput" + index + "_2"} className="form-radio">
								<input onChange={this.setLiftInfo} defaultChecked={this.state.lift == 2 ? 'checked' : ''} type="radio" name="dropCheck1" id="dropCheck2"/>
								<label htmlFor="dropCheck2" className="label"><div className="circle"></div>Грузовой лифт</label>
							</div>
							<div id={"calculatorDropFloorInput" + index + "_3"} className="form-radio">
								<input onChange={this.setLiftInfo} defaultChecked={this.state.lift == 3 ? 'checked' : ''} type="radio" name="dropCheck1" id="dropCheck3"/>
								<label htmlFor="dropCheck3" className="label"><div className="circle"></div>Оба типа лифтов</label>
							</div>
                            <div className="form-group">
                                <button type="button" id={"calculatorDropFloorBtn" + index} className="btn btn-primary" onClick={this.props.dropFloorCloseBtn}>Готово</button>
                            </div>
						</div>
					</div>
				</div>
			</div>
        );
    }

}