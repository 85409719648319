import React    from 'react';
import cx       from 'classnames';

export default class CalculatorSideBarStep5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentType: props.paymentType ? (props.paymentType === 'card' ? 'Картой' : (props.paymentType === 'bank' ? 'Со счета' : 'Наличными')) : 'Наличными'
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.paymentType !== this.props.paymentType) this.setState({paymentType: newProps.paymentType ? (newProps.paymentType === 'card' ? 'Картой' : (newProps.paymentType === 'bank' ? 'Со счета' : 'Наличными')) : 'Наличными'});
    }

    setActive = (val) => {
        this.props.setActive(5);
    };

    render() {
        const { lang, step } = this.props;

		let isActive = step === 5;

        const hasPayment = true;

        const itemCSS = cx({
            'Сalculator-sideBar-item': true,
            'item-5': true,
            'active': isActive,
            'empty': !hasPayment,
			'old': step > 5
        });

        const paymentEmpty = (
            <div className="payment-empty">
                <div className="row">
                    <div className="icon"/>
                    <div className="title-wrap">
                        <span>{lang.CalcSidebar_step5_empty_title}</span>
                    </div>
                </div>
            </div>
        );

        const paymentFull = (
            <div className="payment-full full">
                <div className="row">
                    <div className="icon"/>
                    <div className="title-wrap">
						<span className="prefix">{lang.CalcSidebar_step5_full_prefix}</span>
						<span id="calculatorSBPaymentType" className="type">{this.state.paymentType}</span>
                    </div>
                </div>
            </div>
        );

        return (
            <div id="calculatorSBItem5" className={itemCSS} onClick={this.setActive}>
                {hasPayment ? paymentFull : paymentEmpty}
            </div>
        );
    }
}