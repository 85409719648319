import fetch from 'isomorphic-unfetch';
import {SERVER} from '../config';
import {deleteCookie, headersDELETE, headersGET, headersPOST, headersPUT, setCookie} from '../utils/helpers';

async function getUserInfo (req) {
    const res = await fetch(SERVER + '/me.json', headersGET(req)).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

async function sendVisitor(visitorId) {
    // const searchParams = new URLSearchParams();
    // searchParams.append('visitorId', visitorId);
    //
    // return fetch(SERVER + '/intercom/visitors.json', headersPOST(searchParams)).then(r => {
    //     if (r.status !== 200)
    //         throw r;
    // });
}

async function getBalance(req) {
    const res = await fetch(SERVER + '/me/sum.json', headersGET(req)).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

async function getCards(req) {
    const res = await fetch(SERVER + '/me/cards.json', headersGET(req)).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

async function deleteCard (id) {
    const res = await fetch(SERVER + '/me/cards/' + id + '.json', headersDELETE()).then(res => res).catch((err) => err);
    const data = await res.json();

    if(data.success) return await getCards();
}

async function setActiveCard (id) {
    const res = await fetch(SERVER + '/me/cards/' + id + '/active.json', headersPUT()).then(res => res).catch((err) => err);
    const data = await res.json();

    if(data.success) return await getCards();
}

async function addCard () {
    const searchParams = new URLSearchParams();
    searchParams.append('provider', 'alfa_bank');

    const res = await fetch(SERVER + '/me/cards/request.json', headersPOST(searchParams)).then(res => res).catch((err) => err);
    const data = await res.json();

    if(data.success) return await getAddCardRequest(data.request_id);

}

async function getAddCardRequest (id) {
    const res = await fetch(SERVER + '/me/cards/request/' + id + '.json', headersGET()).then(res => res).catch((err) => err);
    const data = await res.json();

    if(data.success) {
        if(data.status === 'wait_open_page') {
            let token = data.access_token;

            window.open(SERVER + '/cards/request/' + token + '/start', '_blank', 'width=801,height=1200');
        }
    }

    return {}
}

async function getPromoCode(req) {
    const res = await fetch(SERVER + '/me/promo.json?include=entered', headersGET(req)).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

async function logout() {
    const res = await fetch(SERVER + '/logout/device.json', headersGET()).then(res => res).catch((err) => err);
    const data = await res.json();

    if(data.success) {
        deleteCookie('token');
        localStorage.removeItem('token');
    }

    return data;
}

async function login(params) {
    const searchParams = new URLSearchParams();
    searchParams.append('username', params.username);
    searchParams.append('password', params.password);

    const res = await fetch(SERVER + '/login/device.json', headersPOST(searchParams)).then(res => res).catch((err) => err);
    const data = await res.json();

    if(data.success)
        await setupToken();

    return data;
}

export async function setupToken() {
    let tokenData = await getToken();

    if(tokenData.token) {
        setCookie('token', tokenData.token, { expires: 3600 * 24 * 60, SameSite: 'None; Secure' });
        localStorage.setItem('token', tokenData.token);
        return tokenData.token;
    }
}

async function getToken() {
    const res = await fetch(SERVER + '/token', headersGET()).then(res => res).catch((err) => err);
    return await res.json();
}

async function resetPassword (phone) {
    phone = phone[0] == '+' ? phone.slice(1, phone.length) : phone;

    const res = await fetch(SERVER + '/login/restore/by/phone.json?phone=' + phone, headersPOST()).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

async function registration (phone) {
    const searchParams = new URLSearchParams();
    searchParams.append('phone', phone);

    const res = await fetch(SERVER + '/registration/phone.json', headersPOST(searchParams)).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

async function setPromoCode (code) {
    const searchParams = new URLSearchParams();
    searchParams.append('code', code);

    const res = await fetch(SERVER + '/me/promo.json', headersPOST(searchParams)).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}


async function applyPromo(promoCode) {
    const data = await setPromoCode(promoCode);
    const code = data.success ? await getPromoCode() : null;

    return Object.assign({}, data, code);
}

export async function sendInviteSMS (recipient) {
    const res = await fetch(SERVER + '/application/customer/all/download/sms.json?recipient=' + recipient, headersGET()).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

export default {
    // Постоянные методы
    getUserInfo: getUserInfo,
    getBalance: getBalance,
    getCards: getCards,
    getPromoCode: getPromoCode,
    setPromoCode: setPromoCode,
    login: login,
    logout: logout,
    resetPassword: resetPassword,
    registration: registration,
    deleteCard: deleteCard,
    setActiveCard: setActiveCard,
    addCard: addCard,
    getAddCardRequest: getAddCardRequest,
    sendInviteSMS: sendInviteSMS,
    getToken: getToken,
    sendVisitor: sendVisitor,

    // Необходимо удалить после рефакторинга
    applyPromo: applyPromo,

}