import React 					from 'react';
import cx 						from 'classnames';
import { IMaskInput } 			from 'react-imask';
import { stopEvent, ymElem } 	from '../utils/helpers';
import User 					from '../API/user';
import { trackEvent }                       from '../utils/tracking'

export default class StepsWork extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			flagSteps: 1,
            recipient: '',
            statusAjax: false,
			phoneLength: null
		}
	}

	setStepNav(val) {
		this.setState({
			flagSteps: val
		})
	};

	handleSubmit = async (e) => {
        stopEvent(e);

        trackEvent('sms_send')

        let phoneLength = this.state.phoneLength;

		if (phoneLength && phoneLength.length === 11) {
			let data = await User.sendInviteSMS(this.state.recipient);
			this.setState({ statusAjax: data.success });
		}
	};

	handleRecipientChange = (value) => {
		this.setState({recipient: value});

		if (value.length === 11) {
			this.setState({phoneLength: value});
		}else {
			this.setState({phoneLength: null});
		}
	};

    openCalculator = () => {
    	this.props.openCalc();
	};

    render() {

		const { lang } = this.props;

		const stepsImg1cx = cx({
			'index-steps-img': true,
			'active': this.state.flagSteps === 1
		});
		const stepsImg2cx = cx({
			'index-steps-img': true,
			'active': this.state.flagSteps === 2
		});
		const stepsImg3cx = cx({
			'index-steps-img': true,
			'active': this.state.flagSteps === 3
		});

		const stepsTab1Cx = cx({
			'active': this.state.flagSteps === 1
		});
		const stepsTab2Cx = cx({
			'active': this.state.flagSteps === 2
		});
		const stepsTab3Cx = cx({
			'active': this.state.flagSteps === 3
		});

		let disabled = !this.state.phoneLength;

        return (
			<div className="s-index-steps s-index">
				<div className="container">
					<h2 className="s-index__title">{lang.Index_steps_title}</h2>

					<div className="index-steps row">
						<div className="col-4">

							<div className="index-steps-img-wrap">
								<div className="bg-abs" />
								<div className={stepsImg1cx}>
									<img src="/static/img/index-example/step_1.gif" alt=""/>
								</div>
								<div className={stepsImg2cx}>
									<img src="/static/img/index-example/step_2.gif" alt=""/>
								</div>
								<div className={stepsImg3cx}>
									<img src="/static/img/index-example/step_3.gif" alt=""/>
								</div>
							</div>

						</div>

						<div className="col-8">
							<div className="index-steps-cnt">
								<ul className="list-unstyled clearfix index-steps-count-list">
									<li className={stepsTab1Cx} onClick={this.setStepNav.bind(this, 1)}>
										<span className="count">1.</span>
										<p className="desc" dangerouslySetInnerHTML={{__html: lang.Index_steps_count_desc_1}} />
									</li>
									<li className={stepsTab2Cx} onClick={this.setStepNav.bind(this, 2)}>
										<span className="count">2.</span>
										<p className="desc" dangerouslySetInnerHTML={{__html: lang.Index_steps_count_desc_2}} />
									</li>
									<li className={stepsTab3Cx} onClick={this.setStepNav.bind(this, 3)}>
										<span className="count">3.</span>
										<p className="desc" dangerouslySetInnerHTML={{__html: lang.Index_steps_count_desc_3}} />
									</li>
								</ul>

								<div className="index-steps-count-cnt">

									{ this.state.flagSteps === 1 ? (
										<div className="index-steps-count-item active">
											<h3 className="title">{lang.Index_steps_count_item_1_title}</h3>
											<p className="desc">{lang.Index_steps_count_item_1_desc}</p>
											<div className="index-steps-form">
												<p className="hint-info" dangerouslySetInnerHTML={{__html: lang.Index_steps_count_item_1_hint}} />

												{!this.state.statusAjax ? (
                                                    <form action="/" method="post" className="form row" onSubmit={this.handleSubmit}>
                                                        <div className="form-group col-8">
                                                            <IMaskInput
                                                                id='userphone'
                                                                tabIndex='0'
                                                                onKeyDown={this.handleKeyDown}
                                                                mask='+{7} (000) 000 00 00'
                                                                unmask={true}
                                                                className="form-control"
                                                                onAccept={
                                                                    // depending on prop above first argument is
                                                                    // `value` if `unmask=false`,
                                                                    // `unmaskedValue` if `unmask=true`,
                                                                    // `typedValue` if `unmask='typed'`
                                                                    (value, mask) => {
                                                                        this.handleRecipientChange(value)
                                                                    }
                                                                }
                                                                placeholder={lang.PhoneNumber1}
                                                                value={this.state.recipient || ''}
                                                            />

                                                        </div>
                                                        <div className="form-group col-4">
                                                            <button type="button" disabled={disabled} onClick={this.handleSubmit} className="btn btn-block btn-primary">{lang.SendTo}</button>
                                                        </div>
                                                    </form>
												) : (
													<div className="success"><i className="icon" />Сообщение отправлено</div>
												)}

											</div>
											<div className="index-steps-app">
												<ul className="list-unstyled clearfix index-start-app-list">
													<li><a href="https://goo.gl/Wl8y0C" target='_blank' className="google" onClick={() => ymElem('extLink', 'https://goo.gl/Wl8y0C')} /></li>
													<li><a href="https://apps.apple.com/ru/app/mover-грузовое-такси/id1080999192" target='_blank' className="apple" onClick={() => ymElem('extLink', 'https://apps.apple.com/ru/app/mover-грузовое-такси/id1080999192')} /></li>
                                                    <li><span onClick={() => trackEvent('calculate_price_3_steps', this.openCalculator)} className="mover" /></li>
												</ul>
											</div>
										</div>
									) : null}

									{ this.state.flagSteps === 3 ? (
										<div className="index-steps-count-item active">
											<h3 className="title">{lang.Index_steps_count_item_2_title}</h3>
											<p className="desc">{lang.Index_steps_count_item_2_desc}</p>
											<div className="type-list-wrapper row">
												<div className="col-5">
													<ul className="list-unstyled type-list">
														<li className="tooltip-pos"><i className="icon" />{lang.Index_example_1_list_1}</li>
													</ul>
												</div>
												<div className="col-7">
													<ul className="list-unstyled type-list">
														<li className="tooltip-pos"><i className="icon" />{lang.Index_example_1_list_4}</li>
													</ul>
												</div>
											</div>
										</div>
									) : null}

									{ this.state.flagSteps === 2 ? (
										<div className="index-steps-count-item active">
											<h3 className="title">{lang.Index_steps_count_item_3_title}</h3>
											<p className="desc">{lang.Index_steps_count_item_3_desc}</p>
											<ul className="list-unstyled payment-list row">
												<li className="col-6">
													<div className="icon">
														<div className="img-icon bank_card"/>
													</div>
													{lang.Index_steps_count_item_3_list_1}
												</li>
												<li className="col-6">
													<div className="icon">
														<div className="img-icon bank"/>
													</div>
													{lang.Index_steps_count_item_3_list_2}
												</li>
												<li className="col-6">
													<div className="icon">
														<div className="img-icon cash"/>
													</div>
													{lang.Index_steps_count_item_3_list_3}
												</li>
												<li className="col-6">
													<div className="icon">
														<div className="img-icon promocode"/>
													</div>
													{lang.Index_steps_count_item_3_list_4}
												</li>
											</ul>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        );
    }
}