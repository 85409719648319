'use strict';

export default {
    state: {
        uid: 1,
        notifications: []
    },
    reducers: {
        add: (state, payload) => {
            let notification = {
                title: payload.title || 'Новое уведомление',
                message: payload.message || '',
                level: payload.level || 'info',
                dismissible: true,
                uid: state.uid++
            };

            if(payload.autoDismiss) notification.autoDismiss = payload.autoDismiss;
            if(payload.action) notification.action = payload.action;

            let { notifications } = state;
            notifications.push(notification);

            // @todo включить после релиза
            // return Object.assign({}, state, { notifications })

            return state;
        },
        remove: (state, payload) => {
            let { notifications } = state;

            notifications.map((item, i) => {
                if (item.uid === payload) notifications.splice(i, 1);
            });

            return Object.assign({}, state, { notifications })
        }
    }
}