import React 		from 'react';

export default class Reviews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reviews: [
                {
                    title: 'Алина Репина',
                    position: 'Модель',
                    desc: 'Если еще когда-нибудь столкнусь с переездом (тьфу-тьфу), сразу вызову вас!',
                    img: '/static/img/reviews/woman/1.jpg'
                },
                {
                    title: 'Ирина Царенко',
                    position: 'Частный предприниматель',
                    desc: 'Наладила доставку заказов через вас и больше не парюсь о логистике :)',
                    img: '/static/img/reviews/woman/2.jpg'
                },
                {
                    title: 'Екатерина Ольховская',
                    position: 'Врач-терапевт',
                    desc: 'Сервис понравился - и точка. Просто большое человеческое спасибо!',
                    img: '/static/img/reviews/woman/3.jpg'
                },
                {
                    title: 'Владимир Пирогов',
                    position: 'Юрист по семейному праву',
                    desc: 'Грамотно организованная работа и простота в использовании.  Обращаюсь уже второй раз и остаюсь доволен',
                    img: '/static/img/reviews/man/1.jpg'
                },
                {
                    title: 'Василий Лановой',
                    position: 'Менеджер по продажам - Мебельный салон',
                    desc: 'Выручили с доставкой клиенту, когда у «проверенного контакта» заглохла машина. Спасибо!',
                    img: '/static/img/reviews/man/2.jpg'
                },
                {
                    title: 'Елена Ефимова',
                    position: 'Заместитель руководителя - Строительная компания',
                    desc: 'Удобно заказать услугу и больше не заботиться о доставке. Отработали быстро и четко, так что я осталась довольна, что обратилась.',
                    img: '/static/img/reviews/woman/4.jpg'
                },
                {
                    title: 'Ольга Пономарёва',
                    position: 'PR менеджер - Салон мужской одежды',
                    desc: 'Поехать на мероприятие в гольф-клуб с тремя манекенами и ширмой? Легко! Водитель отвез, а потом забрал обратно. Отдельное спасибо, что помог всё выгрузить и донести до площадки',
                    img: '/static/img/reviews/woman/5.jpg'
                },
                {
                    title: 'Лариса Кушнир',
                    position: 'Домохозяйка',
                    desc: 'Утром заказала квартирный переезд, вечером уже пью чай сидя в новой гостиной :) Спасибо, Мувер!',
                    img: '/static/img/reviews/woman/6.jpg'
                },
                {
                    title: 'Вячеслав Соловьёв',
                    position: 'Мастер по ремонту компьютеров',
                    desc: 'Первый на моей памяти сервис, который приехал вовремя и не опоздал со ссылкой на пробки! Четко выдержали график, оплата по тарифу - супер!',
                    img: '/static/img/reviews/man/3.jpg'
                },
                {
                    title: 'Валерия Фролова',
                    position: 'Преподаватель в университете',
                    desc: 'Приложение и его сотрудники понравились. Скоро с коллегами будем переезжать в другое помещение, рассмотрим ваш сервис',
                    img: '/static/img/reviews/woman/7.jpg'
                },
                {
                    title: 'Виктория Комарова',
                    position: 'Онлайн-коуч',
                    desc: 'Если магазин стройматериалов не может доставить покупки сегодня, сразу вызываю МУВЕР. Работал с приложением раз 6 уже. Нареканий нет',
                    img: '/static/img/reviews/woman/8.jpg'
                },
                {
                    title: 'Анна Симонова',
                    position: 'Домохозяйка',
                    desc: 'Большое спасибо, что помогли нашей семье с переездом и упаковкой-распаковкой вещей! С малышом на руках нет ни времени, ни сил самим с этим разбираться.',
                    img: '/static/img/reviews/woman/9.jpg'
                },
                {
                    title: 'Анастасия Белолипецкая',
                    position: 'Мамочка в декрете',
                    desc: 'Увозила летом детей на дачу и поняла, что все наши манежи, коляски, велосипеды и чуток мебели в машину точно не влезут. Понравилось, что теперь это не проблема - вызвала ваше такси и увезла всё без хлопот',
                    img: '/static/img/reviews/woman/10.jpg'
                },
                {
                    title: 'Виктор Титаренко',
                    position: 'Автомеханик',
                    desc: 'Спасибо за отлично проделанную работу!',
                    img: '/static/img/reviews/man/4.jpg'
                },
                {
                    title: 'Евгений Максименко',
                    position: 'Создатель бизнеса',
                    desc: 'Закупил мебель для своих первых сотрудников и не захотел ждать доставку на послезавтра. В итоге муверовский водитель приехал за мной через полчаса! Очень полезный сервис оказался!',
                    img: '/static/img/reviews/man/5.jpg'
                },
                {
                    title: 'Константин Резник',
                    position: 'Бармен - Крафтовый пивной завод',
                    desc: 'Оценил стоимость, вызвал ребят, четко все перевез. Теперь только к вам!',
                    img: '/static/img/reviews/man/6.jpg'
                },
                {
                    title: 'Антон Дьячук',
                    position: 'Менеджер по работе с клиентами - Банк',
                    desc: 'Понадобилась перевозка ночью? Для Мувера никаких проблем! Грузовик приехал в 2 часа. Очень выручили!',
                    img: '/static/img/reviews/man/7.jpg'
                },
                {
                    title: 'Тимур Петров',
                    position: 'Владелец бизнеса',
                    desc: 'Вытащили задачу, когда старый подрядчик подвел. Теперь сотрудничаю с Мувером (уже 2 месяца). Пока без нареканий',
                    img: '/static/img/reviews/man/8.jpg'
                },
                {
                    title: 'Вероника Стоянова',
                    position: 'Владелица салона красоты',
                    desc: 'Доставили мой груз даже быстрее, чем обещали!!!! Понравились вежливые водитель и грузчики. Никакого хамства и недопонимания! Спасибо!',
                    img: '/static/img/reviews/woman/11.jpg'
                },
                {
                    title: 'Мария Винницкая',
                    position: 'Кондитер',
                    desc: 'Удобно, что в сервисе есть машины с холодильной камерой в кузове. Отказалась от частников и теперь все заказы перевожу через MOVER. Клиенты тоже в восторге от доставки!',
                    img: '/static/img/reviews/woman/12.jpg'
                },
                {
                    title: 'Александра Головнина',
                    position: 'Логист',
                    desc: 'Доверила Муверу корпоративные заказы и не прогадала. Желаю вам процветания!',
                    img: '/static/img/reviews/woman/13.jpg'
                },
                {
                    title: 'Семён Зайцев',
                    position: 'Дизайнер на фрилансе',
                    desc: 'Очень круто сработал весь сервис и ребята грузчики! Помогли быстро переехать на новую квартиру со всеми вещами.',
                    img: '/static/img/reviews/man/9.jpg'
                },
                {
                    title: 'Дмитрий Бутенко',
                    position: 'Строитель',
                    desc: 'Если магазин стройматериалов не может доставить покупки сегодня, сразу вызываю МУВЕР. Работал с приложением раз 6 уже. Нареканий нет',
                    img: '/static/img/reviews/man/10.jpg'
                },
                {
                    title: 'Павел Иванов',
                    position: 'Заведующий складом - сетевой гипермаркет',
                    desc: 'Заказал МУВЕР для мамы в другую часть города. Ребята вообще всё решали со мной дистанционно. Маме осталось только открыть дверь и показать, что нужно увезти.',
                    img: '/static/img/reviews/man/11.jpg'
                },
                {
                    title: 'Ольга Волобуева',
                    position: 'Журналист - Газета',
                    desc: 'Понравилась продуманность подхода: после приложений такси с МУВЕРОМ справляешься интуитивно! Круто, что теперь и в грузоперевозках есть такой же современный сервис',
                    img: '/static/img/reviews/woman/14.jpg'
                },
                {
                    title: 'Игорь Тютюнник',
                    position: 'Тренер по фитнесу',
                    desc: 'Всё понравилось, уже посоветовал приложение другу',
                    img: '/static/img/reviews/man/12.jpg'
                },
                {
                    title: 'Елена Васильева',
                    position: 'Event организатор',
                    desc: 'Доставили декорации к свадьбе быстро, чётко, легко!',
                    img: '/static/img/reviews/woman/15.jpg'
                },
                {
                    title: 'Валерий Артёмов',
                    position: 'Менеджер музыкальной группы',
                    desc: 'Всегда когда организуем концерты вне нашей базы, вызываю MOVER и вообще не парюсь о доставке, знаю, что ребята об аппаратуре позаботятся за меня',
                    img: '/static/img/reviews/man/13.jpg'
                }
            ],
            marginLeft: 0
        };

        this.timer = null;
    }

    componentDidMount() {
        let widthReview = 630;
        let allSize = 0 - (this.state.reviews.length - 1) * widthReview;
        this.timer = setInterval(() => {
            let canMove = this.state.marginLeft > allSize;

            this.setState({
                marginLeft: (canMove) ? this.state.marginLeft - widthReview : 0
            });
        }, 3000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    renderReviews = (item, index) => {
        return (
            <div className="reviews-item" key={index} data-index={index}>
                <div className="reviews-item-head">
                    <div className="reviews-item-img">
                        <img src={item.img} alt=""/>
                    </div>
                    <div className="reviews-item-user">
                        <h3 className="title">{item.title}</h3>
                        <p className="position">{item.position}</p>
                    </div>
                </div>
                <div className="reviews-item-cnt">
                    <p className="text">{item.desc}</p>
                </div>
            </div>
        )
    };

    render() {

        const { lang } = this.props;
        const { marginLeft , reviews } = this.state;

        return (
            <div className="s-index-reviews">
                <div className="container">
                    <h2 className="s-index__title">{lang.Index_reviews_title}</h2>
                </div>

                <div className="reviews">
                    <div className="reviews-wrapper" style={{marginLeft}}>
                        {reviews.map(this.renderReviews)}
                    </div>
                </div>
            </div>
        );
    }
}