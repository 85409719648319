import React            from 'react';
import Autocomplete     from './Autocomplete';

export default class GoodsComplete extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: props.goodType,
            goods: []
        };
    }

    makeRequest = async (value, cb) => {
        let data = [
            {
                key: 1,
                goodType: 'Бытовая техника для дома',
                goodTypeExample: 'Стиральные, швейные, посудомоечные машины',
            }, {
                key: 2,
                goodType: 'Бытовая техника для кухни',
                goodTypeExample: 'Вытяжки, микроволновки, плиты, холодильники, морозильные камеры',
            }, {
                key: 3,
                goodType: 'Климатическое оборудование',
                goodTypeExample: 'Вентиляторы, обогреватели, кондиционеры, очистители воздуха',
            }, {
                key: 4,
                goodType: 'Мебель',
                goodTypeExample: 'Компьютерные столы и кресла, Кровати, диваны и кресла, Столы и стулья',
            }, {
                key: 5,
                goodType: 'Ремонт и строительство',
                goodTypeExample: 'Двери, сантехника и сауна',
            }, {
                key: 6,
                goodType: 'Товары для детей и игрушки',
                goodTypeExample: 'Автомобильные кресла, Детские коляски',
            }, {
                key: 7,
                goodType: 'Автозапчасти и аксессуары',
                goodTypeExample: 'Комплектующие авто, Шины, диски, колёса',
            }, {
                key: 8,
                goodType: 'Велосипеды',
                goodTypeExample: 'Велосипеды, самокаты, инвалидные кресла, моноколеса',
            }, {
                key: 9,
                goodType: 'Музыкальные инструменты',
                goodTypeExample: 'Пианино, студийное и концертное оборудование, ударные установки',
            }, {
                key: 10,
                goodType: 'Спорт и отдых (Фитнес и тренажёры)',
                goodTypeExample: 'Штанга, стойки, спортивные снаряды',
            }, {
                key: 11,
                goodType: 'Оборудование для бизнеса',
                goodTypeExample: 'Крупногабаритные приборы, итп',
            }, {
                key: 12,
                goodType: 'Стекло',
                goodTypeExample: 'Посуда, пробирки, колбы, бутылки',
            }, {
                key: 13,
                goodType: 'Стройматериайлы',
                goodTypeExample: 'Цемент, кирпичи, ДСП, краски, лаки',
            }
        ];

        let cnt = 0

        return cb(data.filter((item, index) => {

            if(cnt < 6 && (item.goodType.indexOf(value) !== -1 || item.goodTypeExample.indexOf(value) !== -1)) {
                ++cnt;
                return item;
            }
        }));
    };



    componentWillReceiveProps(nextProps) {
        this.setState({ value: nextProps.goodType });
    }

    clearInput = () => {
        const { index, goods } = this.props;
        this.setState({ value: '', goods: [] });
        document.querySelector('body').focus();

        this.props.cleanItem(index);
    };

    saveValues = (item) => {
        const { goodType, index } = this.props;

        this.props.saveGoodType(item, goodType, index);
    };

    render() {
        const { index, placeholder } = this.props;
        const { goods } = this.state;

        return (
            <div id={"calculatorCargoInput" + index} className="goods-selector">
                <Autocomplete
                    inputProps={{ id: 'goods-selector-' + index, placeholder: placeholder }}
                    wrapperStyle={{ display: 'block', position: 'relative' }}
                    value={this.state.value}

                    items={ goods }

                    getItemValue={(item) => item.goodType}

                    onSelect={(value, item) => {
                        this.setState({ value, goods: [ item ] }, () => {
                            this.saveValues(item);
                        })
                    }}
                    onChange={(event, value) => {
                        this.setState({ value });

                        this.requestTimer = this.makeRequest(value, (data) => {
                            this.setState({value, goods: data }, () => {
                                this.saveValues({
                                    key: -1,
                                    goodType: value,
                                    goodTypeExample: '',
                                });
                            });
                        });
                    }}
                    renderMenu={children => {
                        return (
                            <div className="wrap-controller">
                                <div id={"calculatorCargoInputDel" + index} className="delete" onClick={this.clearInput}/>
                                <div className="menu">
                                    {children}
                                </div>
                            </div>
                        )
                    }}
                    renderItem={(item, isHighlighted) => (
                        <div id={"calculatorCargoInputItem" + index + "_" + item.key} className={`item ${isHighlighted ? 'item-highlighted' : ''}`} key={item.key + ''}>
                            {item.goodType}
                            <span className='city'>{item.goodTypeExample}</span>
                        </div>
                    )}
                />
            </div>
        );
    }
}