// import { IntercomAPI } from 'react-intercom';
import {ymElem, ymElemWithData} from "./helpers";

const tariffGoals = {
    mini2: 'tariff_1',
    standart2: 'tariff_2',
    extra2: 'tariff_3',
    maxi2: 'tariff_4'
};

function trackEvent(event, callback, callbackData) {
    console.log('track', event);

    // IntercomAPI('trackEvent', event);
    ymElem('reachGoal', event);

    if(callback) callback(callbackData);
}

function trackEventWithData(event, data, callback, callbackData) {
    console.log('track', event);

    // IntercomAPI('trackEvent', event, data);
    ymElemWithData(event, data);

    if(callback) callback(callbackData);
}

function trackTariff(tier, callback, callbackData) {
    let goal = tariffGoals[tier];
    if(!goal) {
        console.warn(`Not found goal for tier '${tier}'`);
        return;
    }

    trackEvent(goal, callback, callbackData);
}

export {
    trackEvent,
    trackEventWithData,
    trackTariff
}