'use strict';
import CalculatorAPI from '../API/calculator';

function getInitialState() {
    return {
        errors: null,
		pausePlayOrderId: null,
		pausePlayOrderStatus: 'active'
    };
}

const calculator = {
    state: getInitialState(),
    reducers: {
        update(state, payload) {
            console.log('calculator.update: ', payload);
            return Object.assign({}, state, payload);
        }
    },
    effects: (dispatch) => ({
        async searchStop(data, rootState) {
            let { activeOrder } = rootState.orders;

            if(activeOrder) {
                dispatch.orders.declinedOrder(activeOrder.id);
                await CalculatorAPI.searchStop(data.draftId);
            } else {
                await CalculatorAPI.searchStop(data.draftId);
            }

        },
        async searchStart(data, rootState) {
            await CalculatorAPI.searchStart(data.draftId);
        },
		async stopOrder(data, rootState) {
            let { role } = rootState.user;
            let req = {
                type: ['ROLE_ADMIN', 'ROLE_SUPPORT', 'ROLE_COMPANY'].indexOf(role) !== -1 ? data.freighterId : 'me',
                role: ['ROLE_ADMIN', 'ROLE_SUPPORT', 'ROLE_COMPANY'].indexOf(role) !== -1 ? 'freighter' : 'customer',
            };

            req.orderId = data.orderId;

			const resp = await CalculatorAPI.stopOrder(req);

			if(resp.success) {
				dispatch.calculator.update(resp);
				return true;
			} else {
                // Если 405 обработать, зак действие не возможно выполнить.
                // Отмененный ранее заказ нельзя отменить повторно
                dispatch.calculator.update({
                    errors: {
                        code: resp.code,
                        reason: resp.message
                    }
                });
                return false;
            }
		},
        async editOrderStatus(data, rootState) {
            let { role } = rootState.user;
            let req = {
                type: ['ROLE_ADMIN', 'ROLE_SUPPORT', 'ROLE_COMPANY'].indexOf(role) !== -1 ? data.freighterId : 'me',
                role: ['ROLE_ADMIN', 'ROLE_SUPPORT', 'ROLE_COMPANY'].indexOf(role) !== -1 ? 'freighter' : 'customer',
            };

            req.orderId = data.orderId;
            req.status = data.status;

			const resp = await CalculatorAPI.editOrderStatus(req);

			if(resp.success) {
				dispatch.calculator.update(resp);
			} else {
                // Если 405 обработать, зак действие не возможно выполнить.
                // Отмененный ранее заказ нельзя отменить повторно
                dispatch.calculator.update({
                    errors: {
                        code: resp.code,
                        reason: resp.message
                    }
                });
            }
		},
		async getPausePlayOrder(data) {
			const resp = await CalculatorAPI.getPausePlayOrder(data);
			const result = {};

			if(resp.success) {
				result.pausePlayOrderId = resp.pausePlayOrderId;
				result.pausePlayOrderStatus = resp.pausePlayOrderStatus;
				dispatch.calculator.update(result);
			}
		},
    })
};

export default calculator;
