import React from 'react';
import cx from 'classnames';

export default class CalculatorSideBarStep2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    setActive = (val) => {
        this.props.setActive(2);
    };

    componentDidCatch(error, info) {

    }

    render() {
        const { lang, step, data } = this.props;

		let isActive = step === 2;

        const hasGoods = data.length > 0 && data[0] && data[0].goodType;

        const itemCSS = cx({
            'Сalculator-sideBar-item': true,
            'item-2': true,
            'active': isActive,
            'empty': !hasGoods,
			'old': step > 2
        });

        const goodsEmpty = (
            <div className="cargo-empty">
                <div className="row">
                    <div className="icon"/>
                    <div className="title-wrap">
                        <span>{lang.Calculator_cargo_title}</span>
                    </div>
                </div>
            </div>
        );

        const goodsFull = () => {
            let goods = data.map((item) => {
                if(item.goodType) return item.goodType;
            });

            goods = goods.join(', ');

            return (
                <div className="cargo-full full">
                    <div className="row">
                        <div className="icon"/>
                        <div className="title-wrap">
                            <span className="prefix">{lang.Calculator_your_cargo}</span>
                            <span id="calculatorSBCargoType" className="type">{ goods }</span>
                        </div>
                    </div>
                </div>
            )
        };


        return (
            <div id="calculatorSBItem2" className={itemCSS} onClick={this.setActive}>
                {hasGoods ? goodsFull() : goodsEmpty}
            </div>
        );
    }
}