'use strict';
import User from '../API/user';

function getInitialState() {
    return {
        account: {},
        cards: [],
        promoCodes: [],
        sum: 0,
        errors: null,
        role: 'ROLE_ANON'
    };
}

const user = {
    state: getInitialState(),
    reducers: {
        updateUserInfo(state, payload) {
            return Object.assign({}, state, payload);
        },
        updateUserCards(state, payload) {
            const newState = Object.assign({}, state);
            newState.cards = payload;

            return newState;
        },
        updateUserBalance(state, payload) {
            const newState = Object.assign({}, state);
            newState.sum = payload;

            return newState;
        },
        updateUserPromoCode(state, payload) {
            const newState = Object.assign({}, state);
            newState.promoCodes = payload;

            return newState;
        },
        updateUserInfoFull(state, payload) {
            return Object.assign({}, state, payload);
        },
        updateUserErrors(state, payload) {
            return Object.assign({}, state, payload);
        },
        updateLogout() {
            if(window.jivo_api)
                jivo_api.clearHistory();

            return getInitialState();
        },
    },
    effects: (dispatch) => ({
        // Получение информации о пользователе
        async getUserInfo() {
            const result = {};
            const res = await User.getUserInfo();
            if(res.success) {
                result.account = res.account;
                result.role = res.role;
            }

            dispatch.user.updateUserInfo(result);
        },
        // Запрос списка карт
        async getUserCards() {
            const res = await User.getCards();
            dispatch.user.updateUserCards(res.success ? res.cards : []);
        },
        // Запрос баланса
        async getUserBalance() {
            const res = await User.getBalance();
            dispatch.user.updateUserBalance(res.success ? res.sum : 0);
        },
        // Запрос списка промокодов
        async getUserPromoCode() {
            const res = await User.getPromoCode();
            dispatch.user.updateUserBalance(res.success ? res.promo_codes : []);
        },
        // Получить сводную информацию
        async getUserInfoFull(data) {
            const res1 = await User.getUserInfo(data);
            if (!res1.success) {
                dispatch.user.updateUserInfoFull(result);
                return;
            }

            const result = {
                account: res1.account,
                role: res1.role
            };

            const res2 = await User.getCards(data);
            const res3 = await User.getBalance(data);
            const res4 = await User.getPromoCode(data);

            if(res2.success) result.cards       = res2.cards;
            if(res3.success) result.sum         = res3.sum;
            if(res4.success) result.promoCodes  = res4.promo_codes;

            dispatch.user.updateUserInfoFull(result);
        },
        async logout() {
            const res = await User.logout();

            if(res.success) {
                localStorage.removeItem('curDraft');
                localStorage.removeItem('phone');

                dispatch.user.updateLogout();
            }
        },
        // Авторизация
        async login({username, password}) {
            dispatch.user.updateUserErrors({ errors: null })

            const res = await User.login({username, password});

            if(res.success) {
                localStorage.setItem('phone', JSON.stringify(username));
                dispatch.ws.connect();
                dispatch.user.getUserInfoFull();
                dispatch.user.updateUserErrors({ errors: null });
            } else {
                // Доступ запрещён
                if(res.code === 401) dispatch.user.updateUserErrors({ errors: 401 });
                // Неверный логин или пароль
                if(res.code === 403) dispatch.user.updateUserErrors({ errors: 403 });
                // Пользователь не найден
                if(res.code === 404) dispatch.user.updateUserErrors({ errors: 404 });
                // Системная ошибка
                if(res.code === 500) dispatch.user.updateUserErrors({ errors: 500 });
            }
        },
        // Удаление привязанной карты
        async deleteCard(cardID) {
            const res = await User.deleteCard(cardID);
            dispatch.user.updateUserCards(res.success ? res.cards : []);
        },
        // Удаление привязанной карты
        async setActiveCard(cardID) {
            const res = await User.setActiveCard(cardID);
            dispatch.user.updateUserCards(res.success ? res.cards : []);
        },
        // Применение промокода
        async savePromoCode(promoCode) {
            const resp = await User.applyPromo(promoCode);

            if(resp.success) {
                const res = await User.getPromoCode();
                dispatch.user.updateUserPromoCode({}, res.success ? res.promo_codes : []);
            } else {
                if(resp.code === 401) {
                    let error = {
                        errors: {
                            step: 5,
                            information: {
                                text: 'Чтобы применить промокод, сначала нужно авторизоваться',
                                field: 'userphone'
                            }
                        }
                    };

                    return
                }

                if(resp.code === 409) {
                    let error = {
                        errors: {
                            step: 5,
                            information: {
                                text: 'У вас уже введён код, который ещё не использован',
                                field: 'promocode'
                            }
                        }
                    };

                    return
                }

                if(resp.code === 405) {
                    let error = {
                        errors: {
                            step: 5,
                            information: {
                                text: 'У вас применен промокод из другой акции',
                                field: 'promocode'
                            }
                        }
                    };

                    return
                }

                if(resp.code === 404) {
                    let error = {
                        errors: {
                            step: 5,
                            information: {
                                text: 'У вас не верный промокод',
                                field: 'promocode'
                            }
                        }
                    };

                    return
                }
            }
        },
        // Применение промокода
        async setName(name) {
            const resp = await User.setName(name);

            if(resp.success) {
                const res = await User.getPromoCode();
                dispatch.user.updateUserBalance(res.success ? res.promo_codes : []);
            }
        }
    })
};

export default user;
