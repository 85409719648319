import React from 'react';

export default class Loader extends React.Component {
    render() {
        let { text, loaderText } = this.props;

        return (
            <div className="loader-box">
                <div className="small loading-text">{ loaderText }</div>
                <div className="global-loader">
                    <div className="element-animation">
                        <img src="/static/img/loader/loader.png" width="1180" height="70"/>
                    </div>
                </div>
                {( text ? (<div className="loading-text sub-text">{ text }</div>) : null )}
            </div>
        );
    }
}