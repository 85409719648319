import React                from 'react';
import cx                   from 'classnames';
import { connect }          from 'react-redux';
import { stopEvent }        from '../../utils/helpers';
import { IMaskInput }       from 'react-imask';
import Hint                 from '../Hint';
import User                 from '../../API/user';

export default class CalculatorStep5 extends React.PureComponent {
    constructor(props) {
        super(props);
        let activeCard = null;

        props.userInfo.cards.map((card) => {
            if(card && card.active) activeCard = card;
        });

        this.state = {
            paymentDefault: props.data.paymentType ? props.data.paymentType : 'cache',
			phone: props.userInfo.account.id ? props.userInfo.account.phone : '',
            name: props.userInfo.account.id ? (props.userInfo.account.name ? props.userInfo.account.name : '') + (props.userInfo.account.surname ? ' ' + props.userInfo.account.surname : '') : '',
            cards: props.userInfo.cards,
            activeCard: activeCard,
            code: '',
            promoCode: '',
            error: props.errors && props.errors.step === 5 ? props.errors : null,
            codeDidSent: false,
            goodsServices: props.data.goodsServices
        };
    }

    componentDidMount() {
        window.addEventListener("message", this.addCardListener);
    }

    componentWillUnmount(){
        window.removeEventListener("message", this.addCardListener);
    }

    componentWillReceiveProps(newProps) {
        let name = '', phone = '', cards = [], activeCard = null;

        if(newProps.userInfo.account.id) {
            phone = newProps.userInfo.account.phone ? newProps.userInfo.account.phone : '';
            name = newProps.userInfo.account.name ? newProps.userInfo.account.name : '';
            name += newProps.userInfo.account.surname ? ' ' + newProps.userInfo.account.surname : '';

            cards = newProps.userInfo.cards;
            cards.map((card) => {
                if(card.active) activeCard = card;
            });

            this.setState({
                phone,
                name,
                cards,
                activeCard
            })
        }

        this.setState({error: newProps.errors});
    }

    // Обработчик сохранения номера телефона
    handlePhoneChange = (value) => {
        const { phone } = this.state;

        if(phone !== value) {
            this.setState({phone: value});
            if(this.state.error) this.props.deleteError();
        }
    };

    sendAuthCode = async (event) => {
        stopEvent(event);
        let phone = this.state.phone;

        let resp = await User.registration(phone);

        if(resp.success) {
            document.getElementById('inp-pass').focus();
        } else {
            if(resp.code === 409) {
                let resp = await User.resetPassword(phone);

                if(resp.success) {
                    this.setState({codeDidSent: true});
                }
            }
        }
    };

    handleNameChange = ({ target = {} }) => {
        const { value = '' } = target;

        this.setState({name: value});
    };

    addCard = async () => {
        await User.addCard();
    };

    loginApp = (e) => {
        stopEvent(e);
        let { phone, code } = this.state;

    	if(code.length < 2) return false;
        if(phone.length < 11 || phone.length > 12) return false;
        if(phone.length === 11 && (phone[0] != 7 && phone[0] != 8)) return false;
        if(phone.length === 12 && phone[0] != '+') return false;

        this.props.login(phone, code);
	};

    handlePhoneKeyDown = async(event) => {
        event = event || window.event;
        const keyCode = event.keyCode;

        if (keyCode === 13) await this.sendAuthCode(event);

    };

    handleCodeKeyDown = async(event) => {
        event = event || window.event;
        const keyCode = event.keyCode;

        if (keyCode === 13) await this.loginApp(event);
    };

    handlePromoCodeChange = ({ target = {} }) => {
        const { value = '' } = target;
        let { error } = this.state;

        this.setState({promoCode: value});
        if(error && error.information.field === 'promocode') this.props.deleteError();
    };

    handleCodeChange = ({ target = {} }) => {
        const { value = '' } = target;

        this.setState({code: value});
    };

    renderCard = (card, index) => {
        let cardCSS = cx({
            'card-bank': true,
            't-maestro': card.type === 'Maestro',
            't-mastercard': card.type === 'MasterCard',
            't-visacard': card.type === 'Visa',
            't-mircard': card.type === 'Mir',
        });

        let cardValue = '••••' + card.mask;

        return (
            <div key={'card-' + index} className="col-6">
                <div id={"calculatorPaymentCard" + index} className="payment-cards-item payment-control t-static">
                    <span className={cardCSS}/>
                    <span className="edit">
						<span id={"calculatorPaymentCardEditOk" + index} className="edit-item t-ok" onClick={async () => await this.props.setActiveCard(card.id)}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" className="icon-page">
							  <g transform="translate(1386 2931)">
								<path className="cls-1" d="M0,0H16V16H0Z" transform="translate(-1386 -2931)"/>
								<path className="cls-2" d="M6.951,11.02,3.928,8a.667.667,0,1,1,.943-.943L7.438,9.622l5.316-5.315a.667.667,0,0,1,.943.943L7.925,11.02a.665.665,0,0,1-.472.2H7.422A.665.665,0,0,1,6.951,11.02Z" transform="translate(-1386.578 -2930.37)"/>
							  </g>
							</svg>
						</span>
						<span id={"calculatorPaymentCardEditDel" + index} className="edit-item t-del" onClick={async() => await this.props.deleteCard(card.id)}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" className="icon-page">
							  <g transform="translate(-432 -440)">
								<path className="cls-1" d="M0,0H16V16H0Z" transform="translate(432 440)"/>
								<path className="cls-2" d="M4.667,10V6h-4a.667.667,0,0,1,0-1.333h4v-4A.667.667,0,0,1,6,.667v4h4A.667.667,0,0,1,10,6H6v4a.667.667,0,0,1-1.333,0Z" transform="translate(440 440.458) rotate(45)"/>
							  </g>
							</svg>
						</span>
					</span>
                    <input type="text" id={"calculatorPaymentCardNumber" + index} className="form-control" value={cardValue} disabled="disabled"/>
                </div>
            </div>
        );
    };

    setPaymentType = async (type) => {
        await this.props.updateData({ paymentType: type }, 'paymentType');

        this.setState({
            paymentDefault: type
        });
    };

    renderCacheTab = () => {
        const { lang } = this.props;
        let checked = (this.state.paymentDefault === 'cache' || this.state.paymentDefault === 'cash') ? 'checked' : false;
        let canShow = !this.state.goodsServices.storage.active;

        return ( canShow ?
            <div id="calculatorPaymentChoiceCache" className="radio-choice-item col">
                <input type="radio" name="radioChoice5" id="radioChoice5-1" value="type1" defaultChecked={checked} onChange={() => this.setPaymentType('cache')}/>
                <div className="box">
                    <span className="title">{lang.CalcStep5_radio_choice_1_title}</span>
                </div>
            </div> : null
        );
    };

    renderCardTab = () => {
        const { lang } = this.props;
        let checked = this.state.paymentDefault === 'card' ? 'checked' : false;

        return (
            <div id="calculatorPaymentChoiceCard" className="radio-choice-item col">
                <input type="radio" name="radioChoice5" id="radioChoice5-2" value="type2" defaultChecked={checked} onChange={() => this.setPaymentType('card')}/>
                <div className="box">
                    <span className="title">{lang.CalcStep5_radio_choice_2_title}</span>
                </div>
            </div>
        );
    };

    renderBankAccountTab = () => {
        const { lang, userInfo } = this.props;
        let checked = this.state.paymentDefault === 'bank' ? 'checked' : false;
        let canShow = userInfo && userInfo.account.company_client && userInfo.account.company_client.id > 0;

        return ( canShow ?
            <div id="calculatorPaymentChoiceBank" className="radio-choice-item col">
                <input type="radio" name="radioChoice5" id="radioChoice5-3" value="type3" defaultChecked={checked} onChange={() => this.setPaymentType('bank')}/>
                <div className="box">
                    <span className="title">{lang.CalcStep5_radio_choice_3_title}</span>
                </div>
            </div> : null
        );
    };

    renderCardRow = () => {
        const { lang } = this.props;
        const { cards, activeCard } = this.state;
        let hasActiveCard = !!activeCard, activeCardValue, activeCardCSS = '';

        if (activeCard) {
            activeCardValue = '••••' + activeCard.mask;

            activeCardCSS = cx({
                'card-bank': true,
                't-maestro': activeCard.type === 'Maestro',
                't-mastercard': activeCard.type === 'MasterCard',
                't-visacard': activeCard.type === 'Visa',
                't-mircard': activeCard.type === 'Mir',
            });
        }

        return (
            <div>
                {hasActiveCard ? (
                    <div className="payment-item">
                        <label className="payment__label">{lang.CalcStep5_payment_label_4}</label>
                        <div className="payment-cards row">
                            <div className="col-6">
                                <div id="calculatorPaymentCardActive" className="payment-cards-item payment-control t-disabled is-yes">
                                    <i className="icon" />
                                    <span className={activeCardCSS} />
                                    <input type="text" id="calculatorPaymentCardActiveNumber" className="form-control" value={activeCardValue} disabled="disabled"/>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                {cards.length > 0 ? (
                    <div className="payment-item">
                        <label className="payment__label">{lang.CalcStep5_payment_label_5}</label>
                        <div className="payment-cards row">
                            {cards.map(this.renderCard)}
                        </div>
                        <span className="payment__add-item" onClick={this.addCard}>Добавить карту</span>
                    </div>
                ) : (
                    <div className="payment-item">
                        <p className="payment-cards__help">У вас не добавлено ни одной карты</p>
                        <span id="calculatorPaymentCardAdd" className="payment__add-item" onClick={this.addCard}>Добавить карту</span>
                    </div>
                )}
            </div>
        )
    };

    keyEnterCode = async (event) => {
        event = event || window.event;

        const keyCode = event.keyCode;
        let { promoCode } = this.state;

        if (keyCode === 13 && promoCode) {
            let data = await this.props.savePromoCode(promoCode);
        }
    };

    addCardListener = (message) => {
        if(message.data === 'add card completed') this.props.getUserCards();

    };

    render() {
        const { lang, isActive, userInfo } = this.props;
        const { error, activeCard, codeDidSent } = this.state;

        let userphoneCSS = cx({
            'form-control': true,
            'is-invalid': error && error.information.field == 'userphone'
        });

        const itemCSS = cx({
            'calculator-content': true,
            'step5': true,
            'active': isActive,
        });

        let promoCode = () => {
            let promocodeCSS = cx({
                'form-control': true,
                'is-invalid': error && error.information.field == 'promocode'
            });

            let promoCode = this.state.promoCode;
            let valuePromo = null;

            if(userInfo && userInfo.promoCodes && userInfo.promoCodes.length > 0) {
                promoCode = userInfo.promoCodes[0].code;
                valuePromo = userInfo.promoCodes[0].value + (userInfo.promoCodes[0].discount_type == 'fixed' ? '₽' : '%');
            }

            return (<div className="payment-item">
                <label className="payment__label">{lang.CalcStep5_payment_label}</label>
                <div className="payment-control t-code">
					{valuePromo ? (<span className="promocode">{valuePromo}</span>) : null}
                    <input
                        id='calculatorPaymentInputPromocode'
                        type="text"
                        className={promocodeCSS}
                        value={promoCode || ''}
                        onChange={this.handlePromoCodeChange}
                        onKeyDown={this.keyEnterCode}
                        placeholder="Введите промокод"
                    />
                </div>
            </div>)
        };

        const isAuthorized = !!(userInfo.account.id);

        let loginCSS = cx({
            'payment-control': true,
            't-code-btn': true,
            't-code-edit': isAuthorized,
            'is-yes': isAuthorized
        });

        console.log(this.state);

        return (
            <div className={itemCSS} >
                <h2 className="title">{lang.CalcStep5_title}</h2>

				<div className="payment-wrap">
					<div className="payment-row">
						<div className="payment-item">
							<div id="calculatorPaymentChoiceRow" className="radio-choice row">

                                {this.renderCacheTab()}
                                {this.renderCardTab()}
                                {this.renderBankAccountTab()}

							</div>
						</div>

                        {this.state.paymentDefault === 'card' ? (isAuthorized ? this.renderCardRow() : (
							<p className="payment__help">Чтобы выбрать способ оплаты картой пожалуйста авторизуйтесь</p>
						)) : null}
					</div>

					<div className="payment-row">
						<div className="payment-item">

							{ isAuthorized && this.state.paymentDefault !== 'cache' && !this.state.goodsServices.storage.active ? promoCode() : null }

							<label className="payment__label">{lang.CalcStep5_payment_label_3}</label>
							<div className="payment-control">
								<input disabled={isAuthorized ? 'disabled' : false} type="text" id="calculatorPaymentInputUser" className="form-control" placeholder={lang.CalcStep5_payment_placeholder_1} value={this.state.name || ''} onChange={this.handleNameChange}/>
							</div>

                            <div className="payment-item">
                                <form onSubmit={this.loginApp}>
                                    <div className="payment-item">
                                        <div className={loginCSS}>
                                            {!isAuthorized && this.state.phone.length === 11 ? <span id="calculatorPaymentBtnSendCode" className="code-btn" onClick={this.sendAuthCode}>{lang.CalcStep5_payment_code_btn_3}</span> : null}
                                            {isAuthorized ? <i className="icon"/> : null}
                                            <IMaskInput
                                                disabled={isAuthorized ? 'disabled' : false}
                                                id='calculatorPaymentInputPhone'
                                                tabIndex='0'
                                                onKeyDown={this.handlePhoneKeyDown}
                                                mask='+{7} (000) 000 00 00'
                                                unmask={true}
                                                className={userphoneCSS}
                                                onAccept={
                                                    // depending on prop above first argument is
                                                    // `value` if `unmask=false`,
                                                    // `unmaskedValue` if `unmask=true`,
                                                    // `typedValue` if `unmask='typed'`
                                                    (value, mask) => {
                                                        this.handlePhoneChange(value)
                                                    }
                                                }
                                                autoCorrect="off"
                                                autoCapitalize="off"
                                                autoComplete="nope"
                                                placeholder='+7'
                                                value={this.state.phone || ''}
                                            />
                                            {isAuthorized ? <span className="hint-info">Номер подтвержден</span> : null}
                                        </div>
                                        {!isAuthorized && codeDidSent && this.state.code.length ? (
                                            <div className="payment-control t-code-btn">
                                                {codeDidSent && this.state.code.length ? <span id="calculatorPaymentBtnSuccessCode" className="code-btn login" onClick={this.loginApp}>{lang.CalcStep5_payment_code_btn_2}</span> : null }
                                                {codeDidSent && this.state.phone.length === 11 ? (
                                                    <input
                                                        autoFocus={true}
                                                        type="password"
                                                        id="calculatorPaymentInputPass"
                                                        placeholder={lang.CalcStep5_payment_placeholder_2}
                                                        className="form-control"
                                                        value={this.state.code || ''}
                                                        onChange={this.handleCodeChange}
                                                        onKeyDown={this.handleCodeKeyDown}
                                                    />
                                                ) : null}
                                                <span className="timer-code">Запросить код ещё раз <span className="timer">07:12</span></span>
                                            </div>
                                        ) : null}
                                    </div>
                                </form>
                            </div>

						</div>
					</div>

                    {error ? <Hint deleteError={this.props.deleteError} title={lang.CalcSidebar_step5_hint_title} desc={this.state.error.information.text} type="error" /> : null}
				</div>
            </div>
        );
    }
}
