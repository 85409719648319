const BusinessForm2 = () => (
    <div className="business-form-wrapper">
        <iframe id="bx_form_iframe_6" name="bx_form_iframe_7"
                src="https://mover.bitrix24.ru/pub/form.php?view=frame&amp;form_id=7&amp;widget_user_lang=ru&amp;sec=h6qgoo&amp;r=1540803721866"
                scrolling="no" frameBorder="0" marginHeight="0" marginWidth="0"
                style={{width: '100%', height: '539px', border: 0, overflow: 'hidden', padding: 0, margin: 0}} />
    </div>
);


export default BusinessForm2;