import React 					from 'react';
import { stopEvent, ymElem } 	from '../utils/helpers';
import { IMaskInput } 			from 'react-imask';
import Notifications            from '../API/notifications';
import { trackEvent }                       from '../utils/tracking'

class GetFirstBonus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            myPhone: '',
            friendPhone: '',
            myName: '',
            isSuccess: false,
            isError: false,
            code: null,
            fullForm: false
        };
    }

    componentDidMount() {}

    componentWillReceiveProps(nextProps) {}

    componentDidCatch(error, info) {}

    handleMyPhoneChange = (value) => {
        this.setState({myPhone: value});
    };

    handleFriendPhoneChange = (value) => {
        this.setState({friendPhone: value});
    };

    handleMyNameChange = ({ target = {} }) => {
        const { value = '' } = target;

        this.setState({myName: value});
    };

    closeModal = () => {
        this.setState({
            isError: false,
            isSuccess: false,
            code: null
        });
    };

    handleSubmit = async(e) => {
        let { myName, myPhone, friendPhone } = this.state;
        trackEvent('promo_share_sms');

        stopEvent(e);

        if(myName && myPhone.length > 10 && friendPhone.length > 10) {
			let data = await Notifications.sendPromo({
				name: myName,
				phone: myPhone,
				to: friendPhone
			});

			if(data.success) {
				this.setState({
					isSuccess: true
				})
			}

			if(data.code) {
				this.setState({
					isError: true,
					code: data.code
				})
			}
        }
    };

    testOrder = () => {
        const { lang } = this.props;
        let data = prompt(lang.GetFirstBonus, 'test 71121231020');

        localStorage.setItem('testCode', JSON.stringify({testCode: data}));
    };

    setForm = () => {
        this.setState({
            fullForm: true
        })
    };

    render() {
        let { isSuccess, isError, code } = this.state;
        const { lang } = this.props;

        let formaStart = (
            <div className="index-callback">
                <h2 className="s-index__title t-left t-white">{lang.Index_callback_1_title}</h2>
                <p className="desc">{lang.Index_callback_1_desc}</p>
                <div className="index-callback-form">
                    <form action="/" method="post" className="form row">
                        <div className="form-group col-8">
                            <input type="text" className="form-control" placeholder={lang.PhoneNumber1} onFocus={this.setForm}/>
                        </div>
                        <div className="form-group col-4">
                            <button type="submit" disabled="disabled" className="btn btn-block btn-primary">{lang.SendTo}</button>
                        </div>
                    </form>
                    <div className="hint-form">{lang.Index_callback_1_hint}</div>
                </div>
            </div>
        );

        let formaFinish = (
            <div className="share-form">
                <h4 className="title">{lang.PromoCode_title}</h4>
                <p className="desc">
                    {lang.PromoCode_desc}<span onClick={this.testOrder}>.</span>
                </p>
                <form action="/" method="post" className="form row" onSubmit={this.handleSubmit}>
                    <div className="form-group col-6">
                        <label className="label">{lang.PromoCode_form_label_name}</label>
                        <input type="text" className="form-control" placeholder={lang.PromoCode_form_name_placeholder} value={this.state.myName} onChange={this.handleMyNameChange}/>
                    </div>

                    <div className="form-group col-6">
                        <label className="label">{lang.PromoCode_form_label_phone}</label>
                        <IMaskInput
                            id='userphone2'
                            tabIndex='0'
                            onKeyDown={this.handleKeyDown}
                            mask='+{7} (000) 000 00 00'
                            unmask={true}
                            className='form-control'
                            onAccept={
                                // depending on prop above first argument is
                                // `value` if `unmask=false`,
                                // `unmaskedValue` if `unmask=true`,
                                // `typedValue` if `unmask='typed'`
                                (value, mask) => {
                                    this.handleMyPhoneChange(value)
                                }
                            }
                            placeholder={lang.PhoneNumber1}
                            value={this.state.myPhone || ''}
                        />
                    </div>

                    <div className="form-group col-12">
                        <label className="label">{lang.PromoCode_form_label_phone_2}</label>
                        <div className="row">
                            <div className="col-8">
                                <IMaskInput
                                    id='userphone1'
                                    tabIndex='0'
                                    onKeyDown={this.handleKeyDown}
                                    mask='+{7} (000) 000 00 00'
                                    unmask={true}
                                    className='form-control'
                                    onAccept={
                                        // depending on prop above first argument is
                                        // `value` if `unmask=false`,
                                        // `unmaskedValue` if `unmask=true`,
                                        // `typedValue` if `unmask='typed'`
                                        (value, mask) => {
                                            this.handleFriendPhoneChange(value)
                                        }
                                    }
                                    placeholder={lang.PhoneNumber1}
                                    value={this.state.friendPhone || ''}
                                />
                            </div>
                            <div className="col-4">
                                <button type="submit" className="btn btn-block btn-primary" onSubmit={this.handleSubmit}>{lang.SendTo}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );

        let result = (
            <div className="share-form-success">
                <i className="close" onClick={this.closeModal} />
                <div className="icon">
					<div className="img-icon success"/>
                </div>
                <h4 className="title">{lang.PromoCode_success_title}</h4>
                <p className="desc">{lang.PromoCode_success_desc}</p>
            </div>
        );

        let errorInfo = (code) => {
            return (
                <div className="share-form-success">
                    <i className="close" onClick={this.closeModal} />
                    <div className="icon">
						<div className="img-icon error"/>
                    </div>
                    <h4 className="title">{lang.PromoCode_error_title}</h4>
                    <p className="desc">{lang.PromoCode_error_desc} {code}!</p>
                </div>
            )
        };

        let setFullForm = this.state.fullForm;

        return (
            <div className="s-index-callback t-3">
                <div className="container">
                    <div className="row">
                        {/*<div className="col-6">
							<img src="/static/img/index-callback/iPhone_X_Share_1@2x_2.png" alt="" width="362" height="424" className="img"/>
						</div>*/}
                        <div className="col-6">
                            {setFullForm ? (
                                <div className="share-form-wrap">
                                    {isSuccess ? result : isError ? errorInfo(code) : formaFinish }
                                </div>
                            ) : (
                                formaStart
                            )}
                        </div>
                    </div>
                </div>
                <div className="index-callback-sale">
                    <span className="price">300 ₽</span>
                    <span className="desc">{lang.DiscountText}</span>
                </div>
            </div>
        );
    }
}

export default GetFirstBonus;
