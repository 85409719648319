import React                        from 'react';
import { IS_DEV }                   from '../../config';

export default class WidgetProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemDisabled: 0,
            userInfo: props.userInfo
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.userInfo !== this.props.userInfo) this.setState({userInfo: newProps.userInfo});
    }

    render() {
        const { userInfo } = this.state;
        const { lang } = this.props;

        let name, pic;

        if(userInfo) {
            name = userInfo.account.name ? userInfo.account.name : '';
            name += userInfo.account.surname ? ' ' + userInfo.account.surname : '';
            name = name ? name : userInfo.account.phone;

            pic = userInfo.account.picture_sizes ? userInfo.account.picture_sizes['320'] : '/static/img/avatar.head.svg';
        }

        return (
            <div className="drop-wrapper t-right profile">
                <div className="drop-inner t-right t-p-0">
                    <div className="widget-profile">
                        <div className="widget-profile-user">
                            <div className="user">
                                <img src={pic} alt=""/>
                                {/*<span className="success"></span>*/}
                            </div>
                            <div className="cnt">
                                <span className="name">{name}</span>
                                <span className="balance">{lang.widgBalance}: {userInfo.sum ? userInfo.sum : '0'} <span className="als-rubl">₽</span></span>
                                {IS_DEV ? '<span class="company">ООО «Телесистемы»</span>' : null}
                            </div>
                        </div>

                        <div className="widget-profile-menu">
                            <ul className="list-unstyled menu">
                                {/*<li>
									<a href="#" className="item">
										<span className="icon">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" className="svg">
											  <g transform="translate(-48 -408)">
												<path className="cls-1" d="M0,0H12V12H0Z" transform="translate(48 408)"/>
												<path className="cls-2" d="M-836.5,222a1.5,1.5,0,0,1-1.5-1.5V219a1.5,1.5,0,0,1,1.5-1.5h8A1.5,1.5,0,0,1-827,219v1.5a1.5,1.5,0,0,1-1.5,1.5Zm-.5-3v1.5a.5.5,0,0,0,.5.5h8a.5.5,0,0,0,.5-.5V219a.5.5,0,0,0-.5-.5h-8A.5.5,0,0,0-837,219Zm.5-2.5A1.5,1.5,0,0,1-838,215v-1.5a1.5,1.5,0,0,1,1.5-1.5h8a1.5,1.5,0,0,1,1.5,1.5V215a1.5,1.5,0,0,1-1.5,1.5Zm-.5-3V215a.5.5,0,0,0,.5.5h8a.5.5,0,0,0,.5-.5v-1.5a.5.5,0,0,0-.5-.5h-8A.5.5,0,0,0-837,213.5Z" transform="translate(886.5 197)"/>
											  </g>
											</svg>
										</span>
										<span>Мои перевозки</span>
										<span className="arrow">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" className="svg">
												<g transform="translate(532 -432) rotate(90)">
													<path className="cls-1" d="M0,0H12V12H0Z" transform="translate(432 520)"/>
													<path className="cls-2" d="M10.51,6.217,5.889,1.6,1.268,6.217A.5.5,0,0,1,.561,5.51L5.51.561a.565.565,0,0,1,.757,0l4.95,4.95a.5.5,0,0,1-.707.707Z" transform="translate(432.111 522.611)"/>
												</g>
											</svg>
										</span>
									</a>
								</li>
								<li>
									<a href="#" className="item">
										<span className="icon">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" className="svg">
											  <g transform="translate(-1207 -386)">
												<rect className="cls-1" width="12" height="12" transform="translate(1207 386)"/>
												<path className="cls-2" d="M0,9.5A1.5,1.5,0,1,1,1.5,11,1.5,1.5,0,0,1,0,9.5Zm1,0A.5.5,0,1,0,1.5,9,.5.5,0,0,0,1,9.5Zm7.5.5h-4a.5.5,0,1,1,0-1h4a1.5,1.5,0,0,0,0-3h-6a2.5,2.5,0,1,1,0-5h4a.5.5,0,0,1,0,1h-4a1.5,1.5,0,1,0,0,3h6a2.5,2.5,0,0,1,0,5ZM8,1.5A1.5,1.5,0,1,1,9.5,3,1.5,1.5,0,0,1,8,1.5Zm1,0A.5.5,0,1,0,9.5,1a.5.5,0,0,0-.5.5Z" transform="translate(1207.5 386.5)"/>
											  </g>
											</svg>
										</span>
										<span>Текущий заказ</span>
										<span className="arrow">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" className="svg">
												<g transform="translate(532 -432) rotate(90)">
													<path className="cls-1" d="M0,0H12V12H0Z" transform="translate(432 520)"/>
													<path className="cls-2" d="M10.51,6.217,5.889,1.6,1.268,6.217A.5.5,0,0,1,.561,5.51L5.51.561a.565.565,0,0,1,.757,0l4.95,4.95a.5.5,0,0,1-.707.707Z" transform="translate(432.111 522.611)"/>
												</g>
											</svg>
										</span>
									</a>
								</li>
								<li>
									<a href="#" className="item">
										<span className="icon">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" className="svg">
											  <g transform="translate(-48 -168)">
												<path className="cls-1" d="M0,0H12V12H0Z" transform="translate(48 168)"/>
												<path className="cls-2" d="M-2311.54-295h0v0a5.476,5.476,0,0,1-1.258-3.5,5.506,5.506,0,0,1,5.5-5.5,5.506,5.506,0,0,1,5.5,5.5,5.475,5.475,0,0,1-1.258,3.5v0h0a5.491,5.491,0,0,1-4.24,2A5.491,5.491,0,0,1-2311.54-295Zm4.24,1a4.483,4.483,0,0,0,3.058-1.2,3.521,3.521,0,0,0-3.058-1.8,3.522,3.522,0,0,0-3.058,1.8A4.483,4.483,0,0,0-2307.3-294Zm2.5-5.5a2.494,2.494,0,0,1-.8,1.834A4.526,4.526,0,0,1-2303.56-296a4.476,4.476,0,0,0,.76-2.5,4.505,4.505,0,0,0-4.5-4.5,4.5,4.5,0,0,0-4.5,4.5,4.475,4.475,0,0,0,.759,2.5,4.525,4.525,0,0,1,2.043-1.666,2.492,2.492,0,0,1-.8-1.833,2.5,2.5,0,0,1,2.5-2.5A2.5,2.5,0,0,1-2304.8-299.5Zm-2.471,1.5a1.5,1.5,0,0,0,1.471-1.5,1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.471,1.5h.058Z" transform="translate(2361.3 472.5)"/>
											  </g>
											</svg>
										</span>
										<span>Профиль</span>
										<span className="arrow">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" className="svg">
												<g transform="translate(532 -432) rotate(90)">
													<path className="cls-1" d="M0,0H12V12H0Z" transform="translate(432 520)"/>
													<path className="cls-2" d="M10.51,6.217,5.889,1.6,1.268,6.217A.5.5,0,0,1,.561,5.51L5.51.561a.565.565,0,0,1,.757,0l4.95,4.95a.5.5,0,0,1-.707.707Z" transform="translate(432.111 522.611)"/>
												</g>
											</svg>
										</span>
									</a>
								</li>
								<li>
									<a href="#" className="item">
										<span className="icon">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" className="svg">
											  <g transform="translate(-48 -88)">
												<path className="cls-1" d="M0,0H12V12H0Z" transform="translate(48 88)"/>
												<path className="cls-2" d="M4.058,10.166,4.014,9.85a4.721,4.721,0,0,1-.525-.217l-.244.19a.966.966,0,0,1-1.27-.076l-.7-.7a.965.965,0,0,1-.089-1.27l.193-.254A4.543,4.543,0,0,1,1.156,7L.85,6.958a.966.966,0,0,1-.845-.95L0,5.021a.966.966,0,0,1,.835-.962l.315-.043a4.552,4.552,0,0,1,.217-.527l-.187-.244a.966.966,0,0,1,.074-1.269l.695-.7a.966.966,0,0,1,1.269-.09l.256.194A4.624,4.624,0,0,1,4,1.157L4.041.85A.966.966,0,0,1,4.99.005L5.979,0h.006A.966.966,0,0,1,6.94.833l.044.318a4.536,4.536,0,0,1,.528.218l.242-.189a.967.967,0,0,1,1.269.074l.706.7a.966.966,0,0,1,.09,1.269l-.195.257A4.618,4.618,0,0,1,9.842,4l.3.038a.965.965,0,0,1,.846.95L11,5.98a.966.966,0,0,1-.833.962l-.319.043a4.539,4.539,0,0,1-.217.526l.19.244a.966.966,0,0,1-.076,1.27l-.7.7a.965.965,0,0,1-1.27.089l-.256-.194A4.538,4.538,0,0,1,7,9.843l-.038.307a.966.966,0,0,1-.95.844l-.988,0S4.124,10.643,4.058,10.166ZM3.484,8.625a.963.963,0,0,1,.427.1,3.585,3.585,0,0,0,.443.183A.958.958,0,0,1,5,9.69L5.046,10l.922,0L6,9.695a.969.969,0,0,1,.661-.8,3.19,3.19,0,0,0,.431-.18.957.957,0,0,1,1.012.095L8.358,9l.65-.655-.185-.237a.97.97,0,0,1-.1-1.032,3.16,3.16,0,0,0,.178-.434A.959.959,0,0,1,9.688,6L10,5.958l-.007-.922L9.7,5a.969.969,0,0,1-.8-.661A3.14,3.14,0,0,0,8.718,3.9a.959.959,0,0,1,.095-1.012L9,2.638l-.656-.646-.234.183a.97.97,0,0,1-1.02.1,3.6,3.6,0,0,0-.445-.184A.957.957,0,0,1,6,1.311L5.953,1l-.922.007L5,1.3a.97.97,0,0,1-.651.8,3.582,3.582,0,0,0-.444.185.958.958,0,0,1-1.013-.095L2.64,2l-.65.655.183.234a.969.969,0,0,1,.1,1.032,3.192,3.192,0,0,0-.178.434A.957.957,0,0,1,1.309,5L1,5.043l0,.921L1.3,6a.969.969,0,0,1,.793.647,4.058,4.058,0,0,0,.191.457.957.957,0,0,1-.1,1L2,8.355,2.652,9l.237-.185a.964.964,0,0,1,.582-.2ZM3,5.5A2.5,2.5,0,1,1,5.5,8,2.5,2.5,0,0,1,3,5.5Zm1,0A1.5,1.5,0,1,0,5.5,4,1.5,1.5,0,0,0,4,5.5Z" transform="translate(48.5 88.5)"/>
											  </g>
											</svg>
										</span>
										<span>Настройки</span>
										<span className="arrow">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" className="svg">
												<g transform="translate(532 -432) rotate(90)">
													<path className="cls-1" d="M0,0H12V12H0Z" transform="translate(432 520)"/>
													<path className="cls-2" d="M10.51,6.217,5.889,1.6,1.268,6.217A.5.5,0,0,1,.561,5.51L5.51.561a.565.565,0,0,1,.757,0l4.95,4.95a.5.5,0,0,1-.707.707Z" transform="translate(432.111 522.611)"/>
												</g>
											</svg>
										</span>
									</a>
								</li> */}
								<li>
									<a href='/lk/orders' className="item">
										<span className="icon">
											<i className="img-icon lk"/>
										</span>
										<span>{lang.HeaderSmall_lk}</span>
										<span className="arrow"/>
									</a>
								</li>
                                <li>
									<span onClick={this.props.logout} className="item">
										<span className="icon t-out">
											<i className="img-icon out"/>
										</span>
										<span id="BtnEx">{lang.WidgetLoginBtnEx}</span>
										<span className="arrow"/>
									</span>
                                </li>
                            </ul>
                        </div>

                        {/*

						<a href="#" className="widget-profile-company">
							<span className="icon">
								<img src="static/img/avatar.head.svg" alt=""/>
							</span>
							<div className="cnt">
								<span className="name">Виктор Цой</span>
								<span className="type">Частное лицо</span>
							</div>
							<span className="arrow">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" className="svg">
									<g transform="translate(532 -432) rotate(90)">
										<path className="cls-1" d="M0,0H12V12H0Z" transform="translate(432 520)"/>
										<path className="cls-2" d="M10.51,6.217,5.889,1.6,1.268,6.217A.5.5,0,0,1,.561,5.51L5.51.561a.565.565,0,0,1,.757,0l4.95,4.95a.5.5,0,0,1-.707.707Z" transform="translate(432.111 522.611)"/>
									</g>
								</svg>
							</span>
						</a>

						<a href="#" className="widget-profile-add">
							<span className="icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svg">
								  <g transform="translate(-296 1202)">
									<circle className="cls-1" cx="12" cy="12" r="12" transform="translate(296 -1202)"/>
									<path className="cls-2" d="M5.5,9.5v-3h-3a.5.5,0,0,1,0-1h3v-3a.5.5,0,0,1,1,0v3h3a.5.5,0,0,1,0,1h-3v3a.5.5,0,0,1-1,0Z" transform="translate(302 -1196)"/>
								  </g>
								</svg>
							</span>
							<span>Добавить аккаунт</span>
						</a>
						*/}
                    </div>
                </div>
            </div>
        );
    }
}