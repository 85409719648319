import React from 'react';

export default class DropTypePoint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    setType1 = (event) => {
        const { index } = this.props;
        this.props.setType(event, 'Точка погрузки', index);
    };

    setType2 = (event) => {
        const { index } = this.props;

        this.props.setType(event, 'Точка разгрузки', index);
    };

    render() {
		const { index } = this.props;

        return (
            <div id={"calculatorDropPoint" + index} className="drop-point-type">
                <div id={"calculatorDropPointInner" + index} className="type-item" onClick={this.setType1}>Точка погрузки</div>
                <div id={"calculatorDropPointOuter" + index} className="type-item" onClick={this.setType2}>Точка разгрузки</div>
            </div>
        );
    }

}