'use strict';
import Drafts           from '../API/drafts';
import moment           from 'moment';
import Router           from 'next/router';

function getInitialState() {
    return {
        isLoadingList: true,
        isLoadingOrder: true,
        itemsFull: [],
        itemsList: {},
        filters: {
            phone: null,
            calendar: null,
            orderId: null,
            status: []
        },
        currentDraft: [],
        cars: {},
        updateIndex: 0,
        curDraft: null,
        reload: false
    };
}

function draftsPreprocessor(items) {
    let unicueOrders = [], result = {};

    items.forEach((item, i) => {
        //if(unicueOrders.indexOf(draftId) !== -1 || (filters && !filters.status.length ? item.status !== 'completed' : false)) return;
        moment.locale('ru');
        let cost = item.cost;
        let fixedDiscount = item.fixed_discount;
        let percentDiscount = item.percent_discount;
        let totalCost = item.total_cost || cost;
        let draftId = item.id;
        let paymentMethod = item.pay_method;

        let tariff = item.extra_search_params ? item.extra_search_params.tariff_tier.name : 'нет тарифа';
        let points = [];

        let arrivalTime = item.destinations && item.destinations[0] && item.destinations[0].arrival_time || item.created_at;

        let dateOrder = moment(arrivalTime);
        let dateDisplay = dateOrder.format('DD MMMM YYYY');
        let dateTimestamp = dateOrder.valueOf();
        let dayTimestamp = moment(dateDisplay, 'DD MMMM YYYY').valueOf();
        if(!result[dayTimestamp]) result[dayTimestamp] = [];

        if(item.destinations.length) {
            points = [item.destinations[0].destination.addr];

            if(item.destinations.length > 2) {
                if(item.destinations.length > 3) {
                    // @Todo Сделать склонение адресов
                    points.push('Еще ' + (item.destinations.length - 2) + ' адреса');
                } else {
                    points.push(item.destinations[1].destination.addr);
                }
            }

            points.push(item.destinations[item.destinations.length - 1].destination.addr);
        }

        let endOrderDate = 'Отложенный заказ';
        let distance = item.distance;

        let status = item.taxi_search && item.taxi_search.status || 'stopped';
        let searchExecuted = [ 'search', 'search_via_links' ].indexOf(status) !== -1;

        unicueOrders.push(draftId);

        result[dayTimestamp].push({
            dateTimestamp,  // Время в миллисекундах, для сортировок
            cost,           // Стоимость работы
            fixedDiscount,  // фиксированная скидка
            percentDiscount, // процентная скидка
            totalCost,       // финальная стоимость со скидками
            draftId,        // ID черновика
            dateDisplay,    // Время для отображения
            paymentMethod,  // Способ оплаты
            tariff,         // Тариф
            points,         // Адреса
            endOrderDate,   // Время завершения заказа
            distance,        // дистанция расстояние расчетное
            searchExecuted,   // Признак того, что поиск запущен
            status
        })
    });

    return result;
}

function draftPreprocessor(item) {
    moment.locale('ru');

    let arrivalTime = item.destinations && item.destinations[0] && item.destinations[0].arrival_time || item.created_at;

    let dateOrder = moment(arrivalTime);
    let dateDisplay = dateOrder.format('DD MMMM YYYY в HH:mm');
    let orderId = item.id;
    let id = item.id;
    let cost = item.cost;
    let fixedDiscount = item.fixed_discount;
    let percentDiscount = item.percent_discount;
    let totalCost = item.total_cost || cost;
    let paymentStatus = item.payment_status;
    let paymentMethod = item.pay_method;

    let coords = item.destinations.map((point) => {
        return {
            latitude: point.destination.lat,
            longitude: point.destination.lon,
            name: point.destination.addr
        }
    });

    let points = item.destinations.map((info) => {
        return {
            addr: info.destination.addr,
            elevator: info.destination.elevator,
            floor: info.destination.floor,
            lifting: info.destination.lifting,
            contact_name: info.contact_name || item.client.name,
            contact_phone: info.contact_phone || item.client.phone
        }
    });

    let driver = {
        name: 'Не указано',
        phone: 'Не указано',
        email: 'Не указано',
        rating: 'Не указано',
        img: 'Не указано',
    };
    let car = {
        name: 'Не указано',
        dlina: 'Не указано',
        ob_em_m3: 'Не указано',
        shirina: 'Не указано',
        vyisota: 'Не указано',
        number: 'Не указано',
        tip_kuzova: 'Не указано',
    };

    let company = {
        name: item.client.name,
        phone: item.client.phone,
        rating: item.client.rating,
        img: item.client.picture
    };

    let loaders = {
        count: item.loaders
    };

    let invoice = {
        cost: item.cost,
        discount: item.discount,
        total_cost: item.total_cost,
        period_start: 'Не указано',
        period_end: 'Не указано',
    };

    let endOrderDate = moment().format('hh:mm DD MMMM YYYY');
    let distance = item.distance;
    let tariff = item.extra_search_params ? item.extra_search_params.tariff_tier.name : 'Тариф не указан';
    let tariffIdentifier = item.extra_search_params.tariff_tier.identifier;
    let searchStatus = item.taxi_search ? item.taxi_search.status : null;

    let route = item.points;

    let calculation = {
    	additional_hour_price: item.calculation ? item.calculation.transport_tariff.additional_hour_price : 'Не указано',
		after_mkad_price: item.calculation ? item.calculation.transport_tariff.after_mkad_price : 'Не указано',
		min_price: item.calculation ? item.calculation.transport_tariff.min_price : 'Не указано'
	};

	let features = item.features.map((info) => {
		return {
			identifier: info.feature.identifier,
			name: info.feature.name,
			list: info.feature.list,
			value: info.value
		}
	});

    let status = item.taxi_search && item.taxi_search.status || 'stopped';
    let searchExecuted = [ 'search', 'search_via_links' ].indexOf(status) !== -1;

    let result = {
        route,
        searchStatus,
        id,
        orderId,
        dateDisplay,
        paymentStatus,
        paymentMethod,
        cost,
        fixedDiscount,  // фиксированная скидка
        percentDiscount, // процентная скидка
        totalCost,       // финальная стоимость со скидками
        status,
        points,
        driver,
        car,
        coords,
        company,
        loaders,
        invoice,
        endOrderDate,
        distance,
        tariff,
		tariffIdentifier,
		calculation,
		features,
        searchExecuted
    };

    return result;
}

const drafts = {
    state: getInitialState(),
    reducers: {
        updateDrafts(state, payload) {
            return Object.assign({}, state, payload);
        },
        loadingList(state, payload) {
            return Object.assign({}, state, { isLoadingList: payload });
        },
        loadingOrder(state, payload) {
            return Object.assign({}, state, { isLoadingOrder: payload });
        },
        setFilter(state, payload) {
            return Object.assign({}, state, { filters: payload });
        },
        // Обновление авто на карте.
        searchState(state, payload) {
            let { cars } = state;

            cars[payload.draft] = payload.found.map((car) => {
                return {
                    orderId: car.order.id,
                    loaders: car.loaders,
                    driver: car.driver,
                    point: car.point,
                    transport: car.transport
                }

            });

            return Object.assign({}, state, { cars, updateIndex: ++state.updateIndex });
        },
        saveDraft (state, payload) {
            return Object.assign({}, state, { curDraft: payload });
        }
    },
    effects: (dispatch) => ({
        // Добавление фильтра к заказу
        async setFilterAsync(data, rootState) {
            let { filters } = rootState.orders;
            let newFilters = Object.assign({}, filters, data);

            dispatch.orders.getOrders(newFilters);
        },
        async getDrafts(data) {
            // /customer/me/orders/drafts.json GET
            const resultDraftList = {};
            dispatch.drafts.loadingList(true);

            const res = await Drafts.getDrafts();

            if(res.success) {
                if(res.drafts.length) {
                    resultDraftList.itemsList = draftsPreprocessor(res.drafts, data);
                    resultDraftList.itemsFull = res.drafts;
                    resultDraftList.itemsList.draftsLength = res.drafts.length;

                } else {
                    resultDraftList.itemsList = {
                        draftsLength: 0
                    };
                    resultDraftList.itemsFull = [];
                }
                resultDraftList.currentDraft = [];
                resultDraftList.isLoadingList = false;
                resultDraftList.isLoadingOrder = false;
            }

            if(data) resultDraftList.filters = data;

            dispatch.drafts.updateDrafts(resultDraftList);
        },

        async getDraft(data, rootState) {
            let { role } = rootState.user;
            const result = {};

            dispatch.drafts.loadingOrder(true);

            const res = await Drafts.getDraft(data);

            if(res.success) {
                result.currentDraft = draftPreprocessor(res.draft);
                result.isLoadingOrder = false;
            }

            dispatch.drafts.updateDrafts(result);
        },

        // @Todo Новый поиск
        async newSearch(data) {
            dispatch.notification.add({
                title: 'Начат новый подбор',
                message: 'Заказ №' + data.draft,
                level: 'info'
            });
        },

        async stopOrder(data, rootState) {
            let res = await Drafts.stopSearch(data);

            if(res.success) {
                Router.push('/lk/drafts');
            }
        }
    })
};

export default drafts;



