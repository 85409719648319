import React            from 'react';
import cx               from 'classnames';
import GoodsComplete    from '../GoodsComplete';
import Hint             from '../Hint';
import ImageUpload      from '../uploaderPhoto';

export default class CalculatorStep2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            goods: props.data.goods,
            goodsServices: props.data.goodsServices,
            error: props.errors && props.errors.step === 2 ? props.errors : null,
            paymentType: props.data.paymentType
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.errors && newProps.errors.step === 2) this.setState({error: newProps.errors});
    }

    switchCargoStorage = () => {
        this.setState({
            goodsServices: {
                storage: {
                    active: this.state.goodsServices.storage.active ? 0 : 1
                }
            }
        }, async () => {
            await this.props.updateData({ goodsServices: this.state.goodsServices }, 'goodsServices');

            console.log(this.state.goodsServices.storage.active, this.state.paymentType);
            if(this.state.goodsServices.storage.active && (this.state.paymentType === 'cache' || this.state.paymentType === 'cash')) {
                this.state.paymentType = 'card';
                await this.props.updateData({ paymentType: this.state.paymentType }, 'paymentType');
            }
        })
    }

    // Быстрый выбор типа груза
    setGoodType = async (index, type) => {
        let goods = this.state.goods;
        let good = goods[index];
        good.goodType = type;
        goods.splice(index, 1, good);
        this.setState({ goods }, async () => {
            await this.props.updateData({ goods }, 'goods');
        });
    };

    // Быстрый выбор типа груза
    saveGoodType = async (item, goodType, index) => {
        let goods = this.state.goods;
        let good = goods[index];
        good.goodType = item.goodType;

        goods.splice(index, 1, good);

        this.setState({goods: goods}, async () => {
            await this.props.updateData({ goods }, 'goods');
        });
    };

    addItem = () => {
        this.state.goods.push({
            goodType: '',
            photos: [],
            comment: ''
        }); //тут изменяем стейт напрямую


        this.setState({goods: this.state.goods}, () => {
            let elem = document.querySelector('.calculator-content.step2').parentElement;
            elem.scrollTo(0, elem.clientHeight);
        }); //заставляем изменения примениться
    };

    //Удаляем заданный элемент:
    deleteItem = (index) => {
        let goods = this.state.goods;
        goods.splice(index, 1);

        if(!goods.length) {
            goods.push({
                goodType: '',
                photos: [],
                comment: ''
            })
        }

        this.setState({ goods }, async () => {
            await this.props.updateData({ goods }, 'goods');
        });
    };

    cleanItem = (index) => {
        let goods = this.state.goods;

        goods[index].goodType = '';

        /*goods.splice(index, 1);
        goods.push({
            goodType: '',
            photos: [],
            comment: ''
        });*/

        this.setState({ goods }, async () => {
            await this.props.updateData({ goods }, 'goods');
        });
    };

    changeItem = async (index, { target = {} }) => {
        const { value = '' } = target;
        let goods = this.state.goods;
        let good = goods[index];
        good.comment = value;

        goods.splice(index, 1, good);

        this.setState({ goods }, async () => {
            await this.props.updateData({ goods }, 'goods');
        });
    };

    fileUpload = (index, file) => {
        let goods = this.state.goods;
        let good = goods[index];

        //good.photos = [file];
		good.photos.push(file);
        //goods.splice(index, 1, good);

        this.setState({ goods }, async () => {
            await this.props.updateData({ goods }, 'goods');
        });

        console.log(goods);
    };

    deletePhoto = (index, item) => {
        let goods = this.state.goods;
        let good = goods[index];

        //good.photos = [];
		good.photos.splice(item, 1);
        //goods.splice(index, 1, good);

        this.setState({ goods }, async () => {
            await this.props.updateData({ goods }, 'goods');
        });

		console.log(goods);
    };

    renderItem = (item, index, goods) => {
        const { lang } = this.props;
        let good = goods[index];
        //let hasComment = (good.comment || good.photos.length > 0);

        const images = () => {
            return (
                <div>
					{good.photos.length > 0 ? (
						<div className="photo-list">
							{good.photos.map((g, i) => {
								let customStyles = {
									backgroundImage: 'url(' + g.imagePreviewUrl + ')',
									backgroundSize: '50px'
								};

								return (
									<div id={"calculatorCargoPhoto" + i} className="photo-item" style={customStyles} key={i}>
										<span id={"calculatorCargoPhotoDel" + i} className="delete" onClick={() => this.deletePhoto(index, i)}/>
									</div>
								)
							})}
						</div>
					) : null}

                    <div id={"calculatorCargoUpload" + index} className="cargo-item__add-photo">
                        <ImageUpload id={"calculatorCargoUploadBtn" + index} fileUpload={this.fileUpload} lang={lang} index={index}/>
                    </div>
                </div>
            );
        };

    	return (
            <div id={"calculatorCargoWrapper" + index} className="cargo-item" key={index}>
                <div className="cargo-item__settings">
                    <label className="cargo-item__title">{lang.Calculator_cargo} {index + 1}</label>

                    { this.state.goods.length > 1 ? (<span id={"calculatorCargoItemDel" + index} className="cargo-item__delete" onClick={this.deleteItem.bind(this, index)}>{lang.Calculator_del_cargo}</span>) : null }
                    <GoodsComplete
                        goods={goods}
                        index={index}
                        goodType={item.goodType}
                        placeholder='Тип груза'
                        saveGoodType={this.saveGoodType}
                        cleanItem={this.cleanItem}
                    />
                    <div id={"calculatorCargoTags" + index} className="cargo-tags">
                        <span id="tag1" className="tag" onClick={this.setGoodType.bind(this, index, lang.CalcStep2_cargo_tag_1)}>+ {lang.CalcStep2_cargo_tag_1}</span>
                        <span id="tag2" className="tag" onClick={this.setGoodType.bind(this, index, lang.CalcStep2_cargo_tag_2)}>+ {lang.CalcStep2_cargo_tag_2}</span>
                        <span id="tag3" className="tag" onClick={this.setGoodType.bind(this, index, lang.CalcStep2_cargo_tag_3)}>+ {lang.CalcStep2_cargo_tag_3}</span>
						<span id="tag4" className="tag" onClick={this.setGoodType.bind(this, index, lang.CalcStep2_cargo_tag_4)}>+ {lang.CalcStep2_cargo_tag_4}</span>
						<span id="tag5" className="tag" onClick={this.setGoodType.bind(this, index, lang.CalcStep2_cargo_tag_5)}>+ {lang.CalcStep2_cargo_tag_5}</span>
                    </div>
                </div>

				<div id={"item_comment" + index} className="cargo-item__comment t-1">
					<textarea rows="2" id={"calculatorCargoComment" + index} value={item.comment} onChange={this.changeItem.bind(this, index)} placeholder={lang.Calculator_add_comment}/>
					{images()}
				</div>
            </div>
		)
	};

    render() {
        const { lang, isActive } = this.props;
        const { goods } = this.state;

        const itemCSS = cx({
            'calculator-content': true,
            'step2': true,
            'active': isActive,
        });

        return (
            <div className={itemCSS} >
                <h2 className="title">{lang.Calculator_add_cargo}</h2>

                <h3 className="sub-title">Грузы</h3>

				<div className="cargo-wrap">
                    {goods.map(this.renderItem)}

                    { goods[goods.length -1].goodType ? (<span id="calculatorCargoAdd" className="cargo__add-item" onClick={this.addItem}>{lang.Calculator_add_cargo}</span>) : null }
				</div>


                <h3 className="sub-title">{lang.Calculator_additional_services}</h3>

                <div className="cargo-wrap">
                    <div className="cargo-item">
                        <div className="storage__settings" onClick={this.switchCargoStorage}>
                            <input type="checkbox" checked={!!this.state.goodsServices.storage.active} name="cargoStorage" value="1"/>
                            <label className="cargo-item__title">{lang.Calculator_storage}</label>
                            <div className="qb-logo"/>
                            <div className="note">{lang.Calculator_storage_note}</div>
                            <div className="controls">
                                <div className="row">
                                    <div className="price">{lang.Calculator_storage_price}<span className="period">/ {lang.Calculator_storage_period}</span></div>
                                    <div className="control"><div className="switch"/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Hint idWrapper="calculatorCargoHint" idClose="calculatorCargoHintClose" deleteError={this.props.deleteError} title={lang.CalcStep2_cargo_hint_title} desc={lang.CalcStep2_cargo_hint_desc} type="info" />

            </div>
        );
    }
}
