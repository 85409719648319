import React                from 'react';
import Link                 from 'next/link';

export default class AvailableDevices extends React.Component {
    render() {

		const { lang } = this.props;

        return (
            <div className="s-index-devices">
                <div className="container">
                    <h2 className="s-index__title">{lang.Index_devices_title}</h2>
                    <p className="subtitle">{lang.Index_devices_subtitle}</p>

                    <div className="index-devices row">
                        <div className="index-devices-item col-3">
                            <div className="img" onClick={this.props.openCalc}>
								<div className="img-icon macbook"/>
                            </div>
                            <div className="cnt">
                                <div className="type t-1" onClick={this.props.openCalc} dangerouslySetInnerHTML={{__html: lang.Index_devices_type_1}} />
                                <p className="desc">{lang.Index_devices_desc_1}</p>
                            </div>
                        </div>

                        <div className="index-devices-item col-3">
							<Link href="/app#section-5">
								<a className="img">
									<div className="img-icon driver"/>
								</a>
							</Link>
                            <div className="cnt">
                                <Link href="/app#section-5">
                                    <a className="type t-4" dangerouslySetInnerHTML={{__html: lang.Index_devices_type_2}} />
                                </Link>
                                <p className="desc">{lang.Index_devices_desc_2}</p>
                            </div>
                        </div>

                        <div className="index-devices-item col-3">
							<Link href='/app#section-1'>
								<a className="img">
									<div className="img-icon iphones"/>
								</a>
							</Link>
                            <div className="cnt">
								<Link href='/app#section-1'>
                                    <a className="type t-2" dangerouslySetInnerHTML={{__html: lang.Index_devices_type_3}} />
                                </Link>
                                <p className="desc">{lang.Index_devices_desc_3}</p>
                            </div>
                        </div>

                        <div className="index-devices-item col-3">
							<Link href='/app#section-1'>
								<a className="img">
									<div className="img-icon android"/>
								</a>
							</Link>
                            <div className="cnt">
								<Link href='/app#section-1'>
                                    <a className="type t-3" dangerouslySetInnerHTML={{__html: lang.Index_devices_type_4}} />
                                </Link>
                                <p className="desc">{lang.Index_devices_desc_4}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}