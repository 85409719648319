import React            from 'react';
import ReactDOM         from 'react-dom';
import Modal            from 'react-modal';
import PropTypes        from 'prop-types';
import Head             from '../components/Head';
import Header           from '../components/Header';
import BusinessForm     from '../components/BusinessForm';
import BusinessForm2     from '../components/BusinessForm2';
import BusinessForm3     from '../components/BusinessForm3';
import WidgetCalculator from '../components/Calculator';
import Footer           from '../components/Footer';
import Calculator       from '../API/calculator';
import isServer         from 'detect-node';
import Router           from 'next/router';
import css              from '../scss/main.scss';
import {trackEventWithData} from "../utils/tracking";
import Loader from "../components/Loader";
import {getYclid, getYmClient, saveYclidIf} from "../utils/helpers";
import Drafts from "../API/drafts"

Modal.setAppElement('#__next');

class Layout extends React.PureComponent {
    constructor (props) {
        super(props);

        this.headerRef = React.createRef();

        this.defaultData = {
            promoCode: '',
            arrivalTime: null,
            points: [{
                adress: {
                    longitude: 37.455598,
                    latitude: 55.726650,
                    formated_address: ''
                },
                typePoint: "Точка погрузки",
                placeholder: "Адрес подачи",
                floor: 0,
                lift: 0,
                comment: '',
                contactName: '',
                contactPhone: '',
                defaultValue: true
            }, {
                adress: {
                    longitude: 37.455598,
                    latitude: 55.729650,
                    formated_address: ''
                },
                typePoint: "Точка разгрузки",
                placeholder: "Адрес разгрузки",
                floor: 0,
                lift: 0,
                comment: '',
                contactName: '',
                contactPhone: '',
                defaultValue: true
            }],
            goods: [{
                goodType: '',
                photos: [],
                comment: ''
            }],
            advanceService: {
                assembly: 0,
                lifting: 0
            },
            goodsServices: {
              storage: {
                  active: 0
              }
            },
            stevedores: {
                value: 0,
                advanceInfo: {
                    price: 0
                }
            },
            tariff: {
                value: 'standart2',
                name: 'Стандарт',
                minPrice: 990,
                type: 'Фургон, Цельнометалический',
                capacities: '_1,1_5,_2,2_5'
            },
            selectedGroupLengthValue: [],
            selectedGroupLength: [],
            carSize: [],
            paymentType: 'cash',
            driver: null,
            files: []
        };
        let data, phone = '';

        if(!isServer) {
            data = Object.assign({}, this.defaultData, JSON.parse(localStorage.getItem('curDraft')));
            phone = JSON.parse(localStorage.getItem('phone')) || '';
        } else {
            data = Object.assign({}, this.defaultData);
        }

        this.state = {
            headerBigClass: props.headerClass,
            calcIsOpen: false,
            businessIsOpen: false,
            businessIsOpen2: false,
            businessIsOpen3: false,
            phone: phone,
            data: data,
            tariffs: null,
            calculated: null,
            info: null,
            // curDraft: { draftId: 13827 },
            // draftId: 13827,
            curDraft: null,
            draftId: null,
            selectedDriver: null,
            isFinish: false,
            userInfo: null,
            flagupdate: false,
            show: false,
			calcInfoOpen: false,
            loaderIsVisible: false
        };

        this.searchTimer = null;

        if(!isServer) window.startLoadTariffs = this.startLoadTariffs;
    }

    componentWillReceiveProps(newProps) {
        if (this.props.activeOrder !== newProps.activeOrder) {
            this.setState({ show: true });
        }
    }

    startLoadTariffs = async() => {
        let info = await Calculator.prepareData({
            latitude: '55.726650',
            longitude: '37.455598',
            userData: this.state.data
        });

        let tariffs = Calculator.preparationData(info.tariffs);
        let data = Calculator.setGroupLength(tariffs, this.state.data.tariff.value);

        let newData = Object.assign({}, this.state.data, {
            selectedGroupLengthValue: data.selectedGroupLengthValue,
            selectedGroupLength: data.selectedGroupLength,
        });

        this.setState({
            info,
            tariffs: info.tariffs,
            data: newData
        });
    };

    getUserPosition = async() => {
        let position = await Calculator.getUserPosition();

        let data = this.state.data;
        let points = data.points;
        let point = points[0];

        point.adress = {
            longitude: position.longitude,
            latitude: position.latitude,
            formated_address: position.data.formated_address
        };

        point.defaultValue = false;

        points.splice(0, 1, point);

        data.points = points;

        let newData = Object.assign({}, data);

        let info = await Calculator.prepareData({
            latitude: newData.points[0].adress.latitude,
            longitude: newData.points[0].adress.longitude,
            userData: newData
        });

        this.setInfo( info );
        this.setState({ data: newData, position, flagupdate: !this.state.flagupdate });
    };

    componentDidMount(){
        this.props.wsConnect();
        this.startLoadTariffs();
        saveYclidIf();
    }

    componentDidCatch(error, info) {
        // if(!isServer) localStorage.removeItem('curDraft');
        //location.reload();
    }

    openCalc = () => {
        this.setState({ calcIsOpen: true });
    };

    closeCalc = () => {
        this.setState({ calcIsOpen: false });
    };

    closeModalRefresh = () => {
        this.setState({
            modalIsOpen: false,
            calcInfoOpen: false,
            calcIsOpen: false,
            data: this.defaultData,
        }, () => {
            this.setState({
                curDraft: null,
                draftId: null,
                isFinish: false
            });
        });
        localStorage.removeItem('curDraft');
    };

    setData = (data) => {
        let newData = Object.assign({}, this.state.data, data);

        this.setState({data: newData });
        this.props.calculatorUpdate({ errors: null });

        if(data.paymentType) {
            localStorage.setItem('curDraft', JSON.stringify(data));
        }
    };

    setGroupLength = (data) => {
        let newData = Object.assign({}, this.state.data, data);

        this.setState({data: newData});
    };

    setInfo = (info) => {
        let newInfo = Object.assign({}, this.state.info, info);

        this.setState({info: newInfo});
    };

    updateHeaderClass = (val) => {
        this.setState({headerBigClass: val});
    };

    agreeOrder = async (orderId) => {
        let resp = await Calculator.agreeOrder(orderId);
        return resp;
    };

    cancelOrder = async(orderId) => {
        let resp = await Calculator.cancelOrder(orderId);
        return resp;
    };

    acceptedOrder = (id) => {
        this.props.acceptedOrder(id);
        this.setState({ show: false });
    };

    declinedOrder = async (id, draftId) => {
        this.props.declinedOrder(id);
        this.props.searchStart({draftId});
        this.setState({ show: false });
    };

    openBusinessForm = () => {
        this.setState({ businessIsOpen: true });
    };

    openBusinessForm2 = () => {
        this.setState({ businessIsOpen2: true });
    };

    openBusinessForm3 = () => {
        this.setState({ businessIsOpen3: true });
    };

    closeBusinessForm = () => {
        this.setState({ businessIsOpen: false });
    };

    closeBusinessForm2 = () => {
        this.setState({ businessIsOpen2: false });
    };

    closeBusinessForm3 = () => {
        this.setState({ businessIsOpen3: false });
    };
    // Запускаем поиск машины
    searchStart = async(draftId) => {
        let resp = await Calculator.searchStart(draftId);

        if(resp.success) {
            trackEventWithData('search_start', { draft: draftId });
        }
        /*
            @ToDo Закрывает калькулятор

            setTimeout(() => {
                this.setState({
                    calcIsOpen: false,
                    calcInfoOpen: false
                });

                Router.push('/lk/orders');
            }, 2500);
            */
    };

    createDraft = async () => {
        this.showLoader();

        let { data } = this.state;
        let resp = await Calculator.createDraft(data, !!data.curDraft);

        this.hideLoader();

        if(resp.draftId) {
            trackEventWithData('draft_created', { draft: resp.draftId });
            let ymClientId = await getYmClient();
            Drafts.sendYmDraftLink(resp.draftId, ymClientId, getYclid());

            let curDraft = {...(this.state.curDraft || {}), draftId: resp.draftId};
            this.setState({ calcInfoOpen: true, draftId: resp.draftId, curDraft });

            if(!data.goodsServices.storage.active) {
                await this.searchStart(resp.draftId, true);
            }
        } else {
            if(resp.code !== 409) {
                this.setState({
                    errors: {
                        step: 5,
                        information: {
                            text: 'Пройдите авторизацию, чтобы продолжить',
                            field: 'userphone'
                        }
                    }
                });
            }
        }

        // this.setState({
        //     modalIsOpen: false,
        //     data: this.defaultData,
        // });

        // localStorage.removeItem('curDraft');
    };

    resetDraftCreation = () => {
        let newState = {...this.state, draftId: null};
        if(newState.curDraft)
            delete newState.curDraft.draftId;

        this.setState(newState);
    }

    searchStop = async (draftId) => {
        let data = await Calculator.searchStop(draftId);
        if(data.success) {
            this.resetDraftCreation();
        } else {
            if(data.code === 404) this.resetDraftCreation();
            if(data.code === 409) this.resetDraftCreation();
        }

        clearTimeout(this.searchTimer);
    };

    onClickLayout = e => {
        let headerEl = ReactDOM.findDOMNode(this.headerRef.current.getWrappedInstance());

        if(headerEl && !headerEl.contains(e.target)) {
            this.headerRef.current.getWrappedInstance().menuClose();
        }
    }

    showLoader = () => {
        this.setState({ loaderIsVisible: true });
    }

    hideLoader = () => {
        this.setState({ loaderIsVisible: false });
    }

    render() {
        const { pathname, children, userInfo, activeOrder, title, description, lang } = this.props;
        const { show, loaderIsVisible } = this.state;
        const customStylesBusiness = {
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
            },
            content : {
                width                 : '538px',
                height                : '538px',
                top                   : '50%',
                left                  : '50%',
                right                 : 'auto',
                bottom                : 'auto',
                marginRight           : '-50%',
                transform             : 'translate(-50%, -50%)',
                borderRadius          : '0px',
                backgroundColor       : '#ffffff',
                boxShadow             : '0 8px 40px 0 rgba(0, 0, 0, 0.16)',
                border                : 'none',
                overflow              : 'hidden',
                padding               : '0'
            }
        };

        const customStyles = {
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
            },
            content : {
                width                 : '868px', // 808px
                height                : 'auto', // 778px
                top                   : '0',
                left                  : '0',
                right                 : '0',
                bottom                : '0',
                transform             : 'translate(0, 0)',
                borderRadius          : '0',
                backgroundColor       : 'transparent',
                boxShadow             : 'none',
                border                : 'none',
                overflow              : 'hidden',
				position              : 'relative',
				padding               : '30px 30px'
            }
        };

        const toEnrich = {
            flagupdate: this.state.flagupdate,
            openbussiness: this.openBusinessForm,
            openbussiness2: this.openBusinessForm2,
            openbussiness3: this.openBusinessForm3,
            getUserPosition: this.getUserPosition,
            data: this.state.data,
            info: this.state.info,
            calculated: this.state.calculated,
            openCalc: this.openCalc,
            setInfo: this.setInfo,
            setGroupLength: this.setGroupLength,
            setData: this.setData,
            tariffs: this.state.tariffs,
            Calculator: Calculator
        };

        // @todo curLang - обработать локализацию страницы при первом запуске сервера. { childrenWithProps }

        return (
            <div onClick={this.onClickLayout}>
                { loaderIsVisible ? (<Loader/>) : null }
                <Head title={title} description={description} />
                <Header
                    ref={this.headerRef}
                    pathname={pathname}
                    userInfo={userInfo}
                    openCalc={this.openCalc}
                    headerClass={this.state.headerBigClass}
                />

                {React.cloneElement(children, toEnrich)}

                <Modal
                    isOpen={this.state.businessIsOpen}
                    onRequestClose={this.closeBusinessForm}
                    style={customStylesBusiness}
                    portalClassName="businessBox"
                >
                    <BusinessForm />
                </Modal>

                <Modal
                    isOpen={this.state.businessIsOpen2}
                    onRequestClose={this.closeBusinessForm2}
                    style={customStylesBusiness}
                    portalClassName="businessBox"
                >
                    <BusinessForm2 />
                </Modal>

                <Modal
                    isOpen={this.state.businessIsOpen3}
                    onRequestClose={this.closeBusinessForm3}
                    style={customStylesBusiness}
                    portalClassName="businessBox"
                >
                    <BusinessForm3 />
                </Modal>

                <Modal
                    isOpen={this.state.calcIsOpen}
                    onRequestClose={this.closeCalc}
                    style={customStyles}
                    portalClassName="orderBox"
                >
                    <WidgetCalculator
                        getUserPosition={this.getUserPosition}
                        lang={lang}
                        isFinish={this.state.isFinish}
                        data={this.state.data}
                        phone={this.state.phone}
                        driver={this.state.driver}
                        info={this.state.info}
                        tariffs={this.state.tariffs}
                        curDraft={this.state.curDraft}
                        draftId={this.state.draftId}
                        selectedDriver={this.state.selectedDriver}
                        userInfo={this.state.userInfo}
                        setData={this.setData}
                        setInfo={this.setInfo}
                        createDraft={this.createDraft}
                        searchStart={this.searchStart}
                        searchStop={this.searchStop}
                        agreeOrder={this.agreeOrder}
                        cancelOrder={this.cancelOrder}
                        closeModalRefresh={this.closeModalRefresh}
                        setGroupLength={this.setGroupLength}
						calcInfoOpen={this.state.calcInfoOpen}
                        mask={loaderIsVisible}
                    />
                </Modal>

                <Footer />
            </div>
        )
    }
}

Layout.propTypes = {
    to: PropTypes.string
};

Layout.defaultProps = {
    to: '/private',
};

export default Layout;
