import React from 'react';
import { IMaskInput } from 'react-imask';
import moment from 'moment';
import cx from "classnames";

export default class WidgetLogin extends React.Component {
    constructor(props) {
        super(props);
        const { lang } = props;

        this.state = {
            type: props.type,
            phone: '',
            pass: '',
            errors: null,
            time: '00:00',
            actionType: props.type === 1 ? 'login' : null,
        };

        this.timer = null;

        this.error400 = <span className="form-help t-error">{lang.BusinessNoNumber}</span>;
        this.error401 = <span className="form-help t-error">{lang.BusinessDenied}</span>;
        this.error403 = <span className="form-help t-error">{lang.BusinessNoLogin}</span>;
        this.error404 = <span className="form-help t-error">{lang.BusinessNoAcc}</span>;
        this.error409 = <span className="form-help t-error">{lang.BusinessReged}</span>;
        this.error500 = <span className="form-help t-error">{lang.BusinessSysErr}</span>;
        this.error601 = <span className="form-help t-error">{lang.BusinessPutNumber}</span>;
    }

    startTimer = () => {
        var duration = 1 * 60 * 1000; // 7 min
        var interval = 1000;

        this.timer = setInterval(() => {
            if(duration > 0) {
                duration = moment.duration(duration - interval, 'milliseconds');
                this.setState({time: duration.minutes() + ":" + duration.seconds()})
            } else {
                clearInterval(this.timer);
                this.setState({time: "00:00"})
            }

        }, interval);
    };

    componentWillUnmount () {
        clearInterval(this.timer);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.errors !== this.props.errors) this.setState({errors: newProps.errors});
    }

    // Обработчики ввода в инпуты
    setPhone = (value) => {
        const { phone, type } = this.state;

        if(phone !== value) {
            this.setState({phone: value});

            if (type === 1 && value.length == 11) {
                document.getElementById('inp-pass').focus();
            }
        }
    };

    setPassword = ({ target = {} }) => {
        const { value = '' } = target;
        const { pass } = this.state;

        this.setState({ pass: value });
    };

    // Общение с сервером
    actionLogin = () => {
        const { phone, pass } = this.state;
        if(phone.length < 11) {
            this.setState({ errors: 400 });
            return
        }

        this.props.login(phone, pass);
    };

    actionSendCode = async() => {
        const { phone } = this.state;

        if(this.state.time === '00:00') {

            if(phone.length < 11) {
                this.setState({ errors: 400 });
                return
            }

            let data = await this.props.resetPassword(phone);

            if(data.success) { //resp.code === 404
                this.setState({
                    errors: null,
                    pass: '',
                    type: 3
                });

                this.startTimer();
            } else {
                if(data.code === 404) this.setState({ errors: 404 });
            }
        }
    };

    actionCheckCode = () => {
        const { phone, pass } = this.state;

        this.props.login(phone, pass);
    };

    actionRegistration = async() => {
        const { phone } = this.state;

        if(phone.length < 11) {
            this.setState({ errors: 400 });
            return
        }

        let data = await this.props.registration(phone);

        if(data.success ) { //resp.code === 404
            this.setState({
                errors: null,
                pass: '',
                type: 3
            });

            this.startTimer();
        } else {
            if(data.code === 400) this.setState({ errors: 400 });
            if(data.code === 404) this.setState({ errors: 404 });
            if(data.code === 409) this.setState({ errors: 409 });
            if(data.code === 500) this.setState({ errors: 500 });
        }
    };

    // Форма входа
    setViewLogin = () => {
        this.setState({ type: 1, errors: null, });
    };

    // Форма сброса пароля
    setViewResetPassword = () => {
        clearInterval(this.timer);
        this.setState({ type: 2, errors: null, time: '00:00' });
    };

    // Форма ввода кода
    setViewRegistration = () => {
        this.setState({ type: 4, actionType: 'registration', errors: null, });
    };

    /**
     * Обработка нажатия клавиши:
     * если нажатие enter, отправка запроса на сервер
     * @param event - событие
     */
    keyEnter = (event) => {
        event = event || window.event;
        const keyCode = event.keyCode;
        let { phone, pass, type } = this.state;

        if (keyCode === 13) {
            if(type === 1 && phone && phone.length == 11 && !pass) {
                document.getElementById('inp-pass').focus();
                return;
            }
            if(type === 1 && pass && !phone) {
                document.getElementById('inp-login').focus();
                return;
            }

            if(type === 1) this.actionLogin();
            if(type === 2) this.actionSendCode();
            if(type === 3) this.actionCheckCode();
            if(type === 4) this.actionRegistration();
        }

    };

    renderLogin = () => {
        const { lang } = this.props;

        const { phone, pass, errors} = this.state;
        let error400 = errors && errors === 400 ? this.error400 : null;
        let error401 = errors && errors === 401 ? this.error401 : null;
        let error403 = errors && errors === 403 ? this.error403 : null;
        let error404 = errors && errors === 404 ? this.error404 : null;
        let error500 = errors && errors === 500 ? this.error500 : null;
        let error601 = errors && errors === 601 ? this.error601 : null;
        let disabled = (!phone || !pass) || phone.length !== 11 ? 'disabled' : '';

        return <div className="widget-login">
            <h5 className="title">{lang.WidgetLoginTitle}</h5>
            <div className="drop-form">
                <div className="form-group">
                    <IMaskInput
                        mask='+{7} (000) 000 00 00'
                        unmask={true}
                        className="form-control"
                        onAccept={
                            // depending on prop above first argument is
                            // `value` if `unmask=false`,
                            // `unmaskedValue` if `unmask=true`,
                            // `typedValue` if `unmask='typed'`
                            (value, mask) => {
                                this.setPhone(value)
                            }
                        }
                        autoCorrect="off"
                        autoCapitalize="off"
                        autoComplete="nope"
                        placeholder={lang.PhoneNumber1}
                        value={this.state.phone || ''}
                        onKeyDown={this.keyEnter}
                        id="inp-login"
                    />
                </div>
                <div className="form-group">
                    <input type="password"
					   id="inp-pass"
					   autoComplete="new-password"
					   autoCorrect="off"
					   autoCapitalize="off"
					   onChange={this.setPassword}
					   defaultValue={this.state.pass}
					   className="form-control"
					   placeholder={lang.WidgetPasswordPlaceholder}
					   onKeyDown={this.keyEnter}
                    />
                    {error400}
                    {error401}
                    {error403}
                    {error404}
                    {error500}
                    {error601}
                </div>
                <div className="form-group">
                    <button type="button" id="put_btn" disabled={disabled} onClick={this.actionLogin} className="btn btn-primary btn-block">{lang.WidgetLoginBtn}</button>
                </div>
            </div>
            <div className="widget-login-links">
                <div className="item">
                    <span onClick={this.setViewResetPassword} className="link" id="resPass">{lang.WidgetViewResetPassword}</span>
                </div>
                <div className="item">
                    <span onClick={this.setViewRegistration} className="link" id="viewReg">{lang.WidgetViewRegistration}</span>
                </div>
            </div>
        </div>
    };

    renderResetPassword = () => {
        const { lang } = this.props;
        const { phone, errors } = this.state;

        let error404 = errors && errors === 404 ? this.error404 : null;
        let disabled = !phone || phone.length !== 11 ? 'disabled' : '';

        return <div className="widget-login">
            <h5 className="title">{lang.WidgetResetPasswordTitle}</h5>
            <div className="drop-form">
                <div className="form-group">
                    <IMaskInput
                        mask='+{7} (000) 000 00 00'
                        unmask={true}
                        className="form-control"
                        onAccept={
                            // depending on prop above first argument is
                            // `value` if `unmask=false`,
                            // `unmaskedValue` if `unmask=true`,
                            // `typedValue` if `unmask='typed'`
                            (value, mask) => {
                                this.setPhone(value)
                            }
                        }
                        autoCorrect="off"
                        autoCapitalize="off"
                        autoComplete="nope"
                        placeholder={lang.PhoneNumber1}
                        value={this.state.phone || ''}
                        onKeyDown={this.keyEnter}
                        id="inp-login"
                    />
                    {error404}
                </div>
                <div className="form-group">
                    <button type="button" disabled={disabled} onClick={this.actionSendCode} className="btn btn-primary btn-block">{lang.WidgetResetPasswordBtn}</button>
                </div>
            </div>
            <div className="widget-login-links">
                <div className="item">
                    <span onClick={this.setViewLogin} className="link">{lang.WidgetViewLogin}</span>
                </div>
                <div className="item">
                    <span onClick={this.setViewRegistration} className="link">{lang.WidgetViewRegistration}</span>
                </div>
            </div>
        </div>
    };

    renderChangePhone = () => {
        const { lang } = this.props;
        const { phone, errors } = this.state;

        let error404 = errors && errors === 404 ? this.error404 : null;
        let disabled = !phone || phone.length !== 11 ? 'disabled' : '';

        return <div className="widget-login">
            <h5 className="title">{lang.WidgetViewChangePhone}</h5>
            <div className="drop-form">
                <div className="form-group">
                    <IMaskInput
                        mask='+{7} (000) 000 00 00'
                        unmask={true}
                        className="form-control"
                        onAccept={
                            // depending on prop above first argument is
                            // `value` if `unmask=false`,
                            // `unmaskedValue` if `unmask=true`,
                            // `typedValue` if `unmask='typed'`
                            (value, mask) => {
                                this.setPhone(value)
                            }
                        }
                        autoCorrect="off"
                        autoCapitalize="off"
                        autoComplete="nope"
                        placeholder={lang.PhoneNumber1}
                        value={this.state.phone || ''}
                        onKeyDown={this.keyEnter}
                        id="inp-login"
                    />
                    {error404}
                </div>
                <div className="form-group">
                    <button type="button" disabled={disabled} onClick={this.actionSendCode} className="btn btn-primary btn-block">{lang.WidgetResetPasswordBtn}</button>
                </div>
            </div>
            <div className="widget-login-links">
                <div className="item">
                    <span onClick={this.setViewLogin} className="link">{lang.WidgetViewLogin}</span>
                </div>
                <div className="item">
                    <span onClick={this.setViewRegistration} className="link">{lang.WidgetViewRegistration}</span>
                </div>
            </div>
        </div>
    };

    renderCode = () => {
        const { lang } = this.props;
        const { phone, pass, errors, time, actionType } = this.state;

        let error401 = errors && errors === 401 ? this.error401 : null;
        let error404 = errors && errors === 404 ? this.error404 : null;
        let disabled = !pass ? 'disabled' : '';

        let classResendBtn = cx({
            'widget-login-timer': true,
            't-disabled': time !== '00:00'
        });

        let resendAgain = <div onClick={this.actionSendCode} className={classResendBtn}>{lang.sendAgain} {time}</div>;

        return <div className="widget-login">
            <h5 className="title">{lang.WidgetCodeTitle}</h5>
            <div className="drop-form">
                <div className="form-group">
                    <input
                        type="text"
                        tabIndex='0'
                        className="form-control"
                        onChange={this.setPassword}
                        defaultValue={this.state.pass}
                        placeholder="Код из СМС"
                        onKeyDown={this.keyEnter}
                        id="inp-pass"
                    />
                    <span className="form-help t-success">{lang.sendOn} +{phone}</span>
                    {error401}
                    {error404}
                </div>
                <div className="form-group">
                    <button type="button" disabled={disabled} onClick={this.actionCheckCode} className="btn btn-primary btn-block">{lang.WidgetConfirmBtn}</button>
                </div>
            </div>
            <div className="widget-login-links">
                <div className="item">
                    {actionType === 'login' ? <span onClick={this.setViewResetPassword} className="link change-number">{lang.WidgetViewChangePhone}</span> : null}
                    {actionType === 'registration' ? <span onClick={this.setViewRegistration} className="link change-number">{lang.WidgetViewChangePhone}</span> : null}
                </div>
            </div>

            { resendAgain }
        </div>
    };

    renderRegistration = () => {
        const { lang } = this.props;
        const { phone, errors } = this.state;

        let error400 = errors && errors === 400 ? this.error400 : null;
        let error404 = errors && errors === 404 ? this.error404 : null;
        let error409 = errors && errors === 409 ? this.error409 : null;
        let error500 = errors && errors === 500 ? this.error500 : null;
        let disabled = !phone || phone.length !== 11 ? 'disabled' : '';

        return <div className="widget-login">
            <h5 className="title">{lang.WidgetViewRegistration}</h5>
            <div className="drop-form">
                <div className="form-group">
                    <IMaskInput
                        mask='+{7} (000) 000 00 00'
                        unmask={true}
                        className="form-control"
                        onAccept={
                            // depending on prop above first argument is
                            // `value` if `unmask=false`,
                            // `unmaskedValue` if `unmask=true`,
                            // `typedValue` if `unmask='typed'`
                            (value, mask) => {
                                this.setPhone(value)
                            }
                        }
                        autoCorrect="off"
                        autoCapitalize="off"
                        autoComplete="nope"
                        placeholder={lang.PhoneNumber1}
                        value={this.state.phone || ''}
                        onKeyDown={this.keyEnter}
                        id="inp-login"
                    />
                    {error400}
                    {error404}
                    {error409}
                    {error500}
                </div>
                <div className="form-group">
                    <button type="button" disabled={disabled} onClick={this.actionRegistration} className="btn btn-primary btn-block">{lang.WidgetConfirmBtn}</button>
                </div>
            </div>
            <div className="widget-login-links">
                <div className="item">
                    <span onClick={this.setViewLogin} className="link enter">{lang.WidgetViewLogin}</span>
                </div>
            </div>
        </div>
    };

    render() {
        const { type } = this.state;
        return (
            <div className="drop-wrapper t-right login">
                <div className="drop-inner t-right">
                    {type === 1 ? this.renderLogin() : null}
                    {type === 2 ? this.renderResetPassword() : null}
                    {type === 3 ? this.renderCode() : null}
                    {type === 4 ? this.renderRegistration() : null}
                </div>
            </div>
        );
    }
}