import React from 'react';
import cx from 'classnames';
import {DateUtils} from "../../utils/date";

export default class CalculatorSideBarStep6 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    setActive = (val) => {
        //this.props.setActive(6);
    };

    searchStart = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.props.searchStart();

		setTimeout(() => {
			if (document.getElementsByClassName('hint')) {
				let elem = document.getElementsByClassName('calculator-inner');
				elem[0].scrollTo(0, elem[0].clientHeight);
			}
		}, 250);
    };

    render() {
        const { lang, step, info } = this.props;
        let totalPrice, totalHours, time, distanceKm, distanceMKAD;

        if(info && info.preparedInfo.tariff && info.prices[info.preparedInfo.tariff.value]) {
            let tar = info.prices[info.preparedInfo.tariff.value].price;

            totalPrice = tar.total_price;
            totalHours = DateUtils.minutesToDisplay(tar.tariff.minutes);
            time = tar.tariff.minutes;
            distanceKm = tar.tariff.distance ? (Math.round((tar.tariff.distance - (tar.tariff.after_mkad_distance ? tar.tariff.after_mkad_distance : 0)) / 1000) + 'км') : '0км';
			distanceMKAD = tar.tariff.after_mkad_distance ? (' + ' + Math.round(tar.tariff.after_mkad_distance / 1000) + 'км за МКАД' ) : ' + 0км за МКАД';
        }

		let isActive = step === 6;

        const hasBuild = false;

        const itemCSS = cx({
            'Сalculator-sideBar-item': true,
            'item-6': true,
            'active': isActive,
            'empty': !hasBuild
        });


        let disabled = '';

        const buildEmpty = (
            <div className="build-empty">
                <div className="row">
                    <div className="icon"/>
                    <div className="title-wrap">
						<div className="info">
							<div className="left-info">
								{time ? (<span className="time">
									<i className="icon"/>
									<div id="calculatorSBFullTime" className="value">~ <span>{totalHours}</span></div>
								</span>) : null }
                                <div className="distance">
                                    <span id="calculatorSBFullKm">{distanceKm}</span>
                                    <span id="calculatorSBFullMKAD">{distanceMKAD}</span>
                                </div>
							</div>
							<span id="calculatorSBFullPrice" className="price">{totalPrice} <span className="als-rubl">₽</span>{/*<span className="count">/{totalHours}{lang.CalcSidebar_step6_empty_count}</span>*/}</span>
						</div>
						<div className="btn-submit">
							<button disabled={disabled} type="button" id="calculatorSBFullBtn" className="btn btn-warning btn-block" onClick={this.searchStart}>{lang.Order}</button>
						</div>
                    </div>
                </div>
            </div>
        );

        const buildFull = (
            <div className="build-full">
                <div className="row">
                    <div className="icon"/>
                    <div className="title-wrap">
                        <span>{lang.CalcSidebar_step6_full_title}</span>
                    </div>
                </div>
            </div>
        );

        return (
            <div className={itemCSS} onClick={this.setActive}>
                {hasBuild ? buildFull : buildEmpty}
            </div>
        );
    }
}
