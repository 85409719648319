import Head from 'next/head';

export default ({title, description}) => (
    <div>
        <Head>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0" />
            <meta property="og:title" content={title} />
            <meta property="og:url" content="http://mover24.ru/" />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="https://mover24.ru/static/img/Web_Link_Cover.png" />
            <meta property="og:type" content="website" />
            <meta name="theme-color" content="#000000" />
            <meta name="apple-mobile-web-app-title" content={description}/>
            <meta name="apple-mobile-web-app-capable" content="yes"/>
            <meta name="apple-mobile-web-app-status-bar-style" content="#333333"/>
            <meta name="yandex-verification" content="9ecc4d9633a63a78" />

            <link rel="icon" type="image/x-icon" href="/static/img/favicon.ico" />
            <script async src="https://mc.yandex.ru/metrika/watch.js" />
            <script src="//code-sb1.jivosite.com/widget/Zmqdh41vjT" async></script>
        </Head>
    </div>
)
