import React from 'react';
import moment from 'moment';
import Calculator from '../../API/calculator';
import Router from "next/router";

export default class CalculatorOrder extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
            isFinish: props.isFinish
		};
	}

    componentDidMount() {
	    // Отправляем смс с пречеком
        // if(!this.props.driver) {
        //     Calculator.orderDone(this.props.draftId);
        // }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.isFinish) this.setState({isFinish: newProps.isFinish});
    }

    agreeOrder = async () => {
        let data = await this.props.agreeOrder(this.props.driver.orderId);

        this.setState({
            isFinish: data.success
        });

        localStorage.removeItem('curDraft');
    };

    cancelOrder = () => {
        this.props.cancelOrder(this.props.driver.orderId).then((data) => {
            this.setState({ isFinish: false });
            this.props.searchStart();
        });
    };

    accept = () => {
        const { draftId, driver, lang, identifier } = this.props;
        let arrivalTime = moment().add(driver.arrival_time, 'minutes').format('HH:mm');
        let fullName = (driver.employer.account.name ? driver.employer.account.name : '' ) + ' ' +
            (driver.employer.account.surname ? driver.employer.account.surname : '');

        let time = (moment(driver.order.periods[0].end) - moment(driver.order.periods[0].start)) / 3600000 ; // Время в часах с остатком в минутах
        let timeFloor = Math.floor(time);
        let timeMod = time - timeFloor;
        time = timeMod < 0.16666666666 ? timeFloor :  Math.ceil(time);

		let tariffImg = '/static/img/tariffs/' + identifier + '_active@2x.png';

        return (
            <div className="max-width t-2">
                <div className="cnt">
                    <p className="title">{lang.CalcOrder_title} #{draftId} {lang.CalcOrder_title_1}</p>
                    <span className="count" dangerouslySetInnerHTML={{__html: lang.CalcOrder_accept_title}}></span>
                </div>

                <div className="calculator-order-crew">
                    <div className="calculator-order-crew-head">
                        <span className="title">{lang.CalcOrder_accept_crew_head_title}</span>
                        <div className="buttons">
                            <button type="button" className="btn btn-outline-secondary" onClick={this.cancelOrder}>{lang.CalcOrder_accept_crew_head_btn}</button>
                        </div>
                    </div>

                    <div className="calculator-order-crew-cnt">
                        <div className="column-item">
                            <div className="calculator-order-crew-user">
                                <div className="img"></div>
                                <div className="cnt">
                                    <span className="user">{fullName}<span className="rating"><i className="icon"></i>{driver.employer.account.rating}</span></span>
                                    <span className="char">{driver.arrival_time} {lang.CalcOrder_accept_crew_user_char}</span>
                                </div>
                            </div>

                            <div className="car-img">
                                <img src={tariffImg} alt="" width="278" height="130"/>
                            </div>
                            <p className="car-desc">
								{lang.CalcOrder_accept_crew_cnt_car_desc}
                            </p>
                        </div>
                        <div className="column-item">
                            <div className="calculator-order-crew-char">
                                <span className="car-name">{ driver.transport.car_model }</span>
                                <ul className="list-unstyled clearfix type-list">
                                    <li><img src="img/index-delivery/ic_size_weight.svg" alt="" width="12" height="12"/>{lang.CalcOrder_accept_crew_char_type_1}</li>
                                    <li><img src="img/index-delivery/ic_size_weight.svg" alt="" width="12" height="12"/>{lang.CalcOrder_accept_crew_char_type_2}</li>
                                </ul>
                                <ul className="list-unstyled char-list">
                                    <li className="t-1">
                                        <i className="icon"></i>
                                        <span className="type">{lang.CalcOrder_accept_crew_char_list_1_type}</span>
                                        <span className="count">{ driver.transport.features.Vyisota } {lang.CalcOrder_size}</span>
                                    </li>
                                    <li className="t-2">
                                        <i className="icon"></i>
                                        <span className="type">{lang.CalcOrder_accept_crew_char_list_2_type}</span>
                                        <span className="count">{ driver.transport.features.Dlina } {lang.CalcOrder_size}</span>
                                    </li>
                                    <li className="t-3">
                                        <i className="icon"></i>
                                        <span className="type">{lang.CalcOrder_accept_crew_char_list_3_type}</span>
                                        <span className="count">{ driver.transport.features.Shirina } {lang.CalcOrder_size}</span>
                                    </li>
                                    <li className="t-4">
                                        <i className="icon"></i>
                                        <span className="type">{lang.CalcOrder_accept_crew_char_list_4_type}</span>
                                        <span className="count">{ driver.transport.features.Ob_em_m3 } {lang.CalcOrder_size}³</span>
                                    </li>
                                </ul>
                                <div className="char-help">
                                    <i className="icon"></i>
                                    <span className="type">{lang.CalcOrder_accept_crew_char_help_type}</span>
                                    <span className="price">{driver.price.ttk_price} Р</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="calculator-order-crew-foot">
                        <div className="column-item">
                            <ul className="list-unstyled clearfix char-list">
                                <li>
                                    <span className="title">{lang.CalcOrder_accept_crew_foot_char_list_1_title}</span>
                                    <span className="count">{arrivalTime}</span>
                                </li>
                                <li>
                                    <span className="title">{lang.CalcOrder_accept_crew_foot_char_list_2_title}</span>
                                    <span className="count">{time} {lang.CalcOrder_time}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="column-item">
                            <span className="price">{driver.order.cost} Р</span>
                            <button type="button" className="btn btn-success" onClick={this.agreeOrder}>{lang.CalcOrder_accept_crew_foot_btn}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    onClickQuestions = () => {
        // if(window.Intercom)
        //     window.Intercom('show');
    }

    onClickPrivateArea = (draftId) => {
        Router.push('/lk/drafts/' + draftId);
    }

	search = () => {
        const { draftId, phone, lang, mode } = this.props;
        let modes = {
            urgent: 'CalcOrder_search_desc_urgent',
            pending: 'CalcOrder_search_desc_pending',
            with_storage: 'CalcOrder_search_desc_with_storage'
        }

        let descId = modes[mode] || modes['urgent'];
        let desc = lang[descId];

        return (
            <div className="max-width t-1">
                <div className="cnt">
                    <p className="title">{lang.CalcOrder_title_draft_received}</p>

                    <div className="pic"></div>

                    <span className="count">{lang.CalcOrder_search_draft_number} #{draftId}</span>
                    <p className="desc-title">{lang.CalcOrder_search_form_desc_title}</p>
                    <p className="desc">
						{desc}
                    </p>
                </div>

                {/*<div className="calculator-order-form">*/}
                {/*    <p className="desc-title">{lang.CalcOrder_search_form_desc_title}</p>*/}
                {/*    <p className="desc">*/}
				{/*		{lang.CalcOrder_search_form_desc}*/}
                {/*    </p>*/}
                {/*    <div className="input active">*/}
                {/*        <i className="icon"></i>*/}
                {/*        <input type="tel" disabled className="form-control" value={phone}/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="controls">
                    <button className="questions-btn" type="button" onClick={() => this.onClickQuestions()}>{lang.CalcOrder_search_form_have_any_question}</button>
                    <button className="private-btn" type="button" onClick={() => this.onClickPrivateArea(draftId)}>{lang.CalcOrder_search_form_private_area}</button>
                </div>

				{/*<div className="calculator-order-progress">*/}
				{/*	<div className="progress-line"></div>*/}
				{/*	<div className="title">Обработка заказа</div>*/}
				{/*</div>*/}
            </div>
		)
	};

	finish = () => {
        const { draftId, driver, lang } = this.props;
        let fullName = '', rating = '', phone = '';

        if(driver) {
            fullName = (driver.employer.account.name ? driver.employer.account.name : '' ) + ' ' +
                (driver.employer.account.surname ? driver.employer.account.surname : '');

            rating = driver.employer.account.rating;
            phone = driver.employer.account.phone;
        }

	    return (
            <div className="calculator-order-success">
                <div className="max-width">
                    <div className="cnt">
                        <p className="title">{lang.CalcOrder_title} #{draftId} {lang.CalcOrder_title_3}</p>
                        <div className="calculator-order-check t-success"></div>
                        {driver ? <div>
                                <span className="count">{lang.CalcOrder_finish_count}</span>
                                <div className="calculator-order-crew-user t-success">
                                    <div className="img"></div>

                                    <div className="cnt">
                                        <span className="user">{ fullName }<span className="rating"><i className="icon"></i>{ rating }</span></span>
                                        <span className="number">{ phone }</span>
                                    </div>

                                </div>
                            </div>
                                : null}
                        <p className="number-order">{lang.CalcOrder_finish_cnt_number} <b>#{draftId}</b></p>
                        <p className="desc">
							{lang.CalcOrder_finish_cnt_desc}
                        </p>
                        <div className="buttons">
                            <a href="http://help.mover24.ru/" target="_blank" className="btn btn-primary">{lang.CalcOrder_finish_cnt_btn}</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    render() {

        const { driver } = this.props;
        const { isFinish } = this.state;

		let isSearch = !driver;

		let process = () => {
		    return (
                <div className="calculator-order-process">
                    { isSearch ? this.search() : this.accept() }
                </div>
            )
        };

        return (
			<div>
                {isFinish ? this.finish() : process()}
			</div>
        );
    }
}
