'use strict';
import { init }         from '@rematch/core';
import * as models      from '../models';
import socketMiddleware from  '../middleware/websocket';

export const initStore = (initialState = {}) => init({
    models,
    redux: {
        initialState,
        middlewares: [socketMiddleware]
    }
});