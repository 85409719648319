import pathToRegexp     from 'path-to-regexp';
//import ym               from 'react-yandex-metrika';
import isServer         from 'detect-node';
import { SERVER }       from '../config';
import Promise from "any-promise";

/**
 * Определение типа браузера
 */
const _ua = !isServer ? navigator.userAgent.toLowerCase() : {match: () => {}};
const browser = {
    version: (_ua.match(/.+(?:me|ox|on|rv|it|era|opr|ie|edge)[\/: ]([\d.]+)/) || [0, '0'])[1],
    opera: (/opera/i.test(_ua) || /opr/i.test(_ua)),
    vivaldi: /vivaldi/i.test(_ua),
    amigo: /amigo.*mrchrome soc/i.test(_ua),
    msie: (/msie/i.test(_ua) && !/opera/i.test(_ua) || /trident\//i.test(_ua)) || /edge/i.test(_ua),
    msie6: (/msie 6/i.test(_ua) && !/opera/i.test(_ua)),
    msie7: (/msie 7/i.test(_ua) && !/opera/i.test(_ua)),
    msie8: (/msie 8/i.test(_ua) && !/opera/i.test(_ua)),
    msie9: (/msie 9/i.test(_ua) && !/opera/i.test(_ua)),
    msie_edge: (/edge/i.test(_ua) && !/opera/i.test(_ua)),
    mozilla: /firefox/i.test(_ua),
    chrome: /chrome/i.test(_ua) && !/edge/i.test(_ua),
    safari: (!(/chrome/i.test(_ua)) && /webkit|safari|khtml/i.test(_ua)),
    iphone: /iphone/i.test(_ua),
    ipod: /ipod/i.test(_ua),
    iphone4: /iphone.*OS 4/i.test(_ua),
    ipod4: /ipod.*OS 4/i.test(_ua),
    ipad: /ipad/i.test(_ua),
    android: /android/i.test(_ua),
    bada: /bada/i.test(_ua),
    mobile: /iphone|ipod|ipad|opera mini|opera mobi|iemobile|android/i.test(_ua),
    msie_mobile: /iemobile/i.test(_ua),
    safari_mobile: /iphone|ipod|ipad/i.test(_ua),
    opera_mobile: /opera mini|opera mobi/i.test(_ua),
    opera_mini: /opera mini/i.test(_ua),
    mac: /mac/i.test(_ua),
    search_bot: /(yandex|google|stackrambler|aport|slurp|msnbot|bingbot|twitterbot|ia_archiver|facebookexternalhit)/i.test(_ua)
};
const AlphabetArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'V', 'X', 'Y', 'Z'];

function headersGET(searchParams) {
    let header = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        }
    };

    if(isServer) header.headers.cookie = (SERVER === 'https://test.mover24.ru' ? 'movertest=supermover; ' : '') + (searchParams.token ? 'token=' + searchParams.token : '');

    return header;
};

function headersPUT(searchParams) {
    let header = {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        }
    };
    if(searchParams) header.body = searchParams;

    if(isServer) header.headers.cookie = (SERVER === 'https://test.mover24.ru' ? 'movertest=supermover; ' : '') + 'token=' + searchParams.token;


    return header;
};

function headersPOST(searchParams) {
    let header = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        }
    };

    // @ToDo поместить поисковые параметры в отдельное тело боди.
    if(searchParams) header.body = searchParams;

    if(isServer) header.headers.cookie = (SERVER === 'https://test.mover24.ru' ? 'movertest=supermover; ' : '') + 'token=' + searchParams.token;


    return header;
};

function headersDELETE(searchParams) {
    let header = {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        }
    };

    if(isServer) header.headers.cookie = (SERVER === 'https://test.mover24.ru' ? 'movertest=supermover; ' : '') + 'token=' + searchParams.token;

    return header;
};

// Проверка совпадения  URL с текущим адресом
function activePath(currentPath) {
    const PATH_LIST = {
        '/': 'clients',
        '/app': 'clients',
        '/carpark': 'clients',
        '/tariff': 'clients',
        '/business': 'business',
        '/lk/orders': 'orders',
        '/lk/drafts': 'drafts',
        '/lk/cars': 'cars',
        '/lk/search': 'search',
    };


    return PATH_LIST[currentPath];
}

/**
 * Прокидываем события в Яндекс метрику
 * @param event - событие
 * (a, b, callback, data), ym(a, b);
 */
function yaMetrikaInit() {
    (function (d, w, c) {
        (w[c] = w[c] || []).push(function() {
            try {
                w.yaCounter34362465 = new Ya.Metrika({
                    id:34362465,
                    clickmap:true,
                    trackLinks:true,
                    accurateTrackBounce:true,
                    webvisor:true,
                    trackHash:true
                });
            } catch(e) { }
        });

        let n = d.getElementsByTagName("script")[0],
            s = d.createElement("script"),
            f = function () { n.parentNode.insertBefore(s, n); };
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://mc.yandex.ru/metrika/watch.js";

        if (w.opera == "[object Opera]") {
            d.addEventListener("DOMContentLoaded", f, false);
        } else { f(); }
    })(document, window, "yandex_metrika_callbacks");
}

function roistatInit() {
	(function(w, d, s, h, id) {
		w.roistatProjectId = id; w.roistatHost = h;
		const p = d.location.protocol == "https:" ? "https://" : "http://";
		const u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init";
		const js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u;
		const js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
	})(window, document, 'script', 'cloud.roistat.com', 'b25fc6f8a20b537e8dd61949367cd7d0');
}

function ymElem (typeCounter, optionsCounter, callback, callbackData) {
    let yaCounter34362465 = window.yaCounter34362465 || null;

    if (yaCounter34362465 && typeCounter === 'reachGoal') yaCounter34362465.reachGoal(optionsCounter);
    if (yaCounter34362465 && typeCounter === 'extLink') yaCounter34362465.extLink(optionsCounter);
    if (yaCounter34362465 && typeCounter === 'hit') yaCounter34362465.hit(optionsCounter);
    if(callback) callback(callbackData);
}

function ymElemWithData (optionsCounter, data, callback, callbackData) {
    let yaCounter34362465 = window.yaCounter34362465 || null;

    if (yaCounter34362465) yaCounter34362465.reachGoal(optionsCounter, data, callback);
}

function getYmClient() {
    let yaCounter34362465 = window.yaCounter34362465 || null;

    if (yaCounter34362465) {
        return new Promise(function(resolve) {
            yaCounter34362465.getClientID(clientId => resolve(clientId));
        });
    } else {
        return Promise.resolve();
    }
}

function setYmUserId(userId) {
    let yaCounter34362465 = window.yaCounter34362465 || null;
    if (yaCounter34362465) {
        yaCounter34362465.setUserID(userId);
    }
}

function getUrlParam(p) {
    var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function getYclid() {
    return getCookie('yclid');
}

function saveYclidIf() {
    let yclid = getUrlParam('yclid');
    if (!yclid)
        return;

    setCookie('yclid', yclid, { expires: 90 * (24 * 60 * 60) })
}

/**
 * Остановка всплытия события
 * @param event - событие
 */
function stopEvent(event){
    event = event || window.event;
    event.stopPropagation ? event.stopPropagation() : (event.cancelBubble = true);
    event.preventDefault ? event.preventDefault() : (event.returnValue = false);
}

/**
 * Сортировка по возрастанию ASC
 * @param event - событие
 */
function sorterASC(a, b) {
    if (a > b) return 1;
    else if (a < b) return -1;
    else return 0;
}

/**
 * Сортировка по возрастанию DESC
 * @param event - событие
 */
function sorterDESC(a, b) {
    if (a > b) return -1;
    else if (a < b) return 1;
    else return 0;
}

/**
 * Генератор случайного числа в диапазоне
 * @param event - событие
 */
function rand( min, max ) {
    return Math.round( Math.random() * (max - min) + min );
}

function arrayUnique(array) {
    let a = array.concat();
    for(let i = 0; i < a.length; ++i) {
        for(let j=i+1; j < a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}


function declOfNum(number, titles) {
    let cases = [2, 0, 1, 1, 1, 2];
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}

// возвращает cookie с именем name, если есть, если нет, то undefined
function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options) {
    options = options || {};

    let expires = options.expires;

    if (typeof expires == "number" && expires) {
        let d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = name + "=" + value;

    for (let propName in options) {
        updatedCookie += "; " + propName;
        let propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
}

function deleteCookie(name) {
    setCookie(name, "", {
        expires: -1
    })
}

const statuses = {
    none: 'Статус не установлен',
    finished: 'Завершен',
    moved_to_order: 'Водитель выехал в точку погрузки',
    on_start_place: 'Водитель на месте погрузки',
    loading: 'Погрузка началась',
    moving: 'Перевозка',
    on_end_place: 'Машина в точке назначения',
    unloading: 'Разгрузка началась',
    completed: 'Заказ завершён',
    canceled_by_freighter: 'Отменен перевозчиком',
    canceled_by_client: 'Отменен клиентом',
    canceled_by_system: 'Отменен системой',
    canceled: 'Заказ отменён',
    stopped: 'Остановлен',
    pending: 'Ожидание начала поиска',
    searching: 'Идёт поиск',
    not_found: 'Перевозчик не найден',
    rejected: 'Заказ отклонён',
    declined: 'Заказ отменён',
    accepted: 'Заказ принят перевозчиком',
    wait_payment: 'Ожидание оплаты',
    wait_hand: 'Ожидание сдачи денег',
    wait_hand_accept: 'Ожидание от менеджера подтверждения сдачи денег',
    money_handed: 'Деньги сданы',
    success: 'Заказ успешно завершён',
    money_sent: 'Деньги отправлены'
};

let Timer = function(callback, delay) {
    let timerId, start, remaining = delay;

    this.pause = function() {
        clearTimeout(timerId);
        remaining -= new Date() - start;
    };

    this.resume = function() {
        start = new Date();
        clearTimeout(timerId);
        timerId = setTimeout(callback, remaining);
    };

    this.clear = function() {
        clearTimeout(timerId);
    };

    this.resume();
};

export {
    headersGET,
    headersPUT,
    headersPOST,
    headersDELETE,
    setCookie,
    getCookie,
    deleteCookie,
    declOfNum,
    arrayUnique,
    activePath,
    rand,
    sorterDESC,
    sorterASC,
    stopEvent,
    ymElem,
    ymElemWithData,
	yaMetrikaInit,
    getYmClient,
    getYclid,
	roistatInit,
    browser,
    AlphabetArray,
    statuses,
    Timer,
    saveYclidIf,
    setYmUserId
};
