import React                    from 'react';
import AdressCompleteHome       from './AddressComplete.v2';
import BusinessBottom           from './BusinessBottom';
import Calculator               from '../API/calculator';
import { ymElem, rand }         from '../utils/helpers';
import { trackEvent, trackTariff }  from '../utils/tracking'
import {DateUtils} from "../utils/date";

export default class HomeFirstSlide extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            widgetTariffState: props.data ? props.data.tariff.value : null,
            defaultTariff: props.data ? props.data.tariff.value : null
        };

        this.information = {
            mini2: {
                info: 'Идеально для доставки небольших грузов до 900 кг. и длинной от 2 м. Авто в тарифе похожие на Berlingo, Vito, Partner.',
                weightUpTo: '900кг'
            },
            standart2: {
                info: 'Стандартная газель, Crafter, Transit и похожие на них авто, для перевозки грузов до 3 м. Мин. тариф 1190 ₽ за 1 час.',
                weightUpTo: '1,5т'
            },
            extra2: {
                info: 'Увеличенный экипаж для грузов длиной от 4 м. и больше. Мин. тариф 1290 ₽ за 1 час перевозки.',
                weightUpTo: '1,5т'
            },
            maxi2: {
                info: 'Большой грузовик от 4 м. с профессиональным водителем для объемных грузов и больших переездов. Мин. тариф 3390 ₽ за 4 часа перевозки.',
                weightUpTo: '3т'
            }
        }
    }

    async widgetTariffState(index, value) {
        this.setState({ widgetTariffState: value });

        let tariff;

        this.props.tariffs.map((item) => {
            if(item.identifier === value) tariff = item;
        });

        this.setState({
            defaultTariff: {
                value: value,
                minPrice: tariff.price.total_price,
                name: tariff.name,
                type: Calculator.tariffsDescriptions[value].type
            }
        });

        await this.props.updateData({
            tariff: {
                value: value,
                minPrice: tariff.price.total_price,
                name: tariff.name,
                type: Calculator.tariffsDescriptions[value].type,
                capacities: tariff.capacities.join(',')
            }
        }, 'tariff');
    }

    renderTariff = (tariff, index) => {
        const { lang } = this.props;
        let srcActive = '/static/img/tariffs/' + tariff.identifier + '_active@2x.png';
        let type = tariff.typeCars.split(', ').join(' • ');

        let ekipap = tariff.cars_found.length ? tariff.cars_found.length : rand(4, 15);

        return (
            <div key={index} className={"widget-tariff-item" + (this.state.widgetTariffState === tariff.identifier ? " active" : "")} onClick={this.widgetTariffState.bind(this, index, tariff.identifier)}>
                <div className="widget-tariff-head">
                    <div className="img">
						<div className={"img-icon " + tariff.identifier}/>
                    </div>
                    <div className="info">
                        <span className="type">{ tariff.name } • до {this.information[tariff.identifier].weightUpTo} </span>
                        <span className="time"><i className="icon"/> {tariff.arrival_estimate ? tariff.arrival_estimate + ' мин' : 'около 20 мин'}</span>
                        <span className="price"><b>{ tariff.price && tariff.price.total_price || 0 } <span className="als-rubl">₽</span></b><span className="count">/{DateUtils.minutesToDisplay(tariff.price && tariff.price.tariff.minutes || 0)}</span></span>
                    </div>
                </div>
                <div className="widget-tariff-cnt">
                    <div className="widget-tariff-info">
                        <div className="info">
                            <span className="tariff">Тариф</span>
                            <span className="mass">{ tariff.name } <span className="count">• до {this.information[tariff.identifier].weightUpTo}</span></span>
                        </div>
                        <span className="price"><b>{ tariff.price && tariff.price.total_price || 0 } <span className="als-rubl">₽</span></b><span className="count">/{DateUtils.minutesToDisplay(tariff.price && tariff.price.tariff.minutes || 0)}</span></span>
                    </div>
                    <div className="widget-tariff-car">
                        <div className="column">
                            <div className="car">
                                <img src={srcActive} alt="" width="165" height="70"/>
                            </div>
                            <ul className="list-unstyled clearfix options-list">
                                <li className="t-1"><i className="icon"/> {tariff.capacityVolume}</li>
                                <li className="t-2"><i className="icon"/> {tariff.capacityLengths} м.</li>
                            </ul>
                        </div>
                        <div className="column">
							<div className="column-inner">
								<p className="info-1">
									{Calculator.tariffsDescriptions[tariff.identifier].desc}
								</p>
								<p className="info-2">
									{type}
								</p>
							</div>
                        </div>
                    </div>
					<div className="widget-tariff-car">
						<div className="column">
							<span className="time">
                                <i className="icon"/>
                                Подача <span>{tariff.arrival_estimate ? tariff.arrival_estimate + ' мин' : 'около 20 мин'}</span> <br/> <span>{ekipap} экипажей</span> онлайн
                            </span>
						</div>
						<div className="column">
							<div className="column-inner">
								<button type="button" onClick={() => trackTariff(tariff.identifier, this.props.openCalc)} className="btn btn-primary btn-block">{lang.Index_start_take_order}<i className="icon"/></button>
							</div>
						</div>
					</div>
                </div>
            </div>
        );
    };

    render() {
        const { lang, tariffs, data, info } = this.props;

        let distance, time;

        return (
            <div className="s-index-start">
                <div className="bg-2" />
                {/*<video id="video" width="100%" height="auto" autoPlay="autoplay" loop="loop" muted="true" preload="auto">*/}
                {/*    <source src="/static/cover.mp4"></source>*/}
                {/*</video>*/}


                <div className="container-fluid">
                    <div className="column-wrapper row">
                        <div className="column-item">
                            <h1 className="index-start__title">{lang.Index_start_1_title}</h1>
                            <p className="index-start__desc">
                                {lang.Index_start_1_desc}
                            </p>

                            <div className="index-start-type">
								<div className="wrapper-type row">
									<div className="column-type">
										<div className="item"><div className="icon ikea"/>{lang.indexFirstLanding1}</div>
									</div>
									<div className="column-type">
										<div className="item"><div className="icon taxi"/>{lang.indexFirstLanding2}</div>
									</div>
								</div>
								<div className="wrapper-type row">
									<div className="column-type">
										<div className="item"><div className="icon sklad"/>{lang.indexFirstLanding3}</div>
									</div>
									<div className="column-type">
										<div className="item"><div className="icon pereezd"/>{lang.indexFirstLanding4}</div>
									</div>
								</div>
								<div className="wrapper-type row">
									<div className="column-type">
										<div className="item"><div className="icon avito"/>{lang.indexFirstLanding5}</div>
									</div>
									<div className="column-type">
                                        <div className="item"><div className="icon gruz"/><span dangerouslySetInnerHTML={{__html:lang.indexFirstLanding6}}/></div>
									</div>
								</div>
                            </div>
                            <div className="index-start-app">
                                <ul className="list-unstyled clearfix index-start-app-list">
                                    <li><a href="https://goo.gl/Wl8y0C" target="_blank" className="google" onClick={() => ymElem('extLink', 'https://goo.gl/Wl8y0C')}/></li>
                                    <li><a href="https://apps.apple.com/ru/app/mover-грузовое-такси/id1080999192" target="_blank" className="apple" onClick={() => ymElem('extLink', 'https://apps.apple.com/ru/app/mover-грузовое-такси/id1080999192')}/></li>
                                </ul>
                            </div>

                        </div>
                        <div className="column-item">
                            <div className="widget widget-address">
                                <div className="widget-address-inputs">
                                    <div className="widget-address-input t-1 active">
                                        <i className="icon"/>
                                        <div className="input">
                                            <AdressCompleteHome
                                                value={data.points[0].adress.formated_address}
                                                index='A'
                                                typePoint={data.points[0].typePoint}
                                                placeholder={data.points[0].placeholder}
                                                savePoint={(data) => {
                                                    trackEvent('address_1');
                                                    this.props.saveAdress(data, 0);
                                                }}
                                            />
                                            <span className="geo-icon" onClick={this.props.getUserPosition}/>
                                        </div>
                                    </div>

                                    <div className="widget-address-input t-2 active">
                                        <i className="icon"/>
                                        <div className="input">
                                            <AdressCompleteHome
                                                value={data.points[1].adress.formated_address}
                                                index='B'
                                                typePoint={data.points[1].typePoint}
                                                placeholder={data.points[1].placeholder}
                                                savePoint={(data) => {
                                                    trackEvent('address_2');
                                                    this.props.saveAdress(data, 1);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="widget widget-tariff">
                                { tariffs.length ? tariffs.map(this.renderTariff) : (<div className="loader"/>) }
                            </div>
                        </div>
                    </div>
                </div>

                <BusinessBottom />
            </div>
        );
    }
}

// <p className="index-start__desc2">Попробуйте прямо сейчас с промокодом <span>START</span> на 300 ₽</p>
