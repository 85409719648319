import React        from 'react';
import Autocomplete from './Autocomplete';
import fetch        from 'isomorphic-unfetch';
import { SERVER }   from '../config';

export default class AdressComplete extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            adress: []
        };

        this.requestTimer = null;
    }

    componentWillReceiveProps(newProps) {
        if (this.state.value !== newProps.value) this.setState({value: newProps.value});
    }

    makeRequest = async (value, cb) => {
        let res = await fetch(SERVER + '/geo/address.json?address=' + value, {
            method: 'GET',
            credentials: 'include'
        }).then(res => res).catch((err) => err);

        const data = await res.json();
        cb(data.length ? data : []);
    };

    makeRequestGetCords = async (value, cb) => {
        let res = await fetch(SERVER + '/geo/location/google.json?placeId=' + value, {
            method: 'GET',
            credentials: 'include'
        }).then(res => res).catch((err) => err);

        const data = await res.json();
        cb(data);
    };

    clearInput = (item) => {
        const { typePoint, index } = this.props;

        this.props.savePoint(null, typePoint, index);
        this.setState({ value: '', adress: [] });
        document.querySelector('body').focus();
    };

    saveValues = (item) => {
        const { typePoint, index } = this.props;

        this.props.savePoint(item, typePoint, index);
    };

    render() {
        const { index, placeholder, hasError } = this.props;
        const { adress } = this.state;

        return (
            <div className={"adress-selector " + hasError}>
                <Autocomplete
                    inputProps={{ id: 'adress-selector-' + index, placeholder: placeholder }}
                    wrapperStyle={{ display: 'block', position: 'relative' }}
                    value={this.state.value}
                    items={ adress }
                    getItemValue={(item) => item.formated_address}
                    onSelect={async(value, item) => {
                        if(item.longitude && item.latitude) {
                            this.setState({ value, adress: [ item ] }, () => {
                                this.saveValues(item);
                            })
                        } else {
                            if(item.google_place_id) {
                                await this.makeRequestGetCords(item.google_place_id, (addr) => {
                                    this.setState({ value, adress: [ addr ] }, () => {
                                        this.saveValues(addr);
                                    })
                                });
                            }
                        }
                    }}
                    onChange={(event, value) => {
                        this.setState({ value });

                        if(this.requestTimer) clearTimeout(this.requestTimer);

                        this.requestTimer = setTimeout(async () => {
                            await this.makeRequest(value, (data) => { this.setState({ adress: data }) });
                        }, 1000);
                    }}

                    renderMenu={children => {
                        return (
                            <div className="wrap-controller">
                                <div id={"calculatorDeleteAddress" + index} className="delete" onClick={this.clearInput} />
                                <div className="menu">
                                    {children}
                                </div>
                            </div>
                        )
                    }}
                    renderItem={(item, isHighlighted, param, indexItem) => {
                        return (
                            <div id={"calculatorAddressItem" + index + '_' + indexItem} className={`item ${isHighlighted ? 'item-highlighted' : ''}`} key={ indexItem }>
                                {item.formated_address}
                                <span className='city'>{item.country + ', ' + item.city}</span>
                            </div>
                        );
                    }}
                />
            </div>
        );
    }
}
