import React                            from 'react';
import cx                               from 'classnames';
import { connect }                      from 'react-redux';
import Link                             from 'next/link';
import { ymElem, roistatInit }                       from '../utils/helpers';
import { trackEvent }                       from '../utils/tracking'

class Footer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
		roistatInit();
    }

    render() {
        const { lang } = this.props;

        const footerWrap = cx({
            footer: true
        });

        const langRU = cx({
            'footer-link': true,
            selected: lang.langName === 'ru'
        });

        const langEN = cx({
            'footer-link': true,
            selected: lang.langName === 'en'
        });

        return (
            <footer className={footerWrap}>
                <div className="container first-row">
                    <div className="row">
                        <div className="col-sm">
                            <span className="footer-title">{lang.FooterClients}</span>
                            <div><Link href='/app'><a className="footer-link">{lang.FooterClientsApp}</a></Link></div>
                            <div><Link href='/tariff'><a className="footer-link">{lang.FooterClientsTariff}</a></Link></div>
                            <div><Link href='/carpark'><a className="footer-link">{lang.FooterClientsFleet}</a></Link></div>
                            <div><Link href='/partners'><a className="footer-link">{lang.FooterClientsPartners}</a></Link></div>
                        </div>

                        <div className="col-sm">
                            <span className="footer-title">{lang.FooterBusiness}</span>

                            <div><Link href='/business'><a className="footer-link">{lang.FooterBusMover}</a></Link></div>
                            <div><Link href='/business'><a className="footer-link">{lang.FooterBusIntegration}</a></Link></div>
                            <div><Link href='/business'><a className="footer-link">{lang.FooterBusShop}</a></Link></div>
                            <div><Link href='/business'><a className="footer-link">{lang.FooterBusMalls}</a></Link></div>
                        </div>

                        <div className="col-sm">
                            <span className="footer-title">{lang.FooterDrivers}</span>
                            <div><Link href='http://driver.mover24.ru/'><a className="footer-link">{lang.FooterDriversConnect}</a></Link></div>
                            <div><Link href='http://driver.mover24.ru/#application-driver'><a className="footer-link">{lang.FooterDriversApp}</a></Link></div>
                            <div>
                                <Link href='http://help.mover24.ru/'>
                                    <a target='_blank' className="footer-link">{lang.FooterDriversFaq}</a>
                                </Link>
                            </div>
                            <div><Link href='http://driver.mover24.ru/#application-driver'><a className="footer-link">{lang.FooterPartnership}</a></Link></div>
                            <div><Link href='http://my-truck.ru/'><a className="footer-link">{lang.FooterDriversBlog}</a></Link></div>
                        </div>

                        <div className="col-sm">
                            <span className="footer-title">{lang.FooterAll}</span>
                            <div><Link href='/about'><a className="footer-link">{lang.FooterAllAbout}</a></Link></div>
                            <div><Link href='http://help.mover24.ru'><a className="footer-link">{lang.FooterAllSupport}</a></Link></div>
                            <div><Link href='/job'><a className="footer-link">{lang.Carrer}</a></Link></div>
                        </div>
                    </div>

                    <div className="row second-row">
                        <div className="col-sm">
                            <div className="row">
                                <div className="col-sm">
                                    <div className="footer-title-big">{lang.FooterBigContact}</div>
                                    <div className="footer-adress">
                                        <div>{lang.FooterAddress}</div>
                                        <a className="footer-mail" target="_blank" href="mailto:info@mover24.ru">info@mover24.ru</a>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="footer-social">
                                        <div className="row">
                                            <div className="col-sm">
                                                <Link href="https://vk.com/mover24"><a target='_blank' className="footer-icon vk" onClick={() => ymElem('extLink', 'https://vk.com/mover24')}/></Link>
                                            </div>
                                            <div className="col-sm">
                                                <Link href="https://twitter.com/Mover_Russia"><a target='_blank' className="footer-icon tw" onClick={() => ymElem('extLink', 'https://twitter.com/Mover_Russia')}/></Link>
                                            </div>
                                            <div className="col-sm">
                                                <Link href="https://telegram.me/movernews"><a target='_blank' className="footer-icon tg" onClick={() => ymElem('extLink', 'https://telegram.me/movernews')}/></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="row">
                                <div className="col-sm">
                                    <span className="footer-title">{lang.FooterLangReg}</span>
                                    <div><span className={langRU} onClick={() => this.props.setLang('ru')}>{lang.appLangRU}</span></div>
                                    <div><span className={langEN} onClick={() => this.props.setLang('en')}>{lang.appLangEN}</span></div>
                                </div>

                                <div className="col-sm">
                                    <span className="footer-title">{lang.FooterBlog}</span>
									{/* onClick={() => ymElem('reachGoal', 'googleplay_footer')} */}
                                    <Link href="https://goo.gl/Wl8y0C"><a target='_blank' className="download-app download-app-google" onClick={() => trackEvent('googleplay_footer')}/></Link>
                                    <Link href="https://apps.apple.com/ru/app/mover-грузовое-такси/id1080999192"><a target='_blank' className="download-app download-app-apple" onClick={() => trackEvent('appstore_footer')}/></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row third-row">
						<div>
                            <div className="footer-payment-info">К оплате принимаются карты VISA, MasterCard, МИР.</div>
							<ul className="list-unstyled footer-cards">
								<li className="footer-icon visa" />
								<li className="footer-icon mastercard" />
								<li className="footer-icon mir" />
								<li className="footer-icon uniteller" />
							</ul>
							<Link href='/uniteller'><a className="footer-link">{lang.FooterBtmSecure}</a></Link>
						</div>
                    </div>

                    <div className="row four-row">
                        <div className="col-sm-5">
                            <ul className="list-unstyled footer-cards">
                                <li className="footer-icon alfa"/>
                                <li className="footer-icon info">Оплата банковскими картами<br />осуществляется через АО «АЛЬФА-БАНК».</li>
                            </ul>
                        </div>

                        <div className="col-sm-7">
                            <Link href='/termsofuse'><a className="footer-link">{lang.FooterBtmTerms}</a></Link>
                            <Link href='/legal'><a className="footer-link">{lang.FooterBtmConf}</a></Link>
                            <Link href='/definitions'><a className="footer-link">{lang.FooterBtmDict}</a></Link>
                        </div>
                    </div>
					{/*<YMInitializer accounts={[34362465]} options={{webvisor: true, clickmap: true, trackLinks: true, accurateTrackBounce: true, trackHash: true}} version="2"/>*/}
                </div>
                <noscript><div><img src="https://mc.yandex.ru/watch/34362465" style={{position: 'absolute', left: '-9999px'}} alt="" /></div></noscript>
            </footer>
        );
    }
}

const mapState = state => ({
    lang: state.lang
});

const mapDispatch = ({ lang: { setLang }}) => ({
    setLang: (language) => setLang(language)
});

export default connect(mapState, mapDispatch)(Footer);

