import React from 'react';
import CalculatorStep1 from './CalculatorStep1';
import CalculatorStep2 from './CalculatorStep2';
import CalculatorStep3 from './CalculatorStep3';
import CalculatorStep4 from './CalculatorStep4';
import CalculatorStep5 from './CalculatorStep5';
import CalculatorStep6 from './CalculatorStep6';

export default class CalculatorInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { lang, step, data, updateData, calculated, info, errors, userInfo } = this.props;

        return (
            <div className="calculator-inner">
                {step === 1 ? <CalculatorStep1 deleteError={this.props.deleteError} errors={errors} isActive={true} lang={lang} data={data.points} updateData={updateData} getUserPosition={this.props.getUserPosition}/> : null}
                {step === 2 ? <CalculatorStep2 deleteError={this.props.deleteError} errors={errors} isActive={true} lang={lang} data={data}  updateData={updateData}/> : null}
                {step === 3 ? <CalculatorStep3 deleteError={this.props.deleteError} errors={errors} isActive={true} lang={lang} data={data} updateData={updateData} calculated={calculated} tariffs={info.tariffs}/> : null}
                {step === 4 ? <CalculatorStep4
                    deleteError={this.props.deleteError}
                    errors={errors}
                    isActive={true}
                    lang={lang}
                    data={data.tariff}
                    updateData={updateData}
                    info={info}
                    tariffs={info.tariffs}
                    selectedGroupLengthValue={data.selectedGroupLengthValue}
                    selectedGroupLength={data.selectedGroupLength}
                /> : null}
                {step === 5 ? <CalculatorStep5
                    deleteError={this.props.deleteError}
                    userInfo={userInfo}
                    errors={errors}
                    isActive={true}
                    lang={lang}
                    data={data}
                    updateData={updateData}

                    getUserInfo={this.props.getUserInfo}
                    getUserCards={this.props.getUserCards}
                    getUserBalance={this.props.getUserBalance}
                    getUserPromoCode={this.props.getUserPromoCode}
                    getUserInfoFull={this.props.getUserInfoFull}
                    logout={this.props.logout}
                    login={this.props.login}
                    deleteCard={this.props.deleteCard}
                    setActiveCard={this.props.setActiveCard}
                    savePromoCode={this.props.savePromoCode}

                /> : null}
                {/*{step === 6 ? <CalculatorStep6 isActive={true} lang={lang} data={data.points} updateData={updateData}/> : null} */}
            </div>
        );
    }
}
