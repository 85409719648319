import React                            from 'react';
import AdressComplete                   from '../AdressComplete';
import DropFloor                        from '../Dropdowns/DropFloor';
import DropContact                      from '../Dropdowns/DropContact';
import { stopEvent, AlphabetArray }     from '../../utils/helpers';
import DropTypePoint                    from '../Dropdowns/DropTypePoint';
import cx                               from 'classnames';

export default class AdressPoint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropFloor: 0,
            dropContact: 0,
            dropPointType: 0
        };
    }

    componentDidMount() {
        //window.addEventListener('click', this.dropFloorClose);
    }

    componentWillUnmount() {

    }

    dropFloorCloseBtn = (event) => {
        this.setState({
            dropFloor: 0,
            dropContact: 0
        });
        window.removeEventListener('click', this.dropFloorClose);
    };

    dropFloorClose = (event) => {
        let canClose = !event.target.matches('.drop-wrapper.floor *');

        if (canClose) {
            this.setState({
                dropFloor: 0,
                dropContact: 0
            });
            window.removeEventListener('click', this.dropFloorClose);
            window.removeEventListener('click', this.dropContactClose);
        }
    };

    dropContactClose = (event) => {
        let canClose = !event.target.matches('.drop-wrapper.contacts *');

        if (canClose) {
            this.setState({
                dropFloor: 0,
                dropContact: 0
            });
            window.removeEventListener('click', this.dropFloorClose);
            window.removeEventListener('click', this.dropContactClose);
        }
    };

    contactClose = () => {
        this.setState({
            dropFloor: 0,
            dropContact: 0
        });

        window.removeEventListener('click', this.dropFloorClose);
        window.removeEventListener('click', this.dropContactClose);
    };

    dropPointTypeClose = (event) => {
        this.setState({dropPointType: 0});
        window.removeEventListener('click', this.dropPointTypeClose);
    };

    dropFloorOpen = (event) => {
        stopEvent(event);

        if (!this.state.dropFloor) {
            this.setState({
                dropFloor: 1,
                dropContact: 0
            });
            window.addEventListener('click', this.dropFloorClose);
        } else {
            this.setState({
                dropFloor: 0,
                dropContact: 0
            });
            window.removeEventListener('click', this.dropFloorClose);
            window.removeEventListener('click', this.dropContactClose);
        }
    };

    dropContactOpen = (event) => {
        stopEvent(event);

        if (!this.state.dropContact) {
            this.setState({
                dropContact: 1,
                dropFloor: 0,
            });
            window.addEventListener('click', this.dropContactClose);
        } else {
            this.setState({
                dropFloor: 0,
                dropContact: 0
            });
            window.removeEventListener('click', this.dropFloorClose);
            window.removeEventListener('click', this.dropContactClose);
        }
    };

    dropPointTypeOpen = (event) => {
        stopEvent(event);
        if (!this.state.dropPointType) {
            this.setState({dropPointType: 1});
            window.addEventListener('click', this.dropPointTypeClose);
        }
    };

    setType(event, value, index) {
        this.dropPointTypeClose(event);
        this.props.setType(value, index);
    };

    setAdressInfo = (data) => {
        let {index} = this.props;

        this.props.setAdressInfo(index, data);
    };

    render() {
        let {countPoints, index, item, lang} = this.props;
        let lift;

        switch (item.lift) {
            case 0:
                lift = lang.CalcAddressPoint_lift_1;

                break;
            case 1:
                lift = lang.CalcAddressPoint_lift_2;

                break;
            case 2:
                lift = lang.CalcAddressPoint_lift_3;

                break;
            case 3:
                lift = lang.CalcAddressPoint_lift_4;

                break;
        }

        let tagFloorCSS = cx({
            tag: true,
            floor: true,
            filled: !!item.floor
        });

        let tagLiftCSS = cx({
            tag: true,
            lift: true,
            filled: [1,2,3].indexOf(item.lift) !== -1
        });

        let tagContactCSS = cx({
            tag: true,
            comment: true,
            filled: !!(item.contactName || item.contactPhone)
        });

        let ddLabel = (<div id={"calculatorLabelAddress" + index} className="label-point t-drop">
            <label onClick={this.dropPointTypeOpen}>{item.typePoint} {AlphabetArray[index]}<i id={"calculatorLabelAddressIcon" + index} className="icon"/></label>
            {this.state.dropPointType === 1 ? <DropTypePoint index={index} setType={this.setType.bind(this)}/> : null}
        </div>);
        let simpleLabel = (
            <div id={"calculatorLabelAddress" + index} className="label-point">
                <label>{item.typePoint} {AlphabetArray[index]}</label>
            </div>
        );

        return (
            <div className="point-wrap" key={index}>
                {index > 0 && index < (countPoints - 1) ? ddLabel : simpleLabel}
                {index > 0 && countPoints > 2 ? (<div id={"calculatorDeleteWrapAddress" + index} className="deletePoint" onClick={this.props.deleteItem.bind(this, index)}>{lang.Calculator_del_point}</div>) : null}

                <div id={"calculatorAddress" + index} className={"point-input-wrap" + (index === 0 ? " t-geo" : "")}>
					<AdressComplete
						value={item.adress.formated_address}
						index={index}
						typePoint={item.typePoint}
						placeholder={item.placeholder}
						hasError={this.props.hasError}
                        savePoint={this.props.saveItem}
					/>
					{index === 0 ? (<span id="geo_dot" className="geo-icon" onClick={this.props.getUserPosition} />) : null}
				</div>

                <div className="tags">
                    <div id={"calculatorTagFloor" + index} className={tagFloorCSS} onClick={this.dropFloorOpen}>
                        { item.floor ? (<span id="floor_tag">{item.floor} {lang.CalcAddressPoint_tag_1}</span>) : (<span id="contact_item">+ {lang.CalcAddressPoint_tag_1}</span>)}
                    </div>

                    { item.lift ? (<div id={"calculatorTagLift" + index} className={tagLiftCSS} onClick={this.dropFloorOpen}><span>{lift}</span></div>) : null}

                    <div id={"calculatorTagContact" + index} className={tagContactCSS} onClick={this.dropContactOpen}>
                        { item.contactName || item.contactPhone ? (<span>{(item.contactName ? item.contactName : '') + ' ' + (item.contactPhone ? item.contactPhone : '')}</span>) : (<span>+ {lang.CalcAddressPoint_tag_2}</span>)}
                    </div>

                    {this.state.dropFloor === 1 ?
                        <DropFloor floor={item.floor}
                                   lift={item.lift}
                                   lang={lang}
								   index={index}
                                   dropFloorCloseBtn={this.dropFloorCloseBtn}
                                   setAdressInfo={this.setAdressInfo}/> : null}
                    {this.state.dropContact === 1 ?
                        <DropContact contactName={item.contactName}
                                     lang={lang}
									 index={index}
                                     contactPhone={item.contactPhone}
                                     setAdressInfo={this.setAdressInfo}
                                     dropContactClose={this.contactClose} /> : null}
                </div>
            </div>
        );
    }
}
