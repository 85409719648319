import React 		from 'react';
import cx 			from 'classnames';
import AdressPoint 	from './CalculatorAdressPoint';
import Hint 		from '../Hint';
import {YANDEX_MAP_KEY} from "../../config";

export default class CalculatorStep1 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			points: props.data,
			dropFloor: 0,
			error: props.errors && props.errors.step === 1 ? props.errors : null,
			pointsDefault: [
				{
					adress: {
						longitude: 37.455598,
						latitude: 55.726650,
						formated_address: ''
					}
				},
				{
					adress: {
						longitude: 37.455598,
						latitude: 55.726650,
						formated_address: ''
					}
				}
			]
		};

		this.myMap = null;
		this.multiRoute = null;
	}

	componentWillReceiveProps(newProps) {
		if(newProps.errors !== this.props.errors) {
            this.setState({error: newProps.errors});
		}
	}

	componentDidMount() {
		if(window.ymaps) {
			this.initYaMap();
		} else {
			this.fetchScript(`https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${YANDEX_MAP_KEY}`).then(this.initYaMap);
		}
	}

	fetchScript = (url) => {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script');

			script.type = 'text/javascript';
			script.onload = resolve;
			script.onerror = reject;
			script.src = url;
			script.async = 'async';

			document.head.appendChild(script);
		});
	};

	initYaMap = () => {
		let point = this.state.points[0].adress;
		let lon = point.longitude !== 37.455598 ? point.longitude : 37.620359;
		let lan = point.latitude !== 55.726650 ? point.latitude : 55.754039;

		ymaps.ready(() => {
			// Создаем карту с добавленной на нее кнопкой.
			this.myMap = new ymaps.Map('map-container', {
				center: [lan, lon],
				zoom: 15,
				controls: ['zoomControl', 'fullscreenControl'],
			});

			this.myMap.behaviors.disable('scrollZoom');

			let adress = this.state.points.map((item) => {
				if(item.adress && !item.defaultValue) return [item.adress.latitude, item.adress.longitude];
			});

			this.updateMap(adress);
		});
	};

	updateMap = (adress) => {
		this.myMap.geoObjects.remove(this.multiRoute);

		this.multiRoute = new ymaps.multiRouter.MultiRoute({
			referencePoints: adress,
			params: {
				//Тип маршрутизации - пешеходная маршрутизация.
				routingMode: 'auto'
			}
		}, {
			// Автоматически устанавливать границы карты так, чтобы маршрут был виден целиком.
			boundsAutoApply: true
		});

		this.myMap.geoObjects.add(this.multiRoute);
	};

	//Удаляем заданный элемент:
	deleteItem = (index) => {
		let points = this.state.points;
		points.splice(index, 1);
		points[points.length - 1].typePoint = "Точка разгрузки";

		this.setState({ points, error: null }, async () => {
			let adress = points.map((item) => {
				if(item.adress && !item.defaultValue) return [item.adress.latitude, item.adress.longitude];
			});

			this.updateMap(adress);
			await this.props.updateData({ points }, 'adress');
		});
	};

	saveItem = async (data, typePoint, index) => {
		let points = this.state.points;
		let point = points[index];
		const defaultPoints = this.state.pointsDefault;

		if(data) {
			point.adress = data;
			if(point.defaultValue) point.defaultValue = false;
		} else {
			if (points.length < 3) {
				point.adress = defaultPoints[index].adress;
			} else {
				point.adress = [];
			}

			point.defaultValue = true;
		}

		points.splice(index, 1, point);

		let adress = points.map((item) => {
			if(item.adress && !item.defaultValue) return [item.adress.latitude, item.adress.longitude];
		});

		this.updateMap(adress);
		await this.props.updateData({ points }, 'adress');

		console.log(points);
	};

	addItem = () => {
		const { lang } = this.props;

		this.state.points.push({
			adress: {
				longitude: 37.455598,
				latitude: 55.726650,
				formated_address: ''
			},
			typePoint: lang.CalcSidebar_step1_type_point,
			placeholder: lang.CalcSidebar_step1_push_placeholder,
			floor: 0,
			lift: 0,
			contactName: '',
			contactPhone: '',
			comment: '',
			defaultValue: true
		}); //тут изменяем стейт напрямую


		this.setState({points: this.state.points}, () => {
			let elem = document.querySelector('.calculator-content.step1').parentElement;
			elem.scrollTo(0, elem.clientHeight);
		}); //заставляем изменения примениться
	};

	setAdressInfo = async (index, data) => {
		let points = this.state.points;
		let point = points[index];
		points.splice(index, 1, Object.assign(point, data));
		await this.props.updateData({ points }, 'adress');
	};

	setType = async (typePoint, index) => {
		let points = this.state.points;
		let point = points[index];
		point.typePoint = typePoint;
		points.splice(index, 1, point);
		await this.props.updateData({ points }, 'adress');
	};

	getUserPosition = () => {
		this.props.getUserPosition().then(() => {
			let points = this.state.points;

			let adress = points.map((item) => {
				if(item.adress && !item.defaultValue) return [item.adress.latitude, item.adress.longitude];
			});

			this.updateMap(adress);
		});
	};

	renderPoints = (item, index, arr) => {
		let { error } = this.state;

		let pointCSS = cx({
            'form-control': true,
            'is-invalid': error && error.information.field.indexOf('point-' + index) !== -1
        });

		return <AdressPoint
			getUserPosition={this.getUserPosition}
			countPoints={arr.length}
			index={index}
			key={index}
			item={item}
			deleteItem={this.deleteItem}
			saveItem={this.saveItem}
			lang={this.props.lang}
			setAdressInfo={this.setAdressInfo}
			setType={this.setType}
			hasError={pointCSS}
		/>
	};

	render() {
		const { lang, isActive } = this.props;
		const { points, error } = this.state;

		const itemCSS = cx({
			'calculator-content': true,
			'step1': true,
			'active': isActive,
		});

		let hasAdress = points[points.length -1].adress.formated_address;
		let hasFirstPoint = !points[0].defaultValue;
		let hasSecondPoint = !points[1].defaultValue;
		let hasLastPoint = !points[1].defaultValue;
		let canAddPoint = hasAdress && hasFirstPoint && hasSecondPoint && hasLastPoint;

		return (
			<div className={itemCSS} >
				<h2 className="title">{lang.Calculator_route}</h2>

				<div className="points">
					{points.map(this.renderPoints)}

					{ canAddPoint ? (<span id="calculatorAddAddress" className="addPoint" onClick={this.addItem}>{lang.Calculator_add_point}</span>) : null }
				</div>

				{error && error.step === 1 ? <Hint deleteError={this.props.deleteError} title={lang.CalcSidebar_step1_hint_title} desc={lang.CalcSidebar_step1_hint_desc} type="error" /> : null}

				<div className="map-container" id="map-container" />
			</div>
		);
	}

}
