'use strict';

let ACTIONS = {
    search_state: {
        reducer: 'orders',
        method: 'searchState'
    },
    new_search: {
        reducer: 'drafts',
        method: 'newSearch'
    },
    new_order: {
        reducer: 'orders',
        method: 'newOrder'
    },
    changed: {
        reducer: 'orders',
        method: 'updateOrder'
    },
    // Машина не найдена
    // 2 кнопки, повторить поиск и изменить параметры заказа.
    not_found: {
        reducer: 'orders',
        method: 'notFound'
    },
    // Клиент не подтвердил выбор машины
    no_answer: {
        reducer: 'orders',
        method: 'noAnswer'
    },
    // Машина найдена, требуется подтверждение клиентом
    found: {
        reducer: 'orders',
        method: 'found'
    },
    // Изменение статуса оплаты.
    payment_status: {
        reducer: 'orders',
        method: 'paymentStatus'
    },
    // Уведомление об изменении статуса заказа.
    status: {
        reducer: 'orders',
        method: 'status'
    },
    execution_status: {
        reducer: 'orders',
        method: 'setExecutionStatus'
    },
    bill: {
        reducer: 'orders',
        method: 'bill'
    },
    arrive: {
        reducer: 'orders',
        method: 'arrive'
    }
};

export default {
    state: {
        status: 'disconnected',
        isConnected: false
    },
    reducers: {
        connected: () => {
            return {
                status: 'connected',
                isConnected: true
            }
        },

        connecting: () => {
            return {
                status: 'connecting',
                isConnected: false
            }
        },

        disconnected: () => {
            return {
                status: 'disconnected',
                isConnected: false
            }
        },
        connect: state => state,
        disconnect: state => state,
    },
    effects: (dispatch) => ({
        // Получение информации о пользователе
        message(payload) {
            let actionType = ACTIONS[payload.action];

            if (actionType) {
				dispatch[actionType.reducer][actionType.method](payload.data);
			} else {
                console.log('payload: ws ' + payload);
            }
        }
    })
}