import { initStore }        from '../src/store';
import withRematch          from '../src/utils/withRematch';
import Layout               from '../src/containers/MineLayout';
import Home                 from '../src/containers/Home';

const Index = (props) => {
    return (
        <Layout {...props}>
            <Home num={props.num} lang={props.lang} />
        </Layout>
    );
};

Index.getInitialProps = async function(ctx) {
    const { pathname, req, store } = ctx;

    let token = req ? req.cookies.token : null;

    await store.dispatch.user.getUserInfoFull({token});

    let curLang = req && req.language ? req.language.split('-')[0] : 'ru';

    return {
        headerClass: 'main-menu-opacity',
        curLang,
        pathname,
        title: 'MOVER: Грузовое такси',
        description: 'MOVER – Городской мобильный сервис Грузового такси. Перевозки от 450 руб. в час. Работаем внутри Садового, в пределах МКАД и по области. Заказ машины и поддержка клиентов 24/7 — решим любой ваш вопрос. Подача к месту загрузки за 30 минут. Удобный расчет - наличные, карта или оплата по счёту. Подъем на этаж, монтаж и погрузка. Упакуем бережно, доставим в срок, соберем по инструкции и разместим по плану что угодно. Внимательные водители. Предупредим, если приедем раньше или задержимся. В MOVER нет случайных людей: каждый водитель проходит отбор в 3 этапа и обучение. MOVER - Перевозки к лучшему!'
    }
};

const mapState = state => ({
    lang: state.lang,
    userInfo: state.user,
    calculator: state.calculator,
    activeOrder: state.orders.activeOrder
});

const mapDispatch = ({ orders: { acceptedOrder, declinedOrder }, user: { getUserInfoFull }, calculator: { searchStop, searchStart, update }, ws: { connect }}) => ({
    getUserInfoFull     :    () => getUserInfoFull(),
    calculatorUpdate:   (data) => update(data),
    wsConnect:          () => { connect() },
    searchStop:         (data) => searchStop(data),
    searchStart:        (data) => searchStart(data),
    acceptedOrder:      (orderId) => acceptedOrder(orderId),
    declinedOrder:      (orderId) => declinedOrder(orderId),
});

export default withRematch(initStore, mapState, mapDispatch)(Index);
