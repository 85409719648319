const Lang = {
    langName: 'en',
    error500: 'System error',
    PageApp_first_slide_title: 'Carriage will go without a hassle with MOVER application',
    PageApp_first_slide_desc: 'Thanks to the convenient interface you can order any type of transportation in only 5 touches.',
    PageApp_first_slide_links1: 'Mobile applications',
    PageApp_first_slide_links2: 'E-commerce',
    PageApp_first_slide_links3: 'Cloud services',
    PageApp_first_slide_links4: 'Delivery services',
    PageApp_second_slide_title: 'Easy to order - quick to transport',
    PageApp_second_slide_circle_1_title: 'Download',
    Order: 'Order',
    PageApp_second_slide_circle_3_title: 'Transport',
    PageApp_second_slide_circle_1_info: 'Install the free MOVER app on your smartphone from <a href="https://apps.apple.com/ru/app/mover-грузовое-такси/id1080999192">App Store</a> или <a href="https://goo.gl/Wl8y0C">Google Play</a>',
    PageApp_second_slide_circle_2_info: 'Effortlessly, you get access to over 1,000 cargo crews at cheap prices.',
    PageApp_second_slide_circle_3_info: 'The best crews of your city are available in a couple of clicks, always nearby. They will arrive within 30 minutes.',
    PageApp_slider_1_title: 'Specify the route',
    PageApp_slider_1_desc: 'The intuitive interface of the MOVER application when building a route allows you to enter the addresses of control points in the usual way, and specify them directly on the map! Just 3 clicks..And your cargo is on its way!',
    PageApp_slider_1_bage_1: 'Tracking on the map',
    PageApp_slider_1_bage_2: 'Accurate calculation of the prices',
    PageApp_slider_1_bage_3: 'The monitoring of implementation',
    PageApp_slider_1_bage_4: 'Geolocation of your cargo',
    PageApp_slider_2_title: 'Select the rate',
    PageApp_slider_2_desc: 'MOVER has developed an intellectually accurate and flexible tariff line for its customers. Four basic tariffs can be customized to suit you as you wish. The choice of the client is offered as a mini tariff, for fast delivery of small cargoes, and MAXI, an ideal satellite of large cargoes. Tariffs Standard and Extra-a unique addition to each other on the way to solving complex problems. Freight rates from MOVER service - exact price and transparent work without hidden fees and charges.',
    PageApp_slider_2_bage_1: 'Van builder',
    PageApp_slider_2_bage_2: 'Ready-made transportation templates',
    PageApp_slider_2_bage_3: 'Crew counter online',
    PageApp_slider_2_bage_4: 'Auto-selection of the crews',
    PageApp_slider_3_title: 'Add cargo',
    PageApp_slider_3_desc: 'Specially developed by MOVER algorithm allows you to choose the car for your tasks, and the crew will be ready for any surprises. The service allows you to specify the parameters of the cargo and attach photos to the order! The functionality of the application also includes the mention of specific route parameters. For example, the transmission mode, the features of the passage.',
    PageApp_slider_3_bage_1: 'Cargo photos',
    PageApp_slider_3_bage_2: 'Intuitive comment system',
    PageApp_slider_4_title: 'Send now - download later',
    PageApp_slider_4_desc: 'Send yourself or your friends a link to MOVER mobile services and transport with pleasure.',
    PhoneNumber1: 'Phone number',
	WidgetPasswordPlaceholder: 'Password',
	WidgetViewResetPassword: 'Забыли пароль?',
	WidgetViewRegistration: 'Регистрация',

    SendTo: 'Send', //pres simple
    PageApp_slider_4_send2: 'Send', //past time
    PageApp_fourth_slide_title: 'MOVER app for partners',
    PageApp_fourth_slide_desc: 'An indispensable assistant to any carrier, on-board computer, which will help to become a true master of his craft.',
    PageApp_fourth_slide_item_1_title: 'Distribution of orders by geolocation',
    PageApp_fourth_slide_item_1_desc: 'Get orders near you, spend less fuel and time',
    PageApp_fourth_slide_item_2_title: 'Acceptance of any kind of payments',
    PageApp_fourth_slide_item_2_desc: 'With the MOVER app you are not limited to anything, accept any type of payment',
    PageApp_fourth_slide_item_3_title: 'Really handy app',
    PageApp_fourth_slide_item_3_desc: 'Manage your orders in the most advanced application in the freight market',
    PageApp_fourth_slide_flash_title: 'No comission',
    PageApp_fourth_slide_flash_desc: 'The service works with crews directly, you get prices without hidden markups and overpayments',
    PageApp_fourth_slide_shield_title: 'The best carriers',
    PageApp_fourth_slide_shield_desc: 'We personally check each driver, evaluate the quality of the car and professionalism',
    PageApp_fourth_slide_simple_title: 'Order in 3 clicks',
    PageApp_fourth_slide_simple_desc: 'Get access to any nearby crews in just a couple of minutes on any platform',
    PageApp_fifth_slide_title: 'Make your transportation more profitable',
    PageApp_fifth_slide_desc: 'Share MOVER with your friends, get discounts or free trips.',
    PageApp_fifth_slide_placeholder: 'Email to connect',
    PageApp_fifth_slide_tip: 'Share and get 300 rubles',

    appLang: 'English',
    appLangRU: 'Русский',
    appLangEN: 'English',


    HeaderSmall_lk: 'My orders',
    HeaderSmall_clients: 'FOR CLIENTS',
    HeaderSmall_business: 'FOR BUSINESS',
    HeaderSmall_drivers: 'FOR DRIVERS',
    HeaderSmall_login: 'Log in',
    headerBig_About: 'ABOUT SERVICES',
    headerBig_APPs: 'APPLICATIONS',
    headerBig_Integration: 'INTEGRATIONS',
    headerBig_CarPark: 'AUTOPARK',
    headerBig_Promo: 'ACTIONS',
    headerBig_Tariff: 'TARIFFS',
    headerBig_Help: 'HELP',
    headerBig_Job: 'VACANCIES',
    headerBig_Partners: 'PARTNERS',
    headerBig_Blog: 'BLOG',
    headerBig_btn_calc: 'NEW ORDER',

    //Calculator
    CalculatorHeaderTitle: 'Your order for ',
    CalculatorHeaderTitle2: 'Today ',
    Calculator_pro_mode: 'PRO MODE',
    Calculator_where: 'From',
    Calculator_to: 'To',
    Calculator_cargo_title: 'Cargo',
    Calculator_add_cargo: 'Add cargo',
    Calculator_del_cargo: 'Delete cargo',
    Calculator_add_mov: 'Movers',
    Calculator_mov: 'MOVERS',
    Calculator_sel_tar: 'Select tariff',
    Calculator_pay: 'Payment',
    Calculator_car_del: 'Arrival in 30 minutes',
    Calculator_37_carr: '37 crews online',
    Calculator_route: 'Route',
    Calculator_load_point: 'Loading point',
    Calculator_address_1: 'Pravdy street 24, b. 3 + Floor + Elevator',
    Calculator_add_comm: '+ Comment',
    Calculator_add_cont: '+Contact person',
    Calculator_reverse: 'Swap',
    Calculator_unload_point: 'Unloading point',
    Calculator_address_2: 'Leninskiy Prospekt 53, b. 2, Moscow',
    Calculator_elevator :' freight elevator + contact person',
    Calculator_add_point: 'Add point',
    Calculator_del_point: 'Delete point',
    Calculator_hide: 'Hide map',
    Calculator_add_comment:'Add comment',
    Calculator_add_mover: 'Add movers',
    Calculator_more: 'More ',
    Calculator_calculated_time: 'Estimated operating time',
    Calculator_default: 'Default standard rate',
    Calculator_order: 'Order',
    Calculator_cargoes: 'Cargoes',
    Calculator_cargo: 'Cargo ',
    Calculator_type_cargo: 'Type of cargo',
    Calculator_fragile_cargo: '+Fragile cargo +Furniture + Building materials',
    Calculator_add_services: 'Additional services',
    Calculator_lifting: 'Descent / Ascent',
    Calculator_lifting_desc: 'If the cargo is dimensional and does not fit into the elevator, the movers will help to move it manually on the floors to the specified location.',
    Calculator_assembly: 'Assembly / Disassembly',
    Calculator_assembly_desc: 'Movers will help with disassembly and subsequent assembly of the specified cargo.',
    Calculator_1_mover: ' mover',
    Calculator_heavier_than_60_kg: 'The weight of the cargo per mover shall not exceed 50 kg.',
    Calculator_the_first_hour: 'The first hour of work',
    Calculator_add_hour_work: 'Extra hour of work',
    Calculator_loaders_waiting: 'Waiting',
    Calculator_cargo_lifting: 'Lifting of cargo to the first floor',
    Calculator_2_movers: ' movers',
    Calculator_no_movers: 'No movers',
    Calculator_free: 'Free',
    Calculator_specify: 'Specify the type of cargo',
    Calculator_your_cargo: 'YOUR CARGO',
    Calculator_insured: 'If you specify the cargo, your transportation will be insured',
    Calculator_standard_2_tons: 'Standard 2 tons',
    Calculator_gazelle :' Standard Gazelle, Mercedes Sprinter, Ford Transit, Hyundai Porter',
    Calculator_awning: 'Awning • Van • Full-metal',
    Calculator_FullM: 'Full-metal',
    Calculator_Furgon: 'Van',
    CalculatorGAZBus: 'GAZelle BUSINESS',
    Calculator_normal_long: 'Casual • Long',
    Calculator_length: 'Length • Width • Height • Volume',
    Calculator_mini: 'Mini • 800 kg',
    Calculator_extra: 'Extra • 2.5 tons',
    Calculator_maxi: 'Maxi • 3 tons',

    CalculatorStand: 'Standart',
    CalculatorMax: 'Maxi',
    CalculatorMin: 'Mini',
    CalculatorExtra: 'Extra',

    Calculator_way_payment:'Payment method',
    Calculator_cash: 'Cash',
    Calculator_card: 'Card',
    Calculator_invoice: 'Bank transfer',
    Calculator_promo_code: 'Code',
    Calculator_data: 'Your data',
    Calculator_igor: 'Igor Nikolaev',
    Calculator_get_code: 'Get code',
    Calculator_hour_by_tariff: 'the first hour rate',
    Calculator_add_hour_tariff: '1 extra hour rate',
    Calculator_Leninsky: 'Leninskiy Prospekt 53, b. 2',
    Calculator_total: 'Total',
    Calculator_discount: 'With the discount',
    Calculator_processed: 'Your order is being processed',
    Calculator_drivers: '3 out of 5 drivers',
    Calculator_send_link: 'we\'ll send a link to your phone number to download an app where you can track your order',
    Calculator_number:'Phone number',
    Calculator_send_number: 'Send',
    Calculator_additional_services: 'Additional services',
    Calculator_storage: 'Storage of personal items from our partner QB.',
    Calculator_storage_note: 'We accept for storage items of any size, from books and seasonal clothing to household appliances and furniture. Free packaging, secure warm warehouse in Moscow, online display with a list of your things.',
    Calculator_storage_price: 'from 449 rubles',
    Calculator_storage_period: 'month',
    Job_title_page:'Career',
    Jobs_desc_1_page: 'MOVER Service is designed to simplify the life of an ordinary citizen in everyday household problems associated with the transportation of goods and moving of any complexity.',
    Job_desc_2_page: 'We are always looking for talented people who are constantly seeking the best solutions in the work, people with burning eyes, who want to turn the world around and make a significant contribution to the industry. Join MOVER and together we will transport the world for the better!',
    Job_links_link: 'Suggest',
    Job_links_offer :' Submit your vacancy',
    Partners_title_page: 'Partners',
    Partners_desc_page: 'MOVER has a lot of partners which help us every day to make the service better and more versatile.',
    Partners_links_link: 'Become a partner',
    Partners_links_offer: 'What does it mean to become a partner?',
    Tariff_title_page:'Tariff table',
    Tariff_link_pdf: 'Download PDF',
    Tariff_desc_page: 'Tariffs determine the type and size of vehicle you are calling. Different rates are well suited for different tasks.',
    Tariff_question_1_item_title: 'How to choose a tariff',
    Tariff_question_1_item_desc: 'If you specify cargo - your transportation will be insured',
    Tariff_question_2_item_title: 'View rates on the map',
    Tariff_question_2_item_desc: 'Tariff zones',
    Tariff_example_title: 'How to use MOVER?',
    Tariff_example_cnt_title: 'On-demand delivery',
    Tariff_example_cnt_desc: 'Even if you are a small business owner with a small turnover and uncontrolled volume of deliveries — MOVER is the best option for you. Day in day we will bring on demand cargo to you or your clients in any point of the city and area.',
    Tariff_example_list_item_1: 'Notification system',
    Tariff_example_list_item_2: 'Cargo insurance',
    Tariff_example_list_item_3: 'Cargo forwarding',
    Tariff_example_list_item_4: 'Refund of money and documents',
    Tariff_example_list_item_5: 'Detailed statistics',
    Tariff_example_list_item_6: 'The construction of complex routes',
    Tariff_table_1_desc_0: 'Specifications',
    Tariff: 'Tariff',
    Tariff_table_1_desc_2: 'Capacity',
    Tariff_table_1_desc_3_1: 'Length',
    Tariff_table_1_desc_3_2: 'Width',
    Tariff_table_1_desc_3_3: 'Height',
    Tariff_table_1_desc_3_4: 'Volume',
    Tariff_table_1_desc_4: 'Car types',
    Tariff_table_1_desc_5: 'Cargo',
    Tariff_table_1_desc_6: 'Prices',
    Tariff_table_1_desc_7: 'Time of sending the car',
    Tariff_table_1_desc_8: 'Minimum tariff',
    Tariff_table_1_desc_9: 'Additional 15 minutes',
    Tariff_table_1_desc_10 :'Entering the center',
    Tariff_table_1_desc_11: '1 km from MKAD',
    Tariff_table_2_desc_2: 'The limitation of the size of the cargo',
    Tariff_table_2_desc_3: 'List of works',
    Tariff_table_2_desc_4: 'Prices',
    Tariff_table_2_desc_5: 'Idle',
    Tariff_table_2_desc_6: 'Minimum tariff',
    Tariff_table_2_desc_7: 'Additional 15 minutes',

    TariffMiniIntro: 'Suitable for small loads',
    TariffStandIntro: 'Standard tariff fits almost everyone',
    TariffExtraIntro: 'Perfect tariff for long and oversized cargo',
    TariffMaxiIntro: 'Suitable for relocation or transportation of large size things',

    Index_calculator_default_desc: 'More than 1,200 drivers in service • Average delivery time is 40 min.',
    Index_adv_title_1: 'Without a fee',
    Index_adv_title_2: 'Best carriers',
    Index_adv_title_3: '3-click Order',
	Index_adv_title_4: '3-click Order',
    Index_adv_desc_1: 'The service works with crews directly, you get prices without hidden markups and overpayments',
    Index_adv_desc_2: 'We personally check each driver, evaluate the quality of the car and professionalism',
    Index_adv_dec_3 :'Access any nearby crews in just a couple of minutes from any platform',
	Index_adv_dec_4 :'Access any nearby crews in just a couple of minutes from any platform',
    Index_callback_2_title: 'Make your transportation more profitable',
    Index_callback_2_desc: 'Share MOVER with your friends, get discounts or free rides.',
    Index_callback_2_placeholder: 'Email or phone number',
    Index_callback_2_btn: 'Send',
    Index_callback_2_hint: 'Share and get 3000 rubles',
    Index_steps_title: 'How does it work?',
    Index_steps_count_desc_1: 'Specify route and <br /> tariff',
    Index_steps_count_desc_2: 'Select a type <br /> of goods and <br /> payment method',
    Index_steps_count_desc_3: 'Get the best <br /> crew and manage your <br /> order',
    Index_steps_count_item_1_title: 'Work on air',
    Index_steps_count_item_1_desc: 'The service works with crews directly, you get prices without hidden markups and overpayments',
    Index_steps_count_item_1_hint: 'Ready to try? Let\'s send you a link for the app:',
    Index_steps_count_item_2_title: 'Transport your cargo online',
    Index_steps_count_item_2_desc: 'We personally check every driver, quality vehicle and professional skills.',
    Index_steps_count_item_3_title: 'Tell MOVER about your cargo',
    Index_steps_count_item_3_desc: 'Attach photos of non-standard items, for the convenience of the crew working with them. Pay for transportation in a convenient way.',
    Index_steps_count_item_3_form_label: 'Share link',
    Index_steps_count_item_3_list_1: 'Credit card',
    Index_steps_count_item_3_list_2: 'Bank transfer',
    Index_steps_count_item_3_list_3: 'Cash',
    Index_steps_count_item_3_list_4: 'Promo code',
    Index_example_title: 'How to use MOVER?',
    Index_example_1_title: 'Delivery on demand',
    Index_example_1_desc: 'Even if you are a small business owner with a small turnover and uncontrolled volume of deliveries — MOVER is the best option for you. Day in day we will bring on demand cargo to you or your clients in any point of the city and area.',
    Index_example_1_list_1: 'Notification system',
    Index_example_1_list_2: 'Cargo insurance',
    Index_example_1_list_3: 'Cargo forwarding',
    Index_example_1_list_4: 'Refund of money and documents',
    Index_example_1_list_5: 'Detailed statistics',
    Index_example_1_list_6: 'The construction of complex routes',
    Index_example_2_title: 'car rental<br />+ 1 porter for 3 hours',
    Index_example_2_desc: 'We\'ll drop the rucksack, tent and canoe to the station if you decide to go to Karelia at night. We will return the student to his parents from the hostel together with the goods accumulated in adulthood. We\'ll deliver the family bed to the newlyweds for a housewarming.',
    Index_example_2_list_1 :' Online auto tracking',
    Index_example_2_list_2: 'Loading and unloading',
    Index_example_2_list_3: 'iOS & Android apps',
    Index_example_2_list_4: 'All payment methods',
    Index_example_3_title: 'Small move',
    Index_example_3_desc: 'You don\'t need to spend the whole day for the moving. Give us the exact route. Select a trusted person. Keep track of how and where your cargo is moving through the app.',
    Index_example_3_list_1: 'Loading and unloading',
    Index_example_3_list_2: 'Packaging of goods',
    Index_example_3_list_3: 'Assembling and disassembling of the goods',
    Index_example_3_list_4: 'Verified drivers',
    Index_example_4_title: 'Delivery from the supermarket',
    Index_example_4_desc: 'Kitchen and appliances to a new apartment, canadian maples to the village, new equipment in a cafe or office - we have a solution for everything that does not fit in your car. Day—to-day delivery is an opportunity that cannot be denied.',
    Index_example_4_list_1: 'Purchase article',
    Index_example_4_list_2: 'Loading and unloading',
    Index_example_4_list_3: 'Forwarding services',
    Index_example_4_list_4: 'Day-to-day delivery',
    Index_example_QB_title: 'Storage of personal items and cargo from our partner QB.',
    Index_example_QB_desc: 'We accept for storage items of any size, from seasonal clothing to furniture and appliances.\nPrices from 449 rubles per month',
    Index_example_QB_list_1: 'Secure warehouse',
    Index_example_QB_list_2: 'Packing and loading',
    Index_example_QB_list_3: 'Online showcase',
    Index_example_QB_list_4: 'One-click return',
    Index_callback_1_title: 'Make your transportation more profitable',
    Index_callback_1_desc: 'Share MOVER with your friends, get discounts or free rides.',
    Index_callback_1_hint: 'Share and get 300 rubles',
    PromoCode_title: 'Share',
    PromoCode_desc: 'You like MOVER, but you are not going to transport anything in nearest future? You definitely have a friend or acquaintance who was recently looking for a cargo crew. Share MOVER with your friends, get discounts or free transportation for your next rides',
    PromoCode_form_label_name: 'Your name',
    PromoCode_form_name_placeholder: 'Name',
    PromoCode_form_label_phone: 'Your phone',
    PromoCode_form_label_phone_2: 'Friend\'s phone number',
    PromoCode_success_title: 'Invitation sent',
    PromoCode_success_desc: 'We sent a letter with registration data to your email. Tie the phone number and corporate card to start transportation in 5 minutes!',
    PromoCode_error_title: 'An error occurred',
    PromoCode_error_desc: 'Error code:',
    Index_support_title: 'Support center',
    Index_support_subtitle: 'Everything is under control',
    Index_support_desc: 'Every operator of the Support Center MOVER is your faithful ally in solving logistic and technical problems. Contact us and our support superheroes will not take long to wait!',
    Index_support_more_than: 'Go to the Support Center',
    Index_support_list_1_1_title: 'We will select the best rate',
    Index_support_list_1_1_desc: 'If you need help with the selection of the tariff or a specific machine, our experts are available 24/7 in the chat. They will help you to save your money',
    Index_support_list_more: 'Read more',
    Index_support_list_1_2_title: 'We will help to create an order',
    Index_support_list_1_2_desc: 'We will step you through the order, answer all the questions about the interface, or you can find the answers in our <a href="#" class="link">Knowledge Base</a>',
    Index_support_list_1_3_title: 'We will choose the drivers',
    Index_support_list_1_3_desc: 'Support Center Specialists monitor each application and, if necessary, intervene in the selection process like your logistician in the state',
    Index_support_list_1_4_title: 'We will monitor the terms',
    Index_support_list_1_4_desc: 'MOVER works as your personal transportation department and monitors all the deadlines for ordering',
    Index_support_list_2_1_title: 'We will track the car\'s appearance',
    Index_support_list_2_1_desc: 'We\'ll check the beginning of the order execution by the driver and replace the crew in case of force majeure',
    Index_support_list_2_2_title: 'We will orient crew',
    Index_support_list_2_2_desc: 'Our experts will help the driver to save time during the search for parking, loading area or checkpoint',
    Index_support_list_2_3_title: 'We will help to change the order\'s parameters',
    Index_support_list_2_3_desc: 'You can always change addresses, type of cargo or payment method by yourself or with our specialist\'s help in <a href="# "class=" link " >chat</a>',
    Index_support_list_2_4_title :'We will reply via hotline',
    Index_support_list_2_4_desc: '<a href="#" class="link">Hotline</a> works 24/7 on active orders with employees of the support center supervising the execution of the order',
    Index_support_list_3_1_title: 'We will provide all the numbers',
    Index_support_list_3_1_desc: 'If you do not have enough information in your account, you can always request data from the server to check the work of the driver',
    Index_support_list_3_2_title: 'We will solve the issues with the crew',
    Index_support_list_3_2_desc: 'If you have any questions to the driver or service after transportation - you can leave a claim in My orders and we will solve your question.',
    Index_support_list_3_3_title: 'We will provide all documents',
    Index_support_list_3_3_desc: 'Checks, receipts, closing documents and any shipping documents you may request via Chat',
    Index_support_list_3_4_title: 'We will help with payment',
    Index_support_list_3_4_desc: 'We\'ll give details on the amounts, help to set up a convenient payment method and inform about the status of payment',
    StepsRoad_title: 'Stages of transportation',
    StepsRoad_list_tab_1 :'Before the transportation',
    StepsRoad_list_tab_2: 'On the road',
    StepsRoad_list_tab_3 :'After the transportation',
    StepsRoad_list_item_1_title_1: 'How to order?',
    StepsRoad_list_item_1_title_2: 'How to learn the cost?',
    StepsRoad_list_item_1_title_3: 'Can I pay by card?',
    StepsRoad_list_item_2_title_1: 'How to contact the driver?',
    StepsRoad_list_item_2_title_2: 'How do I know where the driver is?',
    StepsRoad_list_item_2_title_3: 'How do I pay for transportation?',
    StepsRoad_list_item_3_title_1: 'How to get a receipt for the transportation?',
    StepsRoad_list_item_3_title_2: 'I left something in the car, how can I return it?',
    StepsRoad_list_item_3_title_3: 'How to change your payment method?',
    StepsRoad_more_btn: 'I still have questions',
    StepsRoad_more_btn_2: 'Go to Support Center',
    StepsRoad_list_item_1_desc_1: 'If you want ta make an order, you should select the tariff, specify the required number of loaders, loading and unloading points, choose a payment method and start the search! The nearest available crew will accept your application, and you will only have to confirm it.',
    StepsRoad_list_item_1_desc_2: 'The cost of transportation consists of the parameters that you specify: the number of movers, the time of the work and additional parameters. When you specify the necessary parameters the formation of order, you will get the estimated price for transportation.',
    StepsRoad_list_item_1_desc_3: 'When the order is completed you will be presented with the final amount for transportation services. You can pay by credit card or from the company\'s current account.',
    StepsRoad_list_item_2_desc_1: 'When the driver for your carriage is approved, the "call the driver" function will be available in the order.',
    StepsRoad_list_item_2_desc_2: 'When the crew accepts your order and comes to you, its location will be available on the device, you will be able to watch its movement online.',
    StepsRoad_list_item_2_desc_3: 'When the order is completed you can pay it both in cash to the driver and with the credit card attached to the service.',
    StepsRoad_list_item_3_desc_1: 'In MOVER profile settings you can specify the e-mail address to which the receipts for the transportation will be sent.',
    StepsRoad_list_item_3_desc_2: 'All the information about the driver and rides are recorded in the MOVER system. The Support center is available 24/7 and is in touch with all crews, so returning valuables is not a problem at all.',
    StepsRoad_list_item_3_desc_3: 'If during the order you need to change the method of payment, tell this MOVER Help Center.',
    Index_devices_title: 'We work everywhere',
    Index_devices_subtitle: 'Manage your transportations in one place from any convenient platform.',
    Index_devices_type_1: '<i class="icon"></i>MOVER <b>Web version</b>',
    Index_devices_desc_1: 'Convenient web app to order a cargo taxi in the city.',
    Index_devices_more: 'Read More',
    Index_devices_type_2: '<i class="icon"></i>MOVER for <b> Partners</b>',
    Index_devices_desc_2: 'The best app for ordering a cargo taxi in the freight market.',
    Index_devices_type_3: '<i class="icon"></i>MOVER for <b>iPhone</b>',
    Index_devices_desc_3: 'the best app for ordering a cargo taxi in the freight market.',
    Index_devices_type_4: '<i class="icon"></i>MOVER for <b>Android</b>',
    Index_devices_desc_4: 'Intuitive app for ordering a cargo taxi via smartphone.',
    Index_business_title: 'Revolution of transportation for business',
    Index_business_desc: 'Your personal transport department. Platform where you get a professional team of drivers in just a couple of clicks without any questions.',
    Index_business_more_btn: 'Attach your business',
    Index_business_item_1_title: 'Intuitive document management',
    Index_business_item_2_title: 'Verified drivers',
    Index_business_item_3_title: 'Any payment method',
    Index_business_item_4_title: '<span class="pro" >MOVER PRO</span> Service',
    Index_business_item_1_desc: 'We work with your documents according to your standards',
    Index_business_item_2_desc: 'Best cargo crews with a high rating',
    Index_business_item_3_desc: 'Pay from account or by credit card in 1 click',
    Index_business_item_4_desc: 'Powerful logistics service for companies',
    Index_start_1_title: 'Save up to 30% on cargo transportation',
    Index_start_1_desc: 'We will help you quickly and without intermediaries to choose\nthe nearest cargo carrier, carefully load and deliver exactly on time',
    Index_start_take_order: 'Choose cargo',
    Index_start_1_subtitle: 'Calculate the cost of your transportation right now',
    Index_start_1_app: 'Mobile app',
    Index_start_1_input_1_label: 'Loading address',
    Index_start_1_input_2_label: 'Unloading address',
    Index_start_1_Cargo1: 'Building materials',
    Index_start_1_Cargo2: 'Plumbing',
    Index_start_1_Cargo3: 'Windows',
    Index_start_1_Cargo4: 'Garbage removal',
    Index_start_1_Cargo5: 'Moving',
	Index_start_1_Cargo6: 'Moving',
	Index_start_1_Cargo7: 'Moving',
	Index_start_1_Cargo8: 'Moving',
    Index_start_2_sale_title: 'Discount up to 500R',
    Index_start_2_sale_desc: 'The first trip',
    Index_start_2_help: 'How does it work?',
    Index_start_2_title: 'The best way to transport a',
    Index_start_2_desc: 'We\'ll take your cargo in half an hour and bring it wherever you want.',
    Index_start_2_route_head_title: 'Specify route',
    Index_start_2_route_head_desc: 'More than 1,200 drivers in service • Average delivery time is 40 min.',
    Index_start_2_partners_title: 'Our customers',
    WidgetLoginTitle: 'Log in to service',
    WidgetResetPasswordTitle: 'Reset password',
    Widget_code_Title: 'Enter code',
    Widget_Password_Placeholder: 'Password',

    WidgetLoginBtnEx: 'Exit',
    WidgetLoginBtn: 'Log in',
    WidgetResetPasswordBtn: 'Get code',
    WidgetRegistrationBtn: 'Confirm',
    WidgetCodeBtn: 'Confirm',
    Widget_View_Reset_Password: 'Forgot your password?',
    Widget_View_Login: 'Login',
    Widget_View_Registration: 'Registration',
    Widget_View_Change_Phone: 'Change number',
    sendAgain: 'Send again',
    sendOn: 'Code send on',
    widgBalance: 'Balance',

    CalcHeader_btn_1: 'About order',
    CalcHeader_btn_2: 'Cancel',
    CalcOrder_accept_title: 'Your application link, where you will be able to track your order, will be sent to your phone number',
    CalcOrder_accept_crew_head_title: 'Order: #',
    CalcOrder_accept_crew_head_btn: 'Decline',
    CalcOrder_accept_crew_user_char: ' min to you',
    CalcOrder_accept_crew_cnt_car_desc: 'Make sure that all your belongings will fit in the car.',
    CalcOrder_accept_crew_char_type_1: '1 to 1.5 t',
    CalcOrder_accept_crew_char_type_2: 'Allmetall',
    CalcOrder_accept_crew_char_list_1_type: 'Height',
    CalcOrder_accept_crew_char_list_2_type: 'Length',
    CalcOrder_accept_crew_char_list_3_type: 'Width',
    CalcOrder_accept_crew_char_list_4_type: 'Volume',
    CalcOrder_accept_crew_char_help_type: 'TTK Paid entry',
    CalcOrder_accept_crew_foot_char_list_1_title: 'Serve',
    CalcOrder_accept_crew_foot_char_list_2_title: 'Running time',
    CalcOrder_accept_crew_foot_btn: "Accept",
    CalcOrder_search_count: 'Driver search is in progress',
    CalcOrder_search_desc_urgent: 'We have sent your order to the nearest crews, will soon pick up the executor and will send an SMS with all the data. Usually the selection takes no more than 10 minutes.',
    CalcOrder_search_desc_pending: 'We have sent your order to all crews, we will soon pick up the executor and send an SMS with all the data. Usually the selection takes no more than 10 minutes.',
    CalcOrder_search_desc_with_storage: 'We have sent your order to our storage partner QB. The manager will contact you within 2 hours and will specify all the details. After confirmation, the MOVER crew will pick up the cargo at the appointed time.',
    CalcOrder_search_form_desc_title: 'What’s next?',
    CalcOrder_search_form_have_any_question: 'Do you have any questions yet?',
    CalcOrder_search_form_private_area: 'My orders',
    CalcOrder_search_form_desc: 'A link to the app where you can track your order will be sent to your phone number',
    CalcOrder_finish_count: 'Your driver',
    CalcOrder_finish_cnt_number: 'Your order\'s number',
    CalcOrder_finish_cnt_desc: 'You can track the order in the application, the link to which came to you in the SMS. If you have any questions, please contact the Support Center.',
    CalcOrder_finish_cnt_btn: 'Go to the Support Center',
    CalcOrder_title_draft_received: 'Request for transportation accepted!',
    CalcOrder_search_draft_number: 'Order number',
    CalcOrder_title: 'Your order',
    CalcOrder_title_1: 'Processed',
    CalcOrder_title_2: 'Is in process',
    CalcOrder_title_3: 'Started',
    CalcSidebar_step1_eta_value: 'h.',
    CalcSidebar_step3_full_count: 'R / h',
    CalcSidebar_step4_empty_desc: 'The default tariff is <span class="name">Standard</span>',
    CalcSidebar_step4_full_count: 'R',
    CalcSidebar_step5_empty_title: 'Payment',
    CalcSidebar_step5_full_prefix: 'PAYMENT',
    CalcSidebar_step5_full_type: 'Cash',
    CalcSidebar_step6_full_title:'Payment',
    CalcSidebar_step6_empty_count: 'h',
    CalcStep2_cargo_tag_1:'Glass',
    CalcStep2_cargo_tag_2: 'Furniture',
    CalcStep2_cargo_tag_3: 'Building materials',
    CalcStep2_cargo_hint_title: 'Hint',
    CalcStep2_cargo_hint_desc: 'If you specify cargo, your transportation will be insured',
    CalcStep3_count: 'h',
    CalcStep3_time: 'min',
    CalcStep4_option_list: 'to',
    CalcStep4_option_list_size: 'm',
    CalcStep4_size_1_title: 'Length',
    CalcStep4_size_2_title: 'Width',
    CalcStep4_size_3_title: 'Height',
    CalcStep4_size_4_title: 'Volume',
    CalcStep4_count: 'h',
    CalcStep4_title: 'Select a tariff',
    CalcStep5_radio_choice_1_title: 'Cash',
    CalcStep5_radio_choice_2_title: 'Map',
    CalcStep5_radio_choice_3_title: 'From account',
    CalcStep5_payment_label: 'Promo code',
    CalcStep5_title:'Payment',
    CalcStep5_payment_label_2: 'Payment method',
    CalcStep5_payment_label_3: 'Payer\'s data',
    CalcStep5_payment_label_4: 'Active card',
    CalcStep5_payment_label_5:'All cards',
    CalcStep5_payment_placeholder_1: 'First Name Last Name',
    CalcStep5_payment_code_btn: 'Change phone number.',
    CalcStep5_payment_code_btn_2: 'Confirm',
    CalcStep5_payment_code_btn_3: 'Get code',
    CalcStep5_payment_code_btn_4: 'Resend code',
    CalcStep5_payment_code_btn_5: 'code',
    CalcStep5_payment_placeholder_2: 'Enter code from SMS',
    CalcStep6_title:'Order details',
    CalcStep6_info_1_title: 'The first hour rate',
    CalcStep6_info_2_title: 'Extra hour rate',
    CalcStep6_total_title: 'Total',
    CalcStep6_total_desc: 'With the discount',
    CalcAddressPoint_lift_1: 'No elevator',
    CalcAddressPoint_lift_2: 'Passenger elevator',
    CalcAddressPoint_lift_3: 'Freight elevator',
    CalcAddressPoint_lift_4: 'Both types of elevators',
    CalcAddressPoint_tag_1: 'Floor',
    CalcAddressPoint_tag_2: 'Contact person',
    CalcOrder_size: 'm',
    CalcOrder_time: 'h',
    CalcOrderHeader_drop: 'Roll up',
    CalcOrderHeader_title: 'Your order',
    CalcOrderHeader_btn_1: 'About order',
    CalcOrderHeader_btn_2: 'Cancel',
    CalcSidebar_step1_type_point: 'Unloading point',
    CalcSidebar_step1_push_placeholder: 'Where are we taking your cargo',
    CalcSidebar_step1_address_title_1: 'address',
    CalcSidebar_step1_address_title_2: 'addresses',
    CalcSidebar_step1_address_title_3: 'addresses',
    CalcSidebar_step3_type_1: 'mover',
    CalcSidebar_step3_type_2: 'mover',
    CalcSidebar_step6_time_1: 'Serve',
    CalcSidebar_step6_time_2: 'min',
    CalcSidebar_step6_time_3: 'crews',
    CalcSidebar_step6_time_4: 'online',
    UploaderPhoto_preview_text: 'Select photo',
    CalcStep3_check_time_1: 'Serve',
    CalcStep3_check_time_2: 'minutes',
    CalcStep3_check_time_3: 'crews',
    CalcStep3_check_time_4: 'online',
    CalcStep4_check_time_1: 'Serve',
    CalcStep4_check_time_2: 'min',
    CalcStep4_check_time_3: 'crews',
    CalcStep4_check_time_4: 'online',
    CalcStep4_check_time_5: 'More than 1 hour',
    CalcSidebar_step1_hint_title: 'An error occurred',
    CalcSidebar_step1_hint_desc: 'You need to specify addresses for loading and unloading points to make an order.',
    CalcSidebar_step5_hint_title: 'An error occurred',
    CalcSidebar_step5_hint_desc: 'You need to log in to continue the order creation.',
    Index_press_title: 'About us',
    Index_reviews_title: 'Reviews about us',

    ROLE_ANON: 'Not authorized',


    ROLE_ADMIN: 'Administrator',
    ROLE_CUSTOMER: 'Customer',
    ROLE_COMPANY: 'Comnpany',
    ROLE_COMPANY_MANAGER: 'Comnpany Manager',
    ROLE_DRIVER: 'Driver',
    ROLE_PRIVATE_DRIVER: 'Private driver',
    ROLE_MANAGER: 'Manager',
    ROLE_SUPPORT: 'Support',
    ROLE_DELIVERY_API_CLIENT: 'Delivery API client',
    ROLE_DELIVERY_MANAGER: 'Delivery Manager',
    ROLE_PARTNER_API_CLIENT: 'Partner API client',
    ROLE_PARTNER_MANAGER: 'Partner Manager',

    order_phone: 'Phone',
    order_calendar: 'Date',
    order_status: 'Statuses',
    order_number: 'Order ID',

    //footerAdv
    BusinessHead: 'Eliminate additional shipping costs',
    BusinessBtn: 'Connect',
    BusinessText: 'MOVER can work as your personal transport or logistics department. Our team will examine your needs and select the best set of services.',
    Business24Connect: 'Contact you within 24 hours',
    BusinessCntTitle1: 'Traffic analyze',
    BusinessCntTitle2: 'Select suitable service',
    BusinessCntTitle3: 'Run into work',
    BusinessCntTitle4: 'Support 24/7',
    BusinessCntDesc1: 'Сollect necessary information and make an opyimal integration plan.',
    BusinessCntDesc2: 'Or we jointly develop an individual tariff.',
    BusinessCntDesc3: 'Connect and train your employees and our drivers.',
    BusinessCntDesc4: 'Statistics and experience will help to improve integration.',

    //FOOTER
    FooterClients: 'for Client',
    FooterClientsApp: 'Application',
    FooterClientsTariff: 'Tariffs',
    FooterClientsFleet: 'Autopark',
    FooterClientsPartners: 'Partners',
    FooterBusiness: 'for Business',
    FooterDrivers: 'for Driver',
    FooterAll: 'for All',
    FooterBusMover: 'MOVER for Business',
    FooterBusIntegration: 'Integration',
    FooterBusShop: 'Internet shops',
    FooterBusMalls: 'Malls',
    FooterDriversConnect: 'How to join',
    FooterDriversApp: 'Application',
    FooterPartnership: 'Partnership',
    FooterDriversBlog: 'Drivers Blog',
    FooterDriversFaq: 'F.A.Q.',
    FooterAllAbout: 'About MOVER',
    FooterAllSupport: 'Support Center',
    FooterBtmSecure: 'Payment Security',
    FooterBtmTerms: 'Terms of Use',
    FooterBtmConf: 'Privacy Policy',
    FooterBtmDict: 'Terms and Definitions',

    FooterLangReg: 'LANGUAGE AND REGION',
    FooterBlog: 'Blog',
    FooterBigContact: 'Contacts',
    FooterAddress:'Pravdy 24, b. 3, Moscow, Russia',

    //GOODS TOP ADV LIST
    goods1: 'sofa',
    goods2: 'motorbike',
    goods3: 'double glazing',
    goods4: 'snowmobile',
    goods5: 'table',
    goods6: 'building materials',
    goods7: 'fridge',
    goods8: 'conditioner',
    goods9: 'bicycle',
    goods10: 'flat',
    goods11: 'plumbing',
    goods12: 'stove',
    goods13: 'fireplace',
    goods14: 'tires',
    goods15: 'barrel of oil',
    goods16: 'piano',
    goods17: 'treadmill',
    goods18: 'horse',
    goods19: 'non-transportable',
    goods20: 'cupboard',
    goods21: 'trees',
    goods22: 'rhinoceros',
    goods23: 'chair',
    goods24: 'kitchen',
    goods25: 'furniture',

    DiscountText: 'DISCOUNT ON YOUR FIRST TRIP',

    HomeSecondSlide1: 'Perfect for small loads up to 800 kg. and long from 2 m. Car in a tariff similar to Berlingo, Vito, Partner.',
    HomeSecondSlide2: 'Standard truck, Crafter, Transit and similar cars for the transport of goods up to 3 m. Minimum rate is 990 ₽ per 1 hour.',
    HomeSecondSlide3: 'Increased crew for goods lengths up to 4 meters and more. Minimum tariff is 1190 ₽ per 1 hour.',
    HomeSecondSlide4: 'Big truck up to 4 meters with a professional driver for volume cargo and long trips. Minimal rate is 3250 ₽ per 4 hours of transportation.',
    HomeSecondSlideUPTO: 'up to',
    HomeSecondSlide1KG: '800 kg',
    HomeSecondSlideKG: 'kg',
    HomeSecondSlide2KG: '1.5 tons',
    HomeSecondSlide3KG: '2.5 tons',
    HomeSecondSlide4KG: '3 tons',

    TariffGoodType1: '🛋 Furniture',
    TariffGoodType2: '🖥 Home appliances',
    TariffGoodType3: '🎻 Musical instruments',
    TariffGoodType4: '🖨 Office equipment',
    TariffGoodType5: '🏭 Construction garbage',
    TariffGoodType6: '🏭 Industrial equipment',

    TariffCarSize1: 'Big',
    TariffCarSize2: 'Optimal',
    TariffCarSize3: 'Cheapest',
    TariffCarSize4: 'Biggest',

    TariffSlide1: 'Transportation',
    TariffSlide2: 'Loading and unloading',

    TariffCarType1: 'Furgon',
    TariffCarType2: 'Tent',
    TariffCarType3: 'Bort',
    TariffCarType4: 'Fermer-Bort',
    TariffCarType5: 'Full-metall',
    TariffCarType6: 'Fermer',
	TariffCarType7: 'Изотерм',

    TariffGetTime1: 'Always',
    TariffGetTime2: 'Once',
    TariffGetTime3: 'One side',
    TariffGetTime4: 'Free',

    TariffCountTime: 'Estimated time',
    TariffCountMin: 'Minimum working time',
    TariffCountH: 'h',
    TariffCountH2: 'hour',
    TariffCountKM: 'km',
    TariffCountM: 'm',
    TariffCountM2: 'min',
    TariffCountT: 't',
    TariffCountT2: 'tonns',

    TariffCalcCb: 'coubs',

    TariffTime: 'at night',

    TariffCalc: 'Calculate',
    TariffCariages: 'cariages',
    TariffCalcTime: 'The calculated distance is',
    TariffMainTime: 'Lead time for loading and unloading is',
    TariffAddressInp: 'Enter the addresses of your shipment to get the estimated lead time.',
    BusinessNoNumber: 'Wrong phone number',
    BusinessDenied: 'Access denied',
    BusinessNoLogin: 'Wrong login or password',
    BusinessNoAcc: 'This login isn\'t exist',
    BusinessReged: 'This number is registered here',
    BusinessSysErr: 'System error',
    BusinessPutNumber: 'Enter your phone number',

    TariffsSlide2_tb1: 'Loader',
    TariffsSlide2_tb2: 'Adjuster',
    TariffsSlide2_tb3: 'Loader + Floor',
    TariffsSlide2_tb1_1: 'Simple loader',
    TariffsSlide2_tb2_1: 'Assembly and disassembly of cargo',
    TariffsSlide2_tb3_1: 'Descend and climb floors',
    TariffsSlide2_desc1: 'For 1 loader',
    TariffsSlide2_desc2: 'Fixed',
    TariffsSlide2_list1: 'Loading, moving things, managing goods in the car',
    TariffsSlide2_list2: 'Loading, moving things, managing goods in the car, assembly and disassembly of things',
    TariffsSlide2_list3: 'Loading, moving things, managing goods in the car, assembly and disassembly of things, the descent and lifting of cargo on floors and stairs',

    MainFrom: 'from',
    MainTo: 'to',
    MainFromIn: 'Loading point',
    MainFromOut: 'Discharge point',

    GetFirstBonus: 'Enter secret key 🤡',

    checkPrice: 'Check price',
    calcPrice: 'Calculate price',
    CarDescription: 'Car Description',
    entranceFee: 'Paid entrance to downtown',
    entranceFree: 'Free entrance to downtown',

    getCarsInfo1: 'LADA Largus is a small car, perfectly suited for transporting something not large. Perfect for delivering small things.',
    getCarsInfo2: 'Peugeot Partner is a minivan based on a golf class car. Partner is primarily a small class truck, the back door can be both lifting and made in the swing gates, which allows you to load your stuff efficiently and quickly.',
    getCarsInfo3: 'The most popular Russian truck model. Have the stronger body unlike the basic model of "Gazelle". Optimal way for transporting goods in region and off-road. ',
    getCarsInfo4: 'Light commercial truck, one of the most popular models. Pretty good spaciousness, economical fuel consumption, easily move in city.',
    getCarsInfo5: 'Fiat Ducato is Italian low-tonnage van for moving small shipments of goods or small loads of up to 1.5 tons. The high ground clearance and very good maneuverability allow this car to easy menage with any traffic jams with honor, drive into ramps and park close to the curbs what avoids most of the parking problems. ',
    getCarsInfo6: 'Peugeot Boxer is a low-tonnage wagon with an full-metal body, which is rightfully considered one of the leaders in the field of small-scale cargo transportation. He gained wide popularity due to the presentable appearance in combination with practicality, low fuel consumption, large capacity. ',
    getCarsInfo7: 'The most popular Russian truck model. Have the stronger body unlike the basic model of "Gazelle". Optimal way for transporting goods in region and off-road. ',
    getCarsInfo8: 'GAZelle Next is suitable for any task - from moving to the delivery of raw materials and goods. GAZelle Next transportation will be on the most highest level, because this car has managed to prove itself with the utmost worthiness not only in Russia, but also abroad. ',
    getCarsInfo9: 'In terms of price and quality, it is one of the best in its class, it has especial comfort, efficiency, capacity and maneuverability, which makes it one of the most suitable cars for transportation.',
    getCarsInfo10: 'Planning to move to a new apartment? Need to arrange a mid-sized office relocation? Urgently required to deliver building materials to the object? Foton is perfect for all of this. Foton carrying capacity - 3 tons, length - 4.5 m, which is suitable for solving many transport problems. ',
    getCarsInfo11: 'Office, apartment or village moving, delivery of building materials or wholesale deliveries of non-food products - JMC will menage with all of these tasks perfectly.',

    //FOR BUSINESS
    BusIntro1: 'Best carriers',
    BusIntro2: 'for business',
    BusIntro3: 'Thousands of drivers are available online with postpay and cashback',
    BusTit1: 'Keep full responsibility',
    BusTit2: 'Proven drivers',
    BusTit3: 'Pay after',
    BusTitText1: 'You conclude an agreement with us, we are responsible for each performer',
    BusTitText2: '100% of machines have been tested and are ready for service standards',
    BusTitText3: 'You can pay by cash, card or to your current account.',
    BusConnect: 'Connect',
    BusSec1: 'We help to save on transportation',
    BusSec2: 'We have automated the process of ordering, giving you maximum control and transparent processes',
    Bus2_1: 'Ready',
    Bus2_2: 'solutions',
    Bus2_3: 'for your business',
    Bus2Intro: 'Use MOVER at you like, connect only necessary services',
    Bus3_1: 'Express-deliver',
    Bus3_2: 'Relocation',
    Bus3_3: 'Delivery on request',
    Bus3_4: 'Teleportation',
    Bus3_1Intro: 'Collect goods from any points of the city and send on one vehicle.',
    Bus3_2Intro: 'Your customers are always available the full range online. Move your goods easy from a warehouse to retail outlets within 30 minutes.',
    Bus3_3Intro: 'Pick the crew nearest you who will pick up the cargo and perform express delivery or delivery by the time at the request of the buyer.',
    Bus3_4Intro: 'Automated express delivery service to the customer in 3 hours, integrated into your online store or retail outlet.',
    BusCarType1: 'Cargo taxi',
    BusCarType2: 'Delivery on request',
    BusPrivate: 'I need an individual solution',
    BusinessControlTab1: 'Orders',
    BusinessControlTab2: 'Employers',
    BusinessControlTab3: 'Finance',
    BusinessControlTab4: 'Documents',

    BusinessMain1: 'Manage your orders or transport in one window',
    BusinessMain1Intro: 'Your employees always see relevant information about delivery, and can change the route and manage the crews',
    BusinessMain1_1: 'Work online',
    BusinessMain1_2: 'Change any time',
    BusinessMain1_3: 'No human factor',
    BusinessMain1_1Intro: 'Connect all the information flows from the client, driver and employees',
    BusinessMain1_2Intro: 'You can always create, change, cancel or repeat an order',
    BusinessMain1_3Intro: 'Data is automatically entered into the system and is 100% reliable',

    BusinessMain2: 'All your employees from drivers to managers are always at hand',
    BusinessMain2Intro: 'Invite needed people, distribute the roles and add the fleet to the service. Very simple and fast. Absolutely free',
    BusinessMain2_1: 'Salesman, Logistics and Driver',
    BusinessMain2_2: 'Inside chat',
    BusinessMain2_3: 'Action history',
    BusinessMain2_1Intro: 'Everyone has own application and access levels',
    BusinessMain2_2Intro: 'Your employees can communicate with each other and with the drivers on the road',
    BusinessMain2_3Intro: 'We keep information about each change in the system for analysis',

    BusinessMain3: 'Control cash transactions and get accurate statistics.',
    BusinessMain3Intro: 'Convenient tools for working with financial flows from paying deliveries to paying salaries',
    BusinessMain3_1: 'Transparency for all',
    BusinessMain3_2: 'Money orders',
    BusinessMain3_3: 'Statistics for growth',
    BusinessMain3_1Intro: 'No hidden fees and commissions, and what the client sees is up to you',
    BusinessMain3_2Intro: 'Give drivers money orders and monitor performance',
    BusinessMain3_3Intro: 'Comparative schedules for the arrival / expenditure of money for making decisions',

    BusinessMain4: 'Create and send all the necessary documents directly from the order',
    BusinessMain4Intro: 'Any power of attorney, invoices, deeds, consignment notes and consignment notes in certified templates are created in 1 click',
    BusinessMain4_1: 'Send to everyone at once',
    BusinessMain4_2: 'Electronic document management',
    BusinessMain4_3: 'Acceptance of payments from customers',
    BusinessMain4_1Intro: 'All the necessary documents are immediately available in the driver’s personal account and yours',
    BusinessMain4_2Intro: 'Work with us and counterparties on EDI for easy document flow',
    BusinessMain4_3Intro: 'Comparative schedules for the arrival / expenditure of money for making solutions',

    BussBlock3Title: 'Who are we suitable for ',
    BussBlock3Title2: 'right now',
    BussBlock3Intro: 'Connect your business in 24 hours and start providing a unique express delivery service to your customers',
    BussItem1: 'Construction companies',
    BussItem2: 'Auto parts',
    BussItem3: 'Internet shops',
    BussItem4: 'Shops',
    BussItem5: 'Transport companies',
    BussItem6: 'Event agencies',
    BussItem7: 'Furniture shops',
    BussItem8: 'Equipment',
    BussItem9: 'Craft production',
    BussItem10: 'Garden Supplies',
    BussItem11: 'Sporting goods',
    BussItem12: 'Your business',
    BussComp1: 'companies',
    BussComp2: 'companies',
    BussComp3: 'shops',
    BussComp4: 'agencies',

    BussBlock4Title: 'MOVER and Transport Companies',
    BussBlock4Intro: 'There is the same difference between MOVER and ordinary TC, as between a landline phone and a smartphone',
    BussBlock4Column1: 'Parameters',
    BussBlock4Column2: 'Transport company',
    BussBlock4Row1: 'Quantity of cars',
    BussBlock4Row2: 'Responsibility for the cargo',
    BussBlock4Row3: 'Minimum cost of transportation',
    BussBlock4Row4: 'Checkout',
    BussBlock4Row5: 'Car delivery time',
    BussBlock4Ph1: 'By phone',
    BussBlock4Ph2: 'All platforms',

    BussBlock5Title: 'Terminal for ordering a cargo taxi for your customers',
    BussBlock5Intro: 'Unloads the logistics department and gives full control of delivery to the customers of any hypermarket, mall or market',
    BusinessMain5_1: 'Any tariffs',
    BusinessMain5_1Intro: 'The terminal can work with both your and our tariffs',
    BusinessMain5_2: 'Order in 1 minute',
    BusinessMain5_2Intro: 'All information is available on your system and you can always cancel the delivery',

    BussBlock6Title: 'Only the best work with us',
    BussBlock6Intro: 'The rating system and our transport department will not miss scammers and boors. We always hear our customers and work daily on quality control tools.',

    //DRIVER
    driverIntro: 'The service works with crews directly, you get prices without hidden charges and overpayments.',
    DriverInf: 'Information',
    dataReg: 'Date registration',
    birthday: 'Birthday',
    driverCrewTop: 'Top drivers',
    totalMoves: 'Total orders',
    driverCancelled: 'Cancelled orders',
    driverRating: 'Rating',
    driverFeedback: 'Feedback',
    driverFirstH: 'First hour',
    driverAddH: 'Addition hour',

	stockSmallTitle1: 'Скидка 30% в подарок на День Рождения',
	stockSmallTitle2: 'Поделись MOVER с другом',
	stockSmallTitle3: 'Оставь отзыв о MOVER',
	stockSmallTitle4: '300 рублей на первую перевозку',
	stockSmallDesc1: 'Мы дарим вам скидку в 30%. Для активации скидки, свяжитесь со службой поддержки',
	stockSmallDesc2: 'Полный ресайз акции из приложения МУВЕР',
	stockSmallDesc3: 'Расскажите, как вам понравился MOVER и мы с гордостью подарим вам скидку на следующие перевозки в размере 5%',
	stockSmallDesc4: 'Зарегистрируйтесь в приложении MOVER и получите скидку в 300 рублей по промокоду «<b>START</b>»',
	stockAppTitle: 'Скачайте приложение',
	stockSlideTitle1: 'Бесплатный тест-драйв MOVER для <span class="bold">бизнеса</span>',
	stockSlideTitle2: 'ЗАКАЖИ ПЕРЕВОЗКУ НА САЙТЕ СО СКИДКОЙ <span class="bold">10%</span>',
	stockSlideDesc1: 'Подключите свой бизнес к MOVER и получите <b class="bold">1000</b> рублей на будущие перевозки.',
	stockSlideDesc2: 'Только в MOVER, в течении всей зимы, закажи перевозку с сайта <b class="bold">MOVER24.RU</b> и получи перевозку по самой низкой цене в городе.',
	stockSlideHint1: 'После подключения на ваш лицевой счёт будет зачислено <span class="bold">1000</span> рублей, вам останется их потратить на перевозки и принять правильное решение — начать работать с MOVER!',
	stockSlideBtn1: 'Подключить бизнес',
	stockSlideBtn2: 'Заказать',
    indexFirstLanding1: 'Any cargo up to 3 tons',
    indexFirstLanding2: '3040 active crews',
    indexFirstLanding3: '1 or 2 loaders',
    indexFirstLanding4: 'Free car feed',
    indexFirstLanding5: 'Payment after transportation',
    indexFirstLanding6: 'We deliver day to day, pick up from the hypermarket, help with the moving',


};

export default Lang;
