import React    from 'react';

export default class ImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            imagePreviewUrl: ''
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        // Тут обрабатываем загрузку самого фото
        console.log('Обрабочик загрузки фото: ', this.state.file);
    };

    handleImageChange = (e) => {
        let { index } = this.props;
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            let fileData = {
                file: file,
                imagePreviewUrl: reader.result
            };
            this.setState(fileData);

            this.props.fileUpload(index, fileData);
        };

        reader.readAsDataURL(file);

        console.log(e.target);
    };

    render() {
        let { imagePreviewUrl } = this.state;
        const { lang } = this.props;

        let imagePreview = imagePreviewUrl ? (<img src={imagePreviewUrl} />) : (<div className="previewText">{lang.UploaderPhoto_preview_text}</div>);

        return (
            <div className="previewComponent">
                <form onSubmit={this.handleSubmit}>
                    <input className="fileInput" type="file" onChange={this.handleImageChange} />
                    <button className="icon submitButton" type="submit" onClick={this.handleSubmit} />
                </form>

                <div className="imgPreview">{imagePreview}</div>
            </div>
        )
    }
}
