import React            from 'react';
import cx               from 'classnames';
import { declOfNum }    from '../../utils/helpers';

export default class CalculatorSideBarStep1 extends React.Component {
    constructor(props) {
        super(props);
    }

    setActive = (val) => this.props.setActive(1);

    render() {
        const { lang, step, points, info } = this.props;
        const hasAdressFrom = !!(points[0] && points[0].adress && points[0].adress.formated_address);

        let hasAdressTo = false;

        points.map((point) => {
            if(point.adress.formated_address)  hasAdressTo = true;
        });

        const hasAdress = hasAdressFrom || hasAdressTo;

        const itemCSS = cx({
            'Сalculator-sideBar-item': true,
            'item-1': true,
            'active': step === 1,
            'empty': !hasAdress,
			'old': step > 1
        });

        const iconEndCSS = cx({
            icon: true,
            end: true,
            state1: true,
            state2: step !== 1
        });

        let title = declOfNum((points.length - 2), [lang.CalcSidebar_step1_address_title_1, lang.CalcSidebar_step1_address_title_2, lang.CalcSidebar_step1_address_title_3]);

        const adressEmpty = (
            <div className="adress-empty">
                <div className="row start">
                    <div className="icon start"></div>
                    <div className="title-wrap">
                        <span>{lang.Calculator_where}</span>
                    </div>
                </div>
                <div className="row end">
                    <div className={iconEndCSS}></div>
                    <div className="title-wrap">
                        <span>{lang.Calculator_to}</span>
                    </div>
                </div>
            </div>
        );

        const adressFull = () => {
            let startElem = points[0];
            let lastElem = points[points.length - 1]; // Удалили по середине и нельзя обратиться к последнему полю так как порядок ключей нарушен

            return (
                <div className="adress-full">
                    <div className="row start">
                        <div className="icon start"/>

                        {!startElem.defaultValue ? (
                            <div id="calculatorSBAddressStart" className="title-wrap">
                                <span className="prefix">{lang.Calculator_where}</span>
                                <span id="calculatorSBAddressWhere" className="adress">{startElem.adress.formated_address}</span>
                                <span className="city">{startElem.adress.city}</span>
                            </div>
                        ) : (
                            <div className="title-wrap">
                                <span>{lang.Calculator_where}</span>
                            </div>
                        )}
                    </div>

                    <div className="row eta">
						<div className="divider"/>

                        <div id="calculatorSBAddressEta" className="eta-wrap">
                            {points.length - 2 > 0 ? (<div className="more-adreess">{lang.Calculator_more} {points.length - 2} {title}</div>) : null }
                        </div>
                    </div>


                    <div className="row end">
                        <div className={iconEndCSS}/>

                        {!lastElem.defaultValue ? (
                            <div id="calculatorSBAddressFinish" className="title-wrap">
                                <span className="prefix">{lang.Calculator_to}</span>
                                <span id="calculatorSBAddressTo" className="adress">{lastElem.adress.formated_address}</span>
                                <span className="city">{lastElem.adress.city}</span>
                            </div>
                        ) : (
                            <div className="title-wrap">
                                <span>{lang.Calculator_to}</span>
                            </div>
                        )}
                    </div>
                </div>
            )
        };

        return (
            <div id="calculatorSBItem1" className={itemCSS} onClick={this.setActive}>
                {hasAdress ? adressFull() : adressEmpty}
            </div>
        );
    }
}