import React from 'react';
import classnames from "classnames";

export default class Hint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: false
        };
    }

    close = () => {
        this.setState({
            hidden: true
        });

        this.props.deleteError();
    };

    render() {
        const { title, desc, type, idWrapper, idClose } = this.props;
        const className = classnames({
            hint: true,
            info: type === 'info',
            error: type === 'error',
            hidden: this.state.hidden
        });

        return (
            <div id={idWrapper} className={ className }>
                <i id={idClose} className="icon" onClick={this.close} />
                <label className="hint__title">{ title }</label>
                <p className="hint__desc">{ desc }</p>
            </div>
        );
    }
}