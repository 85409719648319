import React from "react";

const Lang = {
    langName: 'ru',
    error500: 'Системная ошибка',
    PageApp_first_slide_title: 'С приложениями MOVER перевозки пройдут без хлопот.',
    PageApp_first_slide_desc: 'Благодаря удобному интерфейсу заказ любого вида перевозки всего в 5 касаний.',
    PageApp_first_slide_links1: 'Мобильные приложения',
    PageApp_first_slide_links2: '  E-commerce',
    PageApp_first_slide_links3: 'Облачные сервисы',
    PageApp_first_slide_links4: '  Сервисы доставки',
    PageApp_second_slide_title: 'Легко заказать - быстро перевезти',
    PageApp_second_slide_circle_1_title: 'Скачай',
    PageApp_second_slide_circle_2_title: 'Закажи',
	Order: 'Заказать',
	PageApp_second_slide_circle_3_title: 'Перевози',
    PageApp_second_slide_circle_1_info: 'Установите бесплатное приложение MOVER на свой смартфон из <a href="https://apps.apple.com/ru/app/mover-грузовое-такси/id1080999192">App Store</a> или <a href="https://goo.gl/Wl8y0C">Google Play</a>',
    PageApp_second_slide_circle_2_info: 'Без лишних усилий вы получите доступ к более 1000 грузовых экипажей по экономным тарифам.',
    PageApp_second_slide_circle_3_info: 'Лучшие экипажи вашего города доступны в пару кликов, всегда поблизости и приедут в течение 30 минут.',
    PageApp_slider_1_title: 'Укажи маршрут',
    PageApp_slider_1_desc: 'Интуитивный интерфейс приложения MOVER при построении маршрута позволяет ввести адреса контрольных точек как привычным способом, так и указать их прямо на карте! Всего 3 клика..И ваш груз в пути!',
    PageApp_slider_1_bage_1: 'Отслеживание на карте',
    PageApp_slider_1_bage_2: 'Точный просчет цены',
    PageApp_slider_1_bage_3: 'Контроль выполнения',
    PageApp_slider_1_bage_4: 'Геолокация вашего груза',
    PageApp_slider_2_title: 'Выберите тариф',
    PageApp_slider_2_desc: 'Компания MOVER разработала интеллектуально точную и максимально гибкую линейку тарифов для своих клиентов. Четыре базовых тарифа можно настраивать под себя как вам угодно. На выбор клиента предлагается как тариф МИНИ, для быстрой доставки мелких грузов, так и МАКСИ, идеальный спутник больших грузов. Тарифы Стандарт и Экстра - уникальное дополнение друг друга на пути к решению сложных задач. Тарифы на грузоперевозки от сервиса MOVER - точная цена и прозрачная работа без скрытых комиссий и платежей.',
    PageApp_slider_2_bage_1: 'Конструктор кузовов',
    PageApp_slider_2_bage_2: 'Готовые шаблоны перевозок',
    PageApp_slider_2_bage_3: 'Счетчик экипажей онлайн',
    PageApp_slider_2_bage_4: 'Авто-подбор экипажей',
    PageApp_slider_3_title: 'Добавьте груз',
    PageApp_slider_3_desc: 'Специально разработанный компанией MOVER алгоритм позволяет подобрать автомобиль под ваши задачи, а экипаж будет готов к любым неожиданностям. Сервис позволяет указать параметры груза и прикрепить к заказу его фотографии! Функциональные возможности приложения также включают в себя упоминание специфических параметров маршрута. Например пропускной режим, особенности проезда.',
    PageApp_slider_3_bage_1: 'Фотографии груза',
    PageApp_slider_3_bage_2: 'Интуитивная система комментариев',
    PageApp_slider_4_title: 'Отправь сейчас - скачай потом',
    PageApp_slider_4_desc: 'Отправьте себе или своим друзьям ссылку на мобильные сервисы MOVER и перевозите с удовольствием.',
    PhoneNumber1: 'Номер телефона',

	SendTo: 'Отправить', //pres simple
    PageApp_slider_4_send2: 'Отправлено', //past simple
    PageApp_fourth_slide_title: 'Приложение MOVER для партнеров',
    PageApp_fourth_slide_desc: 'Незаменимый помощник любого перевозчика, бортовой компьютер, который поможет стать настоящим мастером своего дела.',
    PageApp_fourth_slide_item_1_title: 'Распределение заказов по геолокации',
    PageApp_fourth_slide_item_1_desc: 'Получайте заказы недалеко от вас, расходуйте меньше топлива и времени',
    PageApp_fourth_slide_item_2_title: 'Прием любого вида платежей',
    PageApp_fourth_slide_item_2_desc: 'С приложением MOVER вы ничем не ограничены, принимайте любой тип оплаты',
    PageApp_fourth_slide_item_3_title: 'Действительно удобное приложение',
    PageApp_fourth_slide_item_3_desc: 'Управляйте своими заказами в самом передовом приложении на рынке грузоперевозок',
    PageApp_fourth_slide_flash_title: 'Без комиссии',
    PageApp_fourth_slide_flash_desc: 'Сервис работает с экипажами напрямую, вы получаете цены без скрытых накруток и переплат',
    PageApp_fourth_slide_shield_title: 'Лучшие перевозчики',
    PageApp_fourth_slide_shield_desc: 'Мы лично проверяем каждого водителя, оцениваем качество автомобиля и профессионализм',
    PageApp_fourth_slide_simple_title: 'Заказ в 3 клика',
    PageApp_fourth_slide_simple_desc: 'Получите доступ к любым ближайшим экипажам всего за пару минут на любой платформе',
    PageApp_fifth_slide_title: 'Делаем Ваши перевозки выгоднее',
    PageApp_fifth_slide_desc: 'Делитесь MOVER с друзьями, получайте скидки или бесплатные поездки.',
    PageApp_fifth_slide_placeholder: 'Email для подключения',
    PageApp_fifth_slide_tip: 'Поделись и получи 300 рублей',

    appLang: 'Русский',
    appLangRU: 'Русский',
    appLangEN: 'English',

	HeaderSmall_clients: 'КЛИЕНТАМ',
	HeaderSmall_business: 'БИЗНЕСУ',
	HeaderSmall_drivers: 'ВОДИТЕЛЯМ',
	HeaderSmall_lk: 'Личный кабинет',
	HeaderSmall_login: 'Войти',

    headerBig_About: 'О СЕРВИСЕ',
    headerBig_APPs: 'ПРИЛОЖЕНИЯ',
    headerBig_Integration: 'ИНТЕГРАЦИИ',
	headerBig_CarPark: 'АВТОПАРК',
	headerBig_Promo: 'АКЦИИ',
	headerBig_Tariff: 'ТАРИФЫ',
	headerBig_Help: 'ПОМОЩЬ',
	headerBig_Job: 'ВАКАНСИИ',
	headerBig_Partners: 'ПАРТНЕРЫ',
	headerBig_Blog: 'БЛОГ',
    headerBig_btn_calc: 'РАССЧИТАТЬ ЗАКАЗ',

    // Калькулятор
    CalculatorHeaderTitle: 'Ваш заказ на ',
    CalculatorHeaderTitle2: 'Cегодня ',

    Calculator_pro_mode: 'Режим PRO',
    Calculator_where: 'Откуда',
    Calculator_to: 'Куда',
    Calculator_cargo_title: 'Груз',
    Calculator_add_cargo: 'Добавить груз',
    Calculator_del_cargo: 'Удалить груз',
    Calculator_add_mov: 'Грузчики',
    Calculator_mov: 'ГРУЗЧИКИ',
    Calculator_sel_tar: 'Выберите тариф',
    Calculator_pay: 'Оплата',
    Calculator_car_del: 'Подача 30 минут',
    Calculator_37_carr: '37 экипажей онлайн',
    Calculator_route: 'Маршрут',
    Calculator_load_point: 'Точка погрузки',
    Calculator_address_1: 'Правды? 24с3? Москва +Этаж +Лифт',
    Calculator_add_comm: '+Комментарий',
    Calculator_add_cont: '+Контактное лицо',
    Calculator_reverse: 'Поменять местами',
    Calculator_unload_point: 'Точка разгрузки',
    Calculator_address_2: 'Ленинский проспект, 53с2, Москва',
    Calculator_elevator: 'Грузовой лифт +Контактное лицо',
    Calculator_add_point: 'Добавить точку',
    Calculator_del_point: 'Удалить точку',
    Calculator_hide: 'Скрыть карту',
    Calculator_add_comment: 'Добавить комментарий',
    Calculator_add_mover: 'Добавить грузчиков',

    Calculator_more: 'Еще ',
    Calculator_calculated_time: 'Рассчетное время работы',
    Calculator_default: 'По умолчанию выбран тариф Стандарт',
    Calculator_cargoes: 'Грузы',
    Calculator_cargo: 'Груз ',
    Calculator_type_cargo: 'Тип груза',
    Calculator_fragile_cargo: '+Хрупкий груз +Мебель +Стройматериалы',
    Calculator_add_services: 'Дополнительные услуги',
    Calculator_lifting: 'Спуск / Подъем',
    Calculator_lifting_desc: 'Если груз габаритный и не влезает в лифт, грузчики помогут перенести его вручную по этажам в указанное вами место.',
    Calculator_assembly: 'Сборка / Разборка',
    Calculator_assembly_desc: 'Грузчики помогут с разборкой и последующей сборкой указанного вами груза.',
    Calculator_1_mover: ' грузчик',
    Calculator_heavier_than_60_kg: 'Вес груза приходящийся на одного грузчика не должен превышать более 50 кг.',
    Calculator_the_first_hour: 'Первый час работы',
    Calculator_add_hour_work: 'Дополнительный час работы',
    Calculator_loaders_waiting: 'Ожидание',
    Calculator_cargo_lifting: 'Подъем груза на 1 этаж',
    Calculator_2_movers: ' грузчика',
    Calculator_no_movers: 'Без грузчиков',
    Calculator_free: 'Бесплатно',
    Calculator_specify: 'Разгрузим вас от проблем',
    Calculator_your_cargo: 'ВАШ ГРУЗ',
    Calculator_insured: 'Укажите какое количество грузчиков вам требуется. Заберем любой ваш груз от дверей и доставим в указанное вами место.',


    Calculator_standart_2_tons: 'Стандарт 2 тонны',
    Calculator_gazelle: 'Стандартная Газель, Mercedes Sprinter, Ford Transit, Hyundai Porter',
    Calculator_awning: 'Тент • Фургон • Цельнометаллический',
    Calculator_FullM: 'Цельнометаллический',
    Calculator_Furgon: 'Фургон',
    CalculatorGAZBus: 'GAZelle Бизнес',
    Calculator_normal_long: 'Обычная • Длинная',
    Calculator_lenght: 'Длина • Ширина • Высота • Объём',
    Calculator_mini: 'Мини • 800 кг',
    Calculator_extra: 'Экстра • 2,5 тонны',
    Calculator_maxi: 'Макси • 3 тонны',
    CalculatorStand: 'Стандарт',
    CalculatorMax: 'Макси',
    CalculatorMin: 'Мини',
    CalculatorExtra: 'Экстра',


    Calculator_way_payment: 'Способ оплаты',
    Calculator_cash: 'Наличными',
    Calculator_card: 'Картой',
    Calculator_invoice: 'Со счета',
    Calculator_promo_code: 'Промокод',
    Calculator_data: 'Ваши данные',
    Calculator_igor: 'Игорь Николаев',
    Calculator_get_code: 'Получить код',


	Calculator_hour_by_tariff: 'Первый час по тарифу',
    Calculator_add_hour_tariff: '1 доп час по тарифу',
    Calculator_Leninsky: 'Ленинский проспект, 53с2',
    Calculator_total: 'Итого',
    Calculator_discount: 'С учетом скидки',


    Calculator_processed: 'Ваш заказ обрабатывается',
    Calculator_drivers: '3 из 5 водителей',
    Calculator_send_link: 'Мы отправим ссылку на ваш номер телефона на скачивание приложения, в котором вы можете отследить заказ',
    Calculator_number: 'Номер телефона', //not used
    Calculator_send_number: 'Отправить',

	Calculator_additional_services: 'Дополнительные услуги',
	Calculator_storage: 'Хранение вещей от нашего партнёра QB.',
	Calculator_storage_note: 'Принимаем на хранение вещи любого размера: от книг и сезонной одежды до бытовой техники и мебели. Бесплатная упаковка, охраняемый теплый склад в Москве, онлайн-витрина со списком ваших вещей.',
	Calculator_storage_price: 'от 449 ₽',
	Calculator_storage_period: 'месяц',

	Carrer: 'Карьера',
	Job_desc_1_page: 'Сервис MOVER создан с целью упростить жизнь обычному горожанину в повседневных бытовых проблемах, связанных с перевозкой грузов и переездами любой сложности.',
	Job_desc_2_page: 'Мы постоянно ищем в команду талантливых людей, которые беспрерывно находятся в поиске лучших решений в работе, людей с горящими глазами, желающих перевернуть мир и внести значимый вклад в индустрию. Присоединяйтесь к MOVER и мы вместе перевезем мир к лучшему!',
	Job_links_link: 'Предложить',
	Job_links_offer: 'Предложите свою вакансию',

	Partners_title_page: 'Партнёры',
	Partners_desc_page: 'У MOVER множество партнеров, которые ежедневно помогают нам делать сервис лучше и многогранней.',
	Partners_links_link: 'Стать партнёром',
	Partners_links_offer: 'Что значит стать партнером?',

	Tariff_title_page: 'Тарифная сетка',
	Tariff_link_pdf: 'Скачать PDF',
	Tariff_desc_page: 'Тарифы определяют тип и размер автомобиля, который вы вызываете. Разные тарифы хорошо подходят для разных задач.',
	Tariff_question_1_item_title: 'Как выбрать тариф',
	Tariff_question_1_item_desc: 'Если вы укажете груз - ваша перевозка будет застрахована',
	Tariff_question_2_item_title: 'Посмотреть тарифы на карте',
	Tariff_question_2_item_desc: 'Тарифные зоны',
	Tariff_example_title: 'Как использовать MOVER?',
	Tariff_example_cnt_title: 'Доставка по требованию',
	Tariff_example_cnt_desc: 'Даже если вы малый предприниматель с маленьким оборотом и неконтролируемым объемом доставок — MOVER лучший вариант для вас. День в день привезем по требованию груз вам или вашим клиентам в любую точку города и области.',
	Tariff_example_list_item_1: 'Система уведомлений',
	Tariff_example_list_item_2: 'Страховка груза',
	Tariff_example_list_item_3: 'Экспедирование груза',
	Tariff_example_list_item_4: 'Возврат денег и документов',
	Tariff_example_list_item_5: 'Детальная статистика',
	Tariff_example_list_item_6: 'Построение сложных маршрутов',
	Tariff_table_1_desc_0: 'Характеристики',
	Tariff: 'Тариф',
	Tariff_table_1_desc_2: 'Грузоподъёмность',
	Tariff_table_1_desc_3_1: 'Длина',
	Tariff_table_1_desc_3_2: 'Ширина',
	Tariff_table_1_desc_3_3: 'Высота',
	Tariff_table_1_desc_3_4: 'Объём',
	Tariff_table_1_desc_4: 'Типы автомобилей',
	Tariff_table_1_desc_5: 'Тип груза',
	Tariff_table_1_desc_6: 'Цены',
	Tariff_table_1_desc_7: 'Время подачи',
	Tariff_table_1_desc_8: 'Минимальный тариф',
	Tariff_table_1_desc_9: 'Дополнительные 15 минут работы',
	Tariff_table_1_desc_10: 'Въезд в центр',
	Tariff_table_1_desc_11: '1 км за МКАД',
	Tariff_table_2_desc_2: 'Ограничение габаритов груза',
	Tariff_table_2_desc_3: 'Список работ',
	Tariff_table_2_desc_5: 'Ожидание',
	Tariff_table_2_desc_6: 'Минимальный тариф',
	Tariff_table_2_desc_7: 'Дополнительные 15 минут работы',

	TariffMiniIntro: 'Подходит для перевозок небольших грузов',
	TariffStandIntro: 'Стандартный тариф подходит практически всем',
	TariffExtraIntro: 'Идеальный тариф для длинных и габаритных грузов',
	TariffMaxiIntro: 'Подойдет для переездов или перевозки крупногабарита',


	Index_calculator_default_desc: 'Более 1200 водителей в сервисе • Среднее время подачи 40 мин',
	Index_adv_title_1: 'Подача 30 минут',
	Index_adv_title_2: 'Низкие цены',
	Index_adv_title_3: 'Более 2000 экипажей',
	Index_adv_title_4: 'Заказ в 3 клика',
	Index_adv_desc_1: 'К вам приедет ближайший к адресу экипаж, отслеживайте перемещение вашего груза на интерактивной карте',
	Index_adv_desc_2: 'Сервис работает с экипажами напрямую, вы получаете цены без скрытых накруток и переплат',
	Index_adv_desc_3: 'Лично проверяем и обучаем каждого водителя, оцениваем качество авто и отслеживаем их рейтинг',
	Index_adv_desc_4: 'Самый быстрый способ перевезти груз! Получите доступ к ближайшим экипажам в пару кликов на любой платформе',

	Index_callback_2_title: 'Делаем ваши перевозки выгоднее',
	Index_callback_2_desc: 'Делитесь MOVER с друзьями, получайте скидки или бесплатные поездки.',
	Index_callback_2_placeholder: 'Email или номер телефона',
	Index_callback_2_btn: 'Отправить',
	Index_callback_2_hint: 'Поделитесь и получите 3000 рублей',

	Index_steps_title: 'Как это работает?',
	Index_steps_count_desc_1: 'Укажите маршрут и <br> тариф',
	Index_steps_count_desc_2: 'Выберите тип <br> груза и способ <br> оплаты',
	Index_steps_count_desc_3: 'Получите лучший <br> экипаж и управляйте <br> заказом',
	Index_steps_count_item_1_title: 'Работайте в прямом эфире',
	Index_steps_count_item_1_desc: 'Сервис работает с экипажами напрямую, вы получаете цены без скрытых накруток и переплат',
	Index_steps_count_item_1_hint: 'Готовы попробовать? Давайте отправим вам ссылку на приложение:',
	Index_steps_count_item_2_title: 'Перевозите свой груз в режиме онлайн',
	Index_steps_count_item_2_desc: 'Мы лично проверяем каждого водителя, оцениваем качество автомобиля и профессиональные навыки работы на заказе. ',
	Index_steps_count_item_3_title: 'Расскажите MOVER о вашем грузе',
	Index_steps_count_item_3_desc: 'Прикрепите фото нестандартных предметов, для удобства работы экипажа с ними. Оплачивайте перевозку удобным для себя способом.',
	Index_steps_count_item_3_form_label: 'Поделиться ссылкой',
	Index_steps_count_item_3_list_1: 'Картой банка',
	Index_steps_count_item_3_list_2: 'Банковским переводом',
	Index_steps_count_item_3_list_3: 'Наличными',
	Index_steps_count_item_3_list_4: 'Промокод',

	Index_example_title: 'Как использовать MOVER?',
	Index_example_1_title: 'Срочная доставка груза клиентам',
	Index_example_1_desc: 'Даже если вы малый предприниматель с маленьким оборотом и неконтролируемым объемом доставок — MOVER лучший вариант для вас. День в день привезем по требованию груз вам или вашим клиентам в любую точку города и области.',
	Index_example_1_list_1: 'Система уведомлений',
	Index_example_1_list_2: 'Страховка груза',
	Index_example_1_list_3: 'Экспедирование груза',
	Index_example_1_list_4: 'Возврат денег и документов',
	Index_example_1_list_5: 'Детальная статистика',
	Index_example_1_list_6: 'Построение сложных маршрутов',
	Index_example_2_title: 'Аренда машины с грузчиком',
	Index_example_2_desc: 'Подбросим рюкзак, палатку и байдарку до вокзала, если среди ночи вас потянуло в Карелию. Вернем студента к родителям из общежития вместе добром, накопленным во взрослой жизни. Доставим фамильную кровать на новоселье молодоженам.',
	Index_example_2_list_1: 'Онлайн трекинг авто',
	Index_example_2_list_2: 'Погрузка и разгрузка',
	Index_example_2_list_3: 'iOS & Android приложения',
	Index_example_2_list_4: 'Все способы оплаты',
	Index_example_3_title: 'Небольшой переезд',
	Index_example_3_desc: 'Не нужно тратить целый день на переезд. Дайте нам точный маршрут. Выберите доверенное лицо. Следите за тем, как и куда движется ваш груз через приложение.',
	Index_example_3_list_1: 'Погрузка и разгрузка',
	Index_example_3_list_2: 'Упаковка груза',
	Index_example_3_list_3: 'Сборка и разборка груза',
	Index_example_3_list_4: 'Проверенные водители',
	Index_example_4_title: 'Доставка из IKEA, OBI, LEROY MERLIN',
	Index_example_4_desc: 'Кухня и техника в новую квартиру, канадские клены на дачу, новое оборудование в кафе или офис — для всего, что не поместится в ваш автомобиль, у нас найдется решение. Доставка день в день — возможность в которой себе нельзя отказать.',
	Index_example_4_list_1: 'Покупка по артикулу',
	Index_example_4_list_2: 'Погрузка и разгрузка',
	Index_example_4_list_3: 'Услуги экспедирования',
	Index_example_4_list_4: 'Доставка день в день',
	Index_example_QB_title: 'Хранение личных вещей и грузов от нашего партнера QB.',
	Index_example_QB_desc: 'Мы принимаем на хранение вещи любого размера — от сезонной одежды до мебели и техники.\nСтоимость — от 449₽/месяц',
	Index_example_QB_list_1: 'Безопасный склад',
	Index_example_QB_list_2: 'Упаковка и погрузка',
	Index_example_QB_list_3: 'Онлайн-витрина',
	Index_example_QB_list_4: 'Возврат в один клик',

	Index_callback_1_title: 'Делаем ваши перевозки выгоднее',
	Index_callback_1_desc: 'Делитесь MOVER с друзьями, получайте скидки или бесплатные поездки.',
	Index_callback_1_hint: 'Поделитесь и получите 300 рублей',

	PromoCode_title: 'Поделиться',
	PromoCode_desc: 'Вам понравился MOVER, но в ближайшее время вы не собираетесь ничего перевозить? У вас точно есть друг или знакомый, который недавно искал грузовой экипаж. Поделись MOVER с друзьями, получайте скидки или бесплатные перевозки на свои следующие поездки',
	PromoCode_form_label_name: 'Ваше имя',
	PromoCode_form_name_placeholder: 'Имя',
	PromoCode_form_label_phone: 'Ваш телефон',
	PromoCode_form_label_phone_2: 'Телефон друга',
	PromoCode_success_title: 'Приглашение отправлено',
	PromoCode_success_desc: 'На указанную вами почту отправлено письмо с регистрационными данными. Привяжите номер телефона и корпортавную карту для того чтобы начать перевозку уже через 5 мин!',
	PromoCode_error_title: 'Возникла ошибка',
	PromoCode_error_desc: 'Код ошибки:',

	Index_support_title: 'Центр поддержки',
	Index_support_subtitle: 'Всё под контролем',
	Index_support_desc: 'Каждый оператор Центра Поддержки MOVER - ваш верный соратник в решении логистических и технических задач. Пишите и наши супергерои саппорта не заставят себя долго ждать!',
	Index_support_more_btn: 'Перейти в Центр Поддержки',
	Index_support_list_1_1_title: 'Подберет выгодный тариф',
	Index_support_list_1_1_desc: 'Если вам нужна помощь с подбором тарифа или конкретной машины, наши специалисты круглосуточно доступны в Чате и помогут вам сэкономить',
	Index_support_list_more: 'Подробнее',
	Index_support_list_1_2_title: 'Поможет создать заказ',
	Index_support_list_1_2_desc: 'Пошагово проведем по заказу, ответим на все вопросы по интерфейсу, либо вы можете самостоятельно найти ответы в нашей <a href="#" class="link">Базе Знаний</a>',
	Index_support_list_1_3_title: 'Подберет исполнителей',
	Index_support_list_1_3_desc: 'Специалисты Центра Поддержки следят за каждой заявкой и если возникает необходимость, вмешиваются в процесс подбора, как ваш логист в штате',
	Index_support_list_1_4_title: 'Проследит за сроками',
	Index_support_list_1_4_desc: 'MOVER работает как ваш личный транспортный отдел и контролирует все сроки исполнения работ по заказу',
	Index_support_list_2_1_title: 'Проследят за подачей',
	Index_support_list_2_1_desc: 'Проконтролируем начало исполнения заказа водителем и заменим экипаж в случае форс-мажора',
	Index_support_list_2_2_title: 'Сориентируют экипаж',
	Index_support_list_2_2_desc: 'Наши специалисты помогут водителю сэкономить время с поиском парковки, зоны загрузки или пунктом пропусков',
	Index_support_list_2_3_title: 'Помогут изменить заказ',
	Index_support_list_2_3_desc: 'Вы всегда можете изменить адреса, тип груза или способ оплаты самостоятельно, либо через нашего специалиста в <a href="#" class="link">Чате</a>',
	Index_support_list_2_4_title: 'Ответят по горячей линии',
	Index_support_list_2_4_desc: 'Круглосуточно на активных заказах работает <a href="#" class="link">Горячая линия</a> с сотрудниками Центра Поддержки контролирующими исполнении заказа',
	Index_support_list_3_1_title: 'Предоставят все цифры',
	Index_support_list_3_1_desc: 'Если вам не хватает информации в личном кабинете, вы всегда можете запросить данные с сервера для проверки работы исполнителя',
	Index_support_list_3_2_title: 'Решат вопросы с экипажем',
	Index_support_list_3_2_desc: 'Если после перевозки у вас появились вопросы к исполнителю или сервису - в Моих заказах вы можете оставить претензию и мы решим ваш вопрос.',
	Index_support_list_3_3_title: 'Предоставят все документы',
	Index_support_list_3_3_desc: 'Чеки, квитанции, закрывающие документы и любые документы по перевозке вы можете запросить в Чате',
	Index_support_list_3_4_title: 'Помогут с оплатой',
	Index_support_list_3_4_desc: 'Дадим детализацию по суммам, поможем настроить удобный способ оплаты и сообщим о статусе платежа',

	StepsRoad_title: 'Этапы перевозки',
	StepsRoad_list_tab_1: 'До перевозки',
	StepsRoad_list_tab_2: 'В дороге',
	StepsRoad_list_tab_3: 'После перевозки',
	StepsRoad_list_item_1_title_1: 'Как оформить заказ?',
	StepsRoad_list_item_1_title_2: 'Как узнать стоимость?',
	StepsRoad_list_item_1_title_3: 'Можно ли оплатить картой?',
	StepsRoad_list_item_2_title_1: 'Как связаться с водителем?',
	StepsRoad_list_item_2_title_2: 'Как узнать где водитель?',
	StepsRoad_list_item_2_title_3: 'Как производится оплата за перевозку?',
	StepsRoad_list_item_3_title_1: 'Как получить квитанцию за перевозку?',
	StepsRoad_list_item_3_title_2: 'Я забыл кое-что в машине, как вернуть?',
	StepsRoad_list_item_3_title_3: 'Как поменять способ оплаты?',
	StepsRoad_more_btn: 'У меня остались вопросы',
	StepsRoad_more_btn_2: 'Перейти в Центр Поддержки',
	StepsRoad_list_item_1_desc_1: 'Для заказа достаточно выбрать тариф, указать нужное количество грузчиков, точки погрузки и разгрузки, выбрать способ оплаты и запустить поиск! Ближайший свободный экипаж примет вашу заявку, а вам останется только подтвердить его.',
	StepsRoad_list_item_1_desc_2: 'Стоимость перевозки складывается из параметров, которые вы укажите: кол-во грузчиков, время работы автомобиля и доп. параметры. Указав необходимые параметры при формировании вы увидите расчетную цену за перевозку.',
	StepsRoad_list_item_1_desc_3: 'После завершения заказа, вам будет представлена конечная сумма за услуги перевозки, оплатить можно прикрепленной банковской картой или с расчетного счета компании.',
	StepsRoad_list_item_2_desc_1: 'Когда будет утвержден водитель на вашу перевозку, в заказе будет доступна функция «позвонить водителю»',
	StepsRoad_list_item_2_desc_2: 'Когда экипаж примет ваш заказ и выедет к вам, на устройстве будет доступно его местоположение, вы сможете в режиме онлайн наблюдать за его передвижением.',
	StepsRoad_list_item_2_desc_3: 'По завершении заказа, вы можете оплатить его, как наличными водителю, так и с помощью прикрепленной к сервису банковской картой.',
	StepsRoad_list_item_3_desc_1: 'В настройках профиля, в сервисе MOVER, можно указать адрес электронной почты на которую будут отправляться квитанции за совершенные перевозки.',
	StepsRoad_list_item_3_desc_2: 'Вся информация о водителе и поездках, находятся в системе MOVER. Центр Поддержки доступен 24/7 и находится на связи со всеми экипажами, поэтому вернуть ценные вещи не проблема.',
	StepsRoad_list_item_3_desc_3: 'Если во время заказа необходимо изменить способ оплаты, напишите об этом в Центр Помощи MOVER.',

	Index_devices_title: 'Работаем везде',
	Index_devices_subtitle: 'Управляете своими перевозками в одном месте с любой удобной для себя платформы.',
	Index_devices_type_1: '<i class="icon"></i>MOVER <b>Web-версия</b>',
	Index_devices_desc_1: 'Удобное web приложения для заказа грузового такси в городе.',
	Index_devices_more: 'Подробнее',
	Index_devices_type_2: '<i class="icon"></i>MOVER для <b>Партнеров</b>',
	Index_devices_desc_2: 'Самое передовое приложение по заказу грузового такси на рынке грузоперевозок.',
	Index_devices_type_3: '<i class="icon"></i>MOVER для <b>iPhone</b>',
	Index_devices_desc_3: 'Самое передовое приложение по заказу грузового такси на рынке грузоперевозок.',
	Index_devices_type_4: '<i class="icon"></i>MOVER для <b>Android</b>',
	Index_devices_desc_4: 'Интуитивно понятное приложение для заказа грузового такси через смартфон.',

	Index_business_title: 'Революция грузоперевозок для бизнеса',
	Index_business_desc: 'Ваш личный транспортный отдел. Платформа где вы без лишних вопросов получите профессиональную команду перевозчиков всего в пару кликов.',
	Index_business_more_btn: 'Подключить бизнес',
	Index_business_item_1_title: 'Интуитивный документооборот',
	Index_business_item_2_title: 'Проверенные водители',
	Index_business_item_3_title: 'Любой вид оплаты',
	Index_business_item_4_title: 'Сервис <span class="pro">MOVER PRO</span>',
	Index_business_item_1_desc: 'Работаем с вашими документами по вашим стандартам',
	Index_business_item_2_desc: 'Лучшие грузовые экипажи с высоким рейтингом',
	Index_business_item_3_desc: 'Оплачивайте со счета или по карте в 1 клик',
	Index_business_item_4_desc: 'Мощный логистический сервис для компаний',

	Index_start_1_title: 'Поможем организовать срочную доставку любого груза',
	Index_start_1_desc: `Перевозите вещи, доставляйте заказы интернет-магазина\nили управляйте собственным автопарком`,
	Index_start_1_subtitle: 'Закажи в MOVER по лучшей цене',
	Index_start_1_app: 'Скачай мобильное приложение',
	Index_start_take_order: 'ПРОДОЛЖИТЬ',
	Index_start_1_input_1_label: 'Адрес загрузки',
	Index_start_1_input_2_label: 'Адрес разгрузки',
	Index_start_1_Cargo1: 'Доставка из IKEA',
	Index_start_1_Cargo2: 'Вывоз мусора',
	Index_start_1_Cargo3: 'Небольшой переезд',
	Index_start_1_Cargo4: 'Доставка со склад',
	Index_start_1_Cargo5: 'Доставка вашим клиентам',
	Index_start_1_Cargo6: 'Грузовое такси',
	Index_start_1_Cargo7: 'Аренда экипажа',
	Index_start_1_Cargo8: 'Доставка c Avito',

	Index_start_2_sale_title: 'Скидка до 500Р',
	Index_start_2_sale_desc: 'На первую поездку',
	Index_start_2_help: 'Как это работает?',
	Index_start_2_title: 'Лучший способ перевезти',
	Index_start_2_desc: 'Заберем груз за полчаса и доставим куда укажите',
	Index_start_2_route_head_title: 'Укажите маршрут',
	Index_start_2_route_head_desc: 'Более 1200 водителей в сервисе • Среднее время подачи 40 мин',
	Index_start_2_partners_title: 'Наши клиенты',

    WidgetLoginTitle: 'Войдите в сервис',
    WidgetResetPasswordTitle: 'Сброс пароля',
    WidgetCodeTitle: 'Введите код',
    WidgetPasswordPlaceholder: 'Пароль',

    WidgetLoginBtn: 'Войти',
    WidgetLoginBtnEx: 'Выйти',
    WidgetResetPasswordBtn: 'Получить код',
    WidgetConfirmBtn: 'Подтвердить',
    WidgetViewResetPassword: 'Забыли пароль?',
    WidgetViewLogin: 'Вход',
    WidgetViewRegistration: 'Регистрация',
    WidgetViewChangePhone: 'Изменить номер',
	sendAgain: 'Выслать ещё раз',
	sendOn: 'Код отправлен на',
	widgBalance: 'Баланс',

	CalcHeader_btn_1: 'О заказе',
	CalcHeader_btn_2: 'Отменить',

	CalcOrder_accept_title: 'На ваш номер будет отправлена ссылка на приложение,<br />в котором вы сможете отследить ваш заказ',
	CalcOrder_accept_crew_head_title: 'Заказ: #',
	CalcOrder_accept_crew_head_btn: 'Отклонить',
	CalcOrder_accept_crew_user_char: 'мин до вас',
	CalcOrder_accept_crew_cnt_car_desc: 'Убедитесь что все ваши вещи влезут в автомобиль.',
	CalcOrder_accept_crew_char_type_1: 'от 1 до 1,5 т',
	CalcOrder_accept_crew_char_type_2: 'Цельномет.',
	CalcOrder_accept_crew_char_list_1_type: 'Высота',
	CalcOrder_accept_crew_char_list_2_type: 'Длина',
	CalcOrder_accept_crew_char_list_3_type: 'Ширина',
	CalcOrder_accept_crew_char_list_4_type: 'Объём',
	CalcOrder_accept_crew_char_help_type: 'Платный въезд в ТТК',
	CalcOrder_accept_crew_foot_char_list_1_title: 'Подача',
	CalcOrder_accept_crew_foot_char_list_2_title: 'Время работы',
	CalcOrder_accept_crew_foot_btn: 'Принять',

	CalcOrder_search_count: 'Идет поиск водителей',
	CalcOrder_search_desc_urgent: 'Мы отправили ваш заказ ближайшим экипажам, скоро подберем исполнителя и пришлем SMS со всеми данными. Обычно подбор занимает не более 10 мин.',
	CalcOrder_search_desc_pending: 'Мы отправили ваш заказ всем экипажам, скоро подберем исполнителя и пришлем SMS со всеми данными. Обычно подбор занимает не более 10 мин.',
	CalcOrder_search_desc_with_storage: 'Мы отправили ваш заказ нашему партнеру по хранению QB. В течение 2-х часов с вами свяжется менеджер и уточнит все детали. После подтверждения, экипаж MOVER заберет груз в назначенное время.',
	CalcOrder_search_form_desc_title: 'Что будет дальше?',
	CalcOrder_search_form_desc: 'На ваш номер будет отправлена ссылка на приложение, в котором вы сможете отследить ваш заказ',
	CalcOrder_search_form_have_any_question: 'Остались вопросы?',
	CalcOrder_search_form_private_area: 'Личный кабинет',
	CalcOrder_finish_count: 'Ваш водитель',
	CalcOrder_finish_cnt_number: 'Номер вашего заказа',
	CalcOrder_finish_cnt_desc: 'Вы можете отследить заказ в приложении, ссылка на которое пришла вам в СМС. Со всеми вопросами можно обратиться в Центр Поддержки.',
	CalcOrder_finish_cnt_btn: 'Перейти в Центр Поддержки',
	CalcOrder_title_draft_received: 'Заявка на перевозку принята!',
	CalcOrder_search_draft_number: 'Номер заявки',
	CalcOrder_title: 'Ваш заказ',
	CalcOrder_title_1: 'обработан',
	CalcOrder_title_2: 'обрабатывается',
	CalcOrder_title_3: 'начался',
	CalcSidebar_step1_eta_value: 'ч.',
	CalcSidebar_step3_full_count: 'Р/ч',
	CalcSidebar_step4_empty_desc: 'По умолчанию выбран тариф <span class="name">Стандарт</span>',
	CalcSidebar_step4_full_count: 'Р',
	CalcSidebar_step5_empty_title: 'Оплата',
	CalcSidebar_step5_full_prefix: 'ОПЛАТА',
	CalcSidebar_step5_full_type: 'Наличными',
	CalcSidebar_step6_full_title: 'Оплата',
	CalcSidebar_step6_empty_count: 'ч',
	CalcStep2_cargo_tag_1: 'Мебель',
	CalcStep2_cargo_tag_2: 'Стройматериалы',
	CalcStep2_cargo_tag_3: 'Коробки',
	CalcStep2_cargo_tag_4: 'Оборудование',
	CalcStep2_cargo_tag_5: 'Бытовая техника',
	CalcStep2_cargo_hint_title: 'Важно!',
	CalcStep2_cargo_hint_desc: 'Расскажите о том, какой груз вы перевозите. Это поможет сервису более точно подобрать перевозчика и избежать задержек при погрузке.',

	CalcStep3_count: 'ч',
	CalcStep3_time: 'мин',

	CalcStep4_option_list: 'до',
	CalcStep4_option_list_size: 'м',
	CalcStep4_size_1_title: 'Ширина',
	CalcStep4_size_2_title: 'Высота',
	CalcStep4_size_3_title: 'Объём',
	CalcStep4_size_4_title: 'Тоннаж',
	CalcStep4_count: 'ч',
	CalcStep4_title: 'Выберите тариф',

	CalcStep5_radio_choice_1_title: 'Наличными',
	CalcStep5_radio_choice_2_title: 'Картой',
	CalcStep5_radio_choice_3_title: 'Со счета',
	CalcStep5_payment_label: 'Промокод',
	CalcStep5_title: 'Оплата',
	CalcStep5_payment_label_2: 'Способ оплаты',
	CalcStep5_payment_label_3: 'Данные плательщика',
	CalcStep5_payment_label_4: 'Активная карта',
	CalcStep5_payment_label_5: 'Все карты',
	CalcStep5_payment_placeholder_1: 'Имя Фамилия',
    CalcStep5_payment_code_btn: 'Изменить номер тел.',
	CalcStep5_payment_code_btn_2: 'Подтвердить',
	CalcStep5_payment_code_btn_3: 'Отправить',
    CalcStep5_payment_code_btn_4: 'Отправить повторно код',
    CalcStep5_payment_code_btn_5: ' код',
	CalcStep5_payment_placeholder_2: 'Введите код из СМС',

	CalcStep6_title: 'Детали заказа',
	CalcStep6_info_1_title: 'Первый час по тарифу',
	CalcStep6_info_2_title: 'доп час по тарифу',
	CalcStep6_total_title: 'Итого',
	CalcStep6_total_desc: 'С учетом скидки',

	CalcAddressPoint_lift_1: 'Лифт отсутствует',
	CalcAddressPoint_lift_2: 'Пассажирский лифт',
	CalcAddressPoint_lift_3: 'Грузовой лифт',
	CalcAddressPoint_lift_4: 'Оба типа лифтов',
	CalcAddressPoint_tag_1: 'Этаж',
	CalcAddressPoint_tag_2: 'Контактное лицо',

	CalcOrder_size: 'м',
	CalcOrder_time: 'ч',

	CalcOrderHeader_drop: 'Свернуть',
	CalcOrderHeader_title: 'Ваш заказ',
	CalcOrderHeader_btn_1: 'О заказе',
	CalcOrderHeader_btn_2: 'Отменить',

	CalcSidebar_step1_type_point: 'Точка разгрузки',
	CalcSidebar_step1_push_placeholder: 'Куда везем',
	CalcSidebar_step1_address_title_1: 'адрес',
	CalcSidebar_step1_address_title_2: 'адреса',
	CalcSidebar_step1_address_title_3: 'адресов',

	driver1: 'водитель',
	driver2: 'водителя',
	driver3: 'водителей',

	CalcSidebar_step3_type_1: 'грузчик',
	CalcSidebar_step3_type_2: 'грузчика',

	CalcSidebar_step6_time_1: 'Подача',
	CalcSidebar_step6_time_2: 'мин',
	CalcSidebar_step6_time_3: 'экипажей',
	CalcSidebar_step6_time_4: 'онлайн',

	UploaderPhoto_preview_text: 'Выберите фото',

	CalcStep3_check_time_1: 'Подача',
	CalcStep3_check_time_2: 'минут',
	CalcStep3_check_time_3: 'экипажей',
	CalcStep3_check_time_4: 'онлайн',

	CalcStep4_check_time_1: 'Подача',
	CalcStep4_check_time_2: 'мин',
	CalcStep4_check_time_3: 'экипажей',
	CalcStep4_check_time_4: 'онлайн',
	CalcStep4_check_time_5: 'Более часа',

	CalcSidebar_step1_hint_title: 'Возникла ошибка',
	CalcSidebar_step1_hint_desc: 'Чтобы сделать заказ, вам нужно указать адреса для точек погрузки и разгрузки.',

	CalcSidebar_step5_hint_title: 'Возникла ошибка',
	CalcSidebar_step5_hint_desc: 'Вам нужно авторизоваться, чтобы продолжить создание заказа.',

	Index_press_title: 'О нас пишут',

    Index_reviews_title: 'Отзывы о нас',
    ROLE_ANON: 'Не авторизован',
    ROLE_ADMIN: 'Администратор',
    ROLE_CUSTOMER: 'Клиент',
    ROLE_COMPANY: 'Компания',
    ROLE_COMPANY_MANAGER: 'Менеджер компании',
    ROLE_DRIVER: 'Водитель',
    ROLE_PRIVATE_DRIVER: 'Частник',
    ROLE_MANAGER: 'Менеджер',
    ROLE_SUPPORT: 'Поддержка',
    ROLE_DELIVERY_API_CLIENT: 'Клиент API доставки',
    ROLE_DELIVERY_MANAGER: 'Менеджер доставки',
    ROLE_PARTNER_API_CLIENT: 'Клиент API партнёра',
    ROLE_PARTNER_MANAGER: 'Менеджер партнёра',

    order_phone: 'Телефон',
    order_calendar: 'Дата',
    order_status: 'Статусы',
    order_number: 'Номер заказа',

	//footerAdv
	BusinessHead: 'Устраняем дополнительные транспортные расходы',
	BusinessBtn: 'Подключить',
	BusinessText: 'MOVER может работать как ваш личный транспортный или логистический отдел. Наша команда изучит ваши потребности и подберет оптимальный набор сервисов.',
	Business24Connect: 'Свяжемся с вами в течение 24 часов',
	BusinessCntTitle1: 'Проанализируем характер перевозок',
	BusinessCntTitle2: 'Подберем выгодный набор сервисов',
	BusinessCntTitle3: 'Запустим в работу',
	BusinessCntTitle4: 'Поддержка 24/7',
	BusinessCntDesc1: 'Соберем всю нужную информацию и составим план интеграции.',
	BusinessCntDesc2: 'Либо совместно разработаем индивидуальный тариф.',
	BusinessCntDesc3: 'Подключим и обучим ваших сотрудников и наших водителей.',
	BusinessCntDesc4: 'Статистика и опыт помогут оптимизировать и улучшить интеграцию.',

	//FOOTER
	FooterClients: 'Клиентам',
	FooterClientsApp: 'Приложения',
	FooterClientsTariff: 'Тарифы',
	FooterClientsFleet: 'Автопарк',
	FooterClientsPartners: 'Партнеры',
	FooterBusiness: 'Бизнесу',
	FooterDrivers: 'Водителям',
	FooterAll: 'Всем',
	FooterBusMover: 'MOVER для Бизнеса',
	FooterBusIntegration: 'Интеграция',
	FooterBusShop: 'Интернет-магазинам',
	FooterBusMalls: 'Торговым центрам',
	FooterDriversConnect: 'Как подключиться?',
	FooterDriversApp: 'Приложение',
	FooterPartnership: 'Партнерская программа',
	FooterDriversBlog: 'Блог для водителей',
	FooterDriversFaq: 'F.A.Q.',
	FooterAllAbout: 'О MOVER',
	FooterAllSupport: 'Центр поддержки',
	FooterBtmSecure: 'Безопасность платежей',
	FooterBtmTerms: 'Пользовательское соглашение',
	FooterBtmConf: 'Политика конфиденциальности',
	FooterBtmDict: 'Термины и Определения',

	FooterLangReg: 'ЯЗЫК И РЕГИОН',
	FooterBlog: 'Блог',
	FooterBigContact: 'Контакты',
	FooterAddress: 'Правды 24с3, Москва, Россия',

	//GOODS TOP ADV LIST
	goods1: 'диван',
	goods2: 'мотоцикл',
	goods3: 'стеклопакет',
	goods4: 'снегоход',
	goods5: 'стол',
	goods6: 'стройматериалы',
	goods7: 'холодильник',
	goods8: 'кондиционер',
	goods9: 'велосипед',
	goods10: 'квартиру',
	goods11: 'плиту',
	goods12: 'сантехнику',
	goods13: 'камин',
	goods14: 'шины',
	goods15: 'барель нефти',
	goods16: 'пианино',
	goods17: 'тренажёр',
	goods18: 'коня',
	goods19: 'неперевозимое',
	goods20: 'шкаф',
	goods21: 'ёлку',
	goods22: 'носорога',
	goods23: 'стул',
	goods24: 'кухню',
	goods25: 'мебель',

	DiscountText: 'СКИДКА НА ПЕРВУЮ ПЕРЕВОЗКУ',

	HomeSecondSlide1: 'Идеально для доставки небольших грузов до 800 кг. и длинной от 2 м. Авто в тарифе похожие на Berlingo, Vito, Partner.',
	HomeSecondSlide2: 'Стандартная газель, Crafter, Transit и похожие на них авто, для перевозки грузов до 3 м. Мин. тариф 990 ₽ за 1 час.',
	HomeSecondSlide3: 'Увеличенный экипаж для грузов длиной от 4 м. и больше. Мин. тариф 1190 ₽ за 1 час перевозки.',
	HomeSecondSlide4: 'Большой грузовик от 4 м. с профессиональным водителем для объемных грузов и больших переездов. Мин. тариф 3250 ₽ за 4 часа перевозки.',
	HomeSecondSlideUPTO: 'до',
	HomeSecondSlide1KG: '800 кг',
	HomeSecondSlideKG: 'кг',
	HomeSecondSlide2KG: '1.5 тонн',
	HomeSecondSlide3KG: '2.5 тонн',
	HomeSecondSlide4KG: '3 тонны',

	TariffSlide1: 'Перевозки',
	TariffSlide2: 'Погрузочно-разгрузочные работы',

	TariffGoodType1: '🛋 Мебель',
	TariffGoodType2: '🖥 Домашняя техника',
	TariffGoodType3: '🎻 Музыкальные инструменты',
	TariffGoodType4: '🖨 Офисняя техника',
	TariffGoodType5: '🏭 Строительный мусор',
	TariffGoodType6: '🏭 Промышленное оборудование',

	TariffCarSize1: 'Большой',
	TariffCarSize2: 'Оптимальный',
	TariffCarSize3: 'Самый дешевый',
	TariffCarSize4: 'Очень большой',

	TariffCarType1: 'Фургон',
	TariffCarType2: 'Тент',
	TariffCarType3: 'Борт',
	TariffCarType4: 'Фермер-Борт',
	TariffCarType5: 'Цельнометаллический',
	TariffCarType6: 'Фермер',
	TariffCarType7: 'Изотерм',

	TariffGetTime1: 'Всегда',
	TariffGetTime2: 'Единоразово',
	TariffGetTime3: 'В одну сторону',
	TariffGetTime4: 'Бесплатно',

	TariffCountTime: 'Рассчетное время',
	TariffCountMin: 'Минимальное время работы',
	TariffCountH: 'ч',
	TariffCountH2: 'час',
	TariffCountKM: 'км.',
	TariffCountM: 'м',
	TariffCountM2: 'мин',
	TariffCountT: 'т',
	TariffCountT2: 'тонны',
	TariffCalcCb: 'кубов',

	TariffTime: 'ночью',

	TariffCalc: 'Рассчитать',
	TariffCariages: 'экипажей',
	TariffCalcTime: 'Расчетное расстояние составляет',
	TariffMainTime: 'Время выполнения заказа с учетом загрузки и разгрузки составляет',
	TariffAddressInp: 'Введите адреса вашей перевозки, что бы получить расчетное время выполнения заказа.',
	BusinessNoNumber: 'Неверный номер телефона',
	BusinessDenied: 'Доступ запрещён',
	BusinessNoLogin: 'Неверный логин или пароль',
	BusinessNoAcc: 'Такого аккаунта не существует',
	BusinessReged: 'Этот номер уже зарегистрирован',
	BusinessSysErr: 'Возникла системная ошибка',
	BusinessPutNumber: 'Укажите Ваш номер телефона',

	TariffsSlide2_tb1: 'Грузчик',
	TariffsSlide2_tb2: 'Сборщик',
	TariffsSlide2_tb3: 'Грузчик + Этаж',
	TariffsSlide2_tb1_1: 'Обычный грузчик',
	TariffsSlide2_tb2_1: 'Сборка и разборка груза',
	TariffsSlide2_tb3_1: 'Спуск и подъём по этажам',
	TariffsSlide2_desc1: 'На 1 грузчика',
	TariffsSlide2_desc2: 'Фиксировано',
	TariffsSlide2_list1: 'Погрузка, переноска вещей, составление груза в автомобиле',
	TariffsSlide2_list2: 'Погрузка, переноска вещей, составление груза в автомобиле, сборка и разборка вещей',
	TariffsSlide2_list3: 'Погрузка, переноска вещей, составление груза в автомобиле, сборка и разборка вещей, спуск и подъем груза по этажам и лестницам',

	MainFrom: 'от',
	MainTo: 'до',
	MainFromIn: "Точка погрузки",
	MainFromOut: "Точка разгрузки",

	GetFirstBonus: 'Введите секретный код 🤡',
	checkPrice: 'Узнать стоимость',
	calcPrice: 'Рассчитать стоимость',
	CarDescription: 'Описание автомобиля',
	entranceFee: 'Платный въезд в центр',
	entranceFree: 'Бесплатный въезд в центр',

	getCarsInfo1: 'LADA Largus — автомобиль малого класса, отлично подходящий для перевозок чего-то не габаритного. Прекрасно подходит для доставки мелких товаров клиентам.',
	getCarsInfo2: 'Peugeot Partner – минивен на базе автомобиля гольф-класса. Partner – это прежде всего грузовая машина малого класса, задняя дверь может быть как подъемной, так и выполненной в виде распашных ворот, что позволяет эффективно и быстро работать на погрузке.',
	getCarsInfo3: 'Самая распространенная российская модель грузового автомобиля. Отличается от базовой модели «Газель» повышенной надёжностью кузова. Отличный выбор при перевозке грузов в области и по бездорожью.',
	getCarsInfo4: 'Лёгкий коммерческий грузовой автомобиль, одна из самых популярных моделей. Достаточная вместительность, экономный расход топлива, легко перемещается в городском потоке.',
	getCarsInfo5: 'Фиат Дукато – итальянский малотоннажный фургон, предназначенный для перевозки небольших партий товаров или малогабаритных грузов до 1,5 т. Высокий клиренс и очень хорошая маневренность позволяют этому авто с честью преодолевать любые пробки, въезжать на пандусы и парковаться вплотную к бордюрам, что позволяет избежать большинства проблем с парковкой.',
	getCarsInfo6: 'Peugeot Boxer – малотоннажный фургон с цельнометаллическим кузовом, по праву считающийся одним из лидеров в сфере малогабаритных грузоперевозок. Завоевал широкую популярность благодаря презентабельному внешнему облику в сочетании с практичностью, малым расходом топлива, большой вместимостью.',
	getCarsInfo7: 'Самая распространенная российская модель грузового автомобиля. Отличается от базовой модели «Газель» повышенной надёжностью кузова. Отличный выбор при перевозке грузов в области и по бездорожью.',
	getCarsInfo8: 'GAZelle Next подойдет для любых ваших задач – от переездов до доставки сырья и товаров. С GAZelle Next перевозки пройдут на высшем уровне, ведь этот автомобиль успел в высшей степени достойно зарекомендовать себя не только в РФ, но и за рубежом.',
	getCarsInfo9: 'По соотношению цены и качества является одним из лучших в своем классе, отличается комфортабельностью, экономичностью, вместимостью и маневренностью, что делает его одним из самых подходящих авто для перевозок.',
	getCarsInfo10: 'Планируете перебраться на новую квартиру? Нужно организовать переезд офиса средних размеров? Срочно требуется доставить стройматериалы на объект? Foton прекрасно подойдет для этого. Грузоподъемность Foton – 3 тонны, длина – 4.5 м, что подходит для решения многих транспортных задач.',
	getCarsInfo11: 'Офисный, квартирный или дачный переезд, доставка стройматериалов или оптовые поставки непродовольственных товаров – со всеми этими задачами «JMC» справится на отлично.',

	//FOR BUSINESS
	BusIntro1: 'Лучшие перевозчики',
	BusIntro2: 'для бизнеса',
	BusIntro3: 'Тысячи водителей доступны вам в прямом эфире с постоплатой и кэшбеком',
	BusTit1: 'Несем полную ответственность',
	BusTit2: 'Проверенные водители',
	BusTit3: 'Платите потом',
	BusTitText1: 'Вы заключаете договор с нами, мы несем ответственность за каждого исполнителя',
	BusTitText2: '100% машин прошли проверку и готовы для работы по стандартам сервиса',
	BusTitText3: 'У нас можно платить наличными, картой или на расчетный счет',
	BusConnect: 'Подключиться',
	BusSec1: 'Поможем сэкономить на транспорте',
	BusSec2: 'Мы автоматизировали процесс заказа перевозки, оставив вам максимальный контроль и прозрачные процессы',
	Bus2_1: 'Готовые',
	Bus2_2: 'решения',
	Bus2_3: 'для вашего бизнеса',
	Bus2Intro: 'Используйте MOVER как вам удобно, подключая только нужные сервисы',
	Bus3_1: 'Экспресс - доставка',
	Bus3_2: 'Релокация',
	Bus3_3: 'Доставка по запросу',
	Bus3_4: 'Телепортация',
	Bus3_1Intro: 'Собирайте грузы с точек по всему городу и отправляйте на одном автомобиле.',
	Bus3_2Intro: 'Вашим клиентам всегда доступен полный ассортимент в режиме онлайн. С легкостью перемещайте свой товар со склада в розничные точки в течение 30 минут.',
	Bus3_3Intro: 'Подберите ближайший к вам экипаж, который заберет груз и  выполнит экспресс-доставку или доставку ко времени по запросу покупателя.',
	Bus3_4Intro: 'Автоматизировaный сервис экспресс-доставки до клиента за 3 часа, интегрированный в ваш интернет-магазин или розничную точку.',
	BusCarType1: 'Грузовое такси',
	BusCarType2: 'Доставка по запросу',
	BusPrivate: 'Мне нужно индивидуальное решение',
	BusinessControlTab1: 'Заказы',
	BusinessControlTab2: 'Сотрудники',
	BusinessControlTab3: 'Финансы',
	BusinessControlTab4: 'Документы',

	BusinessMain1: 'Управляйте доставками заказов или транспортом в одном окне',
	BusinessMain1Intro: 'Ваши сотрудники всегда видят всю актуальную информацию по заказам с доставкой, могут изменить маршрут и  управлять экипажами',
	BusinessMain1_1: 'Работайте в прямом эфире',
	BusinessMain1_2: 'Изменяйте в любой момент',
	BusinessMain1_3: 'Убираем человеческий фактор',
	BusinessMain1_1Intro: 'Соединяем все потоки информации от клиента, водителя и сотрудников',
	BusinessMain1_2Intro: 'Создать, изменить, отменить или повторить заказ можно всегда',
	BusinessMain1_3Intro: 'Данные в систему попадают автоматически и на 100% достоверны',

	BusinessMain2: 'Все ваши сотрудники от водителей до менеджеров всегда под рукой',
	BusinessMain2Intro: 'Пригласите всех нужных людей, раздайте роли и добавьте автопарк в сервис. Очень просто и быстро. Абсолютно бесплатно.',
	BusinessMain2_1: 'Продажник, Логист и Водитель',
	BusinessMain2_2: 'Внутренний чат',
	BusinessMain2_3: 'История действий',
	BusinessMain2_1Intro: 'У каждого свое приложение и уровни доступа к данным',
	BusinessMain2_2Intro: 'Все ваши сотрудники могут общаться между собой и с водителями на рейсе',
	BusinessMain2_3Intro: 'Сохраняем информацию о каждом изменении в системе для анализа',

	BusinessMain3: 'Контролируйте все денежные операции и получайте  точную статистику',
	BusinessMain3Intro: 'Удобные инструменты для работы со всеми финансовыми потоками в компании: от оплаты доставок клиентами до выплаты зарплат.',
	BusinessMain3_1: 'Прозрачность для всех',
	BusinessMain3_2: 'Денежные поручения',
	BusinessMain3_3: 'Статистика для роста',
	BusinessMain3_1Intro: 'Никаких скрытых платежей и комиссий, а что увидит клиент решать вам',
	BusinessMain3_2Intro: 'Давайте водителям денежные поручения и контролируйте исполнение',
	BusinessMain3_3Intro: 'Сравнительные графики по приходу/расходу денег для принятия решений',

	BusinessMain4: 'Создавайте и отправляйте все необходимые документы прямо из заказа',
	BusinessMain4Intro: 'Любые доверенности, счета, акты, товарные и транспортные накладные в сертифицированных шаблонах создаются в 1 клик',
	BusinessMain4_1: 'Отправляйте всем и сразу',
	BusinessMain4_2: 'Электронный Документооборот',
	BusinessMain4_3: 'Прием платежей от клиентов',
	BusinessMain4_1Intro: 'Все нужные документы сразу доступны в личном кабинете водителя и вашем',
	BusinessMain4_2Intro: 'Работайте с нами и контрагентами по ЭДО для простоты документооборота',
	BusinessMain4_3Intro: 'Сравнительные графики по приходу/расходу денег для принятия решений',

	BussBlock3Title: 'Кому мы подходим ',
	BussBlock3Title2: 'прямо сейчас',
	BussBlock3Intro: 'Подключите свой бизнес за 24 часа и начните предоставлять уникальный сервис экспресс-доставки вашим клиентам',
	BussItem1: 'Строительные компании',
	BussItem2: 'Автозапчасти',
	BussItem3: 'Интернет магазины',
	BussItem4: 'Магазины',
	BussItem5: 'Транспортные компании',
	BussItem6: 'Event агенства',
	BussItem7: 'Мебельные магазины',
	BussItem8: 'Оборудование',
	BussItem9: 'Крафтовые производства',
	BussItem10: 'Товары для сада',
	BussItem11: 'Спорттовары',
	BussItem12: 'Ваш бизнес',
	BussComp1: 'компании',
	BussComp2: 'компаний',
	BussComp3: 'магазина',
	BussComp4: 'агентств',

	BussBlock4Title: 'MOVER и Транспортные компании',
	BussBlock4Intro: 'Между MOVER и обычной ТК такая же разница, как и между стационарным телефоном и смартфоном',
	BussBlock4Column1: 'Параметры',
	BussBlock4Column2: 'Транспортная компания',
	BussBlock4Row1: 'Количество автомобилей',
	BussBlock4Row2: 'Ответственность за груз',
	BussBlock4Row3: 'Минимальная стоимость перевозки',
	BussBlock4Row4: 'Оформление заказа',
	BussBlock4Row5: 'Время подачи автомобиля',
	BussBlock4Ph1: 'По телефону',
	BussBlock4Ph2: 'Все платформы',

	BussBlock5Title: 'Терминал для заказа грузового такси для ваших клиентов',
	BussBlock5Intro: 'Разгружает отдел логистики и дает полный контроль доставки в руки клиентов любого гипермаркета, ТЦ или рынка',
	BusinessMain5_1: 'Любые тарифы',
	BusinessMain5_1Intro: 'Терминал может работать как с вашими, так и с нашими тарифами',
	BusinessMain5_2: 'Заказ за 1 минуту',
	BusinessMain5_2Intro: 'Вся информация доступна в вашей системе, и вы всегда можете отменить доставку',

	BussBlock6Title: 'С нами работают только лучшие',
	BussBlock6Intro: 'Рейтинговая система и наш транспортный отдел не пропустят мошенников и хамов. Мы всегда слышим наших клиентов и ежедневно работаем над инструментами контроля качества',

	//DRIVER
	driverIntro: 'Сервис работает с экипажами напрямую, вы получаете цены без скрытых накруток и переплат',
	DriverInf: 'Информация',
	dataReg: 'Дата регистрации',
	birthday: 'Дата рождения',
	driverCrewTop: 'Топ экипажей',
	totalMoves: 'Всего заказов',
	driverCancelled: 'Отмен заказов',
	driverRating: 'Рейтинг',
	driverFeedback: 'Отзывы',
	driverFirstH: 'Первй час',
	driverAddH: 'Доп. час',

	stockSmallTitle1: 'Скидка 30% в подарок на День Рождения',
	stockSmallTitle2: 'Поделись MOVER с другом',
	stockSmallTitle3: 'Оставь отзыв о MOVER',
	stockSmallTitle4: '300 рублей на первую перевозку',
	stockSmallDesc1: 'Мы дарим вам скидку в 30%. Для активации скидки, свяжитесь со службой поддержки',
	stockSmallDesc2: 'Полный ресайз акции из приложения МУВЕР',
	stockSmallDesc3: 'Расскажите, как вам понравился MOVER и мы с гордостью подарим вам скидку на следующие перевозки в размере 5%',
	stockSmallDesc4: 'Зарегистрируйтесь в приложении MOVER и получите скидку в 300 рублей по промокоду «<b>START</b>»',
	stockAppTitle: 'Скачайте приложение',
	stockSlideTitle1: 'Бесплатный тест-драйв MOVER для <span class="bold">бизнеса</span>',
	stockSlideTitle2: 'ЗАКАЖИ ПЕРЕВОЗКУ НА САЙТЕ СО СКИДКОЙ <span class="bold">10%</span>',
	stockSlideDesc1: 'Подключите свой бизнес к MOVER и получите <b class="bold">1000</b> рублей на будущие перевозки.',
	stockSlideDesc2: 'Только в MOVER, в течении всей зимы, закажи перевозку с сайта <b class="bold">MOVER24.RU</b> и получи перевозку по самой низкой цене в городе.',
	stockSlideHint1: 'После подключения на ваш лицевой счёт будет зачислено <span class="bold">1000</span> рублей, вам останется их потратить на перевозки и принять правильное решение — начать работать с MOVER!',
	stockSlideBtn1: 'Подключить бизнес',
	stockSlideBtn2: 'Заказать',
	indexFirstLanding1: 'Любой груз до 3 тонн',
	indexFirstLanding2: '3040 активных экипажей',
	indexFirstLanding3: '1 или 2 грузчика',
	indexFirstLanding4: '100% ответственность',
	indexFirstLanding5: 'Оплата после перевозки',
	indexFirstLanding6: 'Ответим на все вопросы <span class="phone">+7 (495) 414 37 13</span>',

};

export default Lang;
