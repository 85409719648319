import React from 'react';
import CalculatorSideBarStep1 from './CalculatorSideBarStep1.jsx';
import CalculatorSideBarStep2 from './CalculatorSideBarStep2.jsx';
import CalculatorSideBarStep3 from './CalculatorSideBarStep3.jsx';
import CalculatorSideBarStep4 from './CalculatorSideBarStep4.jsx';
import CalculatorSideBarStep5 from './CalculatorSideBarStep5.jsx';
import CalculatorSideBarStep6 from './CalculatorSideBarStep6.jsx';

export default class CalculatorSideBar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { lang, step, data, setActive, info, searchStart } = this.props;

        return (
            <div className="сalculator-left">
                <CalculatorSideBarStep1 step={step} setActive={setActive} points={data.points} info={info} lang={lang}/>
                <CalculatorSideBarStep2 step={step} setActive={setActive} data={data.goods} lang={lang}/>
                <CalculatorSideBarStep3 step={step} setActive={setActive} data={data.stevedores} info={info} lang={lang}/>
				<CalculatorSideBarStep4 step={step} setActive={setActive} data={info} lang={lang}/>
                <CalculatorSideBarStep5 step={step} setActive={setActive} paymentType={data.paymentType} lang={lang}/>
                <CalculatorSideBarStep6 step={step} setActive={setActive} data={data} info={info} lang={lang} searchStart={searchStart}/>
            </div>
        );
    }
}