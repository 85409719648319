import getConfig        from 'next/config';
import isServer         from 'detect-node';

const {serverRuntimeConfig, publicRuntimeConfig} = getConfig();

export const IS_DEV = isServer ? serverRuntimeConfig.isDevelopment : publicRuntimeConfig.isDevelopment;
export const IS_STAGING = isServer ? serverRuntimeConfig.isStaging : publicRuntimeConfig.isStaging;

const SERVER_PROD = 'https://api.mover24.ru';
const SERVER_DEV = 'http://localhost:8680';
const SERVER_STAGING = 'https://test.mover24.ru';

const SERVER_PROD_WS = 'wss://ws.mover24.ru';
const SERVER_DEV_WS = 'ws://localhost:8680';
const SERVER_STAGING_WS = 'wss://test.mover24.ru/ws';



export const SERVER = IS_STAGING ? SERVER_STAGING : (IS_DEV ? SERVER_DEV : SERVER_PROD);
export const SERVER_WS = IS_STAGING ? SERVER_STAGING_WS : (IS_DEV ? SERVER_DEV_WS : SERVER_PROD_WS);

export const YANDEX_MAP_KEY = 'eec0fab7-6a25-4f63-8c02-dc5ee3cc145f';