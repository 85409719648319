import React                    from 'react';
import cx                       from 'classnames';
import { arrayUnique }          from '../../utils/helpers';
import Calculator               from '../../API/calculator';
import {trackTariff} from "../../utils/tracking";
import {DateUtils} from "../../utils/date";

const tariffsDescriptions = {
    mini2: {
        desc: 'Идеально для доставки небольших грузов до 800 кг. и длиной до 2 м.',
        type: 'Фургон, Цельнометаллический',
        capacity: '0.7',
        width: '1',
        height: '1',
        volume: '3'
    },
    standart2: {
        desc: 'Стандартная газель, Crafter, Transit и похожие для перевозки грузов до 3 м.',
        type: 'Фургон, Цельнометаллический, Тент, Фермер Фургон, Бортовая',
        capacity: '1',
        width: '1.5',
        height: '1.8',
        volume: '9'
    },
    extra2: {
        desc: 'Увеличенный экипаж для грузов длиной от 4 м. и больше',
        type: 'Фургон, Тент, Цельнометаллический, Фермер Тент',
        capacity: '1.5',
        width: '1.8',
        height: '2',
        volume: '14'
    },
    maxi2: {
        desc: 'Большой грузовик от 4 м. с профессиональным водителем',
        type: 'Фургон',
        capacity: '3',
        width: '2',
        height: '2',
        volume: '18'
    }
};

export default class CalculatorStep4 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tariffs: props.tariffs ? Calculator.preparationData(props.tariffs) : [],
            defaultTariff: props.data,
            error: props.errors && props.errors.step === 4 ? props.errors : null,
            selectedGroupLength: props.selectedGroupLength || [],
            selectedGroupLengthValue: props.selectedGroupLengthValue || []
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.errors && newProps.errors.step === 4) this.setState({error: newProps.errors});
        let tariffs = [], data = {};
        if(newProps.tariffs) tariffs = Calculator.preparationData(newProps.tariffs);

        if(newProps.data.value !== this.props.data.value) data = Calculator.setGroupLength(tariffs, newProps.data.value);

        this.setState(Object.assign({}, {
            tariffs,
            tariff: newProps.data,
            selectedGroupLength: newProps.selectedGroupLength,
            selectedGroupLengthValue: newProps.selectedGroupLengthValue
        }, data));

    }

    async setTariff(minPrice, value) {
        await this.props.deleteError();

        console.log('step ', 1, value);

        let tariff;

        this.props.tariffs.map((item) => {
            if(item.identifier === value) tariff = item;
        });

        let tariffs = this.props.tariffs ? Calculator.preparationData(this.props.tariffs) : [];

        await this.props.updateData({
            tariff: {
                value,
                minPrice,
                name: tariff.name,
                type: tariffsDescriptions[value].type,
                capacities: tariff.capacities.join(',')
            }
        }, 'tariff');

        let groupLength = Calculator.setGroupLength(tariffs, value);

        let newState = Object.assign({}, {
            defaultTariff: {
                value,
                minPrice: minPrice,
                name: name,
                type: tariffsDescriptions[value].type
            },
            selectedGroupLength: groupLength.selectedGroupLength,
            selectedGroupLengthValue: groupLength.selectedGroupLengthValue
        });

        this.setState(newState);

        await this.props.updateData({
            selectedGroupLength: groupLength.selectedGroupLength,
            selectedGroupLengthValue: groupLength.selectedGroupLengthValue
        }, 'selectedGroupLengthValue');
    };

    selectLengthCar = (lengthCar) => {
        // Добавить или удалить группу длин
        let selectedGroupLength = this.state.selectedGroupLength;
        let selectedGroupLengthValue = this.state.selectedGroupLengthValue;
        let idx = selectedGroupLength.indexOf(lengthCar);

        this.state.tariffs.map((tariff) => {
            if(tariff.identifier === this.state.defaultTariff.value) {
                tariff.lengthGroups.map(async (item) => {
                    if(item.lengthGroup === lengthCar) {
                        if(idx !== -1) {
                            // Удаляем данные
                            if(selectedGroupLength.length > 1) {
                                selectedGroupLength.splice(idx, 1);
                                item.lengthValues.map((val) => {
                                    let elemIdx = selectedGroupLengthValue.indexOf(val);

                                    selectedGroupLengthValue.splice(elemIdx, 1);
                                });
                            }
                        } else {
                            // Добавляем данные
                            selectedGroupLength.push(lengthCar);
                            selectedGroupLengthValue = arrayUnique(selectedGroupLengthValue.concat(item.lengthValues));
                        }

                        this.setState({
                            selectedGroupLengthValue,
                            selectedGroupLength
                        });

                        await this.props.updateData({ selectedGroupLengthValue, selectedGroupLength }, 'selectedGroupLengthValue');
                    }
                });
            }

        });
    };

    renderLengthCars = (lengthCar, index) => {
		const { lang } = this.props;
        let selectedGroupLength = this.state.selectedGroupLength;

        console.log('step ', 2, selectedGroupLength, lengthCar);

        let checked = selectedGroupLength.indexOf(lengthCar) !== -1;

        let carLengthCSS = cx({
			'radio-choice-item': true,
			'col': true,
            'is-disabled': selectedGroupLength.length < 2 && checked
        });

        return (
            <div id={"calculatorTariffChoiceItem" + index} className={carLengthCSS} key={lengthCar.toString() + index}>
                <input type="checkbox"
                       name="radioChoice"
                       id={'radioChoice-' + index}
                       value={'type-' + index}
                       defaultChecked={checked ? 'checked' : ''}
                       onChange={this.selectLengthCar.bind(this, lengthCar)}
                />
                <div className="box">
                    <span className="title">{ lengthCar } {lang.CalcStep4_option_list_size}</span>
                </div>
            </div>
        )
    };

    renderTariffInnerData = (tariff, index) => {
        const { lang } = this.props;

        console.log('step ', 3, tariff);


        let renderLengthCars = tariff.lengthCars.map(this.renderLengthCars);


        let width = tariff.widthCars[0] + ' - ' + tariff.widthCars[tariff.widthCars.length - 1];
        let height = tariff.heightCars[0] + ' - ' + tariff.heightCars[tariff.heightCars.length - 1];
        let volume = tariff.volumeCars[0] + ' - ' + tariff.volumeCars[tariff.volumeCars.length - 1];
        let length = tariff.lengthCars.length > 1 ? tariff.lengthCars[0] + ' - ' + tariff.lengthCars[tariff.lengthCars.length -1] : tariff.lengthCars[0];
        let capacity = tariff.capacityLengths; // @todo исправить
        let img = "/static/img/tariffs/" + tariff.identifier + "_active@2x.png";
        let hours = DateUtils.minutesToDisplay(tariff.price.tariff.minutes);

        return (
            <div className="check-cnt">
                <div className="widget-tariff-car">
                    <div className="column">
                        <div className="car">
                            <img src={img} alt="" width="165" height="70" />
                        </div>
                    </div>
                    <div className="column">
                        <p className="info-1">
                            { tariff.description }
                        </p>
                        <p className="info-2">
                            { tariff.typeCars }
                        </p>
                    </div>
                </div>

                <div className="tariff-settings">
					<span className="label-choice">Длина</span>
                    <div id={"calculatorTariffChoiceRow" + index} className="radio-choice row">
                        { renderLengthCars }
                    </div>

                    <div className="tariff-size-wrap">
                        <div className="tariff-size-item row">
							<div className="tariff-size-car col">
								<span className="title">{lang.CalcStep4_size_1_title}</span>
								<span className="size">{tariffsDescriptions[tariff.identifier].width} {lang.CalcStep4_option_list_size}</span>
							</div>
							<div className="tariff-size-car col">
								<span className="title">{lang.CalcStep4_size_2_title}</span>
								<span className="size">{tariffsDescriptions[tariff.identifier].height} {lang.CalcStep4_option_list_size}</span>
							</div>
							<div className="tariff-size-car col">
								<span className="title">{lang.CalcStep4_size_3_title}</span>
								<span className="size">{tariffsDescriptions[tariff.identifier].volume} {lang.CalcStep4_option_list_size}³</span>
							</div>
                            <div className="tariff-size-car col">
                                <span className="title">{lang.CalcStep4_size_4_title}</span>
                                <span className="size">{tariffsDescriptions[tariff.identifier].capacity} т</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="check-info">
                        <span id={"calculatorTariffCheckTime" + index} className="check-time">
                            <i className="icon" />
                            {lang.CalcStep4_check_time_1} <span>{tariff.arrival_estimate} {lang.CalcStep4_check_time_2}</span> <br/> <span>{tariff.cars_found.length} {lang.CalcStep4_check_time_3}</span> {lang.CalcStep4_check_time_4}
                        </span>
                    <span id={"calculatorTariffPriceBig" + index} className="check-price">{tariff.price.tariff.tariff_price} <span className="als-rubl">₽</span><span className="count">/{hours}</span></span>
                </div>
            </div>
        )
    };

	renderTariff = (tariff, index) => {
	    let isActive = tariff.identifier === this.state.defaultTariff.value;
        let hours = DateUtils.minutesToDisplay(tariff.price.tariff.minutes);

	    const { lang } = this.props;

        let itemClass = cx({
            'check-item': true,
            active: isActive
        });

        return (
            <div id={"calculatorTariffCheckItem" + index} className={itemClass} key={index}>
                <div className="check-head" onClick={() => trackTariff(tariff.identifier, this.setTariff.bind(this, tariff.price.total_price, tariff.identifier))}>
                    <span id={"calculatorTariffCheck" + index} className="check" />
                    <div className="info">
                        <div className="img">
                            <div className={"img-icon " + tariff.identifier}/>
                        </div>
                        <span className="type">
							{tariff.name}
                            <span className="count">{tariff.capacity}</span>
                            {/* <i className="icon"></i> */}
						</span>
                        <span className="time"><i className="icon"/> {tariff.arrival_estimate ? tariff.arrival_estimate + ' ' + lang.CalcStep4_check_time_2 : lang.CalcStep4_check_time_5}</span>
                        <span className="price"><b>{tariff.price.tariff.tariff_price} <span className="als-rubl">₽</span></b><span className="count">/{hours}</span></span>
                    </div>
                </div>

                { isActive ? this.renderTariffInnerData(tariff, index) : null}

            </div>
        );
	};

    render() {
        const { lang, isActive, info, data } = this.props;

        let _tariffs = [];
        if(info && info.prices) {
            const { prices } = info;

            Object.keys(prices).forEach(function(key) {
                _tariffs.push(this[key]);
            }, prices);
        }

        console.log(_tariffs);

        const itemCSS = cx({
            'calculator-content': true,
            'step4': true,
            'active': isActive,
        });

        return (
            <div className={itemCSS} >
                <h2 className="title">{lang.CalcStep4_title}</h2>

				<div className="check-wrap">
					{ _tariffs.length ? _tariffs.map(this.renderTariff) : null }
				</div>
            </div>
        );
    }
}
