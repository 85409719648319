import fetch from 'isomorphic-unfetch';
import {SERVER} from '../config';
import {headersGET, headersPOST} from '../utils/helpers';

async function getDrafts (req) {
    const res = await fetch(SERVER + '/customer/me/orders/drafts.json?notAccepted=1', headersGET(req)).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

async function getDraft (req) {
    const res = await fetch(SERVER + '/customer/me/orders/drafts/' + req.draftId + '.json', headersGET(req.token)).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

async function sendYmDraftLink (draftId, clientId, yclid) {
    const requestParams = new URLSearchParams();
    requestParams.append('clientId', clientId);
    if (yclid)
        requestParams.append('yclid', yclid);

    const res = await fetch(SERVER + '/customer/me/orders/drafts/' + draftId + '/yametrika/link.json', headersPOST(requestParams))
        .then(res => res).catch((err) => err);

    return await res.json();
}

async function deleteDateOrder (req) {
    const searchParams = new URLSearchParams();

    searchParams.append('from[arrivalTime]', null);

    const res = await fetch(SERVER + '/customer/me/orders/drafts/' + req.draftId + '.json', headersPOST(searchParams)).then(res => res).catch((err) => err);
    const result = await res.json();

    return result;
}

async function stopSearch(req) {
    const res = await fetch(`${SERVER}/customer/me/orders/drafts/${req.draftId}/taxi/search/stop.json`, headersPOST({})).then(res => res).catch((err) => err);
    return await res.json();
}

export default {
    // Постоянные методы
    getDraft: getDraft,
    getDrafts: getDrafts,
    deleteDateOrder: deleteDateOrder,
    sendYmDraftLink: sendYmDraftLink,
    stopSearch: stopSearch
}
