import { SERVER }                       from '../config';
import fetch                            from 'isomorphic-unfetch';
import { headersGET, headersPOST }      from '../utils/helpers';

async function sendLinkApp(phone) {
    const res = await fetch(SERVER + '/application/customer/all/download/sms.json?recipient=' + phone, headersGET()).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

async function sendPromo (params) {
    const searchParams = new URLSearchParams();
    searchParams.append('phone', params.phone);
    searchParams.append('name', params.name);
    searchParams.append('to', params.to);

    const res = await fetch(SERVER + '/promo/share.json', headersPOST(searchParams)).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
};

export default {
    // Постоянные методы
    sendLinkApp: sendLinkApp,
    sendPromo: sendPromo,
}