import io                   from 'socket.io-client';
import { SERVER_WS }        from '../config';
import  isServer            from 'detect-node';

const websocketMiddleware = (function(){
    let socket = null;
    let timer = null;

    function newConnection(token) {
        return new WebSocket(SERVER_WS + '/ws/?token=' + token);
    }

    // if(!isServer) {
    //     const token = window.localStorage.getItem('token');
    //     socket = new WebSocket(SERVER_WS + '/ws/?token=' + token);
    // }

    return store => next => action => {
        /** Конекстимся для получения sid для создания канала */

        if (action.type === 'ws/connect') {
            const token = window.localStorage.getItem('token');

            if(token) {
                socket = newConnection(token);
                store.dispatch({ type: 'ws/connecting' });
            }
        }

        if(!isServer && socket) {
            socket.onopen = (event) => {
                store.dispatch({ type: 'ws/connected' });

                if(socket.readyState === 1) {
                    socket.send('{"action":"listen_taxi"}');    // Подписка на получение уведомлений по такси
                    socket.send('{"action":"listen_order"}');   // Подписка на получение уведомлений по событиям заказа

                    timer = setInterval(() => socket.send('ping'), 60000);
                }
            };

            window.botTimer = null;

            socket.onmessage = (event) => {
                let data = JSON.parse(event.data);
                if(data.length && data[0] === 'pong') return;
                store.dispatch({ type: 'ws/message', payload: data });
            };
            socket.onclose = () => {
                store.dispatch({ type: 'ws/disconnected' });
                clearInterval(timer);
            };
            socket.onerror = () => {
                store.dispatch({ type: 'ws/disconnected' });
                clearInterval(timer);
            };

            if (action.type === 'ws/disconnect' || action.type === 'user/logout') {
                socket.close();
                store.dispatch({ type: 'ws/disconnected' });
            }
        }

        return next(action);
    }
})();

export default websocketMiddleware;
