import React        from 'react';
import cx           from 'classnames';

export default class CalculatorSideBarStep3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    setActive = (val) => {
        this.props.setActive(3);
    };

    render() {
        const { lang, step, data, info } = this.props;

		let isActive = step === 3;

        const hasLoaders = data.value > 0;

        const itemCSS = cx({
            'Сalculator-sideBar-item': true,
            'item-3': true,
            'active': isActive,
            'empty': !hasLoaders,
			'old': step > 3
        });

        let loadersPrice, loaders, totalHours, eta;

        if(info && info.preparedInfo.tariff && info.prices[info.preparedInfo.tariff.value]) {
            loaders = info.prices[info.preparedInfo.tariff.value].price.loaders;
            loadersPrice = loaders.loaders_price + loaders.loaders_services_price;
            totalHours = Math.round(info.prices[info.preparedInfo.tariff.value].price.tariff.minutes / 60);
        }


        const loadersEmpty = (
            <div id="calculatorSBLoadersEmpty" className="loaders-empty">
                <div className="row">
                    <div className="icon"></div>
                    <div className="title-wrap">
                        <span>{lang.Calculator_add_mov}</span>
                    </div>
                </div>
            </div>
        );

        const loadersFull = (data) => {
            return (
                <div className="loaders-full full">
                    <div className="row">
                        <div className="icon"></div>
                        <div className="title-wrap">
                            <span className="prefix">{lang.Calculator_add_mov}</span>
                            <span className="type"><span id="calculatorSBLoadersType">+ {data.value == 1 ? data.value + ' ' + lang.CalcSidebar_step3_type_1 : data.value + ' ' + lang.CalcSidebar_step3_type_2}</span><span id="calculatorSBLoadersCount" className="count">+ { loadersPrice } <span className="als-rubl">₽</span>/{totalHours}{lang.CalcStep3_count}</span></span>
                        </div>
                    </div>
                </div>
            )
        };

        return (
            <div id="calculatorSBItem3" className={itemCSS} onClick={this.setActive}>
                {hasLoaders ? loadersFull(data) : loadersEmpty}
            </div>
        );
    }
}