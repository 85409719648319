import React            from 'react';
import Calculator       from '../API/calculator';
import BusinessControl  from '../components/BusinessControl';
import HelpCenter       from '../components/HelpCenter';
import AvailableDevices from '../components/AvailableDevices';
import StepsWork        from '../components/StepsWork';
import HomeFirstSlide   from '../components/HomeFirstSlide';
import ExampleUsingOld  from '../components/exampleUsingOld';
import Press            from '../components/Press';
import Reviews          from '../components/Reviews';
import GetFirstBonus    from '../components/GetFirstBonus';

export default class Home extends React.PureComponent {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
    }

    componentDidMount() {

    }

    saveAdress = async (data, index) => {
        let points = this.props.data.points;
        let point = points[index];
        point.adress = data;
        if(point.defaultValue) point.defaultValue = false;
        if(data.formated_address === '') point.defaultValue = true;

        points.splice(index, 1, point);

        await this.updateData({ points }, 'adress');
    };

    updateData = async (newData, type) => {
        let temp = this.props.data;
        let data;
        let selectedGroupLengthValue = this.props.selectedGroupLengthValue;
        let selectedGroupLength = this.props.selectedGroupLength;

        switch (type) {
            case 'adress':
                temp.points = newData.points;

                let info = await Calculator.prepareData({
                    latitude: temp.points[0].adress.latitude,
                    longitude: temp.points[0].adress.longitude,
                    userData: temp
                });

                this.props.setInfo(info);

                break;
            case 'goods':
                temp.goods = newData.goods;
                this.props.setData(temp);

                break;
            case 'goodsServices':
                temp.goodsServices = newData.goodsServices;
                this.props.setData(temp);

                break;
            case 'advanceService':
                temp.advanceService = newData.advanceService;
                this.props.setData(temp);

                break;
            case 'tariff':
                var tariff;

                this.props.tariffs.map((item) => {
                    // Выбираем из тиров тариф
                    if(this.props.data.tariff.value === item.identifier) tariff = item;
                });

                temp.tariff = newData.tariff;
                temp.stevedores.advanceInfo.price = tariff.loaders_min_price * this.props.data.stevedores.value;

                let tars = Calculator.preparationData(this.props.tariffs);
                let groupLength = Calculator.setGroupLength(tars, temp.tariff.value);

                temp.selectedGroupLengthValue = groupLength.selectedGroupLengthValue;
                temp.selectedGroupLength = groupLength.selectedGroupLength;

                info = await Calculator.prepareData({
                    latitude: temp.points[0].adress.latitude,
                    longitude: temp.points[0].adress.longitude,
                    userData: temp
                });

                this.props.setInfo(info);
                this.props.setData(temp);

                this.props.setGroupLength({
                    selectedGroupLengthValue: groupLength.selectedGroupLengthValue,
                    selectedGroupLength: groupLength.selectedGroupLength,
                });

                break;
            case 'selectedGroupLengthValue':
                selectedGroupLengthValue = newData.selectedGroupLengthValue;
                selectedGroupLength = newData.selectedGroupLength;
                this.props.setGroupLength({
                    selectedGroupLength,
                    selectedGroupLengthValue
                });

                break;
            case 'stevedores':
                temp.stevedores = newData.stevedores;

                this.props.setData(temp);

                info = await Calculator.prepareData({
                    latitude: temp.points[0].adress.latitude,
                    longitude: temp.points[0].adress.longitude,
                    userData: temp
                });

                this.props.setData({ info });

                break;
            default:
                data = Object.assign({}, temp, newData);

                this.props.setData(function(state, props) {
                    let temp = props.data;
                    let data = Object.assign({}, temp, newData);

                    return { data }
                });
        }
    };

    render() {
        let { data, lang, info, flagupdate } = this.props;
        let _tariffs = [];
        if(info && info.prices) {
            const { prices } = info;

            Object.keys(prices).forEach(function(key) {
                _tariffs.push(this[key]);
            }, prices);
        }

        // <HomeSecondSlide saveAdress={this.saveAdress} info={info} data={data} lang={lang} tariffs={_tariffs} />

        return (
            <div key="Home" className="page page-index">

				{/*<SvgSprite symbols={svgIconsObject}/>*/}

                <div className="s-index-one-wrapper bg">
                    <HomeFirstSlide
                        openCalc={this.props.openCalc}
                        getUserPosition={this.props.getUserPosition}
                        updateData={this.updateData.bind(this)}
                        saveAdress={this.saveAdress}
                        data={data}
                        info={info}
                        lang={lang}
                        flagupdate={flagupdate}
                        tariffs={_tariffs} />

                    <div className="s-index-adv s-index">
                        <div className="container">
                            <div className="index-adv row">
                                <div className="index-adv-item t-1 col">
                                    <div className="img">
										<div className="img-icon time"/>
                                    </div>
                                    <div className="cnt">
                                        <h3 className="title">{lang.Index_adv_title_1}</h3>
                                        <p className="desc">{lang.Index_adv_desc_1}</p>
                                    </div>
                                </div>

                                <div className="index-adv-item t-2 col">
                                    <div className="img">
										<div className="img-icon price"/>
                                    </div>
                                    <div className="cnt">
                                        <h3 className="title">{lang.Index_adv_title_2}</h3>
                                        <p className="desc">{lang.Index_adv_desc_2}</p>
                                    </div>
                                </div>

                                <div className="index-adv-item t-3 col">
                                    <div className="img">
										<div className="img-icon driver"/>
                                    </div>
                                    <div className="cnt">
                                        <h3 className="title">{lang.Index_adv_title_3}</h3>
                                        <p className="desc">{lang.Index_adv_desc_3}</p>
                                    </div>
                                </div>

								<div className="index-adv-item t-3 col">
									<div className="img">
										<div className="img-icon fast"/>
									</div>
									<div className="cnt">
										<h3 className="title">{lang.Index_adv_title_4}</h3>
										<p className="desc">{lang.Index_adv_desc_4}</p>
									</div>
								</div>
                            </div>

							{/*<SvgIcon name="book"/>*/}
                        </div>
                    </div>

                </div>

                <StepsWork lang={lang} openCalc={this.props.openCalc}/>

                <ExampleUsingOld lang={lang} />

                <GetFirstBonus lang={lang} />

                <HelpCenter lang={lang} />

                <AvailableDevices openCalc={this.props.openCalc} lang={lang} />

                <Reviews lang={lang} />

                <Press lang={lang} />

                <BusinessControl openbussiness={this.props.openbussiness} lang={lang} />

				{/* onClick={() => ymElem('extLink', '')} */}
				{/* onClick={() => ymElem('reachGoal', 'business_form', this.props.openbussiness)} */}
				{/* import { ymElem }                       from '../utils/helpers'; */}

            </div>
        );
    }
}
