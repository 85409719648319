import React from "react";

const ExampleUsingOld = ({ lang }) => {
    return (
        <div className="s-index-example-wrapper">
            <div className="s-index-example">
                <div className="container">
                    <h2 className="s-index__title">{lang.Index_example_title}</h2>
                </div>

                <div className="index-example">
                    <div className="container">
                        <div className="row">
                            <div className="col col-anim">
                                <div className="index-example-cnt t-left">
                                    <div className="img">
										<div className="img-icon demand"/>
                                    </div>
                                    <h3 className="title">{lang.Index_example_1_title}</h3>
                                    <p className="desc">{lang.Index_example_1_desc}</p>
                                    <div className="type-list-wrapper row">
                                        <div className="col-5">
                                            <ul className="list-unstyled type-list">
                                                <li className="tooltip-pos">
                                                    <i className="icon" />{lang.Index_example_1_list_1}
                                                </li>
                                                <li className="tooltip-pos">
                                                    <i className="icon" />{lang.Index_example_1_list_2}
                                                </li>
                                                <li className="tooltip-pos">
                                                    <i className="icon" />{lang.Index_example_1_list_3}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-7">
                                            <ul className="list-unstyled type-list">
                                                <li className="tooltip-pos">
                                                    <i className="icon" />{lang.Index_example_1_list_4}
                                                </li>
                                                <li className="tooltip-pos">
                                                    <i className="icon" />{lang.Index_example_1_list_5}
                                                </li>
                                                <li className="tooltip-pos">
                                                    <i className="icon" />{lang.Index_example_1_list_6}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col col-anim">
                                <div className="index-example-img">
									<div className="illustration illustration-1"/>
                                </div>
                                {/*<div className="index-example-cards">
									<div className="index-example-card t-1">
										<h4 className="title">Mover API</h4>
										<p className="desc">Переход на нашу документацию и описание всего функционала</p>
										<div className="more">
											<a href="#" className="index-example__link">Посмотреть документацию</a>
										</div>
									</div>
									<div className="index-example-card t-2"></div>
									<div className="index-example-card t-3"></div>
								</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="s-index-example">
                <div className="index-example">
                    <div className="container">
                        <div className="row">
                            <div className="col col-anim">
                                <div className="index-example-img">
									<div className="illustration illustration-2"/>
                                </div>
                            </div>

                            <div className="col col-anim">
                                <div className="index-example-cnt t-right">
                                    <div className="img">
										<div className="img-icon renta"/>
                                    </div>
                                    <h3 className="title" dangerouslySetInnerHTML={{__html: lang.Index_example_2_title}}/>
                                    <p className="desc">
                                        {lang.Index_example_2_desc}
                                    </p>
                                    <div className="type-list-wrapper row">
                                        <div className="col-6">
                                            <ul className="list-unstyled clearfix type-list">
                                                <li><i className="icon" />{lang.Index_example_2_list_1}</li>
                                                <li><i className="icon" />{lang.Index_example_2_list_2}</li>
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul className="list-unstyled clearfix type-list">
                                                <li><i className="icon" />{lang.Index_example_2_list_3}</li>
                                                <li><i className="icon" />{lang.Index_example_2_list_4}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="s-index-example">
                <div className="index-example">
                    <div className="container">
                        <div className="row">
                            <div className="col col-anim">
                                <div className="index-example-cnt t-left">
                                    <div className="img">
										<div className="img-icon moving"/>
                                    </div>
                                    <h3 className="title">{lang.Index_example_3_title}</h3>
                                    <p className="desc">
                                        {lang.Index_example_3_desc}
                                    </p>
                                    <div className="type-list-wrapper row">
                                        <div className="col-6">
                                            <ul className="list-unstyled clearfix type-list">
                                                <li><i className="icon" />{lang.Index_example_3_list_1}</li>
                                                <li><i className="icon" />{lang.Index_example_3_list_2}</li>
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul className="list-unstyled clearfix type-list">
                                                <li><i className="icon" />{lang.Index_example_3_list_3}</li>
                                                <li><i className="icon" />{lang.Index_example_3_list_4}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col col-anim">
                                <div className="index-example-img">
									<div className="illustration illustration-3"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="s-index-example">
                <div className="index-example">
                    <div className="container">
                        <div className="row">
                            <div className="col col-anim">
                                <div className="index-example-img">
									<div className="illustration illustration-4"/>
                                </div>
                            </div>

                            <div className="col col-anim">
                                <div className="index-example-cnt t-right">
                                    <div className="img">
										<div className="img-icon delivery"/>
                                    </div>
                                    <h3 className="title">{lang.Index_example_4_title}</h3>
                                    <p className="desc">
                                        {lang.Index_example_4_desc}
                                    </p>
                                    <div className="type-list-wrapper row">
                                        <div className="col-6">
                                            <ul className="list-unstyled clearfix type-list">
                                                <li><i className="icon" />{lang.Index_example_4_list_1}</li>
                                                <li><i className="icon" />{lang.Index_example_4_list_2}</li>
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul className="list-unstyled clearfix type-list">
                                                <li><i className="icon" />{lang.Index_example_4_list_3}</li>
                                                <li><i className="icon" />{lang.Index_example_4_list_4}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="s-index-example">
                <div className="index-example">
                    <div className="container">
                        <div className="row">
                            <div className="col col-anim">
                                <div className="index-example-cnt t-left">
                                    <div className="img">
                                        <div className="img-icon qb"/>
                                    </div>
                                    <h3 className="title">{lang.Index_example_QB_title}</h3>
                                    <p className="desc">
                                        {lang.Index_example_QB_desc}
                                    </p>
                                    <div className="type-list-wrapper row">
                                        <div className="col-6">
                                            <ul className="list-unstyled clearfix type-list">
                                                <li><i className="icon" />{lang.Index_example_QB_list_1}</li>
                                                <li><i className="icon" />{lang.Index_example_QB_list_2}</li>
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul className="list-unstyled clearfix type-list">
                                                <li><i className="icon" />{lang.Index_example_QB_list_3}</li>
                                                <li><i className="icon" />{lang.Index_example_QB_list_4}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col col-anim">
                                <div className="index-example-img">
                                    <div className="illustration illustration-qb"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExampleUsingOld;