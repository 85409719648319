import React    from 'react';
import cx       from 'classnames';
import {DateUtils} from "../../utils/date";

export default class CalculatorSideBarStep4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    setActive = (val) => {
        this.props.setActive(4);
    };

    render() {
        const { lang, step, data } = this.props;
        let tariffPrice = '', totalHours;

        if(!data) return null;

        let curTariff = data.preparedInfo.tariff;
        let curTariffName = data.preparedInfo.tariff.name;

        let type = curTariff.type.split(', ').join(' • ');

        if(data.prices[data.preparedInfo.tariff.value]) {
            tariffPrice = data.prices[data.preparedInfo.tariff.value].price.tariff.tariff_price;
            totalHours = DateUtils.minutesToDisplay(data.prices[data.preparedInfo.tariff.value].price.tariff.minutes);
        }

		let isActive = step === 4;

        const hasTariff = true;

        const itemCSS = cx({
            'Сalculator-sideBar-item': true,
            'item-4': true,
            'active': isActive,
            'empty': !hasTariff,
			'old': step > 4
        });

        const tariffEmpty = (
            <div className="tariff-empty">
                <div className="row">
                    <div className="icon"/>
                    <div className="title-wrap">
                        <span>{lang.Tariff}</span>
						<p className="desc" dangerouslySetInnerHTML={{__html: lang.CalcSidebar_step4_empty_desc}}/>
                    </div>
                </div>
            </div>
        );



        const tariffFull = (
            <div className="tariff-full full">
                <div className="row">
                    <div className="icon"/>
                    <div className="title-wrap">
						<span className="prefix">{lang.Tariff}</span>
						<span className="type"><span id="calculatorSBTariffType">{curTariffName}</span><span id="calculatorSBTariffCount" className="count">{tariffPrice} <span className="als-rubl">₽</span>/{totalHours}</span></span>
						<span id="calculatorSBTariffChar" className="char">{type}</span>
                    </div>
                </div>
            </div>
        );

        return (
            <div id="calculatorSBItem4" className={itemCSS} onClick={this.setActive}>
                {hasTariff ? tariffFull : tariffEmpty}
            </div>
        );
    }
}