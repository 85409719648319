'use strict';
import Orders from '../API/orders';
import OrderAPI from '../API/orders';
import Drafts from '../API/drafts';
import moment from 'moment';

function getInitialState() {
    return {
        agreedOrder: null,
        activeOrder: null,
        currentOrder: null,

        isLoadingList: true,
        isLoadingOrder: true,
        itemsFull: [],
        itemsList: {},
        filters: {
            phone: null,
            calendar: null,
            orderId: null,
            status: []
        },
        cars: {},
        driver: null,
        updateIndex: 0,
        orderInfo: null
    };
}

function orderPreprocessorList(items, filters) {
    let unicueOrders = [], result = {};

    items.forEach((item, i) => {
        if(unicueOrders.indexOf(draftId) !== -1 || (filters && !filters.status.length ? item.status !== 'completed' : false)) return;
        moment.locale('ru');
        let dateOrder = moment(item.created_at);
        let dateDisplay = dateOrder.format('DD MMMM YYYY');
        let dateTimestamp = dateOrder.valueOf();

        let dayTimestamp = moment(dateDisplay, 'DD MMMM YYYY').valueOf();

        if(!result[dayTimestamp]) result[dayTimestamp] = [];

        let cancellable = isOrderCancellable(item);

        /*let driver = (item.periods[0].employer.account.name ? item.periods[0].employer.account.name : '') +
            (item.periods[0].employer.account.surname ? ' ' + item.periods[0].employer.account.surname : '');*/

        let driver = {
            name: '-',
            phone: '-',
            rating: '-',
            email: '-',
            img: '-',
        };

        if(item.periods[0].employer) {
            driver = {
                name: (item.periods[0].employer.account.name ? item.periods[0].employer.account.name : '-') + ' ' +
                    (item.periods[0].employer.account.surname ? item.periods[0].employer.account.surname : '-'),
                phone: item.periods[0].employer.account.phone,
                email: item.periods[0].employer.account.email,
                rating: item.periods[0].employer.account.rating,
                img: item.periods[0].employer.account.picture
            };
        }

        let cost = item.cost;
        let totalCost = item.total_cost;
        let draftId = item.draft.id;
        let freighterId = item.freighter.id;
        let orderId = item.id;
		let status = item.status;
		let paymentStatus = item.payment_status;
		let paymentMethod = item.pay_method;

        /*let carType = item.periods[0].transport.features.Tip_kuzova;*/
        let car = {
            name: '-',
            dlina: '-',
            ob_em_m3: '-',
            shirina: '-',
            vyisota: '-',
            number: '-',
            tip_kuzova: '-'
        };

        if(item.periods[0].transport) {
            car = {
                name: item.periods[0].transport.car_model,
                dlina: item.periods[0].transport.features.Dlina,
                ob_em_m3: item.periods[0].transport.features.Ob_em_m3,
                shirina: item.periods[0].transport.features.Shirina,
                vyisota: item.periods[0].transport.features.Vyisota,
                number: item.periods[0].transport.features.number,
                tip_kuzova: item.periods[0].transport.features.Tip_kuzova
            };
        }


		let invoice = {
			cost: item.cost,
            discount: item.cost - item.total_cost,
			total_cost: item.total_cost,
			period_start: item.periods[0].start,
			period_end: item.periods[0].end
		};

        let tariff = item.draft.extra_search_params && item.draft.extra_search_params.tariff_tier && item.draft.extra_search_params.tariff_tier.name || '-';
        let points = item.draft.destinations.length ? [item.draft.destinations[0].destination.addr] : [];
        let endOrderDate = moment(item.periods[0].end).format('hh:mm DD MMMM YYYY');
        let distance = item.periods[0].distance || '-';

        if(item.draft.destinations.length > 2) {
            if(item.draft.destinations.length > 3) {
                // @Todo Сделать склонение адресов
                points.push('Еще ' + (item.draft.destinations.length - 2) + ' адреса');
            } else {
                points.push(item.draft.destinations[1].destination.addr);
            }
        }

        if(item.draft.destinations.length) points.push(item.draft.destinations[item.draft.destinations.length - 1].destination.addr);

        unicueOrders.push(draftId);

        result[dayTimestamp].push({
            cost,           // Стоимость работы
            totalCost,      // Финальная стоимость работы
			invoice,
			draftId,        // ID черновика
            orderId,        // ID заказа
            freighterId,    // ID компании
            dateDisplay,    // Время для отображения
            dateTimestamp,  // Время в миллисекундах, для сортировок
			status,
            cancellable,
			paymentStatus,
            paymentMethod,  // Способ оплаты
			car,            // Тачка
            driver,         // Водитель
            tariff,         // Тариф
            points,         // Адреса
            endOrderDate,   // Время завершения заказа
            distance        // дистанция расстояние расчетное
        })
    });

    return result;
}

function orderPreprocessorFull(items) {
    let result = items.map((item) => {
        return item
    });

    return result;
}

function orderPreprocessor(item) {
    moment.locale('ru');

    let dateOrder = moment(item.created_at);
    let dateDisplay = dateOrder.format('DD MMMM YYYY');
    let orderId = item.id;
	let cost = item.cost;
	let totalCost = item.total_cost;
	let status = item.status;
	let paymentStatus = item.payment_status;
	let paymentMethod = item.pay_method;
	let calculation = item.calculation;
	let cancellable = isOrderCancellable(item);
	console.log(`cancellable: ${cancellable ? 'yes' : 'no'}`)

	let coords = [];
	coords = item.periods[0].destinations.map((point) => {
		return {
			latitude: point.destination.lat,
			longitude: point.destination.lon,
			name: point.destination.addr
		}
	});

	let points = [];
	points = item.periods[0].destinations.map((info) => {
		return {
			addr: info.destination.addr,
			elevator: info.elevator,
			floor: info.floor,
			lifting: info.lifting,
			contact_name: info.contact_name || item.draft.client.name,
			contact_phone: info.contact_phone || item.draft.client.phone
		}
	});

	let driver = {
		name: (item.periods[0].employer.account.name ? item.periods[0].employer.account.name : '-') + ' ' +
		(item.periods[0].employer.account.surname ? item.periods[0].employer.account.surname : '-'),
		phone: item.periods[0].employer.account.phone,
		email: item.periods[0].employer.account.email,
		rating: item.periods[0].employer.account.rating,
		img: item.periods[0].employer.account.picture
	};
	let car = {
	    id: item.periods[0].transport.id,
		name: item.periods[0].transport.car_model,
		dlina: item.periods[0].transport.features.Dlina,
		ob_em_m3: item.periods[0].transport.features.Ob_em_m3,
		shirina: item.periods[0].transport.features.Shirina,
		vyisota: item.periods[0].transport.features.Vyisota,
		number: item.periods[0].transport.features.number,
		tip_kuzova: item.periods[0].transport.features.Tip_kuzova
	};

	let company = {
		name: item.draft.client.name,
		phone: item.draft.client.phone,
		rating: item.draft.client.rating,
		img: item.draft.client.picture
	};

	let loaders = {
		count: item.draft.loaders
	};

	let invoice = {
		cost: item.cost,
        discount: item.cost - item.total_cost,
		total_cost: item.total_cost,
		period_start: item.periods[0].start,
		period_end: item.periods[0].end,
        for_payment: item.for_payment,
        payed_from_account_sum: item.payed_from_account_sum
	};

    let freighterId = item.freighter.id;
    let endOrderDate = moment(item.periods[0].end).format('hh:mm DD MMMM YYYY');
    let distance = item.periods[0].distance;
	let tariff = item.draft.extra_search_params ? item.draft.extra_search_params.tariff_tier.name : 'не указан';

    let route = item.draft.points;

    let draftTransport = {
		transport_sum_cost: item.draft.calculation.transport_sum_cost,
		additional_hour_price: item.draft.calculation.transport_tariff ? item.draft.calculation.transport_tariff.additional_hour_price : '-',
		after_mkad_price: item.draft.calculation.transport_tariff ? item.draft.calculation.transport_tariff.after_mkad_price : '-',
		hydroelevator_hour_price: item.draft.calculation.transport_tariff ? item.draft.calculation.transport_tariff.hydroelevator_hour_price : '-',
		min_price: item.draft.calculation.transport_tariff ? item.draft.calculation.transport_tariff.min_price : '-',
		min_hours: item.draft.calculation.transport_tariff ? item.draft.calculation.transport_tariff.min_hours : '-',
		night_additional_hour_price: item.draft.calculation.transport_tariff ? item.draft.calculation.transport_tariff.night_additional_hour_price : '-',
		night_min_price: item.draft.calculation.transport_tariff ? item.draft.calculation.transport_tariff.night_min_price : '-',
		ttk_price: item.draft.calculation.transport_tariff ? item.draft.calculation.transport_tariff.ttk_price : '-',
	};

    let draftId = item.draft.id;

    let loadersDraft = {
    	loaders_additional_time: item.draft.calculation.loaders_additional_time,
		loaders_cost: item.draft.calculation.loaders_cost,
		loaders_count: item.draft.calculation.loaders_count,
		loaders_on_the_way_time: item.draft.calculation.loaders_on_the_way_time,
		loaders_sum_cost: item.draft.calculation.loaders_sum_cost,
		loaders_work_time: item.draft.calculation.loaders_work_time,
		additional_hour_price: item.draft.calculation.loaders_tariff ? item.draft.calculation.loaders_tariff.additional_hour_price : '-',
		min_hours: item.draft.calculation.loaders_tariff ? item.draft.calculation.loaders_tariff.min_hours : '-',
		min_price: item.draft.calculation.loaders_tariff ? item.draft.calculation.loaders_tariff.min_price : '-',
		night_additional_hour_price: item.draft.calculation.loaders_tariff ? item.draft.calculation.loaders_tariff.night_additional_hour_price : '-',
		night_min_price: item.draft.calculation.loaders_tariff ? item.draft.calculation.loaders_tariff.night_min_price : '-',
		on_the_way_hour_price: item.draft.calculation.loaders_tariff ? item.draft.calculation.loaders_tariff.on_the_way_hour_price : '-'
	};

    let ratings = item.ratings;

    return {
        route,
        orderId,
        draftId,
        dateDisplay,
        paymentStatus,
        paymentMethod,
        cost,
        totalCost,
        calculation,
        status,
        cancellable,
        points,
        driver,
        car,
        coords,
        company,
        loaders,
        invoice,
        freighterId,
        endOrderDate,
        distance,
        tariff,
        draftTransport,
        loadersDraft,
        ratings
    };
}

function draftsPreprocessor(items) {
    let unicueOrders = [], result = {};

    items.forEach((item, i) => {
        //if(unicueOrders.indexOf(draftId) !== -1 || (filters && !filters.status.length ? item.status !== 'completed' : false)) return;
        moment.locale('ru');
        let dateOrder = moment(item.created_at);
        let dateDisplay = dateOrder.format('DD MMMM YYYY');
        let dateTimestamp = dateOrder.valueOf();
        let dayTimestamp = moment(dateDisplay, 'DD MMMM YYYY').valueOf();
        if(!result[dayTimestamp]) result[dayTimestamp] = [];
        let cost = item.cost;
        let draftId = item.id;
        let paymentMethod = item.pay_method;
        let cancellable = isOrderCancellable(item);


        let tariff = item.draft.extra_search_params ? item.draft.extra_search_params.tariff_tier.name : 'нет тарифа';
        let points = [item.draft.destinations[0].destination.addr];
        let endOrderDate = moment(item.periods[0].end).format('hh:mm DD MMMM YYYY');
        let distance = item.periods[0].distance;

        if(item.draft.destinations.length > 2) {
            if(item.draft.destinations.length > 3) {
                // @Todo Сделать склонение адресов
                points.push('Еще ' + (item.draft.destinations.length - 2) + ' адреса');
            } else {
                points.push(item.draft.destinations[1].destination.addr);
            }
        }

        points.push(item.draft.destinations[item.draft.destinations.length - 1].destination.addr);

        unicueOrders.push(draftId);


        result[dayTimestamp].push({
            dateTimestamp,  // Время в миллисекундах, для сортировок
            cost,           // Стоимость работы
            draftId,        // ID черновика
            dateDisplay,    // Время для отображения
            paymentMethod,  // Способ оплаты
            cancellable,    // Возможность отмены заказа


            carType,        // Тип кузова
            driver,         // Водитель
            tariff,         // Тариф
            points,         // Адреса
            endOrderDate,   // Время завершения заказа
            distance        // дистанция расстояние расчетное
        })
    });

    return result;
}

/**
 * Проверяет заказ на возможность отмены
 *
 * @param order заказ в серверном формате
 * @return bool true, если отмена возможна
 */
function isOrderCancellable(order) {
    let period = order.periods[0];
    return [ 'accepted', 'agree' ].indexOf(order.status) !== -1
        && [ 'none', 'moved_to_order' ].indexOf(period.execution_status) !== -1;
}

const orders = {
    state: getInitialState(),
    reducers: {
        updateOrders(state, payload) {
            return Object.assign({}, state, payload);
        },
        updateActiveOrder(state, payload) {
            return Object.assign({}, state, { activeOrder: payload });
        },
        updateAgreedOrder(state, payload) {
            return Object.assign({}, state, { agreedOrder: payload });
        },
        loadingList(state, payload) {
            return Object.assign({}, state, { isLoadingList: payload });
        },
        loadingOrder(state, payload) {
            return Object.assign({}, state, { isLoadingOrder: payload });
        },
        setFilter(state, payload) {
            return Object.assign({}, state, { filters: payload });
        },
        postOrderRatingResult(state, payload) {
            let newState = { postOrderRatingResult: payload };
            if (payload.success && state.currentOrder) {
                let ratings = state.currentOrder.ratings || [];
                ratings.push({ scale: 'service_quality', rating: payload.rating });

                newState.currentOrder = state.currentOrder;
            }

            return Object.assign({}, state, newState);
        },
        // Обновление авто на карте.
        searchState(state, payload) {
            let { cars } = state;

            cars[payload.draft] = payload.states.flatMap(state => state.found).map((car) => {
                return {
                    orderId: car.order.id,
                    loaders: car.loaders,
                    driver: car.driver,
                    point: car.point,
                    transport: car.transport
                }

            });

            return Object.assign({}, state, { cars, updateIndex: ++state.updateIndex });
        }
    },
    effects: (dispatch) => ({
        // Получение информации о пользователе
        async getOrders(data, rootState) {
            let { role } = rootState.user;

            const resultOrderList = {};
            dispatch.orders.loadingList(true);

            // Если роль есть, используем ее, иначе берем роль из стора

            let req = {
                type: ['ROLE_ADMIN', 'ROLE_SUPPORT'].indexOf(role) !== -1 ? 'all' : 'me',
                role: ['ROLE_ADMIN', 'ROLE_SUPPORT', 'ROLE_COMPANY'].indexOf(role) !== -1 ? 'freighter' : 'customer',
            };

            if(data) req.filters = data;

            const res = await Orders.getOrders(req);
            if(res.success) {
                if(res.orders.length) {
                    resultOrderList.itemsList = orderPreprocessorList(res.orders, data);
                    resultOrderList.itemsFull = orderPreprocessorFull(res.orders);

                    resultOrderList.currentOrder = [];
                    resultOrderList.itemsList.ordersLength = res.orders.length;
                } else {
                    resultOrderList.itemsList = {
                        ordersLength: 0
                    };
                    resultOrderList.itemsFull = [];
                    resultOrderList.currentOrder = [];
                }

                resultOrderList.isLoadingList = false;
                resultOrderList.isLoadingOrder = false;
                if(data) resultOrderList.filters = data;
            }

            dispatch.orders.updateOrders(resultOrderList);
        },
        // Добавление фильтра к заказу
        async setFilterAsync(data, rootState) {
            let { filters } = rootState.orders;
            let newFilters = Object.assign({}, filters, data);

            dispatch.orders.getOrders(newFilters);
        },
        // Метод получения данных о заказе
        async getOrder(data, rootState) {
            let { role } = rootState.user;
            const result = {};

            dispatch.orders.loadingOrder(true);

            let req = {
                type: ['ROLE_ADMIN', 'ROLE_SUPPORT', 'ROLE_COMPANY'].indexOf(role) !== -1 ? data.freighterId : 'me',
                role: ['ROLE_ADMIN', 'ROLE_SUPPORT', 'ROLE_COMPANY'].indexOf(role) !== -1 ? 'freighter' : 'customer',
            };

            req.orderId = data.orderId;

            const res = await Orders.getOrder(req);

            if(res.success) {
                result.currentOrder = orderPreprocessor(res.order);
                result.isLoadingOrder = false;
            }

            dispatch.orders.updateOrders(result);
        },
        // Метод получения списка черновиков
        async getDrafts(data) {
            // /customer/me/orders/drafts.json GET
            const result = {};
            dispatch.orders.loadingOrder(true);

            const res = await Drafts.getDrafts({
                searchStatus: ''
            });

            if(res.success) {
                if(res.drafts.length) {
                    result.itemsList = draftsPreprocessor(res.drafts, data);
                } else {
                    result.itemsList = {
                        ordersLength: 0
                    };
                    result.itemsFull = [];
                    result.currentOrder = [];
                }

                // console.log(res);
            }

            dispatch.orders.updateOrders(result);
        },
        // @Todo обновить данные о заказе, сделать запрос ордера
        async updateOrder(data) {

        },
        // @Todo Новый заказ, запросить данные о заказе
        async newOrder(data) {},
        // @Todo Машина не найдена
        async notFound(data) {
            dispatch.notification.add({
                title: 'Машина не найдена',
                message: 'По вашей заявке №' + data.draft + ' машина не найдена.',
                level: 'error'
            });
        },
        // Машина найдена нужно подтверждение клиентом
        async found(data) {
            let resp = await OrderAPI.getOrderAgreedDriver(data.draft);

            if(resp.success) dispatch.orders.updateActiveOrder(resp.order);

        },
        // @Todo Ожидание оплаты по заказу ...
        async paymentStatus(data) {
            dispatch.notification.add({
                title: 'Ожидание оплаты',
                message: 'Заказ №' + data.order + ' ожидается оплата.',
                level: 'info'
            });
        },
        // @Todo Уведомление об изменении статуса заказа.
        async status(data) {
            dispatch.notification.add({
                title: 'Статус заказа изменен',
                message: 'Заказ №' + data.order + ' статус изменен.',
                level: 'info'
            });
        },
        // @Todo Клиент не подтвердил заказ. Заказ отменён.
        async noAnswer(data) {
            dispatch.notification.add({
                title: 'Заказ отменён',
                message: 'Заявка №' + data.draft + ' отменена',
                level: 'error'
            });
        },
        // @Todo Изменение setExecutionStatus например к вам выехала машина
        async setExecutionStatus(data) {
            dispatch.notification.add({
                title: 'Обновление в заказе',
                message: 'Заказ №' + data.order + ' статус изменен.',
                level: 'success'
            });
        },
        // @Todo Изменение setExecutionStatus например к вам выехала машина
        async bill(data) {
            dispatch.notification.add({
                title: 'Заказ завершен',
                message: 'Заказ №' + data.order + ' завершен.\r\nСумма к оплате: ' + data.total,
                level: 'success'
            });
        },
        // @Todo Экипаж подъезжает
        async arrive(data) {
            dispatch.notification.add({
                title: 'Экипаж подъезжает',
                message: 'Заказ №' + data.order,
                level: 'info'
            });
        },
        // @Todo Новый поиск
        async newSearch(data) {
            dispatch.notification.add({
                title: 'Начат новый подбор',
                message: 'Заказ №' + data.draft,
                level: 'info'
            });
        },

        // Принятие машины клиентом
        async acceptedOrder(orderId) {
            const res = await OrderAPI.acceptedOrder(orderId);
            if(res.success) dispatch.orders.updateAgreedOrder(orderId);
        },
        // Отклоняем машины клиентом
        async declinedOrder(orderId) {
            const res = await OrderAPI.declinedOrder(orderId);
            if(res.success) dispatch.orders.updateAgreedOrder(null);

            // Продолжить поиск
        },

        async getOrderByHash(data) {
            const res = await OrderAPI.getOrderByHash(data);
            if(res.success) dispatch.orders.updateOrders({
                orderInfo: res.order
            });
        },

        async postOrderRating(data) {
            let {orderId, rating, comment} = data;
            const res = await OrderAPI.postOrderRating(orderId, rating, comment);
            res.orderId = orderId;
            res.rating = rating;
            res.comment = comment;
            dispatch.orders.postOrderRatingResult(res);
        }
    })
};

export default orders;



