import React                        from 'react';
import { connect }                  from 'react-redux';

class WidgetLang extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { lang } = this.props;

        return (
            <div className="drop-wrapper t-right language">
                <div className="drop-inner t-right t-p-0">
                    <div className="widget-lang">
						<div className="widget-lang-menu">
							<ul className="list-unstyled menu">
								<li onClick={() => this.props.setLang('ru')}>
									<span className="item">
										<span>🇷🇺 {lang.appLangRU}</span>
									</span>
								</li>
								<li onClick={() => this.props.setLang('en')}>
									<span className="item">
										<span>🇺🇸 {lang.appLangEN}</span>
									</span>
								</li>
							</ul>
						</div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapState = state => ({
	lang: state.lang
});

const mapDispatch = ({ lang: { setLang }}) => ({
	setLang: (language) => setLang(language)
});

export default connect(mapState, mapDispatch)(WidgetLang);
