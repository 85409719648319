import { SERVER }                                            from '../config';
import fetch                                                 from 'isomorphic-unfetch';
import {arrayUnique, headersGET, headersPOST, headersPUT, sorterASC} from '../utils/helpers';

let tariffsDescriptions = {
	mini2: {
		desc: 'Идеально для доставки небольших грузов до 800 кг. и длиной до 2 м.',
		type: 'Фургон, Цельнометалический'
	},
	standart2: {
		desc: 'Стандартная газель, Crafter, Transit и похожие для перевозки грузов до 3 м.',
		type: 'Фургон, Цельнометалический, Тент, Фермер Фургон, Бортовая'
	},
	extra2: {
		desc: 'Увеличенный экипаж для грузов длиной от 4 м. и больше',
		type: 'Фургон, Тент, Цельнометалический, Фермер Тент'
	},
	maxi2: {
		desc: 'Большой грузовик от 4 м. с профессиональным водителем',
		type: 'Фургон'
	}
};

let tiersCache = [];

function calculateSizes (tier) {
    let sizes = {
        minLength: [],
        length: [],
        width: [],
        height: [],
        volume: []
    };

    // Собираем длины
    tier.length_groups.map((lengthGroups) => {
        sizes.length.push(lengthGroups.group);
    });

    tier.car_types.map((carType) => {
        carType.sizes.map((size) => {
            if(size.length == 3 && sizes.minLength.indexOf(size[0] === -1)) sizes.minLength.push(size[0]);
            if(size.length == 3 && sizes.width.indexOf(size[1] === -1)) sizes.width.push(size[1]);
            if(size.length == 3 && sizes.height.indexOf(size[2] === -1)) sizes.height.push(size[2]);
        });
    });

    sizes.minLength.sort((a, b) => { // По возрастанию
        if (a > b) return 1;
        else if (a < b) return -1;
        else return 0;
    });

    sizes.width.sort((a, b) => { // По возрастанию
        if (a > b) return 1;
        else if (a < b) return -1;
        else return 0;
    });

    sizes.height.sort((a, b) => { // По возрастанию
        if (a > b) return 1;
        else if (a < b) return -1;
        else return 0;
    });

    let minVolume = sizes.minLength[0] * sizes.width[0] * sizes.height[0];
    sizes.volume.push(Math.round(minVolume * 10) / 10);

    let maxVolume = sizes.minLength[sizes.minLength.length - 1] * sizes.width[sizes.width.length - 1] * sizes.height[sizes.height.length - 1];
    sizes.volume.push(Math.round(maxVolume * 10) / 10);

    return sizes;
}

function preparationData (data) {
    return data.map((tariff) => {
        let sizes = {
            minLength: [],
            length: [],
            width: [],
            height: [],
            volume: []
        };

        let groupsArray = [];

        tariff.length_groups.map((lengthGroups) => {
            sizes.length.push(lengthGroups.group);
            groupsArray.push({
                lengthGroup: lengthGroups.group,
                lengthValues: lengthGroups.lengths
            });
        });

        // Собираем длины машин
        tariff.car_types.map((carType) => {
            carType.sizes.map((size) => {
                if(size.length == 3 && sizes.minLength.indexOf(size[0] === -1)) sizes.minLength.push(size[0]);
                if(size.length == 3 && sizes.width.indexOf(size[1] === -1)) sizes.width.push(size[1]);
                if(size.length == 3 && sizes.height.indexOf(size[2] === -1)) sizes.height.push(size[2]);
            });
        });

        sizes.minLength.sort(sorterASC);
        sizes.width.sort(sorterASC);
        sizes.height.sort(sorterASC);

        let minVolume = sizes.minLength[0] * sizes.width[0] * sizes.height[0];
        sizes.volume.push(Math.round(minVolume * 10) / 10);

        let maxVolume = sizes.minLength[sizes.minLength.length - 1] * sizes.width[sizes.width.length - 1] * sizes.height[sizes.height.length - 1];
        sizes.volume.push(Math.round(maxVolume * 10) / 10);

        return {
            identifier: tariff.identifier,				                // Key
            name: tariff.name,									        // Стандартный
            description: tariffsDescriptions[tariff.identifier].desc,   // Стандартная газель, Crafter, Transit и похожие для перевозки грузов до 3 м.
            descriptionToolTip: tariff.tier_description,		        // Классика перевозок. Подойдёт для перевозки большого количества вещей.
            capacity: tariff.tier_capacity_description, 		        // до 1,5 т
            capacityVolume: tariff.tier_volume_description, 	        // до 12 м³
            capacityLengths: tariff.lengths[tariff.lengths.length - 1], // до 12 м³
            minPrice: tariff.min_price, 						        // 900 руб
            minHours: tariff.minHours, 							        // 1 час
            typeCars: tariffsDescriptions[tariff.identifier].type,      // Стандартная газель, Crafter, Transit и похожие для перевозки грузов до 3 м.
            lengthCars: sizes.length,                                   // Длины машин
            lengthCarsGroups: sizes.length,                             // Длины машин
            widthCars: sizes.width,                                     // Ширина машин
            heightCars: sizes.height,                                   // Высота машин
            volumeCars: sizes.volume,                                   // Высота машин,
            lengthGroups: groupsArray                                   // Группы длин
        };
    });
}

function setGroupLength (tariffs, value) {
    let selectedGroupLength = [], selectedGroupLengthValue = [];

    tariffs.map((tariff) => {
        if(tariff.identifier === value) {
            console.log(tariff);
            tariff.lengthCars.map((lengthCar, index) => {
                tariff.lengthGroups.map((lg) => {
                    if(lg.lengthGroup == lengthCar) {
                        selectedGroupLength.push(lengthCar);
                        selectedGroupLengthValue = [...selectedGroupLengthValue, ...lg.lengthValues];
                    }
                });
            });
        }
    });

    selectedGroupLengthValue = arrayUnique(selectedGroupLengthValue);

    return {selectedGroupLength, selectedGroupLengthValue}
}

async function getUserPosition () {
    let data = new Promise (function(resolve, reject) {
        navigator.geolocation.getCurrentPosition(async (position) => {
            const { latitude, longitude } = position.coords;
            let res = await fetch(SERVER + '/geo/location.json?location=' + latitude + ',' + longitude, headersGET()).then(res => res).catch((err) => err);
            const data = await res.json();

            if(data.formated_address) {
                resolve({data, latitude, longitude });
            } else {
                reject(data);
            }
        });
    });

    return data;
}

async function orderDone (draftId) {
    const res = await fetch(SERVER + '/customer/me/orders/drafts/' + draftId + '/send/sms.json', headersPOST()).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

async function prepareData (req) {
    var resp = {
        tariffs: {},
        prices: {},
        preparedInfo: {}
    };

    let prom = new Promise(async (resolve, reject) => {
        let tariffsArray = await makeRequestTier({
            latitude: req.userData.points[0].adress.latitude,
            longitude: req.userData.points[0].adress.longitude
        });

        resp.tariffs = tariffsArray;

        let _prices = await Promise.all(tariffsArray.map(async (tariff) => {
            let activeTariff = req.userData.tariff.identifier;

            let someData = Object.assign({}, req.userData, {
                tariff: {
                    value: tariff.identifier,
                    capacities: tariff.capacities
                }
            });

            if(tariff.identifier != activeTariff && tariff.length_groups.length > 0) {
                someData.selectedGroupLength = [tariff.length_groups[0].group];
                someData.selectedGroupLengthValue = tariff.length_groups[0].lengths;
            }

            let sizes = calculateSizes(tariff);  // @todo СДЕЛАТЬ

            let res = await makeRequestCalculator(someData);

            let price = {
                identifier          : tariff.identifier,
                name                : tariff.name,
                capacity            : tariff.capacity,
                capacityVolume      : tariff.tier_volume_description,                           // до 12 м³
                capacityLengths     : tariff.lengths[tariff.lengths.length - 1],                // до 12 м³
                description         : tariffsDescriptions[tariff.identifier].desc,
                typeCars            : tariffsDescriptions[tariff.identifier].type,
                lengthCars          : sizes.length,                                             // Длины машин
                widthCars           : sizes.width,                                              // Ширина машин
                heightCars          : sizes.height,                                             // Высота машин
                volumeCars          : sizes.volume                                              // Объем машины
            };

            return Object.assign(res, price);
        }));

        let prices = {};

        _prices.map((price) => {
            prices[price.identifier] = price;
        });

        resp.prices = prices;
        resp.preparedInfo = req.userData;

        resolve(resp);
    });

    return prom;
}

async function makeRequestTier (req) {
    let cacheKey = makeTiersCacheKey(req);
    if(isValidTiersCache(cacheKey)) {
        return getTiersFromCache(cacheKey);
    }

    const res = await fetch(SERVER + '/tariff/tier.json?lat=' + req.latitude + '&lon=' + req.longitude, headersGET()).then(res => res).catch((err) => err);
    const result = await res.json();

    if(result.success) {
        updateTiersCache(cacheKey, result.tiers);
        return result.tiers;
    }

    return [];
}

function makeTiersCacheKey(req) {
    return `${req.latitude},${req.longitude}`;
}

function isValidTiersCache(key) {
    if(!tiersCache[key])
        return false;

    // если последний запрос был более чем 5 минут назад, то считаем, что данные устарели
    return tiersCache[key] ? new Date().getTime() - tiersCache[key]['time'] < 300000 : false;
}

function updateTiersCache(key, tiers) {
    tiersCache[key] = {
        time: new Date().getTime(),
        tiers
    }
}

function getTiersFromCache(key) {
    return tiersCache[key]['tiers'];
}

async function searchStart (draftId, isStart) {
    let res = await fetch(SERVER + '/customer/me/orders/drafts/' + draftId + '/taxi/search.json', headersPOST()).then(res => res).catch((err) => err);
    return await res.json();
}

async function searchStop (draftId) {
    const res = await fetch(SERVER + '/customer/me/orders/drafts/' + draftId + '/taxi/search/stop.json', headersPOST()).then(res => res).catch((err) => err);
    return await res.json();
}

async function uploadFile (draftId, photo) {
    const formData  = new FormData();
    formData.append('photo', photo);

    const res = await fetch(SERVER + '/customer/me/orders/drafts/' + draftId + '/photos.json', {
        method: 'POST',
        credentials: 'include',
        body: formData
    }).then(res => res).catch((err) => err);

    return await res.json();
}

// Подтверждение выбора водителя клиентом
export async function searchDone (draftId, isAccepted) {
    let type = isAccepted ? 'accepted' : 'declined';

    const res = await fetch(SERVER + '/customer/me/orders/' + draftId + '/status.json?status=' + type, headersPUT())
    return await res.json();
};

async function makeRequestCalculator (data, cb) {
	let capacities = data.tariff.capacities;

	let searchParams = await prepareRequestCalculator(data, 'makeRequestCalculator');

	let url = SERVER + '/orders/calculator/taxi.json';
	let res = await fetch(url, headersPOST(searchParams)).then(res => res).catch((err) => err);
	const result = await res.json();

	return result.success ? Object.assign({capacities}, result) : {};
}

async function prepareRequestCalculator(data, type) {
	let tipKuzova = [];

	let tiers = await makeRequestTier({
        latitude: data.points[0].adress.latitude,
        longitude: data.points[0].adress.longitude
	});

	let description = '';
    if (type === 'createDraft') {
        let sCode = JSON.parse(localStorage.getItem('testCode'));

        if (sCode && sCode.testCode) description += sCode.testCode + '\r\n\r\n';
    }

	const searchParams = new URLSearchParams();
	let selectedGroupLengths = data.selectedGroupLength.join(',');

	tiers.map((tier) => {
		if(tier.identifier === data.tariff.value) {
			tier.car_types.map((carType) => {
				if(carType.default) tipKuzova.push(carType.type)
			})
		}
	});

	searchParams.append('features[capacity][value]', data.tariff.capacities);
	searchParams.append('features[Tip_kuzova][value]', tipKuzova.join(','));
	searchParams.append('payMethod', data.paymentType);
	searchParams.append('assembly', data.advanceService.assembly);
	searchParams.append('avoidTtk', false);
	searchParams.append('taxi', true);
	searchParams.append('autoAcceptTaxi', true);
	searchParams.append('nearest', 0);
	searchParams.append('points', 0);
	searchParams.append('loaders', data.stevedores.value);
    searchParams.append('extra[tier]', data.tariff.value);
    searchParams.append('extra[lengthGroups]', selectedGroupLengths);

	let allDefault = (data.points && data.points.length > 0) ? data.points.every(p => p.defaultValue) : true;

	if(!allDefault) {
        data.points.map((point, index) => {
            if(index === 0) {
                searchParams.append('from[lon]', point.adress.longitude);
                searchParams.append('from[lat]', point.adress.latitude);
                searchParams.append('from[addr]', point.adress.formated_address);
                searchParams.append('from[elevator]', point.lift > 0 ? 1 : 0);
                searchParams.append('from[floor]', point.floor);
                searchParams.append('from[loading]', 1);

                searchParams.append('from[lifting]', data.advanceService.lifting);
                searchParams.append('from[contactPhone]', point.contactPhone);
                searchParams.append('from[contactName]', point.contactName);
                if(data.arrivalTime) searchParams.append('from[arrivalTime]', data.arrivalTime);
            } else {
                searchParams.append('to[' + (index - 1) + '][lon]', point.adress.longitude);
                searchParams.append('to[' + (index - 1) + '][lat]', point.adress.latitude);
                searchParams.append('to[' + (index - 1) + '][addr]', point.adress.formated_address);
                searchParams.append('to[' + (index - 1) + '][loading]', (point.typePoint === "Точка погрузки" ? 1 : 0));
                searchParams.append('to[' + (index - 1) + '][unloading]', (point.typePoint === "Точка разгрузки" ? 1 : 0));
                searchParams.append('to[' + (index - 1) + '][floor]', point.floor);
                searchParams.append('to[' + (index - 1) + '][elevator]', point.lift > 0 ? 1 : 0);

                searchParams.append('to[' + (index - 1) + '][lifting]', data.advanceService.lifting);
                searchParams.append('to[' + (index - 1) + '][contactPhone]', point.contactPhone);
                searchParams.append('to[' + (index - 1) + '][contactName]', point.contactName);
            }

            // Определяем тип
            if (type === 'createDraft') {
                if(point.adress && !point.defaultValue) {
                    let lift;

                    switch (point.lift) {
                        case 0:
                            lift = 'отсутствует';

                            break;
                        case 1:
                            lift = 'пассажирский';

                            break;
                        case 2:
                            lift = 'грузовой';

                            break;
                        case 3:
                            lift = 'пассажирский и грузовой';

                            break;
                    }

                    description += 'По адресу №' + (index + 1) + ' лифт: ' + lift + '\r\n';
                }
            }
        });
    } else {
		searchParams.append('from[lon]', 37.455598);
		searchParams.append('from[lat]', 55.726650);
		searchParams.append('from[addr]', 'Точка погрузки');
		searchParams.append('from[loading]', 1);
		searchParams.append('from[elevator]', 0);
		searchParams.append('from[floor]', 0);
		searchParams.append('from[lifting]', data.advanceService.lifting);
		searchParams.append('from[contactPhone]', '');
		searchParams.append('from[contactName]', '');
	}

	// Определяем тип
	if (type === 'createDraft') {
		data.goods.map((good, index) => {
			description += 'ГРУЗ ' + (index + 1) + ': ' + good.goodType + '\r\n'+
				'Комментарий к грузу: ' + good.comment + '\r\n\r\n';
		});

		searchParams.append('comment', description);
	}

	searchParams.append(
	    'cargoStorage',
        data.goodsServices && data.goodsServices.storage && data.goodsServices.storage.active ? '1' : '0'
    )

	return searchParams;
}

async function createDraft (req, isUpdate) {
	let searchParams = await prepareRequestCalculator(req, 'createDraft');

    let photos = [];
    req.goods.map((good) => {
        if(good.photos.length > 0) photos.push(good.photos[0].file);
    });

	let url = SERVER + (isUpdate ? ('/customer/me/orders/drafts/' + req.curDraft + '.json') : '/customer/me/orders/drafts.json');
    const res = await fetch(url, headersPOST(searchParams)).then(res => res).catch((err) => err);
    const result = await res.json();

    if(!isUpdate) req.curDraft = result.id;

    photos.map(async (photo) => {
        await uploadFile(req.curDraft, photo);
    });

    return result.success ? { draftId: req.curDraft } : {}
};


async function stopOrder(req) {
    // Стоп для админов и сапорта, клиентам доступно только до приезда машины.
    const res = await fetch(SERVER + '/' + req.role + '/' + req.type + '/orders/' + req.orderId + '/cancel.json?reason=cancel', headersPOST()).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

async function editOrderStatus(req) {
    // Стоп для админов и сапорта, клиентам доступно только до приезда машины.
    const res = await fetch(SERVER + '/' + req.role + '/' + req.type + '/orders/' + req.orderId + '/status.json', headersPUT({status: req.status})).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

async function getPausePlayOrder(req) {
    // Только для саппорта и админов
	const res = await fetch(SERVER + '/customer/me/orders/' + req.pausePlayOrderId + '/period/0/taximeter.json?state=' + req.pausePlayOrderStatus, headersPUT()).then(res => res).catch((err) => err);
	const data = await res.json();

	return data;
}

export default {
    // Постоянные методы
    tariffsDescriptions: tariffsDescriptions,
    preparationData: preparationData,
    setGroupLength: setGroupLength,
    getUserPosition: getUserPosition,
    orderDone: orderDone,
    prepareData: prepareData,
    makeRequestCalculator: makeRequestCalculator,
    makeRequestTier: makeRequestTier,
    searchStart: searchStart,
    searchStop: searchStop,
    uploadFile: uploadFile,

    searchDone: searchDone,
    createDraft: createDraft,
    stopOrder: stopOrder,
    editOrderStatus: editOrderStatus,
	getPausePlayOrder: getPausePlayOrder,

}
