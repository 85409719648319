import React from "react";
import { ymElem }                       from '../utils/helpers';

export default class HelpCenter extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			flagSteps: 1
		}
	}

	setStepNav(val) {
		this.setState({
			flagSteps: val
		})
	};

    openIntercom = () => {
        // window.Intercom('show');

		if(window.jivo_api)
			window.jivo_api.open();
    };

    render() {

		const { lang } = this.props;

        return (
			<div className="s-index-support">
				<div className="container">
					<div className="tab-title">
						<h2 className="s-index__title t-left"><i className="icon"></i>{lang.Index_support_title}</h2>

						<ul className="list-unstyled clearfix type-list">
							<li className={this.state.flagSteps === 1 ? "active" : null} onClick={this.setStepNav.bind(this, 1)}><span>{lang.StepsRoad_list_tab_1}</span></li>
							<li className={this.state.flagSteps === 2 ? "active" : null} onClick={this.setStepNav.bind(this, 2)}><span>{lang.StepsRoad_list_tab_2}</span></li>
							<li className={this.state.flagSteps === 3 ? "active" : null} onClick={this.setStepNav.bind(this, 3)}><span>{lang.StepsRoad_list_tab_3}</span></li>
						</ul>
					</div>

					<div className="index-support-cnt">

						{this.state.flagSteps === 1 ? (
							<div className="index-support-list row">
								<div className="index-support-item col">
									<div className="img">
										<div className="img-icon book"/>
									</div>
									<div className="cnt">
										<h3 className="title"><a href="http://help.mover24.ru/для-частных-заказчиков" target="_blank" className="link">{lang.Index_support_list_1_1_title}</a></h3>
										<p className="desc">{lang.Index_support_list_1_1_desc}</p>
									</div>
								</div>

								<div className="index-support-item col">
									<div className="img">
										<div className="img-icon faq"/>
									</div>
									<div className="cnt">
										<h3 className="title"><a href="http://help.mover24.ru/%D1%87%D0%B0%D1%81%D1%82%D0%BE-%D0%B7%D0%B0%D0%B4%D0%B0%D0%B2%D0%B0%D0%B5%D0%BC%D1%8B%D0%B5-%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%D1%8B" target="_blank" className="link">{lang.Index_support_list_1_2_title}</a></h3>
										<p className="desc" dangerouslySetInnerHTML={{__html: lang.Index_support_list_1_2_desc}}/>
									</div>
								</div>

								<div className="index-support-item col">
									<div className="img">
										<div className="img-icon chat"/>
									</div>
									<div className="cnt">
										<h3 className="title link-open-intercom" onClick={this.openIntercom}>{lang.Index_support_list_1_3_title}</h3>
										<p className="desc">
											{lang.Index_support_list_1_3_desc}
										</p>
									</div>
								</div>

								<div className="index-support-item col">
									<div className="img">
										<div className="img-icon hotline"/>
									</div>
									<div className="cnt">
										<h3 className="title link-open-intercom" onClick={this.openIntercom}>{lang.Index_support_list_1_4_title}</h3>
										<p className="desc">{lang.Index_support_list_1_4_desc}</p>
									</div>
								</div>
							</div>
						) : null}

						{this.state.flagSteps === 2 ? (
							<div className="index-support-list row">
								<div className="index-support-item col">
									<div className="img">
										<div className="img-icon book"/>
									</div>
									<div className="cnt">
										<h3 className="title"><a href="http://help.mover24.ru/для-частных-заказчиков" target="_blank" className="link">{lang.Index_support_list_2_1_title}</a></h3>
										<p className="desc">{lang.Index_support_list_2_1_desc}</p>
									</div>
								</div>

								<div className="index-support-item col">
									<div className="img">
										<div className="img-icon faq"/>
									</div>
									<div className="cnt">
										<h3 className="title"><a href="http://help.mover24.ru/%D1%87%D0%B0%D1%81%D1%82%D0%BE-%D0%B7%D0%B0%D0%B4%D0%B0%D0%B2%D0%B0%D0%B5%D0%BC%D1%8B%D0%B5-%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%D1%8B" target="_blank" className="link">{lang.Index_support_list_2_2_title}</a></h3>
										<p className="desc">{lang.Index_support_list_2_2_desc}</p>
									</div>
								</div>

								<div className="index-support-item col">
									<div className="img">
										<div className="img-icon chat"/>
									</div>
									<div className="cnt">
										<h3 className="title link-open-intercom" onClick={this.openIntercom}>{lang.Index_support_list_2_3_title}</h3>
										<p className="desc" dangerouslySetInnerHTML={{__html: lang.Index_support_list_2_3_desc}}/>
									</div>
								</div>

								<div className="index-support-item col">
									<div className="img">
										<div className="img-icon hotline"/>
									</div>
									<div className="cnt">
										<h3 className="title link-open-intercom" onClick={this.openIntercom}>{lang.Index_support_list_2_4_title}</h3>
										<p className="desc" dangerouslySetInnerHTML={{__html: lang.Index_support_list_2_4_desc}}/>
									</div>
								</div>
							</div>
						) : null}

						{this.state.flagSteps === 3 ? (
							<div className="index-support-list row">
								<div className="index-support-item col">
									<div className="img">
										<div className="img-icon book"/>
									</div>
									<div className="cnt">
										<h3 className="title"><a href="http://help.mover24.ru/для-частных-заказчиков" target="_blank" className="link">{lang.Index_support_list_3_1_title}</a></h3>
										<p className="desc">{lang.Index_support_list_3_1_desc}</p>
									</div>
								</div>

								<div className="index-support-item col">
									<div className="img">
										<div className="img-icon faq"/>
									</div>
									<div className="cnt">
										<h3 className="title"><a href="http://help.mover24.ru/%D1%87%D0%B0%D1%81%D1%82%D0%BE-%D0%B7%D0%B0%D0%B4%D0%B0%D0%B2%D0%B0%D0%B5%D0%BC%D1%8B%D0%B5-%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%D1%8B" target="_blank" className="link">{lang.Index_support_list_3_2_title}</a></h3>
										<p className="desc">{lang.Index_support_list_3_2_desc}</p>
									</div>
								</div>

								<div className="index-support-item col">
									<div className="img">
										<div className="img-icon chat"/>
									</div>
									<div className="cnt">
										<h3 className="title link-open-intercom" onClick={this.openIntercom}>{lang.Index_support_list_3_3_title}</h3>
										<p className="desc">
											{lang.Index_support_list_3_3_desc}
										</p>
									</div>
								</div>

								<div className="index-support-item col">
									<div className="img">
										<div className="img-icon hotline"/>
									</div>
									<div className="cnt">
										<h3 className="title link-open-intercom" onClick={this.openIntercom}>{lang.Index_support_list_3_4_title}</h3>
										<p className="desc">{lang.Index_support_list_3_4_desc}</p>
									</div>
								</div>
							</div>
						) : null}

					</div>

					<div className="index-support">
						<h3 className="subtitle">{lang.Index_support_subtitle}</h3>
						<p className="desc">
							{lang.Index_support_desc}
						</p>
						<div className="more-btn">
							<a href="http://help.mover24.ru" target='_blank' className="btn btn-primary" onClick={() => ymElem('extLink', 'http://help.mover24.ru')}>{lang.StepsRoad_more_btn_2}<i className="icon"></i></a>
						</div>
					</div>
				</div>
			</div>
        );
    }
}