import React from 'react';
import cx from "classnames";

export default class CalculatorStep5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { lang, isActive } = this.props;

        const itemCSS = cx({
            'calculator-content': true,
            'step6': true,
            'active': isActive,
        });

        return (
            <div className={itemCSS} >
                <h2 className="title">{lang.CalcStep6_title}</h2>

				<div className="payment-wrap">
					<ul className="list-unstyled payment-total-list">
						<li>
							<div className="info">
								<span className="title">{lang.CalcStep6_info_1_title}</span>
								<span className="city">Москва</span>
							</div>
							<span className="price">990 Р</span>
						</li>
						<li>
							<div className="info">
								<span className="title">1 {lang.CalcStep6_info_2_title}</span>
								<span className="city">Москва</span>
							</div>
							<span className="price">570 Р</span>
						</li>
						<li>
							<div className="info">
								<span className="title">Ленинский просп., 53с2</span>
								<span className="city">Москва</span>
							</div>
							<span className="price">570 Р</span>
						</li>
					</ul>

					<div className="payment-total-sum">
						<div className="info">
							<span className="title">{lang.CalcStep6_total_title}</span>
							<span className="desc">{lang.CalcStep6_total_desc}</span>
						</div>
						<span className="price">1990 Р</span>
					</div>
				</div>
            </div>
        );
    }
}