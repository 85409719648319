import { IMaskInput }           from 'react-imask';
import React                    from 'react';
import Notifications            from '../API/notifications';
import { stopEvent, ymElem }    from '../utils/helpers';
import { trackEvent }                       from '../utils/tracking'

class BusinessControl extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            myPhone: '',
            errors: null
        };

        this.error400 = <span className="form-help t-error">{props.lang.BusinessNoNumber}</span>;
        this.error601 = <span className="form-help t-error">{props.lang.BusinessPutNumber}</span>;
    }

    handleKeyDown = (event) => {
        event = event || window.event;
        const keyCode = event.keyCode;

        if(keyCode === 13) {
            this.handleSubmit(event);
        }
    };

    handleMyPhoneChange = ({ target = {} }) => {
        const { value = '' } = target;

        this.setState({myPhone: value});
    };

    handleSubmit = (e) => {
        let { myPhone } = this.state;

        stopEvent(e);

        if(myPhone.length < 11) {
            this.setState({ errors: 601 });
            return
        }

        // this.props.sendPhone(myPhone);
    };

    render() {
        const { lang } = this.props;
        const { errors } = this.state;

        let error400 = errors && errors === 400 ? this.error400 : null;
        let error601 = errors && errors === 601 ? this.error601 : null;

        return (
            <div className="s-index-business s-business-business t-2">
                <div className="container">
                    <div className="row">
                        <div className="col-7">
                            <div className="index-business">
                                <h2 className="s-index__title t-left t-white">{lang.BusinessHead}</h2>
                                <p className="desc">{lang.BusinessText}
                                </p>
                                <div className="index-callback-form">
									<div className="form-group">
										<button type="button" className="btn btn-primary" onClick={() => trackEvent('business_form', this.props.openbussiness)}>{lang.BusinessBtn}</button>
									</div>
									{/*<form action="/" method="post" className="form row">
                                        <div className="form-group col-8">
                                            <IMaskInput
                                                id='userphone'
                                                tabIndex='0'
                                                onKeyDown={this.handleKeyDown}
                                                mask='+{7} (000) 000 00 00'
                                                unmask={true}
                                                className='form-control'
                                                onAccept={
                                                    // depending on prop above first argument is
                                                    // `value` if `unmask=false`,
                                                    // `unmaskedValue` if `unmask=true`,
                                                    // `typedValue` if `unmask='typed'`
                                                    (value, mask) => {
                                                        this.handleMyPhoneChange(value)
                                                    }
                                                }
                                                placeholder={lang.Index_callback_1_placeholder}
                                                value={this.state.myPhone || ''}
                                            />

                                            {error400}
                                            {error601}
                                        </div>
                                        <div className="form-group col-4">
                                            <button type="submit" disabled="disabled" className="btn btn-block btn-primary">{lang.Index_callback_1_btn}</button>
                                        </div>
                                    </form>*/}
                                    <div className="hint-form">{lang.Business24Connect}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="index-business-list row">
                                <div className="index-business-item col-6">
                                    <div className="img">
										<div className="img-icon docs"/>
                                    </div>
                                    <div className="cnt">
                                        <h5 className="title">{lang.BusinessCntTitle1}</h5>
                                        <p className="desc">{lang.BusinessCntDesc1}</p>
                                    </div>
                                </div>

                                <div className="index-business-item col-6">
                                    <div className="img">
										<div className="img-icon timer"/>
                                    </div>
                                    <div className="cnt">
                                        <h5 className="title">{lang.BusinessCntTitle2}</h5>
                                        <p className="desc">{lang.BusinessCntDesc2}</p>
                                    </div>
                                </div>

                                <div className="index-business-item col-6">
                                    <div className="img">
										<div className="img-icon cards"/>
                                    </div>
                                    <div className="cnt">
                                        <h5 className="title">{lang.BusinessCntTitle3}</h5>
                                        <p className="desc">{lang.BusinessCntDesc3}</p>
                                    </div>
                                </div>

                                <div className="index-business-item col-6">
                                    <div className="img">
										<div className="img-icon pro"/>
                                    </div>
                                    <div className="cnt">
                                        <h5 className="title">{lang.BusinessCntTitle4}</h5>
                                        <p className="desc">{lang.BusinessCntDesc4}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default BusinessControl;
