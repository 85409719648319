export class DateUtils {
    static roundMinutesToHour(minutes) {
        let hours = Math.floor(minutes / 60);
        if(minutes - hours * 60 > 10)
            hours ++;

        return hours;
    }

    static minutesToDisplay(minutes, long) {
        minutes = Math.round(minutes);

        let displayHours = Math.floor(minutes / 60);
        let displayMinutes = minutes - displayHours * 60;

        let components = [];
        if(displayHours > 0)
            components.push(`${displayHours} ч`);

        if(displayMinutes > 0) {
            components.push( `${displayMinutes} ${long ? 'мин.' : 'м.'}`);
        }

        return components.join(' ');
    }
}