import React                    from 'react';
import isServer from "detect-node";
import Calculator from "../API/calculator";

export default class BusinessBottom extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        }
    }

    componentDidMount() {
        if(!isServer && window.localStorage.getItem('bussinesClosed') === null) {
            this.setState({ show: true });
        }
    }

    closeAction = () => {
        this.setState({ show: false });
        localStorage.setItem('bussinesClosed', JSON.stringify(true));
    };

    render() {
        return this.state.show ? (
            <div className="business-driver">
                <div className="container-fluid">
					<div className="inner">
						<div onClick={this.closeAction} className="close"/>
						<div className="text">У вас много заказов? Сократите транспортные расходы с MOVER</div>
						<div className="buttons">
							<a href="/business" className="btn btn-primary">
								Подключить
								<i className="icon"/>
							</a>
						</div>
					</div>
                </div>
            </div>
        ) : null;
    }
};
