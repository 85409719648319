'use strict';
import isServer from 'detect-node';

import RU from '../languages/ru.js';
import EN from '../languages/en.js';

const langLibs = {
    ru: RU,
    en: EN
};

if (!isServer && window.localStorage.getItem('moverLang') === null) {
    window.localStorage.setItem('moverLang', 'ru');
}

const currLang = isServer ? 'ru' : (localStorage.getItem('moverLang') || 'ru');

export default {
    state: {
        ...langLibs[currLang],
        langLibs
    },
    reducers: {
        setLang: (state, lang) => {
            let newLib = langLibs[lang];

            (!isServer ? localStorage.setItem('moverLang', lang) : null);

            return {
                ...newLib
            };
        }
    }
}