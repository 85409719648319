import Link         from 'next/link';
import { ymElem }   from '../utils/helpers';

const Press = ({ lang }) => {
    return (
        <div className="s-index-press">
            <div className="container">
                <h2 className="s-index__title">{lang.Index_press_title}</h2>

                <div className="index-delivery-partners">
                    <ul className="index-delivery-partners-list t-press list-unstyled">
                        <li>
                            <a href="https://vc.ru/30269-taymlayn-mover" target="_blank">
                                <img src="/static/img/press/vc_ru.png" alt="" width="68" height="66"/>
                            </a>
                        </li>
                        <li>
                            <a href="http://apps4all.ru/post/04-29-16-mover24-mobilnyj-gorodskoj-servis-po-gruzoperevozkam" target="_blank">
                                <img src="/static/img/press/appsforall_act.png" alt="" width="190" height="73"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://biz360.ru/materials/rynok-gruzovykh-taksi-zhdut-bolshie-peremeny" target="_blank">
                                <img src="/static/img/press/biz360_act.png" alt="" width="159" height="44"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://rb.ru/opinion/story-to-inspire" target="_blank">
                                <img src="/static/img/press/rb_ru.svg" alt="" width="145" height="40"/>
                            </a>
                        </li>
                    </ul>
                    <ul className="index-delivery-partners-list t-press list-unstyled">
                        <li>
                            <a href="https://dvizhok.su/parts/shinyi-michelin-mozhno-kupit-s-ekspress-dostavkoj" target="_blank">
                                <img src="/static/img/press/dvijok.png" alt="" width="200" height="50"/>
                            </a>
                        </li>
                        <li>
                            <a href="http://www.the-village.ru/village/city/city/260794-takie-dela" target="_blank">
                                <img src="/static/img/press/village.png" alt="" width="150" height="80"/>
                            </a>
                        </li>
                        <li>
                            <a href="http://business.truckandroad.ru/mover-michelin-plany-razvitiya-20180426" target="_blank">
                                <img src="/static/img/press/GID_logo_w_n.png" alt="" width="200" height="51"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Press;