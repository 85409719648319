import fetch                                                    from 'isomorphic-unfetch';
import { SERVER }                                               from '../config';
import {
    headersGET,
    headersPOST,
    headersDELETE,
    headersPUT,
    setCookie,
    deleteCookie
}                                                               from '../utils/helpers';

async function getOrders (req) {
    let urlData = '';

    if(req.filters) {
        if(req.filters.phone)            urlData += (urlData ? '&' : '?') + 'client[phone]=' + req.filters.phone;
        if(req.filters.calendar)         urlData += (urlData ? '&' : '?') + 'period[start]=' + req.filters.calendar[0];
        if(req.filters.calendar)         urlData += (urlData ? '&' : '?') + 'period[end]=' + req.filters.calendar[1];
        if(req.filters.orderId)          urlData += (urlData ? '&' : '?') + 'search=' + req.filters.orderId;
        if(req.filters.status.length)    urlData += (urlData ? '&' : '?') + 'status=' + req.filters.status.join(',');
    }

    const res = await fetch(SERVER + '/' + req.role + '/' + req.type + '/orders.json' + urlData, headersGET(req.token)).then(res => res).catch((err) => err);
    const data = await res.json();

    return data;
}

async function getOrder (req) {
	// @Todo проверить кастомер ми

	const res = await fetch(SERVER + '/' + req.role + '/' + req.type + '/orders/' + req.orderId + '.json?withCalculation=1', headersGET(req.token)).then(res => res).catch((err) => err);
	const data = await res.json();

	return data;
}

export async function getOrderAgreedDriver (draftId) {
    const res = await fetch(SERVER + '/customer/me/orders/drafts/' + draftId + '/agreed.json', headersGET()).then(res => res).catch((err) => err);
    return await res.json();
}

async function declinedOrder (orderId) {
    const res = await fetch(SERVER + '/customer/me/orders/' + orderId + '/status.json?status=declined', headersPUT()).then(res => res).catch((err) => err);
    return await res.json();
}

async function acceptedOrder (orderId) {
    const res = await fetch(SERVER + '/customer/me/orders/' + orderId + '/status.json?status=accepted', headersPUT()).then(res => res).catch((err) => err);
    return await res.json();
}

async function getOrderByHash (data) {
    const res = await fetch(SERVER + '/orders/' + data.hash + '.json', headersGET({token :null})).then(res => res).catch((err) => err);
    return await res.json();
}

async function postOrderRating(orderId, rating, comment) {
    const requestParams = new URLSearchParams();
    requestParams.append('rating[service_quality]', rating);
    if(comment)
        requestParams.append('comment', comment);

    const res = await fetch(`${SERVER}/customer/me/orders/${orderId}/rating.json`, headersPOST(requestParams));
    return await res.json();
}

export default {
    // Постоянные методы
    getOrders: getOrders,
    getOrder: getOrder,
    getOrderAgreedDriver: getOrderAgreedDriver,


    // Одобрить водителя
    declinedOrder: declinedOrder,
    // Отклонить водителя
    acceptedOrder: acceptedOrder,
    getOrderByHash: getOrderByHash,
    // Поставить оценку
    postOrderRating: postOrderRating
}
