import React        from 'react';
import moment       from 'moment';
import DatePicker   from 'react-datepicker';
import cx           from 'classnames';
import isServer     from 'detect-node';

if(!isServer) window.moment = moment;

export default class CalculatorHeader extends React.Component {
    constructor(props) {
        super(props);
        moment.locale('ru');

        this.state = {
            time1: moment().add('30', 'minutes').format('HH:mm'),
            time2: moment().add('45', 'minutes').format('HH:mm'),
            isOpen: false,
            curDate: props.data.arrivalTime ? moment(props.data.arrivalTime).format('DD.MM.YYYY') : null,
            arrivalTime: props.data.arrivalTime ? moment(props.data.arrivalTime) : moment(),
            isFinish: props.isFinish
        };

        this.timer = setInterval(()=>{
            this.setState({
                time1: moment().add('30', 'minutes').format('HH:mm'),
                time2: moment().add('45', 'minutes').format('HH:mm'),
            });
        }, 60000);

        this.format = 'YYYY-MM-DDTHH:mm:ss';
    }

    componentWillReceiveProps(newProps) {
        if (newProps.isFinish) this.setState({isFinish: newProps.isFinish});
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    searchStop = () => {
        this.props.searchStop(this.props.draftId);
    };

    handleChange = (date) => {
        this.setState({arrivalTime: date});
    };

    toggleCalendar = (e) => {
        e && e.preventDefault();
        this.setState({isOpen: !this.state.isOpen});
    };

    saveCalendar = async (e) => {
        e && e.preventDefault();
        this.setState({isOpen: !this.state.isOpen, curDate: moment(this.state.arrivalTime).format('DD.MM.YYYY')});

        await this.props.updateData({ arrivalTime: moment(this.state.arrivalTime).format(this.format)}, 'arrivalTime');
    };

    resetCalendar = async (e) => {
        e && e.preventDefault();

        this.setState({
            isOpen: !this.state.isOpen,
            curDate: null
        });

        await this.props.updateData({ arrivalTime: null}, 'arrivalTime');
    };


    render() {
        const { lang, draftId } = this.props;
        const { time1, time2, curDate, isFinish, arrivalTime } = this.state;
        let headerDate = null;

        let wrapperCSS = cx({
            'сalculator-header': !draftId,
            'calculator-order-header': draftId,
        });

        if(!curDate) {
            headerDate = (
                <div className="col-sm-9 title">
					<b>{lang.CalculatorHeaderTitle}</b> <span className='open-calendar-icon' onClick={this.toggleCalendar}/> <span
                    className='open-calendar' onClick={this.toggleCalendar}>{lang.CalculatorHeaderTitle2}</span> c <span
                    id="calculatorHeaderTimeStart" className='open-calendar' onClick={this.toggleCalendar}>{time1}</span> до <span
					id="calculatorHeaderTimeFinish" className='open-calendar' onClick={this.toggleCalendar}>{time2}</span>
                </div>)
        } else {
            let time1 = moment(arrivalTime).format('HH:mm');
            let time2 = moment(arrivalTime).add('15', 'minutes').format('HH:mm');

            headerDate = (
                <div className="col-sm-9 title">
                    <b>{lang.CalculatorHeaderTitle}</b> <span className='open-calendar-icon' onClick={this.toggleCalendar}/> <span
                    className='open-calendar' onClick={this.toggleCalendar}>{curDate}</span> c <span
                    id="calculatorHeaderTimeStart" className='open-calendar' onClick={this.toggleCalendar}>{time1}</span> до <span
                    id="calculatorHeaderTimeFinish" className='open-calendar' onClick={this.toggleCalendar}>{time2}</span>
                </div>)
        }


        return (
            <div className={wrapperCSS}>
                { draftId ? /*!isFinish ? (
                    <div className="column-item">
                        <div className="buttons">
                            <button type="button" className="btn btn-outline-secondary" onClick={this.searchStop}>{lang.CalcHeader_btn_2}</button>
                        </div>
                    </div>
                ) :*/ (
                    <div className="column-item">
                        <div className="buttons">
                            <button type="button" className="btn btn-outline-secondary" onClick={this.props.closeModal}>Закрыть</button>
                        </div>
                    </div>
                ) : (
                    <div className="row">{headerDate}</div>
                )}

                <div>
                    {this.state.isOpen ? (
                        <DatePicker
                            selected={this.state.arrivalTime}
                            onChange={this.handleChange}
                            showTimeSelect
                            timeCaption="Время"
                            timeFormat="HH:mm"
                            minDate={moment()}
                            maxDate={moment().add(30, "days")}
                            shouldCloseOnSelect={true}
                            inline >
                            <div className='quicButtons'>
                                <button onClick={this.resetCalendar} id="calculatorHeaderCalendarBtnReset" className='btn btn-primary'>Сбросить</button>
                                <button onClick={this.saveCalendar} id="calculatorHeaderCalendarBtnSave" className='btn btn-primary'>Сохранить</button>
                            </div>
                        </DatePicker>
                    ) : null}
                </div>
            </div>
        );
    }
}
