import React from 'react';
import classnames from "classnames";
import { IMaskInput } from 'react-imask';

export default class DropContact extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isMe: false,
            contactName: props.contactName || '',
            contactPhone: props.contactPhone || '',
		};
	}

    setContact = () => {
		this.setState({
			isMe: true
		})
	};

    setName = ({ target = {} }) => {
        const { value = '' } = target;
        const { contactName } = this.state;

        if(contactName !== value) {
            this.setState({ contactName: value }, async () => {
                await this.props.setAdressInfo({ contactName: value });
            });
        }
    };

    setPhone = (value) => {
        const { contactPhone } = this.state;

        if(contactPhone !== value) {
            this.setState({ contactPhone: value }, async () => {
                await this.props.setAdressInfo({ contactPhone: value });
            });
        }
    };

    render() {
        let { lang, index } = this.props;
        let dropContacts = classnames({
			'drop-form': true,
			'show': !this.state.isMe
		});

        let btnCSS = classnames({
			'btn': true,
			'btn-block': true,
			'btn-primary': true,
			'is-active': !this.state.isMe
		});

        return (
			<div className="drop-wrapper contacts">
				<div id={"calculatorDropContactWrapper" + index} className="drop-inner">
					<div className="drop-contacts row">
						<div className="col">
							<div className="user">
								<span className="title">Контактное лицо</span>
								{/*<span className="subtitle">По умолчанию это Вы</span>*/}
							</div>
						</div>
					</div>

					<div className={dropContacts}>
						<div className="form-group">
							<input type="text" id={"calculatorDropContactInput" + index + "_0"} onChange={this.setName} value={ this.state.contactName } className="form-control" placeholder="Имя контактного лица"/>
						</div>
						<div className="form-group">
                            <IMaskInput
								id={"calculatorDropContactInput" + index + "_1"}
                                mask='+{7} (000) 000 00 00'
                                unmask={true}
                                className="form-control"
                                onAccept={
                                    // depending on prop above first argument is
                                    // `value` if `unmask=false`,
                                    // `unmaskedValue` if `unmask=true`,
                                    // `typedValue` if `unmask='typed'`
                                    (value, mask) => {
                                        this.setPhone(value)
                                    }
                                }
                                placeholder='Телефон контактного лица'
                                value={this.state.contactPhone || ''}
                            />

						</div>
						<div className="form-group t-button">
							<button type="button" id={"calculatorDropContactBtn" + index} className="btn btn-primary" onClick={this.props.dropContactClose}>Готово</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

}